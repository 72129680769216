import React from 'react';

import { NavLogo } from '../Navigation/NavLogo';
import { NavLeft } from './NavLeft';
import { NavRight } from './NavRight';

import { config, blankLink } from 'config';
import { useAppRootActions, useAppRootState } from 'App/root/context';

export const NavBar: React.FC = ( props ) => {
  const { menu: menuActions } = useAppRootActions();
  const { menu } = useAppRootState();

  let headerClass = [ 'navbar', 'pcoded-header', 'navbar-expand-lg', config.headerBackColor ];
  if ( config.headerFixedLayout ) {
    headerClass = [ ...headerClass, 'headerpos-fixed' ];
  }

  let toggleClass = [ 'mobile-menu' ];
  if ( menu.collapseMenu ) {
    toggleClass = [ ...toggleClass, 'on' ];
  }
  return (
    <header className={ headerClass.join( ' ' ) }>
      <div className="m-header">
        <a
          className={ toggleClass.join( ' ' ) }
          id="mobile-collapse1"
          href={ blankLink }
          onClick={ menuActions.collapseMenu }
        >
          <span></span>
        </a>
        <NavLogo
          collapseMenu={ menu.collapseMenu }
          onToggleNavigation={ menuActions.collapseMenu }
          logoImageSite={ config.logoImageSite }
          titleSite={ config.titleSite }
        />
      </div>
      <a className="mobile-menu" id="mobile-header" href={ blankLink }>
        <i className="feather icon-more-horizontal" />
      </a>
      <div className="collapse navbar-collapse">
        <NavLeft />
        <NavRight />
      </div>
    </header>
  );
};
