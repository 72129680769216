import React, { useContext } from 'react';
import { ApiContext } from 'Services/api-context';
import { ClaimServiceContext, IClaimService } from 'Services/claim';
import { ServiceProviderProps } from './common';

export const ClaimServiceProvider: React.FC<ServiceProviderProps<IClaimService>> = ( props, context ) => {

  const api = useContext( ApiContext );

  // Create ClaimService that depends on api. To create service we are using useClass implementation
  const claimService: IClaimService = new props.useClass( api );

  return (
    <ClaimServiceContext.Provider value={ claimService }>
      { props.children }
    </ClaimServiceContext.Provider>
  );
};
