import React, { Fragment } from 'react';

import { NavCollapse } from './NavCollapse';
import { NavItem } from './NavItem';
import { MenuItem } from 'App/menu-items';

export interface NavGroupProps {
  group: MenuItem;
}

export const NavGroup: React.FC<NavGroupProps> = ( props ) => {
  let navItems: React.ReactNode = null;
  if ( props.group.children ) {
    const groups = props.group.children;
    navItems = Object.keys( groups ).map( ( key ) => {
      const index: number = parseInt( key );
      const item: MenuItem = groups[index];
      switch ( item.type ) {
        case 'collapse':
          return <NavCollapse key={ item.id } collapse={ item } type="main" />;
        case 'item':
          return <NavItem key={ item.id } item={ item } />;
        default:
          return false;
      }
    } );
  }

  return (
    <Fragment>
      { navItems }
    </Fragment>
  );
};
