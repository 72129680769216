import React from 'react';
import { TFunction } from 'i18next';
import { PermissionType } from 'Services/permissions/interfaces';
import { useTranslation } from 'react-i18next';
import { IAppProductDescriptor, useAppCurrentProduct } from './components/utils/providers/AppProductProvider';
import { useAppUser } from 'App/components/utils/providers/AppUserProvider';
import { IAppUser } from './types';

export type MenuItemBadgeType = 'label-danger' | 'label-info' | 'label-primary';

export interface MenuItemBadge {
  title: string;
  type: MenuItemBadgeType;
}

export type MenuItemType = 'group' | 'collapse' | 'item';
export type IconType = 'material';

export interface MenuItem {
  id: string;
  title: string;
  type: MenuItemType;
  icon?: string;
  iconType?: IconType;
  children?: MenuItem[];
  ids?: string[];
  /**
   * Permissions required for this MenuItem to display
   */
  requiredPermissions: PermissionType[] | PermissionType[][];
  url?: string;
  target?: boolean;
  badge?: MenuItemBadge;
  breadcrumbs?: boolean;
  classes?: string;
  external?: boolean;
  withoutUserPrefix?: boolean;
  nestedApplication?: boolean;
}

export const generateMenuItems = ( t: TFunction, product: IAppProductDescriptor, account: IAppUser ) : MenuItem[] => {
  const commonMenuItems: MenuItem[] = [
    {
      id: 'logout',
      title: t( 'navigation.logout' ),
      type: 'item',
      url: '/logout',
      icon: 'feather icon-log-out',
      breadcrumbs: false,
      requiredPermissions: [],
    },
  ];

  const menuItems: MenuItem[] = [ ...product.menuItems, ...commonMenuItems ];
  const navRoot : MenuItem[] = [
    {
      id: 'navigation',
      title: t( 'menu.item.navigation' ),
      type: 'group',
      icon: 'icon-navigation',
      requiredPermissions: [],
      children: menuItems,
    },
  ];
  return navRoot;
};

// Context for menu
export const AppMenuContext: React.Context<MenuItem[]> = React.createContext( undefined as any );

export const useAppMenu = (): MenuItem[] => {
  return React.useContext( AppMenuContext );
};

/**
 * This component provide menu for left navigation.
 * It create menu based on all products descriptors, current user permissions and current language.
 */
export const AppMenuProvider: React.FC = ( props ) => {
  const { t } = useTranslation( [ 'base' ] );
  const currentProduct = useAppCurrentProduct();
  const currentAccount = useAppUser();
  const menuItems = React.useMemo<MenuItem[]>( () => {
    return generateMenuItems( t, currentProduct, currentAccount );
  }, [ t, currentProduct, currentAccount ] );

  return (
    <AppMenuContext.Provider value={ menuItems }>
      { props.children }
    </AppMenuContext.Provider>
  );
};
