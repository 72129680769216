import React, { useContext } from 'react';

import { ApiContext } from 'Services/api-context';
import { ISettingsService, SettingsServiceContext } from 'Services/settings';
import { ServiceProviderProps } from './common';

export const SettingsProvider: React.FC<ServiceProviderProps<ISettingsService>> = ( props, context ) => {

  const api = useContext( ApiContext );

  // Create SettingsService that depends on api. To create service we are using useClass implementation
  const settingsService: ISettingsService = new props.useClass( api );

  return (
    <SettingsServiceContext.Provider value={ settingsService }>
      { props.children }
    </SettingsServiceContext.Provider>
  );
};
