
import React, { Context, Fragment } from 'react';
import { Loader } from 'App/layout/Loader';
import { ITenantInfo, TenantRequest } from 'Services/api/auth/base';
import { accountsStorageKey, useAppUser } from './AppUserProvider';
import { useAppLogger } from 'Services/logger';
import { useAppRealApi } from 'Services/api-context';
import { AppStorageContext, IStorage } from './AppStorageCtx';
import { IAppUser, IAppUserMeta } from 'App/types';

/**
 * This method assumes that accounts already exists in local storage and it should be used only internally
 * in appTenantProvider after user entity is created and in the context.
 */
const updateAccountTenantInfoInLocalStorage = (
  storage: IStorage,
  user: IAppUser,
  info: ITenantInfo,
): void => {
  const accounts = storage.get<IAppUserMeta[]>( accountsStorageKey )!;
  const accountIndex = accounts.findIndex( ( item ) => item.userName === user.userName );
  const account = accounts[accountIndex];

  account.tenantName = info.name;
  account.tenantSlug = info.slug;

  // save it
  storage.set( accountsStorageKey, accounts );
};

const defaultTenantNameIfMissing: string = '';

/**
 * This provider will try to load tenant for logged in user based on tenantId.
 * It will block UI until tenant loading is not finished.
 * 
 * If there is error in loading tenant it will fallback and create dummy
 * tenant based on access token contents and it will unlock UI and continue.
 */
export const AppTenantProvider: React.FC = ( props ) => {
  const [ tenant, setTenant ] = React.useState<ITenantInfo | null>( null );
  const storage = React.useContext( AppStorageContext );
  const api = useAppRealApi();
  const user = useAppUser();
  const logger = useAppLogger();
  React.useEffect( () => {
    if ( !user.tenantId ) {
      const _tenant: ITenantInfo = {
        id: 1,
        name: user.tenantName || defaultTenantNameIfMissing,
        slug: user.tenantSlug,
      };
      setTenant( _tenant );

      return;
    }

    let mounted = true;
    api.request<ITenantInfo>( new TenantRequest( user.tenantId ) )
      .then( ( _tenant ) => {
        if ( mounted ) {
          updateAccountTenantInfoInLocalStorage( storage, user, _tenant );
          setTenant( _tenant );
        }
      } )
      .catch( ( e ) => {
        if ( mounted ) {
          const errorMessage: string = `Cant load tenant from backend service for tenantId: ${user.tenantId}. ` +
        'Fallback to tenant info from accessToken';
          logger.warn( errorMessage, e );
          // create tenant info from access token data...
          const _tenant: ITenantInfo = {
            id: user.tenantId,
            name: user.tenantName || defaultTenantNameIfMissing,
            slug: user.tenantSlug,
          };
          setTenant( _tenant );
        }
      } );
    return () => {
      mounted = false;
    };
  }, [ api, logger, storage, user ] );

  if ( tenant === null ) {
    return <Loader />;
  }

  return (
    <Fragment>
      <AppTenantContext.Provider value={ tenant }>
        { props.children }
      </AppTenantContext.Provider>
    </Fragment>
  );
};

export const AppTenantContext: Context<ITenantInfo> = React.createContext( undefined as any );

/**
 * Hook that every function component can use to fetch current tenant reference
 */
export const useAppTenant = (): ITenantInfo => {
  return React.useContext( AppTenantContext );
};
