import { FormikProps, FormikValues, setIn, getIn } from 'formik';
import { ValidationError } from 'yup';

import {
  AppFormikPropsType,
  AppFormikChildrenPropsType,
  FormikChanged,
  FormikFieldState,
  FormikFieldStateType,
  ValidationSchemaOptions,
  IValidationError,
  AppFormikErrors,
  AppFormikHelpers,
  AppFormikProps,
  ModifiedFormikConfig,
} from './base';

export const withAppFormikChildrenProps = <Values extends FormikValues = FormikValues>(
  formikChildrenProps: FormikProps<Values>,
  appFormikProps: AppFormikPropsType<Values>,
): AppFormikChildrenPropsType<Values> => {

  // calculate changes based on initialValues and values
  const referenceValues = appFormikProps.referenceValues; // Reference values

  // If reference values are available then we are using them to calculate changes, otherwise we use initialValues
  const initialValues = referenceValues !== undefined ? referenceValues : formikChildrenProps.initialValues;
  const currentValues = formikChildrenProps.values;
  const formikErrors = formikChildrenProps.errors as AppFormikErrors<Values>;
  const changed: FormikChanged<FormikValues> = {};

  const state: FormikFieldState<FormikValues> = {};
  const errors: AppFormikErrors<Values> = formikErrors ;

  const trackingChanges = appFormikProps.trackingChanges !== undefined
    ? appFormikProps.trackingChanges
    : true;

  const editingDisabled = appFormikProps.editingDisabled !== undefined
    ? appFormikProps.editingDisabled
    : false;

  const labelGridSize = appFormikProps.labelGridSize !== undefined
    ? appFormikProps.labelGridSize
    : 4;

  const appFormikPropsWrapper: ModifiedFormikConfig<Values> & Required<AppFormikProps> = {
    ...appFormikProps,
    trackingChanges: trackingChanges,
    editingDisabled: editingDisabled,
    labelGridSize: labelGridSize,
  };

  let fields: string[] = [];
  if ( appFormikProps.detectChangeOnFields ) {
    fields = appFormikProps.detectChangeOnFields;
  } else {
    // Get fields from initialValues. Not working for optional properties in model
    for ( let k in initialValues ) {
      if ( Object.prototype.hasOwnProperty.call( initialValues, k ) ) {
        const fieldName = String( k );
        fields.push( fieldName );
      }
    }
  }

  for ( let k of fields ) {
    const fieldName = String( k );
    changed[fieldName] = false;
    let fieldState: FormikFieldStateType = 'new';
    if ( trackingChanges ) {
      if ( initialValues[fieldName] !== currentValues[fieldName] ) {
        changed[fieldName] = true;
        fieldState = 'changed';
      }
    }
    if ( formikErrors[fieldName] !== undefined ) {
      fieldState = 'invalid';
    }
    state[fieldName] = fieldState;
  }

  const formikActions: AppFormikHelpers<Values> = {
    ...formikChildrenProps,
    setErrors: formikChildrenProps.setErrors as ( errors: AppFormikErrors<Values> ) => void,
  };

  const result: AppFormikChildrenPropsType<Values> = {
    ...formikChildrenProps,
    changed: changed as FormikChanged<Values>,
    fieldState: state as FormikFieldState<Values>,
    errors: errors,
    config: appFormikPropsWrapper,
    actions: formikActions,
  };
  return result;
};

/**
 * Transform Yup ValidationError to a more usable object for AppFormik
 */
export const yupToFormErrors = <Values>(
  yupError: ValidationError,
  validationSchemaOptions: ValidationSchemaOptions,
): AppFormikErrors<Values> => {
  let errors: AppFormikErrors<Values> = {};
  if ( yupError.inner ) {
    if ( yupError.inner.length === 0 ) {
      return setIn( errors, yupError.path, yupError.message );
    }
    if ( validationSchemaOptions.showMultipleFieldErrors ) {
      for ( let err of yupError.inner ) {
        let fieldErrors = getIn( errors, err.path );
        if ( !fieldErrors ) {
          fieldErrors = [];
        }
        const errorWithContext: IValidationError = { message: err.message, context: err.params };
        fieldErrors.push( errorWithContext );
        errors = setIn( errors, err.path, fieldErrors );
      }
    } else {
      for ( let err of yupError.inner ) {
        if ( !getIn( errors, err.path ) ) {
          const errorWithContext: IValidationError = { message: err.message, context: err.params };
          errors = setIn( errors, err.path, errorWithContext );
        }
      }
    }
  }
  return errors;
};

/** Return multi select values based on an array of options */
export function getSelectedValues( options: any[] ) {
  return Array.from( options )
    .filter( ( el ) => el.selected )
    .map( ( el ) => el.value );
}

/** Return the next value for a checkbox */
export function getValueForCheckbox(
  currentValue: string | any[],
  checked: boolean,
  valueProp: any,
) {

  // If the current value was a boolean, return a boolean
  if ( typeof currentValue === 'boolean' || typeof currentValue === 'undefined' ) {
    return Boolean( checked );
  }

  // If the currentValue was not a boolean we want to return an array
  let currentArrayOfValues: any[] = [];
  let isValueInArray = false;
  let index = -1;

  if ( !Array.isArray( currentValue ) ) {
    // eslint-disable-next-line eqeqeq
    if ( !valueProp || valueProp == 'true' || valueProp == 'false' ) {
      return Boolean( checked );
    }
  } else {
    // If the current value is already an array, use it
    currentArrayOfValues = currentValue;
    index = currentValue.indexOf( valueProp );
    isValueInArray = index >= 0;
  }

  // If the checkbox was checked and the value is not already present in the array we
  // want to add the new value to the array of values
  if ( checked && valueProp && !isValueInArray ) {
    return currentArrayOfValues.concat( valueProp );
  }

  // If the checkbox was unchecked and the value is not in the array, simply return the already existing array of values
  if ( !isValueInArray ) {
    return currentArrayOfValues;
  }

  // If the checkbox was unchecked and the value is in the array, remove the value and return the array
  return currentArrayOfValues
    .slice( 0, index )
    .concat( currentArrayOfValues.slice( index + 1 ) );
}
