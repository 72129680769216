import React from 'react';
import { IApiService } from './base';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { LeadItem } from './leads/interfaces';
import { GetLeadVersionRequest } from './lead-versions/lead-versions';

dayjs.extend( utc );

export interface ILeadVersionService {
  getLeadVersion( leadCode: string, leadVersion: number ): Promise<LeadItem>;
}

export class LeadVersionService implements ILeadVersionService {
  protected api: IApiService;
  constructor( api: IApiService ) {
    this.api = api;
  }

  async getLeadVersion( leadCode: string, leadVersionNumber: number ): Promise<LeadItem> {
    try {
      const response = await this.api.request( new GetLeadVersionRequest( leadCode, leadVersionNumber ) );
      const { leadVersion } = response;
      if ( leadVersion && leadVersion.account && leadVersion.account.birthDate ) {
        leadVersion.account.birthDate = dayjs( leadVersion.account.birthDate ).utc();
      }

      return leadVersion;
    } catch ( e ) {
      throw new Error( 'not found' );
    }
  }
}

export const LeadVersionServiceContext: React.Context<ILeadVersionService> = React.createContext( undefined as any );

export const useLeadVersionService = (): ILeadVersionService => {
  return React.useContext( LeadVersionServiceContext );
};
