import {
  PaymentItem,
  PaymentsResponse } from './policies';

const PaymentsData: PaymentItem[] = [
  {
    id: 11337,
    date: '01.02.2020',
    dueDate: '02.02.2020',
    debit: undefined,
    credit: 19.21,
    status: 'Bezahlt',
    description: 'Saldo 2018',
  },
  {
    id: 11338,
    date: '01.02.2020',
    dueDate: '02.02.2020',
    debit: undefined,
    credit: 20.21,
    status: 'Bezahlt',
    description: 'Saldo 2019',
  },
  {
    id: 11339,
    date: '01.02.2020',
    dueDate: '02.02.2020',
    debit: undefined,
    credit: 15.06,
    status: 'Bezahlt',
    description: 'Saldo 2018',
  },
  {
    id: 11340,
    date: '01.02.2020',
    dueDate: '02.02.2020',
    debit: 19.21,
    credit: undefined,
    status: 'Nicht Bezahlt',
    description: 'Folgebeitragsrechnung',
  },
  {
    id: 11341,
    date: '01.02.2020',
    dueDate: '02.02.2020',
    debit: 5.29,
    credit: undefined,
    status: 'Nicht Bezahlt',
    description: 'Folgebeitragsrechnung',
  },
  {
    id: 11342,
    date: '01.02.2020',
    dueDate: '02.02.2020',
    debit: undefined,
    credit: 19.10,
    status: 'Bezahlt',
    description: 'Saldo 2020',
  },
  {
    id: 11343,
    date: '01.02.2020',
    dueDate: '02.02.2020',
    debit: 50.00,
    credit: undefined,
    status: 'Nicht Bezahlt',
    description: 'Folgebeitragsrechnung',
  },
];

export const mockedPayments: PaymentsResponse = {
  items: PaymentsData,
};
