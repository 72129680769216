import React from 'react';
import { IApiService, PagingRequest } from './base';
import { CreatePaymentReqBody, CreatePaymentResponse, Payment } from './payments/interface';
import { CreatePaymentRequest, ListPaymentsRequest } from './payments/requests';
import { IApiListResponse } from './policies/new/service';


export interface IPaymentService {
  createPayment( payload: CreatePaymentReqBody, idempotencyKey: string ): Promise<CreatePaymentResponse>;
  listPayments( paging: PagingRequest ): Promise<IApiListResponse<Payment>>;
}

export class PaymentsService implements IPaymentService{
  protected api: IApiService;
  constructor( api: IApiService ) {
    this.api = api;
  }

  async createPayment( payload: CreatePaymentReqBody, idempotencyKey: string ): Promise<CreatePaymentResponse> {
    const response = await this.api.request( new CreatePaymentRequest( payload, idempotencyKey ) );

    return response;
  }

  async listPayments( paging: PagingRequest ): Promise<IApiListResponse<Payment>> {
    const response = await this.api.request( new ListPaymentsRequest( paging ) );

    return response;
  }
}

export const PaymentServiceContext: React.Context<IPaymentService> = React.createContext( undefined as any );

export const usePaymentsService = (): IPaymentService => {
  return React.useContext( PaymentServiceContext );
};
