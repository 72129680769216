import React, { ReactElement } from 'react';
import { useAppUserPathPrefix } from '../providers/AppUserProvider';
import { isString } from 'types';
import { LocationDescriptor } from 'history';
import { Link, LinkProps } from 'react-router-dom';

export interface AppLinkProps extends LinkProps {
  withoutUserContext? : boolean;
  to: LocationDescriptor;
}

export const AppLink: React.FC<AppLinkProps> = ( props: AppLinkProps ): ReactElement | null => {
  const { to, withoutUserContext, ...rest } = props;
  const userPrefix = useAppUserPathPrefix();
  const putUserPrefix = withoutUserContext === undefined ? true : !withoutUserContext;
  let navigateTo: typeof to = to;

  if ( putUserPrefix ) {
    if ( isString ( navigateTo ) ) {
      if ( navigateTo.startsWith( '/' ) ) {
        navigateTo = `${userPrefix}${navigateTo}`;
      }
    } else {
      if ( navigateTo.pathname?.startsWith( '/' ) ) {
        navigateTo = { ...navigateTo, pathname: `${userPrefix}${navigateTo.pathname}` };
      }
    }
  }

  return (
    <Link { ...rest } to={ navigateTo }>
      { props.children }
    </Link>
  );
};
