import React from 'react';
import { MenuReducer, menuReducer, initialMenuState, MenuActionsExecutor } from './menu';
import { ScreenReducer, screenReducer, initialScreenState, ScreenActionsExecutor } from './screen';
import { LayoutReducer, layoutReducer, initialLayoutState, LayoutActionsExecutor } from './layout';
import { IRootActions, IRootState } from './root';

export const RootActionsContext: React.Context<IRootActions> = React.createContext( undefined as any );
export const RootStateContext: React.Context<IRootState> = React.createContext( undefined as any );

/**
 * This component combine separate root level reducers and state into one rootState.
 * It also uses separate dispatch and state contexts for better render performance than normal old Redux approach
 */
export const AppRoot: React.FC = ( props ) => {
  const [ menuState, menuDispatcher ] = React.useReducer<MenuReducer>( menuReducer, initialMenuState );
  const [ screenState, screenDispatcher ] = React.useReducer<ScreenReducer>( screenReducer, initialScreenState );
  const [ layoutState, layoutDispatcher ] = React.useReducer<LayoutReducer>( layoutReducer, initialLayoutState );

  const rootActions = React.useMemo<IRootActions>( () => {
    return {
      menu: new MenuActionsExecutor( menuDispatcher ),
      screen: new ScreenActionsExecutor( screenDispatcher ),
      layout: new LayoutActionsExecutor( layoutDispatcher ),
    };
  }, [] );

  const rootState = React.useMemo<IRootState>( () => {
    return {
      menu: menuState,
      screen: screenState,
      layout: layoutState,
    };
  }, [ menuState, screenState, layoutState ] );

  return (
    <RootActionsContext.Provider value={ rootActions }>
      <RootStateContext.Provider value={ rootState }>
        { props.children }
      </RootStateContext.Provider>
    </RootActionsContext.Provider>
  );
};

export const useAppRootState = (): IRootState => {
  return React.useContext( RootStateContext );
};

export const useAppRootActions = (): IRootActions => {
  return React.useContext( RootActionsContext );
};
