import { BaseRequest, PagingRequest } from '../base';
import dayjs from 'dayjs';
import { IApiListResponse } from 'Services/policies/new/service';
import { getQueryParamsForListAPI, ListItems } from '../api';
import { ClaimDocument } from '../claims/interfaces';
import { LeadDocument } from '../../App/components/partner/leads/documents/LeadDocumentList';

const documentServiceApiServer = process.env.REACT_APP_DOCUMENT_SERVICE_API_SERVER ?? '';

export interface LayoutHtmlTemplate {
  draftContent: string;
  id?: number;
}

export interface IDocumentDesign {
  id?: number;
  name: string;
  slug: string;
  style: string;
  headerTemplate: LayoutHtmlTemplate;
  footerTemplate: LayoutHtmlTemplate;
}

export interface PreSignedPostRequest {
  templateSlug: string;
  entityType: string;
  description: string;
  requester: string;
  contentType: string;
  isoContentType: string;
  filename: string;
  policyCode?: string;
  accountCode?: string;
  leadCode?: string;
  entityId: number;
  ern?: string;
}

interface PreSignedPostFields extends Record<string, string> {
  templateSlug: string;
  entityId: string;
  entityType: string;
  policyCode: string;
  accountCode: string;
  contentType: string;
  filename: string;
  code: string;
  bucket: string;
  key: string;
}

export interface PreSignedPost {
  fields: PreSignedPostFields;
  url: string;
}

export interface DownloadUrl {
  url: string;
}

export class CreateLayoutRequest extends BaseRequest {
  constructor(
    layout: IDocumentDesign,
  ) {

    super( {
      method: 'POST',
      url: `${documentServiceApiServer}/documentservice/v1/layouts`,
      data: layout,
      responseType: 'json',
    } );
  }
}

export class CreateTemplateRequest extends BaseRequest {
  constructor(
    template: INewDocumentTemplate,
  ) {

    super( {
      method: 'POST',
      url: '/documentservice/v1/doc-templates',
      data: template,
      responseType: 'json',
    } );
  }
}

export class GetLayoutRequest extends BaseRequest {
  constructor(
    layoutId: number,
  ) {

    super( {
      method: 'GET',
      url: `${documentServiceApiServer}/documentservice/v1/layouts/${layoutId}`,
      responseType: 'json',
    } );
  }
}

export class GetTemplateRequest extends BaseRequest {
  constructor(
    templateId: number,
  ) {

    super( {
      method: 'GET',
      url: `${documentServiceApiServer}/documentservice/v1/doc-templates/${templateId}`,
      responseType: 'json',
    } );
  }
}

export class UpdateLayoutRequest extends BaseRequest {
  constructor(
    layoutId: number,
    layout: IDocumentDesign,
  ) {

    super( {
      method: 'PUT',
      url: `${documentServiceApiServer}/documentservice/v1/layouts/${layoutId}`,
      data: layout,
    } );
  }
}

export class UpdateTemplateRequest extends BaseRequest {
  constructor(
    templateId: number,
    templateUpdate: IUpdateDocumentTemplate,
  ) {

    super( {
      method: 'PUT',
      url: `${documentServiceApiServer}/documentservice/v1/doc-templates/${templateId}`,
      data: templateUpdate,
    } );
  }
}

export class DeleteLayoutRequest extends BaseRequest {
  constructor(
    layoutId: number,
  ) {

    super( {
      method: 'DELETE',
      url: `${documentServiceApiServer}/documentservice/v1/layouts/${layoutId}`,
      responseType: 'json',
    } );
  }
}

export class DeleteTemplateRequest extends BaseRequest {
  constructor(
    templateId: number,
  ) {

    super( {
      method: 'DELETE',
      url: `${documentServiceApiServer}/documentservice/v1/doc-templates/${templateId}`,
      responseType: 'json',
    } );
  }
}

export class ListDocumentDesignsRequest extends BaseRequest {
  constructor() {
    //TODO: Currently pageSize is hardcoded, need to review the UI first
    super( {
      method: 'GET',
      url: `${documentServiceApiServer}/documentservice/v1/layouts?pageSize=60&expand=headerTemplate,footerTemplate`,
      responseType: 'json',
    } );
  }
}

export class ListDocumentTemplateRequest extends BaseRequest {
  constructor(
    searchValue: string,
    paging: PagingRequest,
  ) {

    const queryParams = new URLSearchParams();
    queryParams.set( 'pageSize', paging.pageSize.toString() );
    queryParams.set( 'pageToken', paging.pageToken.toString() );

    if ( paging.filter.length ) {
      const filterPars = paging.filter.map( ( item ) => `${item.id}=${encodeURIComponent( item.value )}` ).join( '&' );
      queryParams.set( 'filter', filterPars );
    }

    if ( paging.orderBy.length ) {
      const orderPars = paging.orderBy.map( ( item ) => `${item.id} ${ item.desc ? 'DESC' : 'ASC' }` ).join( '&' );
      queryParams.set( 'order', orderPars );
    }

    if ( searchValue ) {
      queryParams.set( 'search', searchValue );
    }

    super( {
      method: 'GET',
      url: `${documentServiceApiServer}/documentservice/v1/doc-templates?expand=bodyTemplate`,
      responseType: 'json',
      params: queryParams,
    } );
  }
}

export class ListPolicyDocumentRequest extends BaseRequest<IApiListResponse<IApiDocumentItem>> {
  constructor(
    paging: PagingRequest,
    policyCode: string,
    filterByRequester: IDocumentRequester[],
  ) {

    const queryParams = new URLSearchParams( );
    const pCode = `policyCode=${policyCode}`;

    if ( !filterByRequester.includes( 'all' ) ) {
      const filterName = filterByRequester.length > 1 ? 'requesters' : 'requester';
      const filterPars = filterByRequester.map( ( item ) => encodeURIComponent( item ) ).join( ',' );
      queryParams.set( 'filter', pCode + '&' + filterName + '=' + filterPars );
    } else {
      queryParams.set( 'filter', pCode );
    }

    queryParams.set( 'pageSize', paging.pageSize.toString() );
    queryParams.set( 'pageToken', paging.pageToken.toString() );

    if ( paging.orderBy.length ) {
      const orderPars = paging.orderBy.map( ( item ) => `${item.id}:${ item.desc ? 'DESC' : 'ASC' }` ).join( '&' );
      queryParams.set( 'order', orderPars );
    }

    super( {
      method: 'GET',
      url: `${documentServiceApiServer}/documentservice/v1/documents`,
      responseType: 'json',
      params: queryParams,
    } );
  }
}

export class DownloadDocumentRequest extends BaseRequest<BlobPart> {
  constructor(
    code: string,
  ) {

    super( {
      method: 'GET',
      url: `/documentservice/v1/documents/download/${code}`,
      responseType: 'arraybuffer',
      headers: {
        'Accept': 'application/pdf',
      },
    } );
  }
}

export class DownloadStaticDocumentRequest extends BaseRequest<BlobPart> {
  constructor(
    documentKey: string,
  ) {

    super( {
      method: 'GET',
      // eslint-disable-next-line max-len
      url: `${documentServiceApiServer}/documentservice/v1/documents/download-static/${encodeURIComponent( documentKey )}`,
      responseType: 'arraybuffer',
      headers: {
        'Accept': 'application/pdf',
      },
    } );
  }
}

export class ListInvoiceDocumentRequest extends BaseRequest<IApiListResponse<IDocumentItem>> {
  constructor(
    policyCode: string,
    invoiceId?: number,
  ) {

    const queryParams = new URLSearchParams( );

    let baseFilter = `policyCode=${policyCode}`;

    if( invoiceId ) {
      baseFilter = `${baseFilter}&entityId=${invoiceId}`;
    }

    queryParams.set( 'pageSize', '100' );
    queryParams.set( 'filter', baseFilter );

    super( {
      method: 'GET',
      url: `${documentServiceApiServer}/documentservice/v1/documents`,
      responseType: 'json',
      params: queryParams,
    } );
  }
}

export class PresignedUrlRequest extends BaseRequest<PreSignedPost> {
  constructor(
    data: PreSignedPostRequest,
  ) {
    super( {
      method: 'POST',
      url: '/documentservice/v1/documents/pre-signed-post',
      responseType: 'json',
      data,
    } );
  }
}

export class DownloadUrlRequest extends BaseRequest<DownloadUrl> {
  constructor( code: string ) {
    super( {
      method: 'GET',
      url: `${documentServiceApiServer}/documentservice/v1/documents/${code}/download-url`,
      responseType: 'json',
    } );
  }
}

export class UploadDocumentWithPreSignedUrlRequest extends BaseRequest<Record<string, string>> {
  constructor(
    formData: FormData,
    url: string,
    contentType: string,
  ) {
    super( {
      method: 'POST',
      url: url,
      responseType: 'json',
      data: formData,
      headers: { 'Content-Type': contentType },
    }, false );
  }
}

export class ListClaimDocumentsRequest extends BaseRequest<ListItems<ClaimDocument>> {
  constructor( pageParameters: PagingRequest ) {
    super( {
      method: 'GET',
      url: `${documentServiceApiServer}/documentservice/v1/documents`,
      responseType: 'json',
      params: getQueryParamsForListAPI( pageParameters ),
    } );
  }
}

export class ListLeadDocumentsRequest extends BaseRequest<ListItems<LeadDocument>> {
  constructor( pageParameters: PagingRequest ) {
    super( {
      method: 'GET',
      url: `${documentServiceApiServer}/documentservice/v1/documents`,
      responseType: 'json',
      params: getQueryParamsForListAPI( pageParameters ),
    } );
  }
}

export class DeleteDocumentByCodeRequest extends BaseRequest {
  constructor( code: string ) {
    super( {
      method: 'DELETE',
      url: `${documentServiceApiServer}/documentservice/v1/documents/${code}`,
      responseType: 'json',
    } );
  }
}

export class GetSignedUrlRequest extends BaseRequest<GetSignedS3UrlResponse> {
  constructor( s3Key: string ) {
    super( {
      method: 'GET',
      url: `${documentServiceApiServer}/documentservice/v1/documents/signed-s3-url`,
      responseType: 'json',
      params: { s3Key },
    } );
  }
}

export interface GetSignedS3UrlResponse {
  url: string;
}

interface IFooterAndHeaderTemplate {
  content: string;
  createdAt: dayjs.Dayjs;
  draftContent: string;
  id: number;
  tenantId: number;
  type: string;
  updatedAt: dayjs.Dayjs;
}

export type IDocumentRequester = 'billingservice' | 'insuranceservice' | 'accountservice' | 'all';

export interface IDocumentLayout {
  createdAt: dayjs.Dayjs;
  footerTemplate: IFooterAndHeaderTemplate;
  headerTemplate: IFooterAndHeaderTemplate;
  id: number;
  name: string;
  slug: string;
  style: string;
  tenantId: number;
  updatedAt: dayjs.Dayjs;
}

export interface INewDocumentTemplate {
  name: string;
  label?: string;
  slug: string;
  productSlug?: string;
  layoutId: number;
  bodyTemplate: LayoutHtmlTemplate;
}

export interface IUpdateDocumentTemplate {
  name: string;
  label?: string;
  slug: string;
  productSlug: string | undefined;
  layoutId: number;
  bodyTemplate: LayoutHtmlTemplate;
  id: number;
}

export interface IDocumentLayoutResponse {
  layout: IDocumentLayout;
}

export interface IDocumentTemplateResponse {
  template: IDocumentTemplate;
}

export interface IDocumentTemplate {
  label?: string;
  createdAt: dayjs.Dayjs;
  bodyTemplate: IFooterAndHeaderTemplate;
  id: number;
  layoutId: number;
  name: string;
  slug: string;
  productSlug?: string;
  style: string;
  tenantId: number;
  updatedAt: dayjs.Dayjs;
  headerTemplate?: string;
  footerTemplate?: string;
}

export interface IDocumentTemplateResponse {
  template: IDocumentTemplate;
}

export interface ListDocumentDesignsResponse {
  layouts: IDocumentLayout[];
  nextPageToken: string;
}

export interface ListDocumentTemplateResponse {
  templates: IDocumentTemplate[];
  nextPageToken: string;
}

export interface IApiDocumentItem {
  id: number;
  code: string;
  templateId: number;
  description: string;
  policyCode: string;
  accountCode: string;
  entityId: number;
  createdAt: string;
  requester: string;
  filename: string;
  contentType: string;
}

export interface IDocumentItem {
  id: number;
  code: string;
  templateId: number;
  description: string;
  policyCode: string;
  accountCode: string;
  entityId: number;
  createdAt: dayjs.Dayjs | Date;
  requester: string;
  filename: string;
  contentType: string;
}

export interface IStaticDocumentItem {
  id: number;
  code: string;
  templateSlug: string;
  entityType: string;
  description: string;
  requester: string;
  createdAt: string;
  s3Key: string;
  contentType: string;
}

