import { i18n as I18NextType, InitOptions, InterpolationOptions } from 'i18next';
import dayjs from 'dayjs';
import 'dayjs/locale/de';
import 'dayjs/locale/en-gb';
import 'dayjs/locale/pl';
import HttpApi from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';
import { defaultLng, getLanguage } from 'App/ui-utils';
import { appBuildId } from 'App/utils';

export type I18NextPreInitCallbackType = ( i18next: I18NextType ) => void;
export const interpolationOptions: InterpolationOptions = { escapeValue: false };

const lng = getLanguage();

if( lng === 'en' ) {
  dayjs.locale( 'en-gb' );
} else {
  dayjs.locale( lng );
}

/**
 * This callback prepares i18next for initialization. This is app specific.
 */
export const appI18NextPreInitCallback: I18NextPreInitCallbackType = ( i18next ) => {
  i18next
    .use( HttpApi )
    .use( initReactI18next );
};

/**
 * This i18next options is app specific
 */
export const appI18NextInitOptions: InitOptions = {
  lng: lng,
  ns: [ 'base' ],
  defaultNS: 'base',
  fallbackLng: defaultLng,
  keySeparator: '.',
  saveMissing: false,
  updateMissing: false,
  nsSeparator: ':',
  interpolation: interpolationOptions,
  backend: {
    loadPath: '/locales/{{lng}}/{{ns}}.json',
    queryStringParams: { 'build-id': appBuildId },
  },
};
