import { TourItem } from '../tourPages';
import { IAppProductDescriptor } from 'App/components/utils/providers/AppProductProvider';
import { TFunction } from 'i18next';

export const generatePBTourItem = ( t: TFunction, product: IAppProductDescriptor ): TourItem => {
  return {
    pagePath: '/u/:accountIndex/premium-benchmarking',
    run: false,
    steps: [
      {
        title: t( 'pbPageTour.dashboardTitle' ),
        content: t( 'pbPageTour.dashboardContent' ),
        placement: 'bottom',
        target: '#dashboardContainer',
        disableBeacon: true,
      },
      {
        title: t( 'pbPageTour.profileMenuTitle' ),
        content: t( 'pbPageTour.profileMenuContent' ),
        placement: 'top',
        target: '#dropdownProfile',
        disableBeacon: true,
      },
      {
        title: t( 'pbPageTour.productSelectorTitle' ),
        content: t( 'pbPageTour.productSelectorContent' ),
        placement: 'top',
        target: '#dropdownProductSelector',
        disableBeacon: true,
      },
      {
        title: t( 'pbPageTour.helpMenuTitle' ),
        content: t( 'pbPageTour.helpMenuContent' ),
        placement: 'left',
        target: '#dropdownHelp',
        disableBeacon: true,
      },
      {
        title: t( 'pbPageTour.collapseMenuTitle' ),
        content: t( 'pbPageTour.collapseMenuContent' ),
        placement: 'right',
        target: '#mobile-collapse',
        disableBeacon: true,
      },
      {
        title: t( 'pbPageTour.footerLinksTitle' ),
        content: t( 'pbPageTour.footerLinksContent' ),
        placement: 'top',
        target: '#app-footer',
        disableBeacon: true,
      },
    ],
  };
};
