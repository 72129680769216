import React, { ReactElement, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Form } from 'react-bootstrap';
import dayjs from 'dayjs';
import DatePicker, { registerLocale } from 'react-datepicker';
import { TimeFilter } from '../../DocumentsTimeFilter';
import { customFilterTimeFormat, customFilterDateFormat, getLanguage, defaultLng } from 'App/ui-utils';
import de from 'date-fns/locale/de';

export interface CustomTimeFilterProps {
  datePickerHandler: ( sDate: dayjs.Dayjs, eDate?: dayjs.Dayjs ) => void;
  timeFilter: TimeFilter;
  isDisabled: boolean;
}

const CustomTimeFilter: React.FC<CustomTimeFilterProps> =
( props: CustomTimeFilterProps ): ReactElement | null => {
  const { t } = useTranslation( [ 'policy' ] );
  const { datePickerHandler, timeFilter, isDisabled } = props;

  let startDate = timeFilter.startDate?.isValid() ? dayjs( timeFilter.startDate ).toDate() : null;
  let endDate = timeFilter.endDate?.isValid() ? dayjs( timeFilter.endDate ).toDate() : null;
  const timeCaption = t( 'documents.time' );
  const lng = getLanguage();
  if ( lng === defaultLng ) {
    registerLocale( lng, de );
  }

  return (
    <Fragment>
      <Row>
        <div>
          <Form.Group>
            <div>
              <Form.Label className="text-muted">{ t( 'documents.from' ) }:</Form.Label>
            </div>
            <DatePicker
              selected={ startDate }
              onChange={ ( date: string ) => datePickerHandler( dayjs( date ), timeFilter.endDate! ) }
              className="form-control datepicker-from"
              showTimeSelect
              timeFormat={ customFilterTimeFormat }
              timeIntervals={ 15 }
              dateFormat={ customFilterDateFormat }
              placeholderText={ t( 'documents.placeholder' ) }
              timeCaption={ timeCaption }
              locale={ lng }
            />
          </Form.Group>
        </div>
        <div className="ml-3 mb-2">
          <Form.Group>
            <div>
              <Form.Label className="text-muted">{ t( 'documents.to' ) }:</Form.Label>
            </div>
            <DatePicker
              selected={ endDate }
              onChange={ ( date: string ) => datePickerHandler( timeFilter.startDate!, dayjs( date ) ) }
              className="form-control datepicker-to"
              showTimeSelect
              timeFormat={ customFilterTimeFormat }
              disabled={ isDisabled }
              timeIntervals={ 15 }
              placeholderText={ t( 'documents.placeholder' ) }
              dateFormat={ customFilterDateFormat }
              timeCaption={ timeCaption }
              locale={ lng }
            />
          </Form.Group>
        </div>
      </Row>
    </Fragment>
  );
};

export default CustomTimeFilter;
