
import adyen from '../../assets/images/marketplace/adyen_icon.svg';
import braintree from '../../assets/images/marketplace/braintree_icon.svg';
import nafi from '../../assets/images/marketplace/nafi_icon.svg';
import chech24 from '../../assets/images/marketplace/chech24_icon.svg';
import schufa from '../../assets/images/marketplace/schufa_icon.svg';
import infoscore from '../../assets/images/marketplace/infoscore_icon.svg';
import govData from '../../assets/images/marketplace/gov_data_icon.svg';
import claimsforce from '../../assets/images/marketplace/claimsforce_icon.svg';
import freshdesk from '../../assets/images/marketplace/freshdesk_icon.svg';
import deskpro from '../../assets/images/marketplace/deskpro_icon.svg';
import gvd from '../../assets/images/marketplace/gvd_icon.svg';
import gdrive from '../../assets/images/marketplace/gdrive_icon.svg';
import zendesk from '../../assets/images/marketplace/zendesk_icon.svg';
import gmail from '../../assets/images/marketplace/gmail_icon.svg';
import paypal from '../../assets/images/marketplace/paypal_icon.svg';
import stripe from '../../assets/images/marketplace/stripe_icon.svg';
import gsheets from '../../assets/images/marketplace/gsheets_icon.svg';
import outlook from '../../assets/images/marketplace/outlook_icon.svg';
import mailchimp from '../../assets/images/marketplace/mailchimp_icon.svg';
import activeСampaign from '../../assets/images/marketplace/active_campaign_icon.svg';
import mandrill from '../../assets/images/marketplace/mandrill_icon.svg';
import unbounce from '../../assets/images/marketplace/unbounce_icon.svg';
import { IPluginItem } from './interfaces';

export const mockedInstallPluginData: IPluginItem[] = [
  {
    id: 1,
    iconPlugin: {
      iconType: 'image',
      iconImage: adyen,
    },
    pluginName: 'Adyen',
    enPluginDesc: 'Software to make and receive payments',
    dePluginDesc: 'Software-Zahlungsanbieter',
    status: 'install',
  },
  {
    id: 2,
    iconPlugin: {
      iconType: 'image',
      iconImage: braintree,
    },
    pluginName: 'Braintree',
    enPluginDesc: 'Payment Service Provider',
    dePluginDesc: 'Software-Zahlungsanbieter',
    status: 'install',
  },
  {
    id: 3,
    iconPlugin: {
      iconType: 'image',
      iconImage: nafi,
    },
    pluginName: 'NAFI',
    enPluginDesc: 'Insurance and broker solutions',
    dePluginDesc: 'Versicherungs- und Maklerlösungen',
    status: 'install',
  },
];


export const mockedNotInstallPluginData: IPluginItem[] = [
  {
    iconPlugin: {
      iconType: 'image',
      iconImage: chech24,
    },
    pluginName: 'CHECK24',
    enPluginDesc: 'Comparison platform',
    dePluginDesc: 'Vergleichsportal',
    status: 'no_install',
  },
  {
    iconPlugin: {
      iconType: 'image',
      iconImage: schufa,
    },
    pluginName: 'Schufa',
    enPluginDesc: 'Credit check',
    dePluginDesc: 'Bonitätsauskunft',
    status: 'no_install',
  },
  {
    iconPlugin: {
      iconType: 'image',
      iconImage: infoscore,
    },
    pluginName: 'Infoscore',
    enPluginDesc: 'Credit check',
    dePluginDesc: 'Bonitätauskunft',
    status: 'no_install',
  },

  {
    iconPlugin: {
      iconType: 'image',
      iconImage: govData,
    },
    pluginName: 'GovData',
    enPluginDesc: 'Administrative data',
    dePluginDesc: 'Verwaltungsdaten',
    status: 'no_install',
  },
  {
    iconPlugin: {
      iconType: 'image',
      iconImage: claimsforce,
    },
    pluginName: 'Claimsforce',
    enPluginDesc: 'Claims Management',
    dePluginDesc: 'Claims Management',
    status: 'no_install',
  },
  {
    iconPlugin: {
      iconType: 'image',
      iconImage: freshdesk,
    },
    pluginName: 'Freshdesk',
    enPluginDesc: 'Customer relationship management',
    dePluginDesc: 'Customer relationship management',
    status: 'no_install',
  },
  {
    iconPlugin: {
      iconType: 'image',
      iconImage: deskpro,
    },
    pluginName: 'Deskpro',
    enPluginDesc: 'Customer Service software',
    dePluginDesc: 'Customer Service software',
    status: 'no_install',
  },
  {
    iconPlugin: {
      iconType: 'image',
      iconImage: gvd,
    },
    pluginName: 'GDV',
    enPluginDesc: 'Data exchange and reporting',
    dePluginDesc: 'Datenaustausch & Reporting',
    status: 'no_install',
  },
  {
    iconPlugin: {
      iconType: 'image',
      iconImage: gdrive,
    },
    pluginName: 'Google Drive',
    enPluginDesc: 'File storage and synchronization service',
    dePluginDesc: 'File storage and synchronization service',
    status: 'no_install',
  },
  {
    iconPlugin: {
      iconType: 'image',
      iconImage: zendesk,
    },
    pluginName: 'Zendesk',
    enPluginDesc: 'Customer service software',
    dePluginDesc: 'Customer service software',
    status: 'no_install',
  },
  {
    iconPlugin: {
      iconType: 'image',
      iconImage: gmail,
    },
    pluginName: 'Gmail',
    enPluginDesc: 'Email service',
    dePluginDesc: 'Email service',
    status: 'no_install',
  },
  {
    iconPlugin: {
      iconType: 'image',
      iconImage: paypal,
    },
    pluginName: 'Paypal',
    enPluginDesc: 'Online payments system',
    dePluginDesc: 'Online payments system',
    status: 'no_install',
  },
  {
    iconPlugin: {
      iconType: 'image',
      iconImage: stripe,
    },
    pluginName: 'Stripe',
    enPluginDesc: 'Software to make and receive payments',
    dePluginDesc: 'Software to make and receive payments',
    status: 'no_install',
  },
  {
    iconPlugin: {
      iconType: 'image',
      iconImage: gsheets,
    },
    pluginName: 'Google Sheets',
    enPluginDesc: 'Spreadsheet program',
    dePluginDesc: 'Spreadsheet program',
    status: 'no_install',
  },
  {
    iconPlugin: {
      iconType: 'image',
      iconImage: outlook,
    },
    pluginName: 'Outlook',
    enPluginDesc: 'Suite of webmail, contacts, tasks, and calendaring',
    dePluginDesc: 'Suite of webmail, contacts, tasks, and calendaring',
    status: 'no_install',
  },
  {
    iconPlugin: {
      iconType: 'image',
      iconImage: mailchimp,
    },
    pluginName: 'Mailchimp',
    enPluginDesc: 'Email marketing service',
    dePluginDesc: 'Email marketing service',
    status: 'no_install',
  },
  {
    iconPlugin: {
      iconType: 'image',
      iconImage: activeСampaign,
    },
    pluginName: 'ActiveCampaign',
    enPluginDesc: 'CRM software platform',
    dePluginDesc: 'CRM software platform',
    status: 'no_install',
  },
  {
    iconPlugin: {
      iconType: 'image',
      iconImage: mandrill,
    },
    pluginName: 'Mandrill',
    enPluginDesc: 'Transactional email API for Mailchimp users',
    dePluginDesc: 'Transactional email API for Mailchimp users',
    status: 'no_install',
  },
  {
    iconPlugin: {
      iconType: 'image',
      iconImage: unbounce,
    },
    pluginName: 'Unbounce',
    enPluginDesc: 'Landing pages for websites',
    dePluginDesc: 'Landing pages for websites',
    status: 'no_install',
  },
];
