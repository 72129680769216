import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { ButtonToolbar, Dropdown } from 'react-bootstrap';

export interface TimeFilterControlProps {
  selectedTab: string;
  isCustomTab: boolean;
  isRelativeTab: boolean;
  customResetHandler: () => void;
  customFilterHandler: () => void;
  relativeResetHandler:() => void;
  relativeFilterHandler: () => void;
}

const TimeFilterControls: React.FC<TimeFilterControlProps> =
( props: TimeFilterControlProps ): ReactElement | null => {
  const { t } = useTranslation( [ 'policy' ] );
  const { selectedTab, customFilterHandler, customResetHandler, relativeFilterHandler,
    relativeResetHandler, isCustomTab, isRelativeTab } = props;

  return (
    <ButtonToolbar className='float-right'>
      { selectedTab === 'custom' ?
        <div className="d-inline-flex">
          <Dropdown.Item
            className="reset-time-filter btn btn-secondary"
            onClick={ customResetHandler }
          >
            { t ( 'documents.reset' ) }
          </Dropdown.Item>
          <Dropdown.Item
            className="apply-time-filter btn btn-primary mr-4"
            onClick={ customFilterHandler }
            disabled={ isCustomTab }
          >
            { t ( 'documents.apply' ) }
          </Dropdown.Item>
        </div> :
        <div className="d-inline-flex">
          <Dropdown.Item
            className="reset-time-filter btn btn-secondary"
            onClick={ relativeResetHandler }
          >
            { t ( 'documents.reset' ) }
          </Dropdown.Item>
          <Dropdown.Item
            className="apply-time-filter btn btn-primary mr-4"
            onClick={ relativeFilterHandler }
            disabled={ isRelativeTab }
          >
            { t ( 'documents.apply' ) }
          </Dropdown.Item>
        </div> }
    </ButtonToolbar>
  );
};

export default TimeFilterControls;

