import React from 'react';

export interface AppPageContextType<ActionType = any> {
  fireAction: React.Dispatch<ActionType>;
}

const dummyPageContext: AppPageContextType = {
  fireAction: () => {},
};

export const AppPageContext = React.createContext<AppPageContextType>( dummyPageContext );

export const useAppPageContext = <ActionType = any>(): AppPageContextType<ActionType> => {
  const pageCtx = React.useContext( AppPageContext );
  return pageCtx;
};
