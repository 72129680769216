import React from 'react';
import DateTime from 'react-datetime';

// We need to add missing methods and redeclare DateTime component under name FixDateTime
interface FixDateTimeProps {
  renderInput?: ( props: any, openCalendar: () => void, closeCalendar: () => void ) => void;
  closeOnTab?: boolean;
}

export const FixDateTime = DateTime as React.ComponentType<
DateTime.DatetimepickerProps & FixDateTimeProps
>;
