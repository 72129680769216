import React from 'react';
import { Subject, BehaviorSubject } from 'rxjs';
import { sampleTime } from 'rxjs/operators';
import { GeneralItem } from 'Services/api/settings/base';
import { defaultStorage } from './components/utils/providers/AppStorageCtx';
import { FieldErrors } from 'react-hook-form';
import { DocumentEditorStyles } from './components/email-document-editor/document/preview/document-html-template';
import { isEmpty, cloneDeep } from 'lodash';
import { mockedGeneralSettings } from 'Services/api/settings/mock-data';
import { uniqueArray } from './utils';
import Latinize from 'latinize';
import { IEmailLayoutStyles } from 'Services/emails/interfaces';
import styled from 'styled-components';
import ReactTooltip from 'react-tooltip';
import {} from 'App/ui-utils';

export const defaultDateFormat: string = 'DD.MM.YYYY';
export const defaultDateTimeFormat: string = 'DD.MM.YYYY HH:mm';
export const workFlowDateFormat: string = 'DD.MM.YYYY HH:mm:ss';
export const customFilterDateFormat: string = 'MMMM d, yyyy h:mm aa';
export const customFilterTimeFormat: string = 'HH:mm';
export const ticketDateFormat: string = 'DD MMM YYYY [at] HH:mm';
export const editorListDateFormat: string = 'DD.MM.YYYY HH:mm';
export const apiDateFilterFormat: string = 'YYYY-MM-DD';
export const apiDateTimeFormat: string = 'YYYY-MM-DDTHH:mm:ss.SSS[Z]';
export const invoiceDateFormat: string = 'DD MMM YYYY';
export const DATE_FORMAT_EU: string = 'dd.MM.yyyy';
export const TIME_FORMAT_24_HOUR: string = 'HH:mm';
export const DATETIME_FORMAT_24_HOUR = 'do LLL, yyyy HH:mm';
export const DATETIME_FORMAT_FOR_DATETIME_INPUT_TAG = 'yyyy-MM-dd\'T\'HH:mm';
export const policyDateFormat: string = 'YYYY-MM-DD';
export const listLanguages: string[] = [ 'de', 'en', 'pl' ];
export const defaultLng = 'en';
export const DATETIME_FORMAT_HOUR = 'dd.MM.yyyy HH:mm';
export const CLAIM_DATETIME_FORMAT_HOUR: string = 'DD.MM.YYYY, HH:mm';

export interface UmlautValues {
  [key: string]: string;
}

export const umlautMap:UmlautValues = {
  '\u00dc': 'UE',
  '\u00c4': 'AE',
  '\u00d6': 'OE',
  '\u00fc': 'ue',
  '\u00e4': 'ae',
  '\u00f6': 'oe',
  '\u00df': 'ss',
};

export interface WindowSize {
  width: number;
  height: number;
}
const getCurrentWindowSize = (): WindowSize => {
  return {
    width: document.body.clientWidth,
    height: document.body.clientHeight,
  };
};
const windowSizeSignal = new BehaviorSubject<WindowSize>( getCurrentWindowSize() );
const resizeSignal = new Subject<void>();
resizeSignal.pipe( sampleTime( 2000 ) ).subscribe( () => {
  // This sampling will make our application much more responsive and will reduce CPU usage and state context swithing
  const newWindowSize = getCurrentWindowSize();
  windowSizeSignal.next( newWindowSize );
} );
const handleResize = (): void => {
  resizeSignal.next();
};
window.addEventListener( 'resize', handleResize ); // we need only one listener to track browser resizing.

/**
 * Now if component need to know something about window size this hook should be used always
 */
export const useWindowSize = (): WindowSize => {
  const [ windowSize, setWindowSize ] = React.useState<WindowSize>( windowSizeSignal.value );
  React.useEffect( () => {
    const subscription = windowSizeSignal.subscribe( ( size ) => {
      setWindowSize( size );
    } );
    return () => {
      subscription.unsubscribe();
    };
  }, [] );
  return windowSize;
};

export const setCustomStyles = ( elemenId: string, styleVal: string ) => {
  if ( elemenId && styleVal ) {
    const findElement = document.getElementById( elemenId );
    if ( findElement === null ) {
      const elStyle = document.createElement( 'style' );
      elStyle.id = elemenId;
      elStyle.type = 'text/css';
      elStyle.innerHTML = styleVal;
      document.getElementsByTagName( 'head' )[0].appendChild( elStyle );
    } else {
      findElement.innerHTML = styleVal;
    }
  }
};

export const setColorMain = ( colorVal: string ) => {
  if ( colorVal ) {
    // variable "--main-color" default value set in scss file /assets/scss/partials/menu/_menu-lite-custom.scss
    // overrides variable "--main-color" set styles in header :root { --main-color: ${ colorVal }; }
    const innerStyle = `:root { --main-color: ${ colorVal }; }`;
    setCustomStyles( 'main-color', innerStyle );
  }
};

export const setLogoMain = ( logoImage: string ) => {
  if ( logoImage ) {
    const innerStyle = `#logo-block { background-image: url(${logoImage})!important; }`;
    setCustomStyles( 'main-logo', innerStyle );
  }
};

const detectLanguage = (
  supportedLanguages: string[] = listLanguages,
  fallbackLanguage: string = defaultLng,
): string => {
  let found: string[] = [];
  if ( typeof navigator !== 'undefined' ) {
    if ( navigator.languages ) { // chrome only; not an array, so can't use .push.apply instead of iterating
      for ( let i=0; i < navigator.languages.length; i++ ) {
        found.push( navigator.languages[i] );
      }
    }
    if ( navigator.language ) {
      found.push( navigator.language );
    }
  }
  // push fallback language to the end
  found.push( fallbackLanguage );

  // filter languages to only supported languages
  let languageCodes = found.map( ( code ) => {
    // convert en-EN to only language code en and ensure everything is in small letters
    let result = code;
    const idx = result.indexOf( '-' );
    if ( idx > 0 ) {
      result = code.slice( 0, idx );
    }
    result = result.toLocaleLowerCase();
    return result;
  } );
  languageCodes = uniqueArray( languageCodes );
  const matchedLanguages = languageCodes.filter( ( lc ) => supportedLanguages.includes( lc ) );
  return matchedLanguages[0];
};


export const getLanguage = ( ) => {
  const generalItemKey: string = 'GET: /general';
  const generalItem = defaultStorage.get<GeneralItem>( generalItemKey );
  if ( generalItem !== null ) {
    // detect if we have old version with typo
    if ( generalItem['languege'] !== undefined ) {
      // get current language from old property name (with typo) and set it to new corrected property name
      generalItem.language = generalItem['languege'];
      delete generalItem['languege']; // remove old property name with typo
      defaultStorage.set<GeneralItem>( generalItemKey, generalItem );
    }
    return generalItem.language;
  } else {
    const detectedLanguage = detectLanguage();
    const newGeneralItem: GeneralItem = {
      ...mockedGeneralSettings,
      language: detectedLanguage,
    };
    defaultStorage.set<GeneralItem>( generalItemKey, newGeneralItem );
    return detectedLanguage;
  }
};

export const fieldArrayErrorAtIndex = <T>(
  errors: FieldErrors,
  fieldName: string,
  index: number,
): FieldErrors<T> | undefined => {
  if ( errors[fieldName] !== undefined ) {
    return errors[fieldName][index] as FieldErrors<T>;
  }
  return undefined;
};

export const useFieldArrayError = ( errors: FieldErrors ) => {
  const callback = React.useCallback( <T>( fieldName: string, index: number ): FieldErrors<T> | undefined => {
    return fieldArrayErrorAtIndex<T>( errors, fieldName, index );
  }, [ errors ] );
  return callback;
};

const defaultStylesForDocument: DocumentEditorStyles = {
  documentFontFamily: 'sans-serif',
  tableStyles: 'table-style-1',
  bodyTitleStyles: 'title-style-1',
  fontColor: 'none',
  accentColor: 'none',
};

export const footerBgColor = '#3F4D67';

const defaultStylesForEmail: IEmailLayoutStyles = {
  headerBgColor: '',
  headerTextColor: '',
  footerBgColor,
  footerTextColor: '',
  documentFontFamily: 'sans-serif',
  bodyTextColor: '#111111',
  bodyLinkColor: '#007BFF',
  bodyBgColor: '#F5F7FA',
  documentBgColor: '#FFFFFF',
  shadowStyles: 'noneStyles',
  bodyTitleColor: '',
  buttonStyles: 'noneStyles',
  bottomLineStyles: 'noneStyles',
};

const styleField: string[] = [ 'documentFontFamily', 'tableStyles', 'bodyTitleStyles', 'fontColor', 'accentColor' ];

const styleEmailField: string[] = [
  'headerBgColor',
  'headerTextColor',
  'footerBgColor',
  'footerTextColor',
  'documentFontFamily',
  'bodyTextColor',
  'bodyLinkColor',
  'bodyBgColor',
  'documentBgColor',
  'shadowStyles',
  'bodyTitleColor',
  'buttonStyles',
  'bottomLineStyles',
];

export const getValidStyles = ( stylesData: string ): DocumentEditorStyles => {
  try {
    const styles: DocumentEditorStyles = cloneDeep( JSON.parse( stylesData ) );
    if ( !isEmpty( styles ) ) {
      const keyStyles = Object.keys( styles );
      if ( !isEmpty( keyStyles ) ) {
        styleField.forEach( ( k ) => {
          if ( keyStyles.indexOf( k ) === -1 ) {
            styles[k] = defaultStylesForDocument[k];
          }
        } );
        return styles;
      }
    }
  } catch ( e ) {
    return defaultStylesForDocument;
  }
  return defaultStylesForDocument;
};

export const getEmailValidStyles = ( stylesData: string ): IEmailLayoutStyles => {
  try {
    const styles: IEmailLayoutStyles = cloneDeep( JSON.parse( stylesData ) );
    if ( !isEmpty( styles ) ) {
      const keyStyles = Object.keys( styles );
      if ( !isEmpty( keyStyles ) ) {
        styleEmailField.forEach( ( k ) => {
          if ( keyStyles.indexOf( k ) === -1 ) {
            styles[k] = defaultStylesForEmail[k];
          }
        } );
        return styles;
      }
    }
  } catch ( e ) {
    return defaultStylesForEmail;
  }
  return defaultStylesForEmail;
};

Object.keys( umlautMap ).forEach( ( key ) => {
  Latinize.characters[key] = umlautMap[key];
} );

export const latinize = ( value: string ) : string => {
  if ( !value ) {
    return '';
  }
  return Latinize( `${value}` );
};

export const formatVwbDate = ( ( val: string ) => {
  return val.replace( /^(\d{2})(\d{2})/, '$1.$2.' );
} );

export const formatSummary = ( ( val: string ) => {
  return val.replace( new RegExp( 'N/A', 'g' ), '' );
} );

export const StyledTooltip = styled( ReactTooltip ).attrs( {
  className: 'custom-tooltip',
} )`
  &.custom-tooltip {
    max-width: 30%;
    white-space: normal;
    background: white;
    color: #111;
    font-weight: bold;
    box-shadow: 0px 0px 11px rgba(0, 0, 0, 0.1);
    border-radius: 50px;
    z-index: 99999;
    &:after {
      border-top-color: white !important;
      border-top-style: solid !important;
      border-top-width: 6px !important;
    }
  }
`;

export type TooltipType = 'dark' | 'success' | 'warning' | 'error' | 'info' | 'light';
export type Place = 'top' | 'right' | 'bottom' | 'left';
