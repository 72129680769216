import React from 'react';
import { IApiService, ISubscriptionItem, PagingRequest, UpdateSubscriptionsRequest } from './base';
import { IApiListResponse } from './policies/new/service';
import { IReportItem, IReportHistoryItem, IReportDetails } from './reports/interfaces';
import { ListReportHistoryRequest, ListReportRequest, GetReportTypesRequest } from './reports/requests';

export interface IReportService {
  getReports( search: string, paging: PagingRequest ): Promise<IApiListResponse<IReportItem>>;
  getReportTypes(): Promise<IReportDetails[]>;
  updateSubscriptionReport( isActive: boolean, entity: string, entityId: number ): Promise<ISubscriptionItem>;
  getReportHistoryByCode(
    reportCode: string,
    searchValue: string,
    paging: PagingRequest,
    expand?: string[],
  ): Promise<IApiListResponse<IReportHistoryItem>>;
}

export class ReportService implements IReportService {
  protected api: IApiService;

  constructor( api: IApiService ) {
    this.api = api;
  }

  getReports( searchValue: string, paging: PagingRequest, expand?: string[] ): Promise<IApiListResponse<IReportItem>> {
    return this.api.request( new ListReportRequest( searchValue, paging, expand ) )
      .then( ( response ) => {
        let reports: IReportItem[] = [];
        const { items, nextPageToken } = response;

        if ( items ) {
          reports = items.map( ( item, index ) => {
            const report: IReportItem = {
              ...item,
              createdAt: new Date( item.createdAt! ),
              updatedAt: new Date( item.updatedAt! ),
            };

            return report;
          } );
        }

        const res: IApiListResponse<IReportItem> = {
          items: reports,
          nextPageToken: items ? items.length < paging.pageSize ? '1' : nextPageToken : nextPageToken,
        };

        return res;
      } );
  }

  getReportTypes(): Promise<IReportDetails[]> {
    return this.api.request( new GetReportTypesRequest() )
      .then( ( response ) => {
        const { items } = response;
        return items;
      } );
  }

  updateSubscriptionReport( isActive: boolean, entity: string, entityId: number ): Promise<ISubscriptionItem> {
    return this.api.request( new UpdateSubscriptionsRequest( isActive, entity, entityId ) )
      .then( ( response ) => {
        const subscription = {
          ...response.subscription,
        };

        return subscription;
      } );
  }

  getReportHistoryByCode(
    reportCode: string, searchValue: string, paging: PagingRequest, expand?: string[],
  ): Promise<IApiListResponse<IReportHistoryItem>> {
    return this.api.request( new ListReportHistoryRequest( reportCode, searchValue, paging, expand ) )
      .then( ( response ) => {
        let reportHistory: IReportHistoryItem[] = [];
        const { items, nextPageToken } = response;

        if ( items ) {
          reportHistory = items.map( ( item, index ) => {
            const report: IReportHistoryItem = {
              ...item,
              createdAt: new Date( item.createdAt! ),
              updatedAt: new Date( item.updatedAt! ),
            };

            return report;
          } );
        }

        const res: IApiListResponse<IReportHistoryItem> = {
          items: reportHistory,
          nextPageToken: items ? items.length < paging.pageSize ? '1' : nextPageToken : nextPageToken,
        };

        return res;
      } );
  }
};

export const ReportServiceContext: React.Context<IReportService> = React.createContext( undefined as any );

export const useReportService = (): IReportService => {
  return React.useContext( ReportServiceContext );
};
