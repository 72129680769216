import React, { useContext } from 'react';

import { ApiContext } from 'Services/api-context';
import { ServiceProviderProps } from './common';
import { IWFMonitorService, WFMonitorServiceContext } from 'Services/workflow-monitor';

export const WFMonitorServiceProvider: React.FC<ServiceProviderProps<IWFMonitorService>> = ( props, context ) => {
  const api = useContext( ApiContext );
  const workFlowService: IWFMonitorService = new props.useClass( api );

  return (
    <WFMonitorServiceContext.Provider value={ workFlowService }>
      { props.children }
    </WFMonitorServiceContext.Provider>
  );
};
