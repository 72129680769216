import React, { useContext } from 'react';
import { ApiContext } from 'Services/api-context';
import { ServiceProviderProps } from './common';
import { PartnerServiceContext, IPartnerService } from '../../../../Services/partner';

export const PartnerServiceProvider: React.FC<ServiceProviderProps<IPartnerService>> = ( props, context ) => {

  const api = useContext( ApiContext );

  // Create ClaimService that depends on api. To create service we are using useClass implementation
  const partnerService: IPartnerService = new props.useClass( api );

  return (
    <PartnerServiceContext.Provider value={ partnerService }>
      { props.children }
    </PartnerServiceContext.Provider>
  );
};
