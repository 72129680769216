import React from 'react';
import { IApiService, PagingRequest, ApiResponse } from './base';
import { ListItems } from './api';
import {
  Claim,
  CreateClaimReqBody,
  IUpdateSettlementItem,
  NewSettlementItem,
  SettlementItem,
  PatchClaimReqBody,
  CustomSchema, UpdateClaimReqBody, LinkClaimPartnerPayload, PartnerRelation, ClaimPartnerRole,
} from './claims/interfaces';
import {
  CreateClaimsRequest,
  CreateSettlementRequest,
  DeleteSettlementRequest,
  GetClaimRequest,
  DeleteClaimRequest,
  GetListOfClaimsRequest,
  GetSettlementRequest,
  ListSettlementsRequest,
  UpdateClaimRequest,
  UpdateSettlementRequest,
  PatchClaimRequest,
  ListCustomSchemasRequest, ListClaimPartnerRoles, ListClaimPartners, LinkClaimPartner, UnLinkClaimPartner,
} from './claims/requests';

export interface IClaimService {
  createClaim( claimRequest: CreateClaimReqBody ): Promise<{ claim: Claim }>;
  getClaim( claimCode: string ): Promise<{ claim: Claim }>;
  updateClaim( claimCode: string, claimRequest: UpdateClaimReqBody ): Promise<{ claim: Claim}>;
  patchClaim( claimCode: string, payload: PatchClaimReqBody ): Promise<Claim>;
  listClaims( pageParams: PagingRequest, searchValue: string ): Promise<ListItems<Claim>>;
  deleteClaim( claimCode: string ): Promise<ApiResponse>;
  createSettlement( newSettlement: NewSettlementItem ): Promise<SettlementItem>;
  getSettlement( claimCode: string, regulateCode: string ): Promise<SettlementItem>;
  updateSettlement( regulateUpdate: SettlementItem ): Promise<SettlementItem>;
  deleteSettlement( claimCode: string, regulateCode: string ): Promise<ApiResponse>;
  listSettlements( pageParams: PagingRequest ): Promise<ListItems<SettlementItem>>;
  listCustomSchemas( pageParams: PagingRequest ): Promise<ListItems<CustomSchema>>;
  listClaimPartnerRoles( pageParams: PagingRequest ): Promise<ListItems<ClaimPartnerRole>>;
  listClaimPartners( pageParams: PagingRequest, expand: string[] ): Promise<ListItems<PartnerRelation>>;
  linkClaimPartner( payload: LinkClaimPartnerPayload ): Promise<PartnerRelation>;
  unlinkClaimPartner( id: number ): Promise<{ }>;
}

export class ClaimService implements IClaimService {
  protected api: IApiService;

  constructor( api: IApiService ) {
    this.api = api;
  }

  async createClaim( claimRequest: CreateClaimReqBody ): Promise<{ claim: Claim }> {
    return await this.api.request( new CreateClaimsRequest( claimRequest ) );
  }

  async getClaim( claimCode: string ): Promise<{ claim: Claim }> {
    return await this.api.request( new GetClaimRequest( claimCode ) );
  }

  async updateClaim( claimCode: string, claimRequest: CreateClaimReqBody ): Promise<{ claim: Claim}> {
    return await this.api.request( new UpdateClaimRequest( claimCode, claimRequest ) );
  }
  async patchClaim( claimCode: string, payload: PatchClaimReqBody ): Promise<Claim> {
    const body = {
      ...payload,
    };
    return await this.api.request( new PatchClaimRequest( claimCode, body ) );
  }

  async listClaims( pageParams: PagingRequest, searchValue: string ): Promise<ListItems<Claim>> {
    const response = await this.api.request( new GetListOfClaimsRequest( pageParams, searchValue ) );

    const { items, nextPageToken } = response;

    const res: ListItems<Claim> = {
      items: items ? items : [],
      nextPageToken: items ? items.length < pageParams.pageSize ? '1' : nextPageToken : nextPageToken,
    };

    return res;
  }

  async deleteClaim( claimCode: string ): Promise<ApiResponse> {
    const response = await this.api.request( new DeleteClaimRequest( claimCode ) );

    return response;
  }

  async listSettlements( pageParams: PagingRequest ): Promise<ListItems<SettlementItem>> {
    const response = await this.api.request( new ListSettlementsRequest( pageParams ) );

    const { items, nextPageToken } = response;

    const res: ListItems<SettlementItem> = {
      items: items ? items : [],
      nextPageToken: items ? items.length < pageParams.pageSize ? '1' : nextPageToken : nextPageToken,
    };

    return res;
  }

  async createSettlement( newSettlement: NewSettlementItem ): Promise<SettlementItem> {
    const response = await this.api.request( new CreateSettlementRequest( newSettlement ) );

    const settlement = {
      ...response.settlement,
    };

    return settlement;
  }

  async getSettlement( claimCode: string, settlementCode: string ): Promise<SettlementItem> {
    const response = await this.api.request( new GetSettlementRequest( claimCode, settlementCode ) );

    const settlement = {
      ...response.settlement,
    };

    return settlement;
  }

  async updateSettlement( settlementUpdate: SettlementItem ): Promise<SettlementItem> {
    const settlementCode = settlementUpdate.code!;

    const payload: IUpdateSettlementItem = {
      code: settlementCode,
      insuredObject: settlementUpdate.insuredObject,
      reserve: settlementUpdate.reserve,
      payment: settlementUpdate.payment,
      recourse: settlementUpdate.recourse,
      claimCode: settlementUpdate.claimCode,
    };

    const response = await this.api.request(
      new UpdateSettlementRequest(
        settlementCode,
        payload,
      ) );

    const settlement = {
      ...response.settlement,
    };

    return settlement;
  }

  async deleteSettlement( claimCode: string, settlementCode: string ): Promise<ApiResponse> {
    const response = await this.api.request( new DeleteSettlementRequest( claimCode, settlementCode ) );

    return response;
  }

  async listCustomSchemas( pageParams: PagingRequest ): Promise<ListItems<CustomSchema>> {
    const response = await this.api.request( new ListCustomSchemasRequest( pageParams ) );

    return response;
  }

  async listClaimPartnerRoles( pageParams: PagingRequest ): Promise<ListItems<ClaimPartnerRole>> {
    const response = await this.api.request( new ListClaimPartnerRoles( pageParams ) );

    return response;
  }

  async listClaimPartners( pageParams: PagingRequest, expand: string[] ): Promise<ListItems<PartnerRelation>> {
    const response = await this.api.request( new ListClaimPartners( pageParams, expand ) );

    return response;
  }

  async linkClaimPartner( payload: LinkClaimPartnerPayload ): Promise<PartnerRelation> {
    const response = await this.api.request( new LinkClaimPartner( payload ) );

    return response;
  }

  async unlinkClaimPartner( id: number ): Promise<{ }> {
    const response = await this.api.request( new UnLinkClaimPartner( id ) );

    return response;
  }
}

export const ClaimServiceContext: React.Context<IClaimService> = React.createContext( undefined as any );

export const useClaimService = (): IClaimService => {
  return React.useContext( ClaimServiceContext );
};
