import React, { useContext } from 'react';

import { ApiContext } from 'Services/api-context';
import { ServiceProviderProps } from './common';
import { IDocumentService, DocumentServiceContext } from 'Services/document';

export const DocumentServiceProvider: React.FC<ServiceProviderProps<IDocumentService>> = ( props, context ) => {

  const api = useContext( ApiContext );
  const documentService: IDocumentService = new props.useClass( api );

  return (
    <DocumentServiceContext.Provider value={ documentService }>
      { props.children }
    </DocumentServiceContext.Provider>
  );
};
