export enum InsuredObjectTypes {
  All = 'all',
  Car = 'car',
  TPL = 'tpl',
  Custom = 'custom',
  Motorbike = 'motorbike',
  Moped = 'moped',
  Bike = 'bike',
  Accident = 'accident',
  Travel = 'travel',
  Device = 'device',
  HomeContents = 'homeContents',
  LegalProtection = 'legalProtection',
  Health = 'health',
  Default = 'default',
}

export enum BasicProductFields {
  Number = 'number',
  Boolean = 'boolean',
  String = 'string',
  Date = 'date',
}

export enum CustomProductFields {
  DateTime = 'dateTime',
  Email = 'email',
  LicensePlateRegion = 'licensePlateRegion',
  LicensePlateLetters = 'licensePlateLetters',
  LicensePlateNumbers = 'licensePlateNumbers',
  VehicleType = 'vehicleType',
  VehicleUsage = 'vehicleUsage',
  LicensePlateType = 'licensePlateType',
  SfClass = 'sfClass',
  DurationUnit = 'durationUnit',
  RenewalFrequency = 'renewalFrequency'
}

export enum ProductFieldTypeSystem {
  Basic = 'basic',
  Custom = 'custom'
}

export enum ProductFieldTypeEntity {
  SystemProductFieldType = 'system_product_field_type',
  ProductFactorType = 'product_factor_type'
}

export enum SystemProductFieldReadOnly {
  PolicyDurationUnit = 'policyDurationUnit',
  PolicyDurationValue = 'policyDurationValue',
  BillingFrequencyUnit = 'billingFrequencyUnit',
  BillingFrequencyValue = 'billingFrequencyValue',
  PolicyRenewalFrequency = 'policyRenewalFrequency',
  RecurringBillingDay = 'recurringBillingDay',
  RecurringPaymentDay = 'recurringPaymentDay',
  BillingMethod = 'billingMethod',
  IsRecurringBilling = 'isRecurringBilling',
  BrokerID = 'brokerID'
}

export enum ProductFactorValuesEnum {
  None = 'none',
  Short = 'short',
  All = 'all',
}

export enum DeviceTypes {
  Device = 'device',
  MobileDevice = 'mobileDevice'
}

export enum PdfDocumentType {
  Sepa = 'sepa',
  Policy = 'policy',
  Invoice = 'invoice',
  Terms = 'terms',
  Static = 'static',
  IPID = 'ipid',
  Mediation = 'mediation',
  Protocol = 'protocol',
  Rules = 'rules',
  Link = 'link',
}
