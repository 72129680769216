import React from 'react';
import { IApiService, PagingRequest } from './base';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { LeadItem, PatchLead } from './leads/interfaces';
import { IItemService, ListItemsResponse } from './base/interfaces';
import { GetLeadRequest, ListLeadsRequest, PatchLeadRequest } from './leads/leads';
import { ProductItemApp } from 'App/components/partner/common/AddApplicationNav';

dayjs.extend( utc );

export interface ILeadService extends IItemService{
  patchLead( leadCode: string, payload: PatchLead ): Promise<LeadItem>;
}

export class LeadService implements ILeadService{
  protected api: IApiService;
  constructor( api: IApiService ) {
    this.api = api;
  }

  async getItemsData(
    paging: PagingRequest,
    searchValue: string,
    productList?: ProductItemApp[],
  ): Promise<ListItemsResponse> {
    const response = await this.api.request( new ListLeadsRequest( paging, searchValue ) );

    let leadsData: LeadItem[] = [];
    const items = response.items as LeadItem[];
    const nextPageToken = response.nextPageToken;

    if ( items ) {
      leadsData = items.map( ( item ) => {
        item.createdAt = dayjs( item.createdAt ).toDate();
        return item;
      } );

      if ( productList ) {
        leadsData.forEach( ( lead ) => {
          const matchedProduct = productList.find( ( product ) => product.productSlug === lead.productSlug );
          if ( matchedProduct ) {
            lead.productName = matchedProduct.productName;
          }
        } );
      }
    }

    const res: ListItemsResponse = {
      items: leadsData,
      nextPageToken: items ? items.length < paging.pageSize ? '1' : nextPageToken : nextPageToken,
    };

    return res;
  }

  async getLead( leadCode: string ): Promise<LeadItem> {
    try {
      const response = await this.api.request( new GetLeadRequest( leadCode, [] ) );
      const { lead } = response;
      if ( lead && lead.account && lead.account.birthDate ) {
        lead.account.birthDate = dayjs( lead.account.birthDate ).utc();
      }

      return lead;
    } catch ( e ) {
      throw new Error( 'not found' );
    }
  }

  async patchLead( leadCode: string, payload: PatchLead ): Promise<LeadItem> {
    const response = await this.api.request( new PatchLeadRequest( leadCode, payload ) );

    return response.lead;
  }
}
export const LeadServiceContext: React.Context<ILeadService> = React.createContext( undefined as any );

export const useLeadService = (): ILeadService => {
  return React.useContext( LeadServiceContext );
};
