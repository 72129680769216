import React from 'react';
import { IApiService, PagingRequest, ApiResponse } from './base';
import { UserItem, DataResponse, UserResponse, NotificationsItem,
  NotificationsResponse, IUserDetailsItem, IUpdateUsers } from './api/users/base';
import { mockedUser } from './api/users/mock-data';
import { IApiListResponse } from './policies/new/service';

export interface IUserService {
  getUsersData( searchUser: string, roleName: string ): Promise<UserItem[]>;
  addUser( user: UserItem ): Promise<UserResponse>;
  updateUser( user: UserItem ): Promise<UserResponse>;
  deleteUser( userId: number ): Promise<DataResponse>;
  getUser( userId: number ): Promise<UserItem>;
  getNotifications( userId: number ): Promise<NotificationsItem>;
  updateNotifications( notifications: NotificationsItem ): Promise<NotificationsResponse>;
  getUserByEmail( userEmail: string ): Promise<UserItem | null>;
  getUsers( searchUser: string, paging: PagingRequest ): Promise<IApiListResponse<IUserDetailsItem>>;
}

export interface IUsersService {
  getUsers( searchUser: string, paging: PagingRequest ): Promise<IApiListResponse<IUserDetailsItem>>;
  deleteUsers( userIds: number[] ): Promise<ApiResponse>;
  disableUsers( userIds: number[] ): Promise<ApiResponse>;
  createUsers( users: IUpdateUsers ): Promise<ApiResponse>;
  getUser( userid: number ): Promise<IUserDetailsItem>;
  updateUser( update: IUserDetailsItem ): Promise<IUserDetailsItem>;
  updateUsers( update: IUpdateUsers ): Promise<ApiResponse>;
}

export class UserService implements IUserService {

  protected api: IApiService;

  constructor( api: IApiService ) {
    this.api = api;
  }

  getUsersData( searchUser: string, roleName: string ): Promise<UserItem[]> {
    // TODO Implement later because backend is not ready yet
    /*
    const result = this.api.request<ServerResponse>( new UsersRequest( searchUser, roleName  ) )
      .then( ( response ) => {

      } );
    */
    return Promise.resolve( [] );
  }

  getUser( userId: number ): Promise<UserItem> {
    // TODO Implement later because backend is not ready yet
    /*
    const userId = user.user_id;
    const result = this.api.request<UserResponse>( new UserRequest( user ) )
      .then( ( response ) => {

      } );
    */
    const res: UserItem = mockedUser;
    return Promise.resolve( res );
  }

  addUser( user: UserItem ): Promise<UserResponse> {
    // TODO Implement later because backend is not ready yet
    /*
    const result = this.api.request<UserResponse>( new AddUserRequest( user ) )
      .then( ( response ) => {

      } );
    */
    const res: UserResponse = {
      item: mockedUser,
      message: 'Ok',
      success: true,
    };
    return Promise.resolve( res );
  }

  updateUser( user: UserItem ): Promise<UserResponse> {
    // TODO Implement later because backend is not ready yet
    /*
    const userId = user.user_id;
    const result = this.api.request<UserResponse>( new UpdateUserRequest( user ) )
      .then( ( response ) => {

      } );
    */
    const res: UserResponse = {
      item: mockedUser,
      message: 'Ok',
      success: true,
    };
    return Promise.resolve( res );
  }

  deleteUser( userId: number ): Promise<DataResponse> {
    // TODO Implement later because backend is not ready yet
    /*
    const result = this.api.request<ServerResponse>( new DeleteUserRequest( `/users/${ userId }` ) )
      .then( ( response ) => {

      } );
    */
    const res: DataResponse = {
      message: 'Ok',
      success: true,
    };
    return Promise.resolve( res );
  }

  getUserByEmail( userEmail: string ): Promise<UserItem | null> {
    // TODO Implement later because backend is not ready yet
    /*
    const result = this.api.request<UserResponse>( new UserByEmailRequest( userEmail ) )
      .then( ( response ) => {

      } );
    */
    const res: UserItem = mockedUser;
    return Promise.resolve( res );
  }

  getNotifications( userId: number ): Promise<NotificationsItem> {
    throw new Error( 'Method getNotifications not implemented.' );
  }

  updateNotifications( notifications: NotificationsItem ): Promise<NotificationsResponse> {
    throw new Error( 'Method updateNotifications not implemented.' );
  }

  getUsers( searchUser: string, paging: PagingRequest ): Promise<IApiListResponse<IUserDetailsItem>> {
    throw new Error( 'Method getUsers not implemented.' );
  }
};

export class UsersService implements IUsersService {
  protected api: IApiService;

  constructor( api: IApiService ) {
    this.api = api;
  }

  getUsers( searchUser: string, paging: PagingRequest ): Promise<IApiListResponse<IUserDetailsItem>> {
    throw new Error( 'Method getUsers not implemented.' );
  }

  deleteUsers( userIds: number[] ): Promise<ApiResponse> {
    throw new Error( 'Method deleteUsers not implemented.' );
  }

  disableUsers( userIds: number[] ): Promise<ApiResponse> {
    throw new Error( 'Method disableUsers not implemented.' );
  }

  createUsers( users: IUpdateUsers ): Promise<ApiResponse> {
    throw new Error( 'Method createUsers not implemented.' );
  }

  getUser( userid: number ): Promise<IUserDetailsItem> {
    throw new Error( 'Method getUser not implemented.' );
  }

  updateUser( update: IUserDetailsItem ): Promise<IUserDetailsItem> {
    throw new Error( 'Method updateUser not implemented.' );
  }

  updateUsers( update: IUpdateUsers ): Promise<ApiResponse> {
    throw new Error( 'Method updateUsers not implemented.' );
  }
};

export const UserServiceContext: React.Context<IUserService> = React.createContext( undefined as any );
export const UsersServiceContext: React.Context<IUsersService> = React.createContext( undefined as any );

export const useUsersService = (): IUsersService => {
  return React.useContext( UsersServiceContext );
};

