/* eslint-disable max-len */
import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { Router } from 'react-router-dom';
import * as serviceWorker from './serviceWorker';
import { CookiesProvider } from 'react-cookie';

import { App } from './App';
import { MockedApiProvider, ApiProvider } from 'Services/api';
import { UserServiceProvider, UsersServiceProvider } from 'App/components/utils/providers/UserProvider';
import { RoleServiceProvider } from 'App/components/utils/providers/RoleProvider';
import { FormServiceProvider } from 'App/components/utils/providers/FormProvider';
import { MockedUserService, MockedUsersService } from 'Services/api/users/mock-service';
import { MockedRoleService } from 'Services/api/role/mock-service';
import { FormService } from 'Services/forms/base';
import { CustomerServiceProvider } from 'App/components/utils/providers/CustomerProvider';
import { LeadServiceProvider } from 'App/components/utils/providers/LeadProvider';
import { SettingsProvider } from 'App/components/utils/providers/SettingsProvider';
import { MockedSettingsService } from 'Services/api/settings/mock-service';
import { createBrowserHistory, History } from 'history';
import { AuthService } from 'Services/auth';
import { AppConfigProvider } from 'config/provider';
import { AppLoggerProvider } from 'App/components/utils/providers/AppLoggerProvider';
import { Loader } from 'App/layout/Loader';
import { AuthServiceProvider } from 'App/components/utils/providers/AuthProvider';
import { CustomerService } from 'Services/customer';
import { LeadService } from 'Services/lead';
import { AppProductsProvider } from 'App/components/utils/providers/AppProductProvider';
import { EmailServiceProvider } from 'App/components/utils/providers/EmailProvider';
import { EmailService } from 'Services/email';
import { AppRoot } from 'App/root/context';
import { UserApiServiceProvider } from 'App/components/utils/providers/UserApiProvider';
import { UserApiService } from 'Services/user';
import { DocumentService } from 'Services/document';
import { DocumentServiceProvider } from 'App/components/utils/providers/DocumentProvider';
import { DocxTemplateService } from 'Services/docx-template';
import { DocxTemplateServiceProvider } from 'App/components/utils/providers/DocxTemplateProvider';
import { DashboardServiceProvider } from 'App/components/utils/providers/DashboardProvider';
import { DashboardService } from 'Services/dashboard';
import { ProductServiceProvider } from 'App/components/utils/providers/ProductProvider';
import { ProductService } from 'Services/product';
import { I18NextProvider } from 'App/components/utils/providers/I18NextProvider';
import { appI18NextInitOptions, appI18NextPreInitCallback } from 'i18n';
import { BillingServiceProvider } from 'App/components/utils/providers/BillingProvider';
import { BillingService } from 'Services/billing';
import { WorkFlowServiceProvider } from 'App/components/utils/providers/WorkFlowProvider';
import { MockedWorkFlowService } from 'Services/workflow/mock-service';
import { WFMonitorServiceProvider } from 'App/components/utils/providers/WFMonitorProvider';
import { WFMonitorService } from 'Services/workflow-monitor';
import { PluginServiceProvider } from 'App/components/utils/providers/PluginProvider';
import { MockedPluginService } from 'Services/plugin/mock-service';
import { GdvServiceProvider } from 'App/components/utils/providers/GdvProvider';
import { GdvService } from 'Services/gdv';
import { AlertServiceProvider } from 'App/components/utils/providers/AlertProvider';
import { ReportServiceProvider } from 'App/components/utils/providers/ReportProvider';
import { ReportService } from 'Services/report';
import { AlertService } from './Services/alert';
import { HelpCenterServiceProvider } from 'App/components/utils/providers/HelpCenterProvider';
import { HelpCenterService } from 'Services/helpCenter';
import { TariffComparisonServiceProvider } from 'App/components/utils/providers/TariffComparisonProvider';
import { TariffComparisonService } from './Services/tariff-comparison';
import { ClaimServiceProvider } from 'App/components/utils/providers/ClaimProvider';
import { ClaimService } from 'Services/claim';
import { LeadStatusService } from 'Services/lead-status';
import { LeadStatusServiceProvider } from 'App/components/utils/providers/LeadStatusServiceProvider';
import { ClaimStatusService } from 'Services/claim-status';
import { ClaimStatusServiceProvider } from 'App/components/utils/providers/ClaimStatusServiceProvider';
import { ProductDocumentService } from 'Services/product-document';
import { ProductDocumentProvider } from 'App/components/utils/providers/ProductDocumentProvider';
import { PolicyServiceProvider } from 'App/components/utils/providers/PolicyProvider';
import { PolicyService } from 'Services/policies/new/service';
import { PaymentServiceProvider } from 'App/components/utils/providers/PaymentProvider';
import { PaymentsService } from 'Services/payments';
import { StatusTransitionRuleServiceProvider } from 'App/components/utils/providers/StatusTransitionProvider';
import { StatusTransitionRuleService } from 'Services/status-transition-rule';
import { LeadVersionService } from 'Services/lead-version';
import { LeadVersionServiceProvider } from 'App/components/utils/providers/LeadVersionServiceProvider';
import { PartnerServiceProvider } from './App/components/utils/providers/PartnerProvider';
import { PartnerService } from './Services/partner';
import { PartnerTypeServiceProvider } from './App/components/utils/providers/PartnerTypeProvider';
import { PartnerTypeService } from './Services/partner-type';

export const history: History = createBrowserHistory();

let previousPathname: string = '';
history.listen( ( location ) => {
  if ( !previousPathname || previousPathname !== location.pathname ) {
    analytics?.page();
    previousPathname = location.pathname;
  }
} );

const app: JSX.Element = (
  <AppConfigProvider>
    <AppLoggerProvider name="logger">
      <I18NextProvider preInit={ appI18NextPreInitCallback } initOptions={ appI18NextInitOptions }>
        <Suspense fallback={ <Loader /> }>
          { /* From this level we can use translations */ }
          <AppProductsProvider>
            <Router history={ history }>
              <ApiProvider>
                <AuthServiceProvider useClass={ AuthService }>
                  <ProductServiceProvider useClass={ ProductService }>
                    <CustomerServiceProvider useClass={ CustomerService }>
                      <LeadServiceProvider useClass={ LeadService }>
                        <LeadVersionServiceProvider useClass={ LeadVersionService }>
                          <LeadStatusServiceProvider useClass={ LeadStatusService }>
                            <UserApiServiceProvider useClass={ UserApiService }>
                              <DashboardServiceProvider useClass={ DashboardService }>
                                <PolicyServiceProvider useClass={ PolicyService }>
                                  <PaymentServiceProvider useClass={ PaymentsService } >
                                    <DocumentServiceProvider useClass={ DocumentService }>
                                      <ProductDocumentProvider useClass={ ProductDocumentService }>
                                        <DocxTemplateServiceProvider useClass={ DocxTemplateService }>
                                          <EmailServiceProvider useClass={ EmailService }>
                                            <BillingServiceProvider useClass={ BillingService }>
                                              <GdvServiceProvider useClass={ GdvService }>
                                                <AlertServiceProvider useClass={ AlertService }>
                                                  <HelpCenterServiceProvider useClass={ HelpCenterService }>
                                                    <ReportServiceProvider useClass={ ReportService }>
                                                      <TariffComparisonServiceProvider useClass={ TariffComparisonService }>
                                                        <WFMonitorServiceProvider useClass={ WFMonitorService }>
                                                          <ClaimServiceProvider useClass={ ClaimService }>
                                                            <ClaimStatusServiceProvider useClass={ ClaimStatusService }>
                                                              <PartnerTypeServiceProvider useClass={ PartnerTypeService }>
                                                                <PartnerServiceProvider useClass={ PartnerService }>
                                                                  <StatusTransitionRuleServiceProvider useClass={ StatusTransitionRuleService }>
                                                                    <MockedApiProvider>
                                                                      <WorkFlowServiceProvider useClass={ MockedWorkFlowService }>
                                                                        <SettingsProvider useClass={ MockedSettingsService }>
                                                                          <PluginServiceProvider useClass={ MockedPluginService }>
                                                                            <UsersServiceProvider useClass={ MockedUsersService }>
                                                                              <UserServiceProvider useClass={ MockedUserService }>
                                                                                <RoleServiceProvider useClass={ MockedRoleService }>
                                                                                  <FormServiceProvider useClass={ FormService }>
                                                                                    <CookiesProvider>
                                                                                      <AppRoot>
                                                                                        <App />
                                                                                      </AppRoot>
                                                                                    </CookiesProvider>
                                                                                  </FormServiceProvider>
                                                                                </RoleServiceProvider>
                                                                              </UserServiceProvider>
                                                                            </UsersServiceProvider>
                                                                          </PluginServiceProvider>
                                                                        </SettingsProvider>
                                                                      </WorkFlowServiceProvider>
                                                                    </MockedApiProvider>
                                                                  </StatusTransitionRuleServiceProvider>
                                                                </PartnerServiceProvider>
                                                              </PartnerTypeServiceProvider>
                                                            </ClaimStatusServiceProvider>
                                                          </ClaimServiceProvider>
                                                        </WFMonitorServiceProvider>
                                                      </TariffComparisonServiceProvider>
                                                    </ReportServiceProvider>
                                                  </HelpCenterServiceProvider>
                                                </AlertServiceProvider>
                                              </GdvServiceProvider>
                                            </BillingServiceProvider>
                                          </EmailServiceProvider>
                                        </DocxTemplateServiceProvider>
                                      </ProductDocumentProvider>
                                    </DocumentServiceProvider>
                                  </PaymentServiceProvider>
                                </PolicyServiceProvider>
                              </DashboardServiceProvider>
                            </UserApiServiceProvider>
                          </LeadStatusServiceProvider>
                        </LeadVersionServiceProvider>
                      </LeadServiceProvider>
                    </CustomerServiceProvider>
                  </ProductServiceProvider>
                </AuthServiceProvider>
              </ApiProvider>
            </Router>
          </AppProductsProvider>
        </Suspense>
      </I18NextProvider>
    </AppLoggerProvider>
  </AppConfigProvider>
);

ReactDOM.render( app, document.getElementById( 'root' ) );

// If you want your app to work offline and load faster, you can change
// Unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
