import React, { useContext } from 'react';
import { ApiContext } from 'Services/api-context';
import { ServiceProviderProps } from './common';
import { ITariffComparisonService, TariffComparisonServiceContext } from 'Services/tariff-comparison';

export const TariffComparisonServiceProvider: React.FC<ServiceProviderProps<ITariffComparisonService>> = (
  props, context,
) => {
  const api = useContext( ApiContext );
  const tariffComparisonService: ITariffComparisonService = new props.useClass( api );

  return (
    <TariffComparisonServiceContext.Provider value={ tariffComparisonService }>
      { props.children }
    </TariffComparisonServiceContext.Provider>
  );
};
