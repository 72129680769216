import React from 'react';
import HashTabs from '../utils/HashTabs';
import dayjs from 'dayjs';
import * as qs from 'query-string';
import NotFound from '../NotFound';
import { Tab } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { websiteTitle } from 'config';
import { OrganizationDataItem, OrganizationDataForm } from './steps/OrganizationDataForm';
import { AuthServiceContext } from 'Services/auth';
import { useHistory, useLocation } from 'react-router-dom';
import { useLoadingSpinnerOnFullContainer } from '../utils/LoadingSpinner';
import { useAppAlertService } from '../utils/alerts/AppAlertService';
import { CustomDataForm } from './steps/CustomDataForm';
import { UserDataForm, UserDataItem } from './steps/UserDataForm';
import { CustomSchemaItem } from '../../../Services/api/auth/base';
import { cloneDeep } from 'lodash';

export enum ActiveStep {
  OrgData = 'orgData',
  CustomData = 'customData',
  UserData = 'userData',
};

export type FormData = OrganizationDataItem | Object | UserDataItem;

export interface OrganizationState {
  [ActiveStep.OrgData]: OrganizationDataItem;
  [ActiveStep.CustomData]: JSON;
  [ActiveStep.UserData]: UserDataItem;
  customSchema: CustomSchemaItem;
  invitationToken: string;
  samlIdpLoginUrl?: string;
}

export interface OrgActions {
  goToStep( step: ActiveStep, nameState: ActiveStep, formData: FormData ): void;
}

export const OrgActionsContext: React.Context<OrgActions> = React.createContext( undefined as any );

export const useOrgActions = (): OrgActions => {
  return React.useContext( OrgActionsContext );
};

export const OrganizationRegistration: React.FC = () => {
  const { t } = useTranslation( [ 'registration', 'base' ] );
  const { showAlert } = useAppAlertService();

  document.title = t( 'organizations.pageTitle' ) + ` | ${ websiteTitle }`;

  const history = useHistory();
  const location = useLocation();

  const { pathname, search } = location;
  const query = new URLSearchParams( search );
  const token: string | null = query.get( 'token' );
  const loadingOnFullContainer = useLoadingSpinnerOnFullContainer();
  const authService = React.useContext( AuthServiceContext );
  const isFirstRender = React.useRef<boolean | null>( null );
  const [ isLoading, setIsLoading ] = React.useState<boolean>( false );
  const [ organizationState, setOrganizationState ] = React.useState<OrganizationState | undefined>( undefined );

  const getParamByName = React.useCallback( ( paramName: string ): string | undefined => {
    const parsedUrl = qs.parse( window.location.href, { decode: false } );
    return parsedUrl[paramName];
  }, [] );

  const getFullUrl = React.useCallback( ( stepName: string ): string => {
    let fullPath: string = `${pathname}${search}#step=${stepName}`;

    const leadCode = getParamByName( 'token' );
    if ( leadCode ) {
      fullPath = `${fullPath}&token=${leadCode}`;
    }

    return fullPath;
  }, [ getParamByName, pathname, search ] );

  React.useEffect( () => {
    let isMounted = true;
    const fetchData = async () => {
      if ( token ) {
        setIsLoading( true );
        try {
          const result = await authService.verifyOrgInvitation( token );
          if ( isMounted ) {
            const isExpired = dayjs().isAfter( result.invitation.expiresAt );

            if ( isExpired ) {
              history.push( '/expired-token' );
              return;
            }

            const { invitation, customSchema, samlIdpLoginUrl } = result;

            setOrganizationState( {
              [ActiveStep.OrgData]: {
                name: invitation.name,
                street: invitation.street,
                houseNumber: invitation.houseNumber,
                zipCode: invitation.zipCode,
                city: invitation.city,
                country: invitation.country,
              },
              [ActiveStep.CustomData]: invitation.customFields as JSON,
              [ActiveStep.UserData]: {
                email: invitation.email,
                firstName: '',
                lastName: '',
                newPassword: '',
                confirmPassword: '',
                isEulaAccepted: false,
              },
              customSchema,
              invitationToken: token,
              samlIdpLoginUrl,
            } );

            setIsLoading( false );
          }
        } catch ( error ) {
          if ( isMounted ) {
            setIsLoading( false );
            history.push( '/expired-token' );
          }
        }
      }
    };

    fetchData();

    return () => {
      isMounted = false;
    };
  }, [ authService, history, showAlert, t, token ] );

  const orgActions = React.useMemo<OrgActions>( () => {
    return {
      goToStep: ( stepName: ActiveStep, nameState: ActiveStep, formData: FormData ): void => {
        const prevState = cloneDeep( organizationState );
        const newState: OrganizationState = { ...prevState!, [nameState]: formData };

        setOrganizationState( newState );

        history.push( getFullUrl( stepName ) );
      },
    };
  }, [ getFullUrl, history, organizationState ] );

  if ( isLoading ) {
    return (
      <div className="loading-box">
        { loadingOnFullContainer }
      </div>
    );
  }

  if ( !token ) {
    return (
      <NotFound />
    );
  }

  if ( isFirstRender.current === null ) {
    history.push( getFullUrl( ActiveStep.OrgData ) );
    isFirstRender.current = true;
  }

  return (
    <OrgActionsContext.Provider value={ orgActions }>
      <div id="org-page" className="auth-wrapper">
        <div className="auth-content">
          <div className="auth-bg">
            <span className="r" />
            <span className="r s" />
            <span className="r s" />
            <span className="r" />
          </div>
          { organizationState && (
            <HashTabs
              className="m-0 p-0 d-none-nav"
              variant="pills"
              defaultActiveKey={ ActiveStep.OrgData }
              id="step"
            >
              <Tab eventKey={ ActiveStep.OrgData } title={ t( 'base:organizations.steps.organizationData' ) }>
                <OrganizationDataForm initData={ organizationState } />
              </Tab>
              { organizationState.customSchema && (
                <Tab eventKey={ ActiveStep.CustomData } title={ t( 'base:organizations.steps.customFields' ) }>
                  <CustomDataForm initData={ organizationState } />
                </Tab>
              ) }
              <Tab eventKey={ ActiveStep.UserData } title={ t( 'base:organizations.steps.contactPerson' ) }>
                <UserDataForm initData={ organizationState } />
              </Tab>
            </HashTabs>
          ) }
        </div>
      </div>
    </OrgActionsContext.Provider>
  );
};

export default OrganizationRegistration;
