import React, { useContext } from 'react';

import { ApiContext } from 'Services/api-context';
import { ServiceProviderProps } from './common';
import { IDocxTemplateService, DocxTemplateServiceContext } from 'Services/docx-template';

export const DocxTemplateServiceProvider: React.FC<ServiceProviderProps<IDocxTemplateService>> = ( props, context ) => {

  const api = useContext( ApiContext );
  const docxTemplateService: IDocxTemplateService = new props.useClass( api );

  return (
    <DocxTemplateServiceContext.Provider value={ docxTemplateService }>
      { props.children }
    </DocxTemplateServiceContext.Provider>
  );
};
