import React, { Context, Fragment } from 'react';
import { useRouteMatch, useLocation, useHistory } from 'react-router-dom';
import { AppStorageContext } from './AppStorageCtx';
import { AuthServiceContext } from 'Services/auth';
import { BaseApiError } from 'Services/base';
import { IFullAuth, IToken } from 'Services/api/auth/base';
import { Loader } from 'App/layout/Loader';
import { PermissionType } from 'Services/permissions/interfaces';
import { hasRequiredPermissions } from 'Services/permissions';
import { useAppLogger } from 'Services/logger';
import { IAppUser, IAppUserMeta } from 'App/types';
import { isObject } from 'types';
import { JsonObject } from '@cover42/protobuf-util';

interface UserParam {
  accountIndex: string;
}

const isAccountIndexValid = ( userId: string ): boolean => {
  return /^\d+$/.test( userId );
};

export const accountParamName: string = 'u';
export const accountsStorageKey: string = 'APP_ACCOUNTS';
// export const tenantsStorageKey: string = 'APP_TENANTS';
export const stripAccountPathRegExp: RegExp = new RegExp( `^\\/${accountParamName}\\/\\d+` );
export const appTokenStorageKey = 'APP_TOKEN';

export const AppUserProvider: React.FC = ( props ) => {
  // BOOTSTRAPING USER FROM URL
  const fullPathWithoutAccount = React.useRef( '' );
  const fullPath = React.useRef( '' );
  const authService = React.useContext( AuthServiceContext );
  const logger = useAppLogger();
  const location = useLocation();
  const history = useHistory();
  const match = useRouteMatch<UserParam>();
  //const storage = React.useContext( AppStorageContext );
  const users = useAppAccounts();

  const matchingPartLength = match.url.length;
  const { pathname, search, hash } = location;
  const path = pathname.substr( matchingPartLength );
  const accountIdx = match.params.accountIndex;

  fullPathWithoutAccount.current = path + search + hash;
  fullPath.current = pathname + search + hash;

  const [ currentUser, setCurrentUser ] = React.useState<IAppUser>( undefined as any );

  // FIX. We need to clear current user if accountIndex in url and current user account index are different
  const idx: number = +accountIdx;
  if ( currentUser !== undefined && idx !== currentUser.accountIndex ) {
    setCurrentUser( undefined as any );
  }

  // If SUCCESS:
  // Put this user to UserCtx and inform API about UserCtx
  React.useEffect( () => {
    // Get User Data and call setCurrentUser
    if ( !isAccountIndexValid( accountIdx ) ) {
      // Redirect to /u/0 and try again
      history.replace( '/u/0' + fullPathWithoutAccount.current );
      return;
    }

    const backUrl = createBackUrl( fullPath.current );
    const loginPath = createPath( '/login', backUrl );
    if ( users.length === 0 ) {
      // create backUrl without account selector part
      history.replace( loginPath );
      return;
    }

    const aid: number = +accountIdx;
    const userMeta = users[aid];
    if ( userMeta === undefined ) {
      // Redirect to /u/0 and try again
      history.replace( '/u/0' + fullPathWithoutAccount.current );
      return;
    }

    authService.refreshToken( userMeta.userName )
      .then( ( auth ) => {
        const user = new AppUser( auth );
        setCurrentUser( user );
      } )
      .catch( ( error: BaseApiError ) => {
        // error during refreshing token and building user context
        logger.error( 'Error during refreshing token. Redirecting to login page', error );
        history.replace( loginPath );
      } );

    return () => {
      // Clear token because we left user aware component
      // This will happen when for example user is switching between logged in accounts
      // Or will go to login page to login as a new account.
      authService.clearToken();
    };
  }, [ accountIdx, authService, history, logger, users ] );

  return (
    <Fragment>
      { currentUser === undefined
        ? ( <Loader /> )
        : (
          <AppAccountContext.Provider value={ currentUser }>
            { props.children }
          </AppAccountContext.Provider>
        )
      }
    </Fragment>
  );
};
export class AppUser implements IAppUser {
  readonly userName: string;
  readonly userFullName: string;
  readonly tenantId: number;
  readonly tenantName: string | undefined;
  readonly tenantSlug: string;
  readonly sub: string;

  readonly accountIndex: number;
  readonly token: React.MutableRefObject<IToken | null>;

  readonly userPermissions: PermissionType[];
  readonly isRequiredToChangePassword: boolean;
  readonly tokenMeta?: JsonObject;

  constructor( auth: IFullAuth ) {
    this.userName = auth.userMeta.userName;
    this.userFullName = auth.userMeta.userFullName;
    this.tenantId = auth.userMeta.tenantId;
    this.tenantName = auth.userMeta.tenantName;
    this.tenantSlug = auth.userMeta.tenantSlug;
    this.sub = auth.userMeta.sub;
    this.accountIndex = auth.accountIndex;
    this.token = { current: auth.token };
    this.isRequiredToChangePassword = auth.userChangePasswordRequired;
    this.tokenMeta = auth.tokenMeta;

    if ( auth.userPermissions && isObject( auth.userPermissions ) ) {
      this.userPermissions = auth.userPermissions as PermissionType[];
    } else {
      this.userPermissions = auth.userPermissions.split( ',' );
    }
  }

  hasPermissions( permissions: PermissionType[] | PermissionType[][] ): boolean {
    if ( !permissions.length ) {
      return true;
    }

    if ( Array.isArray( permissions[0] ) ) {
      return permissions.some( ( permission ) => {
        return hasRequiredPermissions( this.userPermissions, permission );
      } );
    }

    return hasRequiredPermissions( this.userPermissions, permissions as PermissionType[] );
  }
}

/**
 * The user that has access to everything
 */
export class AppUserRoot extends AppUser implements IAppUser {
  hasPermissions( permissions: PermissionType[] ): boolean {
    return true;
  }
}

// Context definition and default value for user context
// export const anonymousUser: IAppUser = new AppUserAnonymous();
/**
 * User Ctx definition. By default it does contain anonymous not logged User - anonymousUser
 */
export const AppAccountContext: Context<IAppUser> = React.createContext( undefined as any );

/**
 * Hook that every function component can use to fetch current user reference
 */
export const useAppUser = (): IAppUser => {
  return React.useContext( AppAccountContext );
};

export const appUserPathPrefix = ( accountIndex: number ): string => {
  return `/${accountParamName}/${accountIndex}`;
};

/**
 * Strips the user context part from path if exists at the beginning
 * @param path
 */
export const appUserPathPrefixRemove = ( path: string ): string => {
  return path.replace( stripAccountPathRegExp, '' );
};

/**
 * Strips the user context part from fullpath if exists at the beginning and
 * encode uri component. The resulting string can be used as a backUrl
 * @param fullpath
 */
export const createBackUrl = ( fullpath: string ): string => {
  return appUserPathPrefixRemove( fullpath );
};

/**
 * Create path to url with backUrl or without. If backUrl is undefined or empty then
 * created path will not contain backUrl parameter.
 * @param to
 * @param withBackUrl
 */
export const createPath = ( to: string, withBackUrl?: string ): string => {
  if ( withBackUrl !== undefined && withBackUrl.length > 0 ) {
    const backUrl = encodeURIComponent( withBackUrl );
    return `${to}?backUrl=${backUrl}`;
  } else {
    return to;
  }
};

export const useAppUserPathPrefix = (): string => {
  const user = useAppUser();
  return user !== undefined ? appUserPathPrefix( user.accountIndex ) : '';
};

/**
 * Gets Accounts From Local Storage
 */
export const useAppAccounts = (): IAppUserMeta[] => {
  const storage = React.useContext( AppStorageContext );
  const accounts = React.useMemo( () => {
    const result = storage.get<IAppUserMeta[]>( accountsStorageKey );
    return result !== null ? result : [];
  }, [ storage ] );
  return accounts;
};
