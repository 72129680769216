import { Dispatch, Reducer } from 'react';
import { LayoutType } from 'config';

export interface ILayoutState {
  layout: LayoutType;
}

export const initialLayoutState: ILayoutState = {
  layout: 'vertical',
};

type LayoutActionMessageType =
 | { type: 'CHANGE_LAYOUT'; layout: LayoutType };

export type LayoutReducer = Reducer<ILayoutState, LayoutActionMessageType>;

export const layoutReducer: LayoutReducer = ( prevState, action ) => {
  switch ( action.type ) {
    case 'CHANGE_LAYOUT': {
      return {
        ...prevState,
        layout: prevState.layout,
      };
    }
    default:
      return prevState;
  }
};

export interface ILayoutActions {
  changeLayout( layout: LayoutType ): void;
}

export class LayoutActionsExecutor implements ILayoutActions {

  private readonly dispatcher: Dispatch<LayoutActionMessageType>;

  constructor( dispatcher: Dispatch<LayoutActionMessageType> ) {
    this.dispatcher = dispatcher;
    this.changeLayout = this.changeLayout.bind( this );
  }

  changeLayout( layout: LayoutType ): void {
    this.dispatcher( { type: 'CHANGE_LAYOUT', layout: layout } );
  }

}
