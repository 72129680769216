import React, { ReactElement } from 'react';
import { MenuItem } from 'App/menu-items';
export interface NavBadgeProps {
  items: MenuItem;
}

export const NavBadge: React.FC<NavBadgeProps> = ( props: NavBadgeProps ): ReactElement | null => {
  let navBadges: ReactElement | null = null;
  if ( props.items.badge ) {
    const badgeClass = [ 'label', 'pcoded-badge', props.items.badge.type ];

    navBadges = (
      <span className={ badgeClass.join( ' ' ) }>
        { props.items.badge.title }
      </span>
    );
  }
  return navBadges;
};
