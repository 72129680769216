import { INewAlertItem, IApiAlertItem, IAlertItem, IAlertResponse, IApiAlertHistoryItem } from './interfaces';
import { ApiResponse, BaseRequest, PagingRequest } from 'Services/base';
import { IApiListResponse } from 'Services/policies/new/service';


export class CreateAlertRequest extends BaseRequest<IAlertResponse> {
  constructor(
    alert: INewAlertItem,
  ) {

    super( {
      method: 'POST',
      url: '/pbmservice/v1/alerts',
      data: alert,
      responseType: 'json',
    } );
  }
}

export class GetAlertRequest extends BaseRequest<IAlertResponse> {
  constructor(
    alertCode: string,
  ) {

    super( {
      method: 'GET',
      url: `/pbmservice/v1/alerts/${alertCode}`,
      responseType: 'json',
    } );
  }
}

export class UpdateAlertRequest extends BaseRequest<IAlertResponse> {
  constructor(
    alertCode: string,
    alert: IAlertItem,
  ) {

    super( {
      method: 'PUT',
      url: `/pbmservice/v1/alerts/${alertCode}`,
      data: alert,
    } );
  }
}

export class ListAlertRequest extends BaseRequest<IApiListResponse<IApiAlertItem>> {
  constructor(
    searchValue: string,
    paging: PagingRequest,
    expand?: string[],
  ) {

    const queryParams = new URLSearchParams();
    queryParams.set( 'pageSize', paging.pageSize.toString() );
    queryParams.set( 'pageToken', paging.pageToken.toString() );

    if ( paging.filter.length ) {
      const filterParams = paging.filter
        .map( ( item ) => `${item.id}=${encodeURIComponent( item.value )}` )
        .join( '&' );
      queryParams.set( 'filter', filterParams );
    }

    if ( paging.orderBy.length ) {
      const orderParams = paging.orderBy.map( ( item ) => `${item.id}:${ item.desc ? 'DESC' : 'ASC' }` ).join( '&' );
      queryParams.set( 'order', orderParams );
    }

    if ( searchValue ) {
      queryParams.set( 'query', searchValue );
    }

    if ( expand && expand.length > 0 ) {
      const expandParams = expand.map( ( item ) => `${encodeURIComponent( item )}` ).join( ',' );
      queryParams.set( 'expand', expandParams );
    }

    super( {
      method: 'GET',
      url: '/pbmservice/v1/alerts',
      responseType: 'json',
      params: queryParams,
    } );
  }
}

export class DeleteAlertRequest extends BaseRequest<ApiResponse> {
  constructor(
    alertCode: string,
  ) {

    super( {
      method: 'DELETE',
      url: `/pbmservice/v1/alerts/${alertCode}`,
      responseType: 'json',
    } );
  }
}

export class ListAlertHistoryRequest extends BaseRequest<IApiListResponse<IApiAlertHistoryItem>> {
  constructor(
    alertCode: string,
    paging: PagingRequest,
    expand?: string[],
  ) {

    const queryParams = new URLSearchParams();
    queryParams.set( 'pageSize', paging.pageSize.toString() );
    queryParams.set( 'pageToken', paging.pageToken.toString() );

    if ( paging.filter.length ) {
      const filterParams = paging.filter
        .map( ( item ) => `${item.id}=${encodeURIComponent( item.value )}` )
        .join( '&' );
      queryParams.set( 'filter', filterParams );
    }

    if ( paging.orderBy.length ) {
      const orderParams = paging.orderBy.map( ( item ) => `${item.id}:${ item.desc ? 'DESC' : 'ASC' }` ).join( '&' );
      queryParams.set( 'order', orderParams );
    }

    if ( expand && expand.length > 0 ) {
      const expandParams = expand.map( ( item ) => `${encodeURIComponent( item )}` ).join( ',' );
      queryParams.set( 'expand', expandParams );
    }

    super( {
      method: 'GET',
      url: `/pbmservice/v1/alerts/${alertCode}/history`,
      responseType: 'json',
      params: queryParams,
    } );
  }
}
