import React from 'react';
import { useAppConfig } from 'config/provider';
import { AppLoggerContext } from 'Services/logger';
import rootLogger, { Logger } from 'loglevel';

export interface AppLoggerProps {
  name: string;
}

export const AppLoggerProvider: React.FC<AppLoggerProps> = ( props ) => {
  const config = useAppConfig();
  const logLevel = config.logger.logLevel;
  const logger = React.useMemo<Logger>( () => {
    const l = rootLogger.getLogger( props.name );
    l.setLevel( logLevel );
    return l;
  }, [ logLevel, props.name ] );

  return (
    <AppLoggerContext.Provider value={ logger }>
      { props.children }
    </AppLoggerContext.Provider>
  );
};
