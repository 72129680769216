import { FzvMessageItem } from 'Services/gdv/fzv.message';
import dayjs from 'dayjs';

export class FzvDemoOrchestrator {
  private static instance: FzvDemoOrchestrator;

  messages: FzvMessageItem[];

  public static getInstance() {
    if ( !FzvDemoOrchestrator.instance ) {
      FzvDemoOrchestrator.instance = new FzvDemoOrchestrator();
    }
    return FzvDemoOrchestrator.instance;
  }

  private constructor() {
    this.messages = [ {
      id: 1098847,
      createdAt: dayjs().add( -1, 'day' ),
      processed: false,
      type: 'FZV',
      category: 'Änderung Halteranschrift',
      details: {
        messageType: 'GDV_VM_003',
        customerType: 'PP',
        messageIds: [
          'GDVDLevbprod3.21051.180901062913.000318195',
          'ZB053580DN-QG520__21824406284652XX00039',
        ],
        sender: {
          communicationPartner: 'GDV',
          softwareVersion: 'Evb.149.009293',
          timestamp: dayjs().add( -1, 'day' ).toISOString(),
        },
        requestNumber: 'eVB_WP_636662239287310428',
        insuranceInfo: {
          companyType: '1',
          insuranceCompanyNumber: '5858',
          insuranceLocation: '000',
          agencyInsurance: '77777',
          insuranceStartDate: dayjs().format( 'YYYY.MM.DD' ),
          insurancePolicyNumber: '370100248',
        },
        carOwnerInfo: {
          isCarOwnerDifferentFromPolicyHolder: true,
          naturalPerson: true,
          firstName: 'DOHMEN',
          lastName: 'YASMIN NICOLE',
          gender: 'w',
          zipCode: '52353',
          city: 'DÜREN',
          street: 'D',
          houseNumber: '7',
          communityKey: '05358008',
        },
        carInfo: {
          brandKey: '3003',
          brand: 'PEUGEOT (F)',
          typeKey: '546',
          variantVersionKey: '004',
          carType: '2',
          carClassKey: '01',
          structureKey: '0200',
          carClassText: 'PERSONENKRAFTWAGEN',
          structureText: 'GESCHLOSSEN',
          vin: 'VF33CKFWB82639773',
          motorVolume: '01360',
          powerKw: '0055',
          isOldCar: false,
          isVehicleUsed: false,
        },
        licensePlate: {
          value: 'DN QG 520',
          mark: 'Z',
          changeMark: '0',
          issueDate: '2017-12-06T06:00:00+01:00',
        },
        authorityInfo: {
          areaKey: '05358',
          addDigit: '0',
          processedTimestamp: dayjs().add( -1, 'day' ).toISOString(),
        },
        changeLog: {
          shareMessageType: 2,
          cancellation: 0,
          ownerChange: 0,
          insuranceChange: 0,
          licensePlateChange: 0,
          carClassChange: 0,
          oldCarChange: 0,
        },
        timestamp: dayjs().add( -1, 'day' ).toISOString(),
      },
    }, {
      id: 1101910,
      createdAt: dayjs().add( -1, 'day' ),
      processed: false,
      type: 'FZV',
      category: 'Änderung Halter',
      details: {
        messageType: 'GDV_VM_001',
        customerType: 'PP',
        messageIds: [
          'GDVDLevbprod3.21051.180901062913.000318195',
          'ZB053580DN-QG520__21824406284652XX00039',
        ],
        sender: {
          communicationPartner: 'GDV',
          softwareVersion: 'Evb.149.009293',
          timestamp: dayjs().add( -2, 'day' ).toISOString(),
        },
        requestNumber: 'eVB_WP_636662239287310428',
        insuranceInfo: {
          companyType: '1',
          insuranceCompanyNumber: '0153',
          insuranceLocation: '000',
          agencyInsurance: '13334',
          insuranceStartDate: dayjs().format( 'YYYY.MM.DD' ),
          insurancePolicyNumber: '370100248',
        },
        carOwnerInfo: {
          isCarOwnerDifferentFromPolicyHolder: true,
          firstName: 'Maxim',
          lastName: 'Dedov',
          naturalPerson: true,
          gender: 'm',
          zipCode: '10245',
          city: 'Berlin',
          street: 'Gärtnerstrasse',
          houseNumber: '20',
          communityKey: '053511',
        },
        carInfo: {
          brandKey: '588',
          brand: 'Audi',
          typeKey: '555',
          variantVersionKey: '004',
          carType: '2',
          carClassKey: '01',
          structureKey: '0200',
          carClassText: 'PERSONENKRAFTWAGEN',
          structureText: 'GESCHLOSSEN',
          vin: 'WAUZZZNN26FN008532',
          motorVolume: '2000',
          powerKw: '184',
          isOldCar: false,
          isVehicleUsed: false,
        },
        licensePlate: {
          value: 'B OA 777',
          mark: 'Z',
          changeMark: '0',
          issueDate: '2021-08-27T06:00:00+01:00',
        },
        authorityInfo: {
          areaKey: '05358',
          addDigit: '0',
          processedTimestamp: dayjs().add( -1, 'day' ).toISOString(),
        },
        changeLog: {
          shareMessageType: 2,
          cancellation: 0,
          ownerChange: 4,
          insuranceChange: 3,
          licensePlateChange: 4,
          carClassChange: 0,
          oldCarChange: 0,
        },
        timestamp: dayjs().add( -1, 'day' ).toISOString(),
      },
    }, {
      id: 1078723,
      createdAt: dayjs().add( -1, 'day' ),
      processed: false,
      type: 'FZV',
      category: 'Änderung Halter',
      details: {
        messageType: 'GDV_VM_001',
        customerType: 'PP',
        messageIds: [
          'GDVDLevbprod3.21051.180901062913.000318195',
          'ZB053580DN-QG520__21824406284652XX00039',
        ],
        sender: {
          communicationPartner: 'GDV',
          softwareVersion: 'Evb.149.009293',
          timestamp: dayjs().add( -1, 'day' ).toISOString(),
        },
        requestNumber: 'eVB_WP_636662239287310428',
        insuranceInfo: {
          companyType: '1',
          insuranceCompanyNumber: '0773',
          insuranceLocation: '033',
          agencyInsurance: '7777',
          insuranceStartDate: dayjs().format( 'YYYY.MM.DD' ),
          insurancePolicyNumber: '370100248',
        },
        carOwnerInfo: {
          isCarOwnerDifferentFromPolicyHolder: true,
          firstName: 'Oliver',
          lastName: 'Knappmann',
          naturalPerson: true,
          gender: 'm',
          zipCode: '12060',
          city: 'Berlin',
          street: 'Kurfürstenstrasse',
          houseNumber: '5',
          communityKey: '051111',
        },
        carInfo: {
          brandKey: 'undefined',
          brand: 'Volkswagen',
          typeKey: '111',
          variantVersionKey: '044',
          carType: '2',
          carClassKey: '01',
          structureKey: '0200',
          carClassText: 'PERSONENKRAFTWAGEN',
          structureText: 'GESCHLOSSEN',
          vin: 'WAUZZZNN26FN008532',
          motorVolume: '1884',
          powerKw: '123',
          isOldCar: false,
          isVehicleUsed: false,
        },
        licensePlate: {
          value: 'B OA 4569',
          mark: 'Z',
          changeMark: '0',
          issueDate: '2022-10-20T06:00:00+01:00',
        },
        authorityInfo: {
          areaKey: '05358',
          addDigit: '0',
          processedTimestamp: dayjs().add( -1, 'day' ).toISOString(),
        },
        changeLog: {
          shareMessageType: 2,
          cancellation: 0,
          ownerChange: 0,
          insuranceChange: 0,
          licensePlateChange: 0,
          carClassChange: 0,
          oldCarChange: 0,
        },
        timestamp: dayjs().add( -1, 'day' ).toISOString(),
      },
    }, {
      id: 89037,
      createdAt: dayjs().add( -1, 'day' ),
      processed: false,
      type: 'FZV',
      category: 'Änderung Halter',
      details: {
        messageType: 'GDV_VM_001',
        customerType: 'PP',
        messageIds: [
          'GDVDLevbprod3.21051.180901062913.000318195',
          'ZB053580DN-QG520__21824406284652XX00039',
        ],
        sender: {
          communicationPartner: 'GDV',
          softwareVersion: 'Evb.149.009293',
          timestamp: dayjs().add( -1, 'day' ).toISOString(),
        },
        requestNumber: 'eVB_WP_23645672339287332',
        insuranceInfo: {
          companyType: '1',
          insuranceCompanyNumber: '0773',
          insuranceLocation: '033',
          agencyInsurance: '7777',
          insuranceStartDate: dayjs().format( 'YYYY.MM.DD' ),
          insurancePolicyNumber: '0000001602',
        },
        carOwnerInfo: {
          isCarOwnerDifferentFromPolicyHolder: true,
          firstName: 'Oliver',
          lastName: 'Knappmann',
          naturalPerson: true,
          gender: 'm',
          zipCode: '12060',
          city: 'Berlin',
          street: 'Kurfürstenstrasse',
          houseNumber: '5',
          communityKey: '051111',
        },
        carInfo: {
          brandKey: 'undefined',
          brand: 'Volkswagen',
          typeKey: '111',
          variantVersionKey: '044',
          carType: '2',
          carClassKey: '01',
          structureKey: '0200',
          carClassText: 'PERSONENKRAFTWAGEN',
          structureText: 'GESCHLOSSEN',
          vin: 'WAUZZZNN26FN008532',
          motorVolume: '1884',
          powerKw: '123',
          isOldCar: false,
          isVehicleUsed: false,
        },
        licensePlate: {
          value: 'B OA 4569',
          mark: 'Z',
          changeMark: '0',
          issueDate: '2022-10-20T06:00:00+01:00',
        },
        authorityInfo: {
          areaKey: '05358',
          addDigit: '0',
          processedTimestamp: dayjs().add( -1, 'day' ).toISOString(),
        },
        changeLog: {
          shareMessageType: 2,
          cancellation: 0,
          ownerChange: 0,
          insuranceChange: 0,
          licensePlateChange: 0,
          carClassChange: 0,
          oldCarChange: 0,
        },
        timestamp: dayjs().add( -1, 'day' ).toISOString(),
      },
    } ];
  }
}
