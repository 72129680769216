import React, { Fragment, ReactElement } from 'react';

import { NavIcon } from './NavIcon';
import { NavBadge } from './NavBadge';
import { NavItem } from './NavItem';
import { NavCollapse as LoopNavCollapse } from './NavCollapse';
import { MenuItem } from 'App/menu-items';
import { blankLink } from 'config';
import { useAppRootActions, useAppRootState } from 'App/root/context';

export interface NavCollapseProps {
  collapse: MenuItem;
  type: string;
}

export const NavCollapse: React.FC<NavCollapseProps> = ( props ) => {
  const { menu: menuActions } = useAppRootActions();
  const { menu: menuState } = useAppRootState();
  const { collapse: cItem, type: cItemType } = props;
  const cItemId = cItem.id;
  const cItemIds = cItem.ids;

  // We need to simulate componentDidMount here without executing when some of variables changed so we need
  // to create refs because side effects are not repainted when refs changes.
  const propsRef = React.useRef( props );
  const menuStateRef = React.useRef( menuState );

  // Out useEffect function is only dependent on menuActions but we are using refs inside.
  React.useEffect( () => {
    const { collapse: item, type: itemType } = propsRef.current;
    const { openItems, triggerItems } = menuStateRef.current;
    const pathSegments = document.location.pathname.toString().split( '/' );
    let currentIndex = pathSegments.findIndex( ( id ) => id === item.id );

    if ( item && item.ids ) {
      currentIndex = pathSegments.findIndex( ( id ) => item.ids!.includes( id ) );
    }

    if ( currentIndex > -1 ) {
      // Check if required menu items are expanded / collapsed
      const menuItemCollapsed: boolean = !triggerItems.includes ( item.id );
      if ( menuItemCollapsed ) {
        menuActions.collapseToggle( item.id, itemType );
      }
    } else {
      const menuItemOpened: boolean = openItems.includes ( item.id );
      if ( menuItemOpened ) {
        menuActions.collapseToggle( item.id, itemType );
      }
    }
  }, [ menuActions ] );

  const { openItems, triggerItems } = menuState;

  let navItems: React.ReactNode = null;
  if ( cItem.children ) {
    const collapses: MenuItem[] = cItem.children;
    navItems = Object.keys( collapses ).map( ( key ) => {
      const index: number = parseInt( key );
      const item: MenuItem = collapses[index];
      switch ( item.type ) {
        case 'collapse':
          return <LoopNavCollapse key={ item.id } collapse={ item } type="sub" />;
        case 'item':
          return <NavItem key={ item.id } item={ item } />;
        default:
          return null;
      }
    } );
  }

  let itemTitle: React.ReactNode = cItem.title;
  if ( cItem.icon ) {
    itemTitle = <span className="pcoded-mtext">{ itemTitle }</span>;
  }

  let navLinkClass = [ 'nav-link' ];

  let navItemClass = [ 'nav-item', 'pcoded-hasmenu' ];
  const openIndex = openItems.findIndex( ( id ) => id === cItemId );
  if ( openIndex > -1 ) {
    navItemClass = [ ...navItemClass, 'active' ];
    navLinkClass = [ ...navLinkClass, 'active' ];
  }

  const triggerIndex = triggerItems.findIndex( ( id ) => id === cItemId );
  if ( triggerIndex > -1 ) {
    navItemClass = [ ...navItemClass, 'pcoded-trigger' ];
  }

  let currentIndex = ( ( document.location.pathname ).toString().split( '/' ) )
    .findIndex( ( id ) => id === cItemId );

  if ( cItemIds ) {
    currentIndex = ( ( document.location.pathname ).toString().split( '/' ) )
      .findIndex( ( id ) => cItemIds.includes( id ) );
  }
  if ( currentIndex > -1 ) {
    navItemClass = [ ...navItemClass, 'active' ];
    navLinkClass = [ ...navLinkClass, 'active' ];
  }

  const subContent: React.ReactNode = (
    <Fragment>
      <a
        href={ blankLink } className={ navLinkClass.join( ' ' ) }
        onClick={ () => menuActions.collapseToggle( cItemId, cItemType ) }
      >
        <NavIcon items={ cItem } />
        { itemTitle }
        <NavBadge items={ cItem } />
      </a>
      <ul className="pcoded-submenu">
        { navItems }
      </ul>
    </Fragment>
  );
  let mainContent: ReactElement | null = null;
  mainContent = (
    <li id={ 'menu-' + cItemId } className={ navItemClass.join( ' ' ) }>
      { subContent }
    </li>
  );

  return (
    <Fragment>
      { mainContent }
    </Fragment>
  );
};
