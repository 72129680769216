import React, { useContext } from 'react';

import { ApiContext } from 'Services/api-context';
import { ServiceProviderProps } from './common';
import { IEmailService, EmailServiceContext } from 'Services/email';

export const EmailServiceProvider: React.FC<ServiceProviderProps<IEmailService>> = ( props, context ) => {
  const api = useContext( ApiContext );
  const emailEditorService: IEmailService = new props.useClass( api );

  return (
    <EmailServiceContext.Provider value={ emailEditorService }>
      { props.children }
    </EmailServiceContext.Provider>
  );
};
