import React, { ReactElement } from 'react';
import { MenuItem } from 'App/menu-items';

export interface NavIconProps {
  items: MenuItem;
}

export const NavIcon: React.FC<NavIconProps> = ( props: NavIconProps ): ReactElement | null => {
  let navIcons: ReactElement | null = null;
  if ( props.items.icon ) {
    navIcons = <span className="pcoded-micon"><i className={ props.items.icon } /></span>;

    if ( props.items.iconType === 'material' ) {
      navIcons = <span className="pcoded-micon"><i className="material-icons f-22">{ props.items.icon }</i></span>;
    }
  }
  return navIcons;
};
