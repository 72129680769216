
import React from 'react';

import { ApiContext } from 'Services/api-context';
import { AuthServiceContext, IAuthService } from 'Services/auth';
import { ServiceProviderProps } from './common';
import { AppStorageContext } from './AppStorageCtx';

export const AuthServiceProvider: React.FC<ServiceProviderProps<IAuthService>> = ( props, context ) => {
  const storage = React.useContext( AppStorageContext );
  let api = React.useContext( ApiContext );
  if ( props.overrideApiService !== undefined ) {
    api = props.overrideApiService;
  }

  // Create authService that depends on api. To create service we are using useClass implementation
  const authService: IAuthService = new props.useClass( api, storage );

  return (
    <AuthServiceContext.Provider value={ authService }>
      { props.children }
    </AuthServiceContext.Provider>
  );
};
