import { PbmProduct } from '@cover42/ts-contracts';
import { BaseRequest } from 'Services/base';
import {
  InsuranceListResponse,
  CompareTariffsResponse,
  TariffListResponse,
  InsuranceTariff,
  GetSearchableDocumentOwnersResponse,
  GetSearchableDocumentsResponse,
  GetSearchKeywordsResponse,
} from './interfaces';

const documentServiceApiServer = process.env.REACT_APP_DOCUMENT_SERVICE_API_SERVER ?? '';
const pbmServiceApiServer = process.env.REACT_APP_PBM_SERVICE_API_SERVER ?? '';

export class GetInsuranceListRequest extends BaseRequest<InsuranceListResponse> {
  constructor(
    product: PbmProduct,
  ) {
    const queryParams = new URLSearchParams();
    queryParams.set( 'product', product );

    super( {
      method: 'GET',
      url: `${pbmServiceApiServer}/pbmservice/v1/tariff-comparisons/insurance-companies`,
      responseType: 'json',
      params: queryParams,
    } );
  }
}

export class GetTariffListRequest extends BaseRequest<TariffListResponse> {
  constructor(
    product: PbmProduct,
    insurerName: string,
  ) {
    const queryParams = new URLSearchParams();
    queryParams.set( 'product', product );
    queryParams.set( 'insurerName', insurerName );

    super( {
      method: 'GET',
      url: `${pbmServiceApiServer}/pbmservice/v1/tariff-comparisons/tariffs`,
      responseType: 'json',
      params: queryParams,
    } );
  }
}

export class GetCompareTariffsRequest extends BaseRequest<CompareTariffsResponse> {
  constructor(
    product: PbmProduct,
    dataForm: InsuranceTariff[],
  ) {
    super( {
      method: 'POST',
      url: `${pbmServiceApiServer}/pbmservice/v1/tariff-comparisons`,
      responseType: 'json',
      data: { product, 'insuranceTariffs': dataForm },
    } );
  }
}


export class GetSearchableDocumentOwnersRequest extends BaseRequest<GetSearchableDocumentOwnersResponse> {
  constructor( product: PbmProduct ) {
    super( {
      method: 'GET',
      url: `${documentServiceApiServer}/documentservice/v1/searchable-document-owners`,
      responseType: 'json',
      params: {
        'product': product,
      },
    } );
  }
}

export class GetSearchableDocumentsRequest extends BaseRequest<GetSearchableDocumentsResponse> {
  constructor(
    searchText: string,
    ownerIds: number[],
    product: PbmProduct,
  ) {
    super( {
      method: 'GET',
      url: `${documentServiceApiServer}/documentservice/v1/searchable-documents`,
      responseType: 'json',
      params: {
        'searchText': searchText,
        'ownerIds': ( ownerIds.length ? ownerIds.join( '|' ) : '*' ),
        'product': product,
      },
    } );
  }
}

export class GetSearchKeywordsRequest extends BaseRequest<GetSearchKeywordsResponse> {
  constructor(
    searchText: string,
  ) {
    super( {
      method: 'GET',
      url: `${documentServiceApiServer}/documentservice/v1/search-keywords`,
      responseType: 'json',
      params: {
        'searchText': searchText,
      },
    } );
  }
}

