import cloneDeep from 'lodash/cloneDeep';
import { IDashboardDates, IFilter, IFilterSet, IHashParam, IValueSet } from 'Services/dashboard/interfaces';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { isEmpty, upperFirst } from 'lodash';
import { IDashboardHistoryItem } from 'Services/alerts/interfaces';
import { AlertType } from 'Services/alerts/enums';
import { DateName } from 'Services/dashboard/enums';
import { PbmProduct } from '@cover42/ts-contracts';

const localeList: Record<string, string> = {
  'de': 'de-DE',
  'en': 'en-US',
  'pl': 'pl-PL',
};
const hideFilters: string[] = [ 'Kunde' ];
export const hideFiltersByWords: string[] = [ 'title', 'app' ];

export const DEFAULT_VALUES_ALL: string[] = [ 'All', 'Alle' ];
export const hashClient: string = '#c/';
export const timeoutLoadDashboard = 90000;

export const convertFiltersToDashboardFilters = ( filters: IFilter[] ): IValueSet => {
  const cloneFilters = cloneDeep( filters );
  const resFilter: IValueSet = {};
  cloneFilters?.forEach( ( f ) => {
    resFilter[f.name] = f.value;
  } );
  return resFilter;
};

export const convertHashParamToDashboardFilters = ( params: string ): IValueSet => {
  const filters = params.split( ';' ).filter( ( v ) => v !== '' );
  let resFilter: IValueSet = {};

  filters.forEach( ( f ) => {
    const filterNameValuePair = f.split( ':' );
    if ( filterNameValuePair.length === 2 && filterNameValuePair[1] ) {
      const filterValues = filterNameValuePair[1].split( ',' );
      const filterName = filterNameValuePair[0];

      if ( filterValues.length > 1 ) {
        const decodeValues = filterValues.filter(
          ( v ) => !DEFAULT_VALUES_ALL.includes( v ),
        ).map( ( val ) => decodeURIComponent( val ) );
        resFilter = { ...resFilter, [filterName]: decodeValues };
      } else {
        const filterValue = filterNameValuePair[1];
        if ( !DEFAULT_VALUES_ALL.includes( filterValue ) ) {
          resFilter = { ...resFilter, [filterName]: decodeURIComponent( filterValue ) };
        }
      }
    }
  } );

  return resFilter;
};

export const convertHashParamToFilters = ( params: string ): IFilter[] => {
  const filterParams = params.split( ';' ).filter( ( v ) => v !== '' );
  const filters: IFilter[] = [];

  filterParams.forEach( ( f ) => {
    const filterNameValuePair = f.split( ':' );
    if ( filterNameValuePair.length === 2 && filterNameValuePair[1] ) {
      const filterValues = filterNameValuePair[1].split( ',' );
      const filterName = filterNameValuePair[0];

      if ( filterValues.length > 1 ) {
        const decodeValues = filterValues.filter(
          ( v ) => !DEFAULT_VALUES_ALL.includes( v ),
        ).map( ( val ) => decodeURIComponent( val ) );
        filters.push( {
          name: filterName,
          value: decodeValues,
        } );
      } else {
        const filterVal = filterNameValuePair[1];
        if ( !DEFAULT_VALUES_ALL.includes( filterVal ) ) {
          filters.push( {
            name: filterName,
            value: decodeURIComponent( filterVal ),
          } );
        }
      }
    }
  } );
  return filters;
};

export const resetFiltersParams = (
  filters: IFilter[], initialFilters: IFilter[],
  filterValueForAll: string,
): IValueSet => {
  let resetFilter: IValueSet = {};

  const cloneFilters = cloneDeep( filters );
  const cloneInitialFilters = cloneDeep( initialFilters );
  cloneFilters?.forEach( ( f ) => {
    const initFilter = cloneInitialFilters?.find( ( item ) => item.name === f.name );

    if ( initFilter ) {
      resetFilter = {
        ...resetFilter,
        [initFilter.name]: !isEmpty( filterValueForAll ) ? initFilter.value : filterValueForAll,
      };
    } else {
      if ( Array.isArray( f.value ) ) {
        resetFilter = { ...resetFilter, [f.name]: [ filterValueForAll ] };
      } else {
        resetFilter = { ...resetFilter, [f.name]: filterValueForAll };
      }
    }
  } );
  return resetFilter;
};

export const doFilter = ( dataFilter: IFilter[] ): IFilter[] => {
  return dataFilter.filter(
    ( f ) => !DEFAULT_VALUES_ALL.includes( f.value as string )
      && f.value !== null
      && !hideFilters.includes( f.name )
      && !hideFiltersByWords.some( ( word ) => f.name.startsWith( word ) ),
  );
};

export const doFilterInitial = ( dataFilter: IFilter[] ): IFilter[] => {
  return dataFilter.filter(
    ( f ) => f.value !== null
      && !DEFAULT_VALUES_ALL.includes( f.value as string )
      && !hideFilters.includes( f.name ),
  );
};

const serializeFiltersToHash = ( dataFilter: IFilter[] ): string => {
  return dataFilter.map( ( param ) => {
    let kv: string = '';
    if ( param.value === undefined ) {
      kv = param.name;
    } else {
      if ( Array.isArray( param.value ) ) {
        kv = `${param.name}:${ param.value.map( ( v ) => encodeURIComponent( v ) ).join( ',' ) }`;
      } else {
        kv = `${param.name}:${encodeURIComponent( param.value as string ) }`;
      }
    }
    return kv;
  } ).join( ';' );
};

export const serializeSettingsToHash = ( settings: IFilterSet ): string => {
  let hash: string = hashClient;

  if ( settings.sheetId ) {
    hash = `${hash}sheetId=${settings.sheetId}&`;
  }

  if ( settings.filters ) {
    const filtersParams = serializeFiltersToHash( settings.filters );

    if ( filtersParams ) {
      hash = `${hash}filters=${filtersParams}`;
    }
  }

  return hash !== hashClient ? hash : '';
};

export const parseClientParams = ( hash: string ): IHashParam[] => {
  const splitHash = hash.split( '/' );
  if( splitHash.length > 1 && splitHash[0] === 'c' ) {
    return splitHash[1].split( '&' ).filter( ( v ) => v !== '' ).map( ( kv ) => {
      const tmp = kv.split( '=' );
      const param: IHashParam = {
        name: tmp[0],
        value: tmp[1],
      };
      return param;
    } );
  }
  return [];
};

export const createFilterState = ( dbFilterSettings: IFilterSet, urlHash: string ): IFilterSet | null => {
  const urlParams: IHashParam[] = parseClientParams( urlHash );
  if ( urlParams.length > 0 ) {
    const filterItemsFromUrlHash = urlParams.find( ( p ) => p.name === 'filters' );
    if ( filterItemsFromUrlHash?.value ) {
      const sheetItem = urlParams.find( ( p ) => p.name === 'sheetId' );
      const state: IFilterSet = {
        sheetId: sheetItem?.value ? sheetItem?.value : dbFilterSettings.sheetId,
        filters: convertHashParamToFilters( filterItemsFromUrlHash.value ),
        initialFilters: dbFilterSettings.initialFilters,
      };
      return state;
    }
  } else {
    return !isEmpty( dbFilterSettings ) ? dbFilterSettings : null;
  }
  return null;
};

export const serializeFiltersForAnalytics = ( data: IFilter[] ): string => {
  const cloneFilters = cloneDeep( data );
  return cloneFilters.sort( ( a, b ) => a.name.localeCompare( b.name ) )
    .map( ( param ) => {
      let filters: string = '';
      if ( param.value === undefined ) {
        filters = param.name;
      } else {
        if ( Array.isArray( param.value ) ) {
          filters = `${param.name}:${
            param.value.sort( ( vOne, vTwo ) => vOne.localeCompare( vTwo ) ).map( ( val ) => val ).join( ',' )
          }`;
        } else {
          filters = `${param.name}:${param.value as string}`;
        }
      }
      return filters;
    } ).join( ';' );
};

export const useDashboardLocale = (): string => {
  const { i18n } = useTranslation();
  const locale = React.useMemo( () => {
    const lng = i18n.language;
    const currentLocale = localeList[lng];
    if ( currentLocale ) {
      return currentLocale;
    }
    // Set German localization for dashboard by default
    return localeList['de'];
  }, [ i18n.language ] );
  return locale;
};

const prepareFilter = ( filterData: string[], transFilterAll: string ): string[] => {
  const allNameFilter = DEFAULT_VALUES_ALL[0];
  let filters = filterData.filter( ( item ) => !isEmpty( item ) )!;

  if ( filters.includes( allNameFilter ) ) {
    filters = [ transFilterAll ];
  }

  return filters.length ? filters : [ transFilterAll ];
};

export const serializeAlertHistoryToFilterSet = (
  alertHistory: IDashboardHistoryItem,
  transFilterAll: string,
): IValueSet => {
  const channelNames = prepareFilter( alertHistory.channelNames!, transFilterAll );
  let filters: IValueSet = {
    WocheSingle: alertHistory.week,
    JahrSingle: alertHistory.year,
    TagSingle: alertHistory.day,
    Quelle: channelNames,
  };

  if ( AlertType.Price === alertHistory.type || AlertType.Ranking === alertHistory.type ) {
    const negativePrice = alertHistory.minFactor > 0 ? -alertHistory.minFactor : alertHistory.minFactor;
    const insurers = prepareFilter( alertHistory.insurers, transFilterAll );
    const keyFilter = upperFirst( alertHistory.type );

    filters = {
      ...filters,
      [`thresholdNegativeAlert${keyFilter}`]: negativePrice,
      [`thresholdPositiveAlert${keyFilter}`]: alertHistory.maxFactor,
      Wettbewerber: insurers,
    };
  }

  return filters;
};

export const getFilterDates = (
  filters: IFilterSet,
  product: PbmProduct | null,
): IDashboardDates=> {
  const filterDates = {
    day: 0,
    week: 0,
    year: 0,
  };
  const { filters: currentFilters, initialFilters } = filters;

  if( !currentFilters ) {
    return filterDates;
  }

  const currentFilterMap = {};
  currentFilters.forEach( ( filter ) => currentFilterMap[filter.name] = filter.value );

  filterDates.day = currentFilterMap[DateName.TagSingle] ?? 0;
  filterDates.week = currentFilterMap[DateName.WocheSingle] ?? 0;
  filterDates.year = currentFilterMap[DateName.JahrSingle] ?? 0;

  if( !initialFilters ) {
    return filterDates;
  }

  const initialFilterMap = {};
  initialFilters.forEach( ( filter ) => initialFilterMap[filter.name] = filter.value );

  if ( filterDates.day === 0 ) {
    filterDates.day = initialFilterMap[DateName.WocheSingle] ?? 0;
  }
  if ( filterDates.week === 0 ) {
    filterDates.week = initialFilterMap[DateName.TagSingle] ?? 0;
  }
  if ( filterDates.year === 0 ) {
    filterDates.year = initialFilterMap[DateName.JahrSingle] ?? 0;
  }

  return formatDates( filterDates, product );
};


export const compareDates = (
  filterDates: IDashboardDates,
  newestScrapingDates: IDashboardDates ): boolean => {
  return JSON.stringify( filterDates ) === JSON.stringify( newestScrapingDates );
};

export const formatDates = (
  filterDates: IDashboardDates,
  product: PbmProduct | null,
): IDashboardDates => {
  if ( product !== PbmProduct.Car ) {
    delete filterDates.day;
  }

  return filterDates;
};
