import React, { useContext } from 'react';

import { ApiContext } from 'Services/api-context';
import { IStatusTransitionRuleService, StatusTransitionRuleServiceContext } from 'Services/status-transition-rule';
import { ServiceProviderProps } from './common';

export const StatusTransitionRuleServiceProvider: React.FC<ServiceProviderProps<IStatusTransitionRuleService>>
  = ( props, context ) => {

    const api = useContext( ApiContext );

    const statusTransitionRuleService: IStatusTransitionRuleService = new props.useClass( api );

    return (
      <StatusTransitionRuleServiceContext.Provider value={ statusTransitionRuleService }>
        { props.children }
      </StatusTransitionRuleServiceContext.Provider>
    );
  };
