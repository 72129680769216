import React from 'react';
import { Dropdown } from 'react-bootstrap';
import { NavProfile } from './NavProfile';
import { NavHelp } from './NavHelp';
import { AppLink } from 'App/components/utils/routing/AppLink';
import { permissionDict as p } from 'Services/permissions/permission-dict';
import { NavProductsSelector } from './NavProductSelector';
import { useAppSecurity } from 'App/components/utils/providers/AppProductProvider';

export const NavRight: React.FC = () => {
  const user = useAppSecurity();
  const canDisplayTicketsLink = user.hasPermissions( [ p.ts_ListTickets ] );

  return (
    <ul className="navbar-nav ml-auto">
      { canDisplayTicketsLink &&
        <li>
          <AppLink className="align-vertical-middle" to="/tickets">
            <i className="material-icons material-icons-outlined align-vertical-middle">assignment</i>
          </AppLink>
        </li>
      }
      <li>
        <NavHelp />
      </li>
      <li>
        <NavProductsSelector />
      </li>
      <li>
        <Dropdown className="drp-user">
          <NavProfile />
        </Dropdown>
      </li>
    </ul>
  );
};
