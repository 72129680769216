import React from 'react';
import { IApiService, ApiResponse, PagingRequest } from './base';
import { Logger } from 'loglevel';
import {
  IEmailLayout,
  IListEmailLayoutsResponse,
  INewEmailTemplate,
  IEmailTemplate,
  IListEmailTemplateResponse,
  IUpdateEmailTemplate,
  INewEmailLayout,
  IUpdateEmailLayout,
} from './emails/interfaces';
import {
  CreateEmailLayoutRequest,
  ListEmailLayoutsRequest,
  GetEmailLayoutRequest,
  UpdateEmailLayoutRequest,
  DeleteEmailLayoutRequest,
  CreateEmailTemplateRequest,
  ListEmailTemplateRequest,
  DeleteTemplateRequest,
  GetEmailTemplateRequest,
  UpdateEmailTemplateRequest,
} from './emails/requests';
import dayjs from 'dayjs';

export interface IEmailService {
  createEmailLayout( layout: INewEmailLayout ): Promise<IEmailLayout>;
  getEmailLayout( layoutId: number ): Promise<IEmailLayout>;
  updateEmailLayout( layoutId: number, layoutUpdate: IUpdateEmailLayout ): Promise<IEmailLayout>;
  getEmailLayouts( searchValue: string, paging: PagingRequest, expand?: string[] ): Promise<IListEmailLayoutsResponse>;
  deleteEmailLayout( layoutId: number ): Promise<ApiResponse>;
  createEmailTemplate( template: INewEmailTemplate ): Promise<IEmailTemplate>;
  getEmailTemplate( templateId: number ): Promise<IEmailTemplate>;
  updateEmailTemplate( templateId: number, templateUpdate: IUpdateEmailTemplate ): Promise<IEmailTemplate>;
  getEmailTemplates(
    searchValue: string, paging: PagingRequest, expand?: string[] ): Promise<IListEmailTemplateResponse>;
  deleteEmailTemplate( templateId: number ): Promise<ApiResponse>;
}

export class EmailService implements IEmailService {
  protected api: IApiService;
  protected logger: Logger;

  constructor( api: IApiService, logger: Logger ) {
    this.api = api;
    this.logger = logger;
  }

  createEmailLayout( layout: INewEmailLayout ): Promise<IEmailLayout> {
    return this.api.request( new CreateEmailLayoutRequest( layout ) )
      .then( ( response ) => {
        const layoutData = {
          ...response.layout,
        };

        return layoutData;
      } );
  }

  getEmailLayout( layoutId: number ): Promise<IEmailLayout> {
    return this.api.request( new GetEmailLayoutRequest( layoutId ) )
      .then( ( response ) => {
        const layout = {
          ...response.layout,
        };

        return layout;
      } );
  }

  updateEmailLayout( layoutId: number, layoutUpdate: IUpdateEmailLayout ): Promise<IEmailLayout> {
    return this.api.request( new UpdateEmailLayoutRequest( layoutId, layoutUpdate ) )
      .then( ( response ) => {
        const layout = {
          ...response.layout,
        };

        return layout;
      } );
  }

  getEmailLayouts( searchValue: string, paging: PagingRequest, expand?: string[] ): Promise<IListEmailLayoutsResponse> {
    return this.api.request( new ListEmailLayoutsRequest( searchValue, paging, expand ) )
      .then( ( response ) => {
        let emailLayouts: IEmailLayout[] = [];
        const { items, nextPageToken } = response;

        if ( items ) {
          emailLayouts = items.map( ( item ) => {
            item.createdAt = dayjs( item.createdAt );

            return item;
          } );
        }

        const res: IListEmailLayoutsResponse = {
          items: emailLayouts,
          nextPageToken: nextPageToken,
        };

        return res;
      } );
  }

  deleteEmailLayout( layoutId: number ): Promise<ApiResponse> {
    return this.api.request( new DeleteEmailLayoutRequest( layoutId ) )
      .then( ( response ) => {
        return response;
      } );
  }

  createEmailTemplate( template: INewEmailTemplate ): Promise<IEmailTemplate> {
    return this.api.request( new CreateEmailTemplateRequest( template ) )
      .then( ( response ) => {
        const tempResponse = {
          ...response.template,
        };

        return tempResponse;
      } );
  }

  getEmailTemplate( templateId: number ): Promise<IEmailTemplate> {
    return this.api.request( new GetEmailTemplateRequest( templateId ) )
      .then( ( response ) => {
        const template = {
          ...response.template,
        };

        return template;
      } );
  }

  updateEmailTemplate( templateId: number, templateUpdate: IUpdateEmailTemplate ): Promise<IEmailTemplate> {
    return this.api.request( new UpdateEmailTemplateRequest( templateId, templateUpdate ) )
      .then( ( response ) => {
        const template = {
          ...response.template,
        };

        return template;
      } );
  }

  getEmailTemplates(
    searchValue: string, paging: PagingRequest, expand?: string[],
  ): Promise<IListEmailTemplateResponse> {
    return this.api.request( new ListEmailTemplateRequest( searchValue, paging, expand ) )
      .then( ( response ) => {
        let emailTemplates: IEmailTemplate[] = [];
        const { items, nextPageToken } = response;

        if ( items ) {
          emailTemplates = items.map( ( item, index ) => {
            const email: IEmailTemplate = {
              ...item,
              createdAt: new Date( item.createdAt! ),
              updatedAt: new Date( item.updatedAt! ),
            };

            return email;
          } );
        }

        const res: IListEmailTemplateResponse = {
          items: emailTemplates,
          nextPageToken: items ? items.length < paging.pageSize ? '1' : nextPageToken : nextPageToken,
        };

        return res;
      } );
  }

  deleteEmailTemplate( templateId: number ): Promise<ApiResponse> {
    return this.api.request<ApiResponse>( new DeleteTemplateRequest( templateId ) )
      .then( ( response ) => {
        return response;
      } );
  }
};

export const EmailServiceContext: React.Context<IEmailService> = React.createContext( undefined as any );

export const useEmailService = (): IEmailService => {
  return React.useContext( EmailServiceContext );
};
