import React from 'react';
import { PagingRequest, IApiService } from './base';
import { Logger } from 'loglevel';
import { IApiListResponse } from './policies/new/service';
import { IPublicUrl, IDocxTemplate, IDocxTemplateResponse,
  IPreSignedPost, IUploadDocxTemplate } from './docx-template/interfaces';
import { GetDocxTemplateRequest, ListDocxTemplatesRequest, UploadDocxWithPreSignedUrlRequest,
  UploadDocxTemplateRequest, GetPublicUrlRequest, DeleteDocxTemplateByCodeRequest } from './docx-template/requests';
import dayjs from 'dayjs';

export interface IDocxTemplateService {
  listDocxTemplates(
    searchValue: string, paging: PagingRequest, expand?: string[]
  ): Promise<IApiListResponse<IDocxTemplate>>;
  getDocxTemplate( code: string, expand?: string[] ): Promise<IDocxTemplate>;
  uploadDocxTemplate( data: IUploadDocxTemplate ): Promise<IPreSignedPost>;
  uploadDocxTemplateUsingPreSignedUrl(
    { fields, url }: IPreSignedPost,
    file: File,
  ): Promise<Record<string, string>>;
  getPublicUrl( code: string ): Promise<IPublicUrl>;
  deleteDocxTemplateByCode( code: string ): Promise<void>;
}

export class DocxTemplateService implements IDocxTemplateService {
  protected api: IApiService;
  protected logger: Logger;

  constructor( api: IApiService, logger: Logger ) {
    this.api = api;
    this.logger = logger;
  }

  listDocxTemplates(
    searchValue: string, paging: PagingRequest, expand?: string[],
  ): Promise<IApiListResponse<IDocxTemplate>> {
    return this.api.request( new ListDocxTemplatesRequest( searchValue, paging, expand ) )
      .then( ( response ) => {
        let docxTemplates: IDocxTemplate[] = [];
        const { items, nextPageToken } = response;

        if ( items ) {
          docxTemplates = items.map( ( item, index ) => {
            const docxTemplate: IDocxTemplate = {
              ...item,
              createdAt: dayjs.utc( item.createdAt ),
              updatedAt: dayjs.utc( item.updatedAt ),
            };

            return docxTemplate;
          } );
        }

        const res: IApiListResponse<IDocxTemplate> = {
          items: docxTemplates,
          nextPageToken: docxTemplates ? docxTemplates.length < paging.pageSize ? '1' : nextPageToken : nextPageToken,
        };

        return res;
      } );
  }

  getDocxTemplate( code: string, expand?: string[] ): Promise<IDocxTemplate> {
    return this.api.request<IDocxTemplateResponse>( new GetDocxTemplateRequest( code, expand ) )
      .then( ( response ) => {
        const template = {
          ...response.docxTemplate,
          createdAt: dayjs.utc( response.docxTemplate.createdAt ),
          updatedAt: dayjs.utc( response.docxTemplate.updatedAt ),
        };

        return template;
      } );
  }

  uploadDocxTemplate( data: IUploadDocxTemplate ): Promise<IPreSignedPost> {
    return this.api.request<IPreSignedPost>( new UploadDocxTemplateRequest( data ) )
      .then( ( response ) => {
        if ( response === undefined ) {
          throw new Error( `Can't upload, filename: ${data.filename}` );
        }

        return response;
      } );
  }

  async uploadDocxTemplateUsingPreSignedUrl(
    { fields, url }: IPreSignedPost,
    file: File,
  ): Promise<Record<string, string>> {
    const formData = new FormData();
    Object.entries( fields ).forEach( ( [ k, v ] ) => {
      formData.append( k, v.toString() );
    } );
    formData.append( 'file', file, file.name );
    const response = await this.api.request(
      new UploadDocxWithPreSignedUrlRequest( formData, url ),
    );

    return response;
  }

  async getPublicUrl( code: string ): Promise<IPublicUrl> {
    const response = await this.api.request( new GetPublicUrlRequest( code ) );
    return response;
  }

  async deleteDocxTemplateByCode( code: string ): Promise<void> {
    await this.api.request( new DeleteDocxTemplateByCodeRequest( code ) );
  }};

export const DocxTemplateServiceContext: React.Context<IDocxTemplateService> = React.createContext( undefined as any );

export const useDocxTemplateService = (): IDocxTemplateService => {
  return React.useContext( DocxTemplateServiceContext );
};
