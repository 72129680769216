import { BaseRequest, PagingRequest } from 'Services/base';
import { AddProfileItem, CustomerPaymentItem, ListCustomersResponse, UpdateCustomerItem } from './interfaces';
import { getQueryParamsForListAPI } from 'Services/api';

export class CustomersRequest extends BaseRequest {
  constructor() {
    super( {
      method: 'GET',
      url: '/accountservice/v1/accounts',
      responseType: 'json',
    } );
  }
}

export class ListCustomersRequest extends BaseRequest<ListCustomersResponse> {
  constructor(
    paging: PagingRequest,
    searchValue: string,
  ) {
    super( {
      method: 'GET',
      url: '/accountservice/v1/accounts',
      responseType: 'json',
      params: getQueryParamsForListAPI( paging, searchValue, 'search' ),
    } );
  }
}

export class GetCustomerByIdRequest extends BaseRequest {
  constructor(
    customerId: number,
  ) {

    super( {
      method: 'GET',
      url: `/customers/${customerId}`,
      responseType: 'json',
    } );
  }
}

export class GetCustomerByCodeRequest extends BaseRequest {
  constructor(
    customerCode: string,
  ) {

    super( {
      method: 'GET',
      url: `/accountservice/v1/accounts/${customerCode}`,
      responseType: 'json',
    } );
  }
}

export class AddCustomerRequest extends BaseRequest {
  constructor(
    customer: AddProfileItem,
  ) {

    super( {
      method: 'POST',
      url: '/customers',
      data: customer,
      responseType: 'json',
    } );
  }
}

export class UpdateCustomerRequest extends BaseRequest {
  constructor(
    account: UpdateCustomerItem,
    code: string,
  ) {

    super( {
      method: 'PUT',
      url: `/accountservice/v1/accounts/${code}`,
      data: account,
      responseType: 'json',
    } );
  }
}

export class DeleteCustomerRequest extends BaseRequest {
  constructor(
    customerId: number,
  ) {

    super( {
      method: 'DELETE',
      url: `/customers/${customerId}`,
      responseType: 'json',
    } );
  }
}

export class GetPaymentByCustomerIdRequest extends BaseRequest {
  constructor(
    customerId: number,
  ) {

    super( {
      method: 'GET',
      url: `/customers/${customerId}/payment`,
      responseType: 'json',
    } );
  }
}

export class AddPaymentRequest extends BaseRequest {
  constructor(
    customerId: number,
    payment: CustomerPaymentItem,
  ) {

    super( {
      method: 'POST',
      url: `/customers/${customerId}/payment`,
      data: payment,
      responseType: 'json',
    } );
  }
}

export class UpdatePaymentRequest extends BaseRequest {
  constructor(
    customerId: number,
    payment: CustomerPaymentItem,
  ) {

    super( {
      method: 'PUT',
      url: `/customers/${customerId}/payment`,
      data: payment,
      responseType: 'json',
    } );
  }
}

export class DeletePaymentRequest extends BaseRequest {
  constructor(
    customerId: number,
  ) {

    super( {
      method: 'DELETE',
      url: `/customers/${customerId}/payment`,
      responseType: 'json',
    } );
  }
}
