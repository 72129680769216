import React from 'react';

export interface IAppDefaultStyles {
  maxMenuHeight: number;
  bgColorSelected: string;
  bgColorFocused: string;
  borderColorDefault: string;
  borderColorError: string;
  borderColorFocused: string;
  boxShadowDefault: string;
  boxShadowError: string;
  boxShadowFocused: string;
  textColor: string;
  placeholderColor: string;
  widthInputUserSearch: string;
  maxMenuHeightUserSearch: number;
}

const defaultStyles: IAppDefaultStyles = {
  maxMenuHeight: 400,
  bgColorSelected: '#F0F2F4',
  bgColorFocused: '#04a9f5',
  borderColorDefault: '#cccccc',
  borderColorError: '#dc3545',
  borderColorFocused: '#80bdff',
  boxShadowDefault: '',
  boxShadowError: '0 0 0 0.2rem #dc354540',
  boxShadowFocused: '0 0 0 0.2rem #007bff40',
  textColor: '#111',
  placeholderColor: '',
  widthInputUserSearch: '243px',
  maxMenuHeightUserSearch: 183,
};

export const AppDefaultStylesContext: React.Context<IAppDefaultStyles> = React.createContext( defaultStyles );

export const useAppDefaultStyles = (): IAppDefaultStyles => {
  return React.useContext( AppDefaultStylesContext );
};
