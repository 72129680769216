import React from 'react';
import { JsonValue } from '@cover42/protobuf-util';
import {
  DashboardResponse, IDataSettings, IFilterSet,
  IFilter, Dashboard, CreateDashboardReqBody,
  UpdateDashboardReqBody,
  IDashboardDates,
} from './dashboard/interfaces';
import { IApiService, PagingRequest } from 'Services/base';
import {
  AlertDashboardSettingsRequest,
  CreateDashboardRequest,
  DashboardEmbeddedUrlRequest,
  DashboardRequest,
  DeleteDashboardRequest,
  ExportDashboardDataRequest,
  DashboardSettingsRequest,
  GetDashboardRequest,
  ListDashboardsRequest,
  SaveSettingsDashboardRequest,
  SettingsDashboardRequest,
  UpdateDashboardRequest,
} from './dashboard/requests';
import { GetDatesRequest } from'./users/users';
import { DEFAULT_VALUES_ALL } from '../App/components/management/dashboard/hooks/dashboard-hooks';
import { isEmpty } from 'lodash';
import { IApiListResponse } from 'Services/policies/new/service';
import { IAlertDashboard } from './dashboard/interfaces';

export interface IDashboardService {
  getSettingsDashboard( dashboardId: string ): Promise<IFilterSet>;
  saveSettingsDashboard( dashboardSettings: IDataSettings ): Promise<IDataSettings>;
  getEmbedUrl( productType: string ): Promise<DashboardResponse>;
  getDashboard( dashboardCode: string ): Promise<Dashboard>;
  createDashboard( payload: CreateDashboardReqBody ): Promise<Dashboard>;
  updateDashboard( payload: UpdateDashboardReqBody ): Promise<Dashboard>;
  deleteDashboard( dashboardCode: string ): Promise<void>;
  getEmbedUrlById( dashboardId: string ): Promise<DashboardResponse>;
  getDashboards( searchValue: string, paging: PagingRequest, expand?: string[] ): Promise<IApiListResponse<Dashboard>>;
  getDashboardIdForAlert( alertType: string ): Promise<string>;
  getDashboardSettingsByKey( dashboardKey: string ): Promise<JsonValue>;
  getNewestScrapingDates( product: string ): Promise<IDashboardDates>;
  exportDashboardData( dashboardName: string, dashboardPageUrl: string ): Promise<string>;
}

export class DashboardService implements IDashboardService {
  protected api: IApiService;
  constructor( api: IApiService ) {
    this.api = api;
  }

  getSettingsDashboard( dashboardId: string ): Promise<IFilterSet> {
    return this.api.request( new SettingsDashboardRequest( dashboardId ) )
      .then( ( response ) => {
        const filterSet: IFilterSet = {
          ...response.values[0],
        };

        if ( response.values[0] && response.values[0].filters ) {
          filterSet.filters = this.filterData( response.values[0].filters );
        }

        return filterSet;
      } );
  }

  saveSettingsDashboard( dashboardSettings: IDataSettings ): Promise<IDataSettings> {
    return this.api.request( new SaveSettingsDashboardRequest( dashboardSettings ) )
      .then( ( response ) => {
        return response;
      } );
  }

  getEmbedUrl( productType: string ): Promise<DashboardResponse> {
    return this.api.request( new DashboardRequest( productType ) )
      .then( ( response ) => {
        const res: DashboardResponse = {
          embedUrl: response.embedUrl,
          dashboardId: this.getDashboardId( response.embedUrl ),
        };
        return res;
      } );
  }
  createDashboard( payload: CreateDashboardReqBody ): Promise<Dashboard> {
    return this.api.request( new CreateDashboardRequest( payload ) )
      .then( ( response ) => {
        return response.dashboard;
      } );
  }

  updateDashboard( payload: UpdateDashboardReqBody ): Promise<Dashboard> {
    return this.api.request( new UpdateDashboardRequest( payload ) )
      .then( ( response ) => {
        return response.dashboard;
      } );
  }

  getDashboard( dashboardCode: string ): Promise<Dashboard> {
    return this.api.request( new GetDashboardRequest( dashboardCode ) )
      .then( ( response ) => {
        const dashboard = {
          ...response.dashboard,
        };

        return dashboard;
      } );
  }

  deleteDashboard( dashboardCode: string ): Promise<void> {
    return this.api.request( new DeleteDashboardRequest( dashboardCode ) )
      .then( () => {
      } );
  }

  getEmbedUrlById( dashboardId: string ): Promise<DashboardResponse> {
    return this.api.request( new DashboardEmbeddedUrlRequest( dashboardId ) )
      .then( ( response ) => {
        const res: DashboardResponse = {
          embedUrl: response.url,
          dashboardId: this.getDashboardId( response.url ),
        };
        return res;
      } );
  }

  getDashboards(
    searchValue: string, paging: PagingRequest, expand?: string[],
  ): Promise<IApiListResponse<Dashboard>> {
    return this.api.request( new ListDashboardsRequest( searchValue, paging, expand ) )
      .then( ( response ) => {
        let dashboards: Dashboard[] = [];
        const { items, nextPageToken } = response;

        if ( items ) {
          dashboards = items.map( ( item, index ) => {
            const dashboard: Dashboard = {
              ...item,
              createdAt: new Date( item.createdAt as string ),
              updatedAt: new Date( item.updatedAt as string ),
            };

            return dashboard;
          } );
        }

        const res: IApiListResponse<Dashboard> = {
          items: dashboards,
          nextPageToken: nextPageToken ? nextPageToken : '1',
        };

        return res;
      } );
  }

  getDashboardIdForAlert( alertType: string ): Promise<string> {
    return this.api.request( new AlertDashboardSettingsRequest( ) )
      .then( ( response ) => {
        let dashboardId = '';
        const alertDashboard: IAlertDashboard = {
          ...response.values[0],
        };

        if ( alertDashboard && alertDashboard[alertType] ) {
          dashboardId = alertDashboard[alertType];
        }

        return dashboardId;
      } );
  }

  getDashboardSettingsByKey( dashboardKey: string ): Promise<JsonValue> {
    return this.api.request( new DashboardSettingsRequest( dashboardKey ) )
      .then( ( response ) => {
        if ( response && response.values.length > 0 ) {
          const responseDashboard: JsonValue = {
            ...response.values[0],
          };

          return responseDashboard;
        }

        return null;
      } );
  }

  getNewestScrapingDates( product: string ): Promise<IDashboardDates> {
    return this.api.request( new GetDatesRequest( product ) )
      .then( ( response ) => {
        const newestScraping = response.items.slice( -1 )[0];
        const { week, year, days } = newestScraping;
        const day = days.slice( -1 )[0];

        return {
          day,
          week,
          year,
        };
      } );
  }

  exportDashboardData( dashboardName: string, dashboardPageUrl: string ): Promise<string> {
    return this.api.request( new ExportDashboardDataRequest( { dashboardName, dashboardPageUrl } ) )
      .then( ( response ) => {
        const { exportRequestId } = response;

        return exportRequestId;
      } );
  }

  private getDashboardId( embedUrl: string ): string {
    if ( embedUrl ) {
      const splitUrl = embedUrl.split( '/' );
      const idxDasboard: number = splitUrl.findIndex( ( item ) => item === 'dashboards' );
      if ( idxDasboard > -1 ) {
        const paramDashboard = splitUrl[idxDasboard + 1].split( '?' );
        return paramDashboard[0];
      }
    }
    return '';
  }

  private filterData( filters: IFilter[] ): IFilter[] {
    const newFilters: IFilter[] = [];
    if ( !isEmpty( filters ) ) {
      filters.forEach( ( f ) => {
        const filterName = f.name;
        const filterValue = f.value;

        if ( Array.isArray( filterValue ) ) {
          const filteringValue = filterValue.filter(
            ( v ) => !DEFAULT_VALUES_ALL.includes( v ),
          );

          if ( filteringValue.length > 0 ) {
            newFilters.push( {
              name: filterName,
              value: filteringValue,
            } );
          }
        } else {
          if ( !DEFAULT_VALUES_ALL.includes( filterValue as string ) ) {
            newFilters.push( {
              name: filterName,
              value: filterValue,
            } );
          }
        }
      } );
    }

    return newFilters;
  }
}

export const DashboardServiceContext: React.Context<IDashboardService> = React.createContext( undefined as any );

export const useDashboardService = (): IDashboardService => {
  return React.useContext( DashboardServiceContext );
};
