import React from 'react';
import { IApiService } from './base';
import { GroupType } from 'react-select';
import { OptionBase, ViewAllOption, SearchItemType } from 'App/search';

export interface ISearchService {
  globalSearch( input: string ): Promise<GroupType<OptionBase>[]>;
  customerSearch( input: string ): Promise<GroupType<OptionBase>[]>;
}

export class SearchService implements ISearchService {

  protected api: IApiService;
  protected viewAllPoliciesOption: ViewAllOption;
  protected viewAllCustomersOption: ViewAllOption;

  constructor( api: IApiService ) {
    this.api = api;
    this.viewAllCustomersOption = this.buildViewAllOption(
      'search.viewAllCustomers', '/customers', 'view-all-customer' );
    this.viewAllPoliciesOption = this.buildViewAllOption( 'search.viewAllPolicies', '/policies', 'view-all-policy' );

  }

  globalSearch( input: string ): Promise<GroupType<OptionBase>[]> {
    // TODO Implement later because backend is not ready yet
    /*
    const result = this.api.request<SearchResponse>( new SearchRequest( input ) )
      .then( ( response ) => {

      } );
    */
    return Promise.resolve( [] );
  }

  customerSearch( input: string ): Promise<GroupType<OptionBase>[]> {
    // TODO Implement later because backend is not ready yet
    /*
    const result = this.api.request<SearchResponse>( new SearchRequest( input ) )
      .then( ( response ) => {

      } );
    */
    return Promise.resolve( [] );
  }

  private buildViewAllOption( label: string, navPath: string, type: SearchItemType ): ViewAllOption {
    return {
      type: type,
      label: label,
      navigation_path: navPath,
      index: '',
    };
  }
};

export const SearchServiceContext: React.Context<ISearchService> = React.createContext( undefined as any );
