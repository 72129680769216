import { wsApiUrl } from 'App/utils';
import React, { Context } from 'react';
import ReconnectingWebsocket from 'reconnecting-websocket';
import { Subject, BehaviorSubject } from 'rxjs';
import { filter } from 'rxjs/operators';
import { useAppLogger } from 'Services/logger';
import { useAppUser } from '../providers/AppUserProvider';
import { INotificationEvent, INotificationService, NotificationCallbackType, SystemEventCallbackType } from './base';
import { IToken } from 'Services/api/auth/base';


export const notificationTypeSystem: string = 'notification-system';
export interface ISystemEventPayload {
  connected: boolean;
  message: string;
}

export interface ISystemEvent {
  entityType: string;
  payload: ISystemEventPayload;
}

const createSystemEvent = ( connected: boolean, message: string ): ISystemEvent => {
  const notification: ISystemEvent = {
    entityType: notificationTypeSystem,
    payload: {
      connected: connected,
      message: message,
    },
  };
  return notification;
};

// Create service with subject. This is internal hook user only by provider to create service
const useCreateNotificationService: () => INotificationService = () => {
  const user = useAppUser();
  const authToken: IToken = user.token.current!;
  const logger = useAppLogger();

  const eventBus = React.useMemo<Subject<INotificationEvent<any>>>( () => {
    return new Subject<INotificationEvent<any>>();
  }, [] );

  // system bus must be behaviorSubject because some monitoring component can be dynamically mounted and dismounted
  // to present state of systemBus.
  const systemBus = React.useMemo<BehaviorSubject<ISystemEvent>>( () => {
    // create systemBus with initial event.
    return new BehaviorSubject<ISystemEvent>(
      createSystemEvent( false, 'Connecting...' ),
    );
  }, [] );

  // for all events
  const onEventCallback = React.useCallback( ( callback: NotificationCallbackType ) => {
    return eventBus.subscribe( callback );
  }, [ eventBus ] );

  // for events with specific types
  const onEventTypeCallback = React.useCallback( ( type: string, callback: NotificationCallbackType ) => {
    return eventBus
      .pipe(
        filter( ( notification ) => notification.type === type ),
      )
      .subscribe( callback );
  }, [ eventBus ] );

  const onSystemEventCallback = React.useCallback( ( callback: SystemEventCallbackType ) => {
    return systemBus
      .pipe(
        filter( ( notification ) => notification.entityType === notificationTypeSystem ),
      )
      .subscribe( callback );
  }, [ systemBus ] );

  React.useEffect( () => {
    let unexpectedClose = true;
    const url = `${wsApiUrl}?authToken=${authToken.value}`;
    logger.info( 'opening websocket connection url:', url );
    const rws = new ReconnectingWebsocket( url, [], {
      debug: false,
      reconnectionDelayGrowFactor: 1.0,
      minReconnectionDelay: 10000.0,
    } );
    rws.onmessage = ( ev ) => {
      const notification: INotificationEvent = JSON.parse( ev.data );
      eventBus.next( notification );
    };
    rws.onopen = () => {
      const event = createSystemEvent( true, 'Connection opened.' );
      systemBus.next( event );
    };
    rws.onclose = () => {
      if ( unexpectedClose ) {
        const event = createSystemEvent( false, 'Connection dropped. Trying to reconnect...' );
        systemBus.next( event );
      } else {
        const event = createSystemEvent( false, 'Connection closed.' );
        systemBus.next( event );
      }
    };
    return () => {
      logger.info( 'closing websocket connection url:', url );
      unexpectedClose = false;
      rws.close( 1000 );
    };
  }, [ eventBus, logger, systemBus, authToken.value ] );

  const service = React.useMemo<INotificationService>( () => {
    return {
      eventBus: eventBus.asObservable(),
      systemBus: systemBus.asObservable(),
      onEvent: onEventCallback,
      onEventType: onEventTypeCallback,
      onSystemEvent: onSystemEventCallback,
    };
  }, [ eventBus, systemBus, onEventCallback, onEventTypeCallback, onSystemEventCallback ] );

  return service;
};

export const NotificationServiceContext: Context<INotificationService> = React.createContext( undefined as any );

export const NotificationServiceProvider: React.FC = ( props ) => {
  const service = useCreateNotificationService();
  return (
    <NotificationServiceContext.Provider value={ service }>
      { props.children }
    </NotificationServiceContext.Provider>
  );
};

export const useNotificationService = (): INotificationService => {
  return React.useContext( NotificationServiceContext );
};
