import { PartnerType } from './interfaces';
import { BaseRequest, PagingRequest } from 'Services/base';
import { getQueryParamsForListAPI, ListItems } from 'Services/api';

export class ListPartnerTypesRequest extends BaseRequest<ListItems<PartnerType>> {
  constructor(
    pageParameters: PagingRequest,
  ) {
    super( {
      method: 'GET',
      url: '/partnerservice/v1/partner-types',
      responseType: 'json',
      params: getQueryParamsForListAPI( pageParameters ),
    } );
  }
}
