import React from 'react';
import { PagingRequest, IApiService } from './base';
import { Logger } from 'loglevel';
import { IApiListResponse } from './policies/new/service';
import { IPublicUrl, IProductDocument, IStaticDocumentResponse,
  IPreSignedPost, IUploadProductDocument } from './product-documents/interfaces';
import { GetProductDocumentRequest, ListProductDocuments,
  UploadFileWithPreSignedUrlRequest, UploadProductDocumentRequest,
  GetPublicUrlRequest, DeleteProductDocumentRequest } from './product-documents/requests';
import dayjs from 'dayjs';

export interface IProductDocumentService {
  listProductDocuments(
    searchValue: string, paging: PagingRequest, expand?: string[]
  ): Promise<IApiListResponse<IProductDocument>>;
  getProductDocument( code: string, productSlug: string, expand?: string[] ): Promise<IProductDocument>;
  uploadProductDocument( data: IUploadProductDocument ): Promise<IPreSignedPost>;
  getProductDocumentDownloadUrl( code: string, productSlug: string ): Promise<IPublicUrl>;
  deleteProductDocument( code: string, productSlug: string ): Promise<void>;
  uploadFileUsingPreSignedUrl(
    { fields, url }: IPreSignedPost,
    file: File,
  ): Promise<Record<string, string>>;
}

export class ProductDocumentService implements IProductDocumentService {
  protected api: IApiService;
  protected logger: Logger;

  constructor( api: IApiService, logger: Logger ) {
    this.api = api;
    this.logger = logger;
  }

  async listProductDocuments(
    searchValue: string, paging: PagingRequest, expand?: string[],
  ): Promise<IApiListResponse<IProductDocument>> {
    const response = await this.api.request( new ListProductDocuments( searchValue, paging, expand ) );
    let documents: IProductDocument[] = [];
    const { items, nextPageToken } = response;

    if ( items ) {
      documents = items.map( ( item, index ) => {
        const document: IProductDocument = {
          ...item,
          createdAt: dayjs.utc( item.createdAt ).toDate(),
        };

        return document;
      } );
    }

    const res: IApiListResponse<IProductDocument> = {
      items: documents,
      nextPageToken: documents ? documents.length < paging.pageSize ? '1' : nextPageToken : nextPageToken,
    };

    return res;

  }

  async getProductDocument( code: string, productSlug: string, expand?: string[] ): Promise<IProductDocument> {
    const response = await this.api.request<IStaticDocumentResponse>(
      new GetProductDocumentRequest( code, productSlug, expand ),
    );
    const document = {
      ...response.productDocument,
      createdAt: dayjs.utc( response.productDocument.createdAt ).toDate(),
    };

    return document;
  }

  async uploadProductDocument( data: IUploadProductDocument ): Promise<IPreSignedPost> {
    const response = await this.api.request<IPreSignedPost>( new UploadProductDocumentRequest( data ) );
    if ( response === undefined ) {
      throw new Error( `Can't upload, filename: ${data.filename}` );
    }

    return response;
  }

  async uploadFileUsingPreSignedUrl(
    { fields, url }: IPreSignedPost,
    file: File,
  ): Promise<Record<string, string>> {
    const formData = new FormData();
    Object.entries( fields ).forEach( ( [ k, v ] ) => {
      formData.append( k, v.toString() );
    } );
    formData.append( 'file', file, file.name );
    const response = await this.api.request(
      new UploadFileWithPreSignedUrlRequest( formData, url ),
    );

    return response;
  }

  async getProductDocumentDownloadUrl( code: string, productSlug: string ): Promise<IPublicUrl> {
    const response = await this.api.request( new GetPublicUrlRequest( code, productSlug ) );
    return response;
  }

  async deleteProductDocument( code: string, productSlug: string ): Promise<void> {
    await this.api.request( new DeleteProductDocumentRequest( code, productSlug ) );
  }};

export const ProductDocumentServiceContext:
React.Context<IProductDocumentService> = React.createContext( undefined as any );

export const useProductDocumentService = (): IProductDocumentService => {
  return React.useContext( ProductDocumentServiceContext );
};
