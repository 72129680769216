import React, { Fragment } from 'react';

import { NavIcon } from './NavIcon';
import { NavBadge } from './NavBadge';
import { MenuItem } from 'App/menu-items';

import { AppNavLink } from 'App/components/utils/routing/AppNavLink';
import { useAppRootActions } from 'App/root/context';
import { useWindowSize } from 'App/ui-utils';
import { useAppUserPathPrefix } from 'App/components/utils/providers/AppUserProvider';

export interface NavItemProps {
  item: MenuItem;
}

export const NavItem: React.FC<NavItemProps> = ( props ) => {
  const { menu: menuActions } = useAppRootActions();
  const windowSize = useWindowSize();
  const userPrefix = useAppUserPathPrefix();

  const item: MenuItem = props.item;
  let itemTitle: React.ReactNode = item.title;
  if ( item.icon ) {
    itemTitle = <span className="pcoded-mtext">{ itemTitle }</span>;
  }

  let itemTarget: string = '';
  if ( item.target ) {
    itemTarget = '_blank';
  }

  let subContent: React.ReactNode = null;
  if ( item.url ) {
    if( item.external ) {
      subContent = (
        <a href={ item.url } target='_blank' rel='noopener noreferrer'>{ itemTitle }</a>
      );
    } else if ( item.nestedApplication ) {
      subContent = (
        <a href={ `${userPrefix}${item.url}` } target='_self'>{ itemTitle }</a>
      );
    } else {
      subContent = (
        <AppNavLink
          to={ item.url }
          className="nav-link"
          exact={ true }
          target={ itemTarget }
          withoutUserContext={ item.withoutUserPrefix }
        >
          <NavIcon items={ item } />
          { itemTitle }
          <NavBadge items={ item } />
        </AppNavLink>
      );
    }
  }
  let mainContent: React.ReactNode = '';
  if ( windowSize.width < 992 ) {
    mainContent = (
      <li id={ 'menu-' + item.id } className={ item.classes }
        onClick={ menuActions.collapseMenu }
      >
        { subContent }
      </li>
    );
  } else {
    mainContent = (
      <li id={ 'menu-' + item.id } className={ item.classes }>{ subContent }</li>
    );
  }

  return (
    <Fragment>
      { mainContent }
    </Fragment>
  );

};
