import React from 'react';
import { IApiService, ApiResponse, PagingRequest, OrderByPage } from './base';
import rootLogger, { Logger } from 'loglevel';
import {
  IDocumentDesign,
  CreateLayoutRequest,
  IDocumentLayoutResponse,
  IDocumentLayout,
  ListDocumentDesignsResponse,
  ListDocumentDesignsRequest,
  GetLayoutRequest,
  UpdateLayoutRequest,
  DeleteLayoutRequest,
  INewDocumentTemplate,
  CreateTemplateRequest,
  IDocumentTemplate,
  IDocumentTemplateResponse,
  ListDocumentTemplateResponse,
  ListDocumentTemplateRequest,
  DeleteTemplateRequest,
  GetTemplateRequest,
  IUpdateDocumentTemplate,
  UpdateTemplateRequest,
  IDocumentItem,
  ListPolicyDocumentRequest,
  IDocumentRequester,
  DownloadDocumentRequest,
  DownloadStaticDocumentRequest,
  ListInvoiceDocumentRequest,
  PreSignedPostRequest,
  PreSignedPost,
  PresignedUrlRequest,
  UploadDocumentWithPreSignedUrlRequest,
  ListClaimDocumentsRequest,
  DeleteDocumentByCodeRequest,
  GetSignedUrlRequest,
  DownloadUrl,
  DownloadUrlRequest, ListLeadDocumentsRequest,
} from './document/document';
import dayjs from 'dayjs';
import { TimeFilter, isTimeInFilter } from 'App/components/policy/tables/DocumentsTimeFilter';
import { IApiListResponse } from './policies/new/service';
import { ListItems } from './api';
import { ClaimDocument } from './claims/interfaces';
import { CLAIM_DOCUMENT_ENTITY_TYPE } from '../App/components/management/claims/claim-documents/ClaimDocuments';
import { documentServiceS3Bucket } from '../App/utils';
import { LeadDocument } from '../App/components/partner/leads/documents/LeadDocumentList';

export const S3_PREFIX = `https://${documentServiceS3Bucket}.s3.eu-central-1.amazonaws.com`;

export interface IDocumentService {
  createDocumentDesign( layoutDesign: IDocumentDesign ): Promise<IDocumentLayout>;
  getDocumentDesigns(): Promise<ListDocumentDesignsResponse>;
  getDocumentDesign( layoutId: number ): Promise<IDocumentLayout>;
  deleteDocumentDesign( layoutId: number ): Promise<ApiResponse>;
  updateDocumentDesign( layoutId: number, layoutDesign: IDocumentDesign ): Promise<IDocumentLayout>;
  createDocumentTemplate( template: INewDocumentTemplate ): Promise<IDocumentTemplate>;
  getDocumentTemplates( searchValue: string, paging: PagingRequest ): Promise<ListDocumentTemplateResponse>;
  deleteDocumentTemplate( templateId: number ): Promise<ApiResponse>;
  getDocumentTemplate( templateId: number ): Promise<IDocumentTemplate>;
  updateDocumentTemplate( templateId: number, templateUpdate: IUpdateDocumentTemplate ): Promise<IDocumentTemplate>;
  downloadDocument( code: string ): Promise<BlobPart>;
  downloadStaticDocument( documentKey: string ): Promise<BlobPart>;
  listPolicyDocuments( policyCode: string, filterByEntityType: IDocumentRequester[],
    filterByTime: TimeFilter, paging: PagingRequest ): Promise<IApiListResponse<IDocumentItem>>;
  listInvoiceDocuments( policyCode: string, invoiceId?: number ): Promise<IDocumentItem[]>;
  getPreSignedRequestUrl( data: PreSignedPostRequest ): Promise<PreSignedPost>;
  uploadDocumentUsingPreSignedUrl( { fields, url }: PreSignedPost, file: File ): Promise<Record<string, string>>;
  listClaimDocuments( claimId: number, pageToken: number, order: OrderByPage[] ): Promise<ListItems<ClaimDocument>>;
  listLeadDocuments( leadId: string, pageToken: number, order: OrderByPage[] ): Promise<ListItems<LeadDocument>>;
  deleteDocumentByCode( documentCode: string ): Promise<void>;
  getSignedS3Url( s3key: string ): Promise<string>;
  downloadDocumentUrl( code: string ): Promise<DownloadUrl>;
}

export class DocumentService implements IDocumentService {
  protected api: IApiService;
  protected logger: Logger;

  constructor( api: IApiService, logger: Logger ) {
    this.api = api;
    this.logger = logger;
  }

  createDocumentDesign( layoutDesign: IDocumentDesign ): Promise<IDocumentLayout> {
    return this.api.request<IDocumentLayoutResponse>( new CreateLayoutRequest( layoutDesign ) )
      .then( ( response ) => {
        const layout = {
          ...response.layout,
        };

        return layout;
      } );
  }

  getDocumentDesigns(): Promise<ListDocumentDesignsResponse> {
    return this.api.request<ListDocumentDesignsResponse>( new ListDocumentDesignsRequest() )
      .then( ( response ) => {
        let documentDesigns : IDocumentLayout[] = [];
        const { layouts, nextPageToken } = response;

        if ( layouts ) {
          documentDesigns = layouts.map( ( item ) => {
            item.createdAt = dayjs( item.createdAt );

            return item;
          } );
        }

        const res: ListDocumentDesignsResponse = {
          layouts: documentDesigns,
          nextPageToken: nextPageToken,
        };

        return res;
      } );
  }

  getDocumentTemplates( searchValue: string, paging: PagingRequest ): Promise<ListDocumentTemplateResponse> {
    return this.api.request<ListDocumentTemplateResponse>( new ListDocumentTemplateRequest( searchValue, paging ) )
      .then( ( response ) => {
        let documentTemplates : IDocumentTemplate[] = [];
        const { templates, nextPageToken } = response;

        if ( templates ) {
          documentTemplates = templates.map( ( item ) => {
            item.bodyTemplate.createdAt = dayjs( item.bodyTemplate.createdAt );
            item.bodyTemplate.updatedAt = dayjs( item.bodyTemplate.updatedAt );

            return item;
          } );
        }

        const res: ListDocumentTemplateResponse = {
          templates: documentTemplates,
          nextPageToken: templates ? templates.length < paging.pageSize ? '1' : nextPageToken : nextPageToken,
        };

        return res;
      } );
  }

  getDocumentDesign( layoutId: number ): Promise<IDocumentLayout> {
    return this.api.request<IDocumentLayoutResponse>( new GetLayoutRequest( layoutId ) )
      .then( ( response ) => {
        const layout = {
          ...response.layout,
        };

        return layout;
      } );
  }

  getDocumentTemplate( templateId: number ): Promise<IDocumentTemplate> {
    return this.api.request<IDocumentTemplateResponse>( new GetTemplateRequest( templateId ) )
      .then( ( response ) => {
        const template = {
          ...response.template,
        };

        return template;
      } );
  }

  updateDocumentDesign( layoutId: number, layoutDesign: IDocumentDesign ): Promise<IDocumentLayout> {
    return this.api.request<IDocumentLayoutResponse>( new UpdateLayoutRequest( layoutId, layoutDesign ) )
      .then( ( response ) => {
        const layout = {
          ...response.layout,
        };

        return layout;
      } );
  }

  updateDocumentTemplate( templateId: number, templateUpdate: IUpdateDocumentTemplate ): Promise<IDocumentTemplate> {
    return this.api.request<IDocumentTemplateResponse>( new UpdateTemplateRequest( templateId, templateUpdate ) )
      .then( ( response ) => {
        const template = {
          ...response.template,
        };

        return template;
      } );
  }

  deleteDocumentDesign( layoutId: number ): Promise<ApiResponse> {
    return this.api.request<ApiResponse>( new DeleteLayoutRequest( layoutId ) )
      .then( ( response ) => {
        return response;
      } );
  }

  deleteDocumentTemplate( templateId: number ): Promise<ApiResponse> {
    return this.api.request<ApiResponse>( new DeleteTemplateRequest( templateId ) )
      .then( ( response ) => {
        return response;
      } );
  }

  createDocumentTemplate( template: INewDocumentTemplate ): Promise<IDocumentTemplate> {
    return this.api.request<IDocumentTemplateResponse>( new CreateTemplateRequest( template ) )
      .then( ( response ) => {
        const tempResponse = {
          ...response.template,
        };

        return tempResponse;
      } );
  }

  listPolicyDocuments( policyCode: string, filterByRequester: IDocumentRequester[],
    filterByTime: TimeFilter, paging: PagingRequest ) : Promise<IApiListResponse<IDocumentItem>> {
    return this.api.request( new ListPolicyDocumentRequest( paging, policyCode, filterByRequester ) )
      .then( ( response ) => {
        let documentData : IDocumentItem[] = [];
        const { items, nextPageToken } = response;

        if ( items ) {
          documentData = items.map( ( item ) => {
            const policyItem: IDocumentItem = {
              ...item,
              createdAt: new Date( item.createdAt ),
            };
            return policyItem;
          } ).filter( ( { createdAt } ) => isTimeInFilter( createdAt, filterByTime ) );
        }
        const res: IApiListResponse<IDocumentItem> = {
          items: documentData,
          nextPageToken: documentData ? documentData.length < paging.pageSize ? '1' : nextPageToken : nextPageToken,
        };

        return res;
      } );
  }

  downloadDocument( code: string ): Promise<BlobPart> {
    return this.api.request( new DownloadDocumentRequest( code ) )
      .then( ( response ) => {
        return response;
      } );
  }

  downloadStaticDocument( documentKey: string ): Promise<BlobPart> {
    return this.api.request( new DownloadStaticDocumentRequest( documentKey ) )
      .then( ( response ) => {
        return response;
      } );
  }

  async listInvoiceDocuments( policyCode: string, invoiceId?: number ): Promise<IDocumentItem[]> {
    const response = await this.api.request( new ListInvoiceDocumentRequest( policyCode, invoiceId ) );
    const { items } = response;

    return items;
  }

  async getPreSignedRequestUrl(
    data: PreSignedPostRequest,
  ): Promise<PreSignedPost> {
    const response = await this.api.request( new PresignedUrlRequest( data ) );
    return response;
  }

  async downloadDocumentUrl( code: string ): Promise<DownloadUrl> {
    const response = await this.api.request( new DownloadUrlRequest( code ) );
    return response;
  }

  async uploadDocumentUsingPreSignedUrl(
    { fields, url }: PreSignedPost,
    file: File,
  ): Promise<Record<string, string>> {
    const formData = new FormData();
    Object.entries( fields ).forEach( ( [ k, v ] ) => {
      formData.append( k, v.toString() );
    } );
    formData.append( 'file', file, file.name );
    const response = await this.api.request(
      new UploadDocumentWithPreSignedUrlRequest( formData, url, fields.contentType ),
    );

    return response;
  }

  async listClaimDocuments(
    claimId: number,
    pageToken: number,
    order: OrderByPage[] = [],
  ): Promise<ListItems<ClaimDocument>> {
    const response = await this.api.request(
      new ListClaimDocumentsRequest( {
        filter: [
          { id: 'entityId', value: String( claimId ) },
          { id: 'entityType', value: CLAIM_DOCUMENT_ENTITY_TYPE },
        ],
        pageSize: 100,
        pageToken,
        orderBy: order,
      } ),
    );

    return response;
  }

  async listLeadDocuments(
    leadCode: string,
    pageToken: number,
    order: OrderByPage[] = [],
  ): Promise<ListItems<LeadDocument>> {
    const response = await this.api.request(
      new ListLeadDocumentsRequest( {
        filter: [
          { id: 'leadCode', value: String( leadCode ) },
        ],
        pageSize: 20,
        pageToken,
        orderBy: order,
      } ),
    );

    return response;
  }

  async deleteDocumentByCode( documentCode: string ): Promise<void> {
    await this.api.request( new DeleteDocumentByCodeRequest( documentCode ) );
  }

  async getSignedS3Url( s3Key: string ): Promise<string> {
    const response = await this.api.request( new GetSignedUrlRequest( s3Key ) );
    const { url } = response;

    return url;
  }
}

export const DocumentServiceContext: React.Context<IDocumentService> = React.createContext(
  undefined as any,
);

export const useDocumentService = (): IDocumentService => {
  return React.useContext( DocumentServiceContext );
};

export const useDynamicDocumentService = (
  api: IApiService,
): DocumentService => {
  return React.useContext(
    React.createContext( new DocumentService( api, rootLogger as Logger ) ),
  );
};
