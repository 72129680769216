import { sleep } from 'App/utils';
import { ApiError, ApiResponse, PagingRequest } from 'Services/base';
import { Logger } from 'loglevel';
import { mockedInstallPluginData, mockedNotInstallPluginData } from './mock-data';
import { IPluginService } from 'Services/plugin';
import { IPluginItem, StatusType } from './interfaces';

export class MockedPluginService implements IPluginService {
  private logger: Logger;
  private installPluginData: IPluginItem[];
  private notInstallPluginData: IPluginItem[];

  constructor( logger: Logger ) {
    this.logger = logger;
    this.installPluginData = mockedInstallPluginData;
    this.notInstallPluginData = mockedNotInstallPluginData;
  }

  getInstallPluginItems( paging: PagingRequest ): Promise<IPluginItem[]> {
    const result = sleep ( 500 )
      .then( () => {
        return this.installPluginData;
      } );

    return result;
  }

  getNotInstallPluginItems( paging: PagingRequest, searchQuery: string, currentLng: string ): Promise<IPluginItem[]> {
    const result = sleep ( 500 )
      .then( () => {
        let pluginItems: IPluginItem[] = this.notInstallPluginData;
        const input = searchQuery.toLowerCase();
        if ( input.length > 0 ) {
          // Filtering based on searchQuery - case insensitive
          pluginItems = pluginItems.filter( ( v ) => {
            const index = `${v.pluginName} ${v[`${currentLng}PluginDesc`]} `;
            return index.toLowerCase().includes ( input );
          } );
        }

        return pluginItems;
      } );

    return result;
  }

  installPlugin( plugin: IPluginItem, statusPlugin: StatusType ): Promise<ApiResponse>{
    return sleep ( 2000 )
      .then( () => {
        const idxPlugin = this.notInstallPluginData.findIndex( ( p ) => p.pluginName === plugin.pluginName );

        if ( idxPlugin > -1 ) {
          const newPlg: IPluginItem = {
            ...plugin,
            id: this.calculateNextId(),
            status: statusPlugin,
          };

          this.installPluginData.push( newPlg );
          this.notInstallPluginData = this.notInstallPluginData.filter( ( p ) => p.pluginName !== plugin.pluginName );

          const apiRes: ApiResponse = {
            message: 'Ok',
            success: true,
          };

          return apiRes;
        } else {
          throw new ApiError( `Failed to install plugin: ${plugin.pluginName}` );
        }
      } );
  }
  uninstallPlugin( plugin: IPluginItem, statusPlugin: StatusType ): Promise<ApiResponse>{
    return sleep ( 2000 )
      .then( () => {
        const idxPlugin = this.installPluginData.findIndex( ( p ) => p.id === plugin.id );

        if ( idxPlugin > -1 ) {
          const uninstallPlg: IPluginItem = {
            iconPlugin: plugin.iconPlugin,
            pluginName: plugin.pluginName,
            enPluginDesc: plugin.enPluginDesc,
            dePluginDesc: plugin.dePluginDesc,
            status: statusPlugin,
          };

          this.notInstallPluginData.push( uninstallPlg );
          this.installPluginData = this.installPluginData.filter( ( p ) => p.id !== plugin.id );

          const apiRes: ApiResponse = {
            message: 'Ok',
            success: true,
          };

          return apiRes;
        } else {
          throw new ApiError( `Failed to uninstall plugin: ${plugin.id} not found` );
        }
      } );
  }

  private calculateNextId(): number {
    let nextId: number = 1;
    const pluginIds = this.installPluginData.map( ( t ) => t.id! );
    if ( pluginIds.length ) {
      nextId = Math.max( ...pluginIds ) + 1;
    }

    return nextId;
  }
}
