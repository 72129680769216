import React, { useContext } from 'react';

import { ApiContext } from 'Services/api-context';
import { ILeadService, LeadServiceContext } from 'Services/lead';
import { ServiceProviderProps } from './common';

export const LeadServiceProvider: React.FC<ServiceProviderProps<ILeadService>> = ( props, context ) => {

  const api = useContext( ApiContext );

  // Create LeadService that depends on api. To create service we are using useClass implementation
  const leadService: ILeadService = new props.useClass( api );

  return (
    <LeadServiceContext.Provider value={ leadService }>
      { props.children }
    </LeadServiceContext.Provider>
  );
};
