import React, { useContext } from 'react';

import { ApiContext } from 'Services/api-context';
import { ICustomerService, CustomerServiceContext } from 'Services/customer';
import { ServiceProviderProps } from './common';

export const CustomerServiceProvider: React.FC<ServiceProviderProps<ICustomerService>> = ( props, context ) => {

  const api = useContext( ApiContext );

  // Create CustomerService that depends on api. To create service we are using useClass implementation
  const customerService: ICustomerService = new props.useClass( api );

  return (
    <CustomerServiceContext.Provider value={ customerService }>
      { props.children }
    </CustomerServiceContext.Provider>
  );
};
