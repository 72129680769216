import React from 'react';
import { blankLink } from 'config';
import { AppLink } from 'App/components/utils/routing/AppLink';
import { useAppCurrentProduct } from 'App/components/utils/providers/AppProductProvider';

export interface NavLogoProps {
  collapseMenu: boolean;
  windowWidth?: number;
  onToggleNavigation: () => void;
  logoImageSite: string;
  titleSite: string;
}

export const NavLogo: React.FC<NavLogoProps> = ( props ) => {
  let toggleClass = [ 'mobile-menu' ];
  const product = useAppCurrentProduct();
  if ( props.collapseMenu ) {
    toggleClass = [ ...toggleClass, 'on' ];
  }
  const logoStyle = {
    backgroundImage: 'url(/assets/logos/emil_group.png)',
  };

  const onToggleLeftPanel = React.useCallback( ( e: React.MouseEvent<HTMLAnchorElement> ) => {
    e.stopPropagation();
    e.preventDefault();

    if ( !props.collapseMenu ) {
      props.onToggleNavigation();
      setTimeout( () => {
        document.body.classList.add( 'menu-collapsed' );
      }, 500 );
    } else {
      props.onToggleNavigation();
      setTimeout( () => {
        document.body.classList.remove( 'menu-collapsed' );
      }, 500 );
    }
  }, [ props ] );

  return (
    <div className="navbar-brand header-logo">
      <AppLink to="/" className="b-brand">
        <div id="logo-block" className="b-bg" style={ logoStyle }>
          <i></i>
        </div>
        <span className="b-title">{ product.name }</span>
      </AppLink>
      <a
        href={ blankLink }
        className={ toggleClass.join( ' ' ) }
        id="mobile-collapse"
        onClick={ onToggleLeftPanel }
      >
        <span></span>
      </a>
    </div>
  );
};
