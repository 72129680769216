import { BaseRequest, PagingRequest } from 'Services/base';
import { IApiListResponse } from 'Services/policies/new/service';
import { IPublicUrl, IDocxTemplate, IDocxTemplateResponse, IPreSignedPost, IUploadDocxTemplate } from './interfaces';
import { getQueryParamsForListAPI } from 'Services/api';

const docxServiceApiServer = process.env.REACT_APP_DOCUMENT_SERVICE_API_SERVER ?? '';

export class ListDocxTemplatesRequest extends BaseRequest<IApiListResponse<IDocxTemplate>> {
  constructor(
    searchValue: string,
    paging: PagingRequest,
    expand?: string[],
  ) {
    const queryParams = getQueryParamsForListAPI( paging, searchValue, 'search', ' ' );

    if ( expand && expand.length > 0 ) {
      const expandPars = expand.map( ( item ) => `${encodeURIComponent( item )}` ).join( ',' );
      queryParams.set( 'expand', expandPars );
    }

    super( {
      method: 'GET',
      url: `${docxServiceApiServer}/documentservice/v1/docx-templates`,
      responseType: 'json',
      params: queryParams,
    } );
  }
}

export class GetDocxTemplateRequest extends BaseRequest<IDocxTemplateResponse> {
  constructor(
    code: string,
    expand?: string[],
  ) {
    const queryParams = new URLSearchParams();

    if ( expand && expand.length > 0 ) {
      const expandPars = expand.map( ( item ) => `${encodeURIComponent( item )}` ).join( ',' );
      queryParams.set( 'expand', expandPars );
    }

    super( {
      method: 'GET',
      url: `${docxServiceApiServer}/documentservice/v1/docx-templates/${ code }`,
      responseType: 'json',
      params: queryParams,
    } );
  }
}

export class UploadDocxTemplateRequest extends BaseRequest<IPreSignedPost> {
  constructor(
    data: IUploadDocxTemplate,
  ) {
    super( {
      method: 'POST',
      url: `${docxServiceApiServer}/documentservice/v1/docx-templates`,
      responseType: 'json',
      data,
    } );
  }
}

export class UploadDocxWithPreSignedUrlRequest extends BaseRequest<Record<string, string>> {
  constructor(
    formData: FormData,
    url: string,
  ) {
    super( {
      method: 'POST',
      url: url,
      responseType: 'json',
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' },
    }, false );
  }
}

export class GetPublicUrlRequest extends BaseRequest<IPublicUrl> {
  constructor( code: string ) {
    super( {
      method: 'GET',
      url: `${docxServiceApiServer}/documentservice/v1/docx-templates/${code}/download-url`,
      responseType: 'json',
    } );
  }
}
export class DeleteDocxTemplateByCodeRequest extends BaseRequest {
  constructor( code: string ) {
    super( {
      method: 'DELETE',
      url: `${docxServiceApiServer}/documentservice/v1/docx-templates/${code}`,
      responseType: 'json',
    } );
  }
}
