import { useAppLinks } from 'App/components/utils/providers/AppLinks';
import { AppNavLink } from 'App/components/utils/routing/AppNavLink';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { NavLanguage } from './NavBar/NavLanguage';

export const AppFooter: React.FC = () => {
  const { t } = useTranslation( [ 'base' ] );
  const links = useAppLinks();
  return (
    <div className="app-footer py-1">
      <div id="app-footer" className="d-flex flex-row justify-content-center align-items-center">
        <a
          className="btn btn-link m-0 px-1 app-secondary-link"
          href={ links.impressum }
          target="_blank"
          rel="noopener noreferrer"
        >
          { t( 'helpNav.impressum' ) }
        </a>
        <a
          className="btn btn-link m-0 px-1 app-secondary-link"
          href={ links.privacyPolicy }
          target="_blank"
          rel="noopener noreferrer"
        >
          { t( 'base:helpNav.privacyPolicy' ) }
        </a>
        <AppNavLink
          to={ links.contactSupportLink }
          className="btn btn-link m-0 px-1 app-secondary-link"
          exact={ true }
        >
          { t( 'base:helpNav.contactSupport' ) }
        </AppNavLink>
        <NavLanguage />
      </div>
    </div>
  );

};
