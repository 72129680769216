import { CustomerMockItem, CustomersResponse, CustomerPaymentItem, ProfileItem } from './interfaces';
import dayjs from 'dayjs';
import { defaultDateFormat } from 'App/ui-utils';

export const pamentMockData: CustomerPaymentItem = {
  payment_method: 'directDebit',
  account_name: 'Klaus-jürgen Krawietz',
  iban: 'DE12897104671991416157',
  mandate_reference: 'n9C1Z193zsYvIFZE',
};

export const customerMockData: ProfileItem = {
  customer_id: 500001,
  customer_number: 557908,
  customer_code: 'cust_2yc7TnQ',
  gender: 'male',
  first_name: 'Klaus-jürgen',
  last_name: 'Krawietz',
  street: 'Rathausplatz',
  house_number: '21',
  zip_code: '67814',
  city: 'Jakobsweiler',
  birth_date: '20.04.1927',
  email: 'klaus-jürgen-krawietz@gmx.net',
  phone: '+4920203128234',
};

const customersData : CustomerMockItem[] = [
  {
    'id': 1,
    'code': 'acc_hFWlbfAtv',
    'accountNumber': 47559,
    'firstName': 'Bernita',
    'lastName': 'Kunze',
    'email': 'Jayce_Barton@hotmail.com',
    'gender': 'male',
    'street': 'McClure Corners',
    'houseNumber': '597 Brock Land',
    'zipCode': '41875',
    'city': 'New Jaylinshire',
    'birthDate': dayjs( '08.04.1927', defaultDateFormat ),
    'phone': '987.775.7634 x6501',
    'navigation_path': '/customers/acc_hFWlbfAtv',
    'mandateReference': 'n9C1Z193zsYvIFZE',
    'accountName': 'Bernita Kunze',
    'iban': 'DE12897104671991416157',
    'paymentMethod': 'bankTransfer',
    'type': 'customer',
    'index': 'acc_hFWlbfAtv 47559 Bernita Kunze 08.04.1927 41875 McClure Corners 597 Brock Land New Jaylinshire',
    'version': 1,
  },
  {
    'id': 4,
    'code': 'acc_Gk0KJk2dOd',
    'accountNumber': 500002,
    'firstName': 'Oliver Bernd',
    'lastName': 'Wilke',
    'email': 'Abdiel.Berge83@hotmail.com',
    'gender': 'male',
    'street': 'Borstellstraße',
    'houseNumber': '4',
    'zipCode': '57076',
    'city': 'Siegen',
    'birthDate': dayjs( '01.01.1970', defaultDateFormat ),
    'phone': '+4935846626264',
    'navigation_path': '/customers/acc_Gk0KJk2dOd',
    'mandateReference': 'n9C1Z193zsYvIFZE',
    'accountName': 'Oliver Bernd Wilke',
    'iban': 'DE12897104671991416157',
    'paymentMethod': 'bankTransfer',
    'type': 'customer',
    'index': 'acc_Gk0KJk2dOd 500002 Oliver Bernd Wilke 01.01.1970 57076 Borstellstraße 4 Siegen',
    'version': 1,
  },
  {
    'id': 6,
    'code': 'acc_fSPAuLcszd',
    'accountNumber': 500008,
    'firstName': 'Günter Ullrich',
    'lastName': 'Richter',
    'email': 'Edward2@yahoo.com',
    'gender': 'male',
    'street': 'Stauffenbergstraße',
    'houseNumber': '35',
    'zipCode': '39576',
    'city': 'Hanse.stendal',
    'birthDate': dayjs( '06.12.1961', defaultDateFormat ),
    'phone': '+4953082438746',
    'navigation_path': '/customers/acc_fSPAuLcszd',
    'mandateReference': 'n9C1Z193zsYvIFZE',
    'accountName': 'Günter Ullrich Richter',
    'iban': 'DE12897104671991416157',
    'paymentMethod': 'bankTransfer',
    'type': 'customer',
    'index': 'acc_fSPAuLcszd 500008 Günter Ullrich Richter 06.12.1961 39576 Stauffenbergstraße 35 Hanse.stendal',
    'version': 1,
  },
  {
    'id': 8,
    'code': 'acc_9uH-S83M1U',
    'accountNumber': 500006,
    'firstName': 'Andree',
    'lastName': 'Deniz',
    'email': 'George20@gmail.com',
    'gender': 'male',
    'street': 'Bekstück',
    'houseNumber': '17',
    'zipCode': '23758',
    'city': 'Oldenburg/h.',
    'birthDate': dayjs( '01.01.1970', defaultDateFormat ),
    'phone': '+4961051060995',
    'navigation_path': '/customers/acc_9uH-S83M1U',
    'mandateReference': 'n9C1Z193zsYvIFZE',
    'accountName': 'Andree Deniz',
    'iban': 'DE12897104671991416157',
    'paymentMethod': 'bankTransfer',
    'type': 'customer',
    'index': 'acc_9uH-S83M1U 500006 Andree Deniz 01.01.1970 23758 Bekstück 17 Oldenburg/h.',
    'version': 1,
  },
  {
    'id': 9,
    'code': 'acc_uutn6mpylV',
    'accountNumber': 500010,
    'firstName': 'Frank',
    'lastName': 'Huber',
    'email': 'Wilton99@gmail.com',
    'gender': 'male',
    'street': 'Alte Dorfstraße',
    'houseNumber': '22',
    'zipCode': '44581',
    'city': 'Castrop-rauxel',
    'birthDate': dayjs( '01.01.1970', defaultDateFormat ),
    'phone': '+4983017248083',
    'navigation_path': '/customers/acc_uutn6mpylV',
    'mandateReference': 'n9C1Z193zsYvIFZE',
    'accountName': 'Frank Huber',
    'iban': 'DE12897104671991416157',
    'paymentMethod': 'bankTransfer',
    'type': 'customer',
    'index': 'acc_uutn6mpylV 500010 Frank Huber 01.01.1970 44581 Alte Dorfstraße 22 Castrop-rauxel',
    'version': 1,
  },
  {
    'id': 10,
    'code': 'acc_1EG-1Anbiy',
    'accountNumber': 500007,
    'firstName': 'Baris',
    'lastName': 'Kosak',
    'email': 'April.Feil@hotmail.com',
    'gender': 'male',
    'street': 'Wildenhof',
    'houseNumber': '60',
    'zipCode': '8525',
    'city': 'Plauen',
    'birthDate': dayjs( '03.04.1928', defaultDateFormat ),
    'phone': '+49768231083',
    'navigation_path': '/customers/acc_1EG-1Anbiy',
    'mandateReference': 'n9C1Z193zsYvIFZE',
    'accountName': 'Baris Kosak',
    'iban': 'DE12897104671991416157',
    'paymentMethod': 'bankTransfer',
    'type': 'customer',
    'index': 'acc_1EG-1Anbiy 500007 Baris Kosak 03.04.1928 8525 Wildenhof 60 Plauen',
    'version': 1,
  },
  {
    'id': 11,
    'code': 'acc_XszfxkWkJj',
    'accountNumber': 500001,
    'firstName': 'Klaus-jürgen',
    'lastName': 'Krawietz',
    'email': 'Davion_Grimes86@hotmail.com',
    'gender': 'male',
    'street': 'Rathausplatz',
    'houseNumber': '21',
    'zipCode': '67814',
    'city': 'Jakobsweiler',
    'birthDate': dayjs( '01.01.1970', defaultDateFormat ),
    'phone': '+4920203128234',
    'navigation_path': '/customers/acc_XszfxkWkJj',
    'mandateReference': 'n9C1Z193zsYvIFZE',
    'accountName': 'Klaus-jürgen Krawietz',
    'iban': 'DE12897104671991416157',
    'paymentMethod': 'bankTransfer',
    'type': 'customer',
    'index': 'acc_XszfxkWkJj 500001 Klaus-jürgen Krawietz 01.01.1970 67814 Rathausplatz 21 Jakobsweiler',
    'version': 1,
  },
  {
    'id': 12,
    'code': 'acc_VtKLJ2JvDv',
    'accountNumber': 500012,
    'firstName': 'Viorel',
    'lastName': 'Gehrke',
    'email': 'Adolph_Quitzon62@hotmail.com',
    'gender': 'male',
    'street': 'An Maria Bronn',
    'houseNumber': '52',
    'zipCode': '71696',
    'city': 'Möglingen',
    'birthDate': dayjs( '01.01.1970', defaultDateFormat ),
    'phone': '+4972987832594',
    'navigation_path': '/customers/acc_VtKLJ2JvDv',
    'mandateReference': 'n9C1Z193zsYvIFZE',
    'accountName': 'Viorel Gehrke',
    'iban': 'DE12897104671991416157',
    'paymentMethod': 'bankTransfer',
    'type': 'customer',
    'index': 'acc_VtKLJ2JvDv 500012 Viorel Gehrke 01.01.1970 71696 An Maria Bronn 52 Möglingen',
    'version': 1,
  },
  {
    'id': 13,
    'code': 'acc_4lf3QTw6K0',
    'accountNumber': 500011,
    'firstName': 'Dennis',
    'lastName': 'Wagenschwanz',
    'email': 'Karelle.Johnson@gmail.com',
    'gender': 'male',
    'street': 'Zietenstraße',
    'houseNumber': '79',
    'zipCode': '58099',
    'city': 'Hagen',
    'birthDate': dayjs( '01.01.1970', defaultDateFormat ),
    'phone': '+4947555731465',
    'navigation_path': '/customers/acc_4lf3QTw6K0',
    'mandateReference': 'n9C1Z193zsYvIFZE',
    'accountName': 'Dennis Wagenschwanz',
    'iban': 'DE12897104671991416157',
    'paymentMethod': 'bankTransfer',
    'type': 'customer',
    'index': 'acc_4lf3QTw6K0 500011 Dennis Wagenschwanz 01.01.1970 58099 Zietenstraße 79 Hagen',
    'version': 1,
  },
  {
    'id': 14,
    'code': 'acc_ABmF7GOQ-V',
    'accountNumber': 500013,
    'firstName': 'Jörg',
    'lastName': 'Gehrke',
    'email': 'Aliyah7@yahoo.com',
    'gender': 'male',
    'street': 'Waldseestraße',
    'houseNumber': '5',
    'zipCode': '22397',
    'city': 'Hamburg',
    'birthDate': dayjs( '03.05.1994', defaultDateFormat ),
    'phone': '+4988250530456',
    'navigation_path': '/customers/acc_ABmF7GOQ-V',
    'mandateReference': 'n9C1Z193zsYvIFZE',
    'accountName': 'Jörg Gehrke',
    'iban': 'DE12897104671991416157',
    'paymentMethod': 'bankTransfer',
    'type': 'customer',
    'index': 'acc_ABmF7GOQ-V 500013 Jörg Gehrke 03.05.1994 22397 Waldseestraße 5 Hamburg',
    'version': 1,
  },
  {
    'id': 15,
    'code': 'acc_JJ-s7jQpzI',
    'accountNumber': 500020,
    'firstName': 'Hans-jörgen',
    'lastName': 'Pfitzer',
    'email': 'Isobel_Hickle27@hotmail.com',
    'gender': 'male',
    'street': 'Belchenweg',
    'houseNumber': '10',
    'zipCode': '73734',
    'city': 'Esslingen Am Neckar',
    'birthDate': dayjs( '01.01.1970', defaultDateFormat ),
    'phone': '+4944166413822',
    'navigation_path': '/customers/acc_JJ-s7jQpzI',
    'mandateReference': 'n9C1Z193zsYvIFZE',
    'accountName': 'Hans-jörgen Pfitzer',
    'iban': 'DE12897104671991416157',
    'paymentMethod': 'bankTransfer',
    'type': 'customer',
    'index': 'acc_JJ-s7jQpzI 500020 Hans-jörgen Pfitzer 01.01.1970 73734 Belchenweg 10 Esslingen Am Neckar',
    'version': 1,
  },
  {
    'id': 16,
    'code': 'acc_xRfUKD_cRg',
    'accountNumber': 500018,
    'firstName': 'Betty',
    'lastName': 'Ciftci',
    'email': 'Willard64@yahoo.com',
    'gender': 'male',
    'street': 'Am Schlart',
    'houseNumber': '50',
    'zipCode': '42781',
    'city': 'Haan',
    'birthDate': dayjs( '01.01.1970', defaultDateFormat ),
    'phone': '+4925575606452',
    'navigation_path': '/customers/acc_xRfUKD_cRg',
    'mandateReference': 'n9C1Z193zsYvIFZE',
    'accountName': 'Betty Ciftci',
    'iban': 'DE12897104671991416157',
    'paymentMethod': 'bankTransfer',
    'type': 'customer',
    'index': 'acc_xRfUKD_cRg 500018 Betty Ciftci 01.01.1970 42781 Am Schlart 50 Haan',
    'version': 1,
  },
  {
    'id': 17,
    'code': 'acc_deCNjxO3h5',
    'accountNumber': 500016,
    'firstName': 'Ulrike',
    'lastName': 'Lichner',
    'email': 'Margarett51@yahoo.com',
    'gender': 'male',
    'street': 'Breite Straße',
    'houseNumber': '100',
    'zipCode': '40591',
    'city': 'Düsseldorf',
    'birthDate': dayjs( '01.01.1970', defaultDateFormat ),
    'phone': '+4999037737358',
    'navigation_path': '/customers/acc_deCNjxO3h5',
    'mandateReference': 'n9C1Z193zsYvIFZE',
    'accountName': 'Ulrike Lichner',
    'iban': 'DE12897104671991416157',
    'paymentMethod': 'bankTransfer',
    'type': 'customer',
    'index': 'acc_deCNjxO3h5 500016 Ulrike Lichner 01.01.1970 40591 Breite Straße 100 Düsseldorf',
    'version': 1,
  },
  {
    'id': 18,
    'code': 'acc_I3PHB8TFkvs',
    'accountNumber': 500019,
    'firstName': 'Jeannette',
    'lastName': 'Zänsdorf',
    'email': 'Rosalind14@gmail.com',
    'gender': 'male',
    'street': 'Wochenendplatz Beverblick',
    'houseNumber': '3',
    'zipCode': '32105',
    'city': 'Bad Salzuflen',
    'birthDate': dayjs( '01.01.1970', defaultDateFormat ),
    'phone': '+4923382554893',
    'navigation_path': '/customers/acc_I3PHB8TFkvs',
    'mandateReference': 'n9C1Z193zsYvIFZE',
    'accountName': 'Jeannette Zänsdorf',
    'iban': 'DE12897104671991416157',
    'paymentMethod': 'bankTransfer',
    'type': 'customer',
    'index': 'acc_I3PHB8TFkvs 500019 Jeannette Zänsdorf 01.01.1970 32105 Wochenendplatz Beverblick 3 Bad Salzuflen',
    'version': 1,
  },
  {
    'id': 19,
    'code': 'acc_YwaG7G8PE5O',
    'accountNumber': 500014,
    'firstName': 'Yvonne',
    'lastName': 'Raudenkolb',
    'email': 'Mona.Swift@gmail.com',
    'gender': 'male',
    'street': 'Wordstraße',
    'houseNumber': '25',
    'zipCode': '58093',
    'city': 'Hagen',
    'birthDate': dayjs( '01.01.1970', defaultDateFormat ),
    'phone': '+4926804986955',
    'navigation_path': '/customers/acc_YwaG7G8PE5O',
    'mandateReference': 'n9C1Z193zsYvIFZE',
    'accountName': 'Yvonne Raudenkolb',
    'iban': 'DE12897104671991416157',
    'paymentMethod': 'bankTransfer',
    'type': 'customer',
    'index': 'acc_YwaG7G8PE5O 500014 Yvonne Raudenkolb 01.01.1970 58093 Wordstraße 25 Hagen',
    'version': 1,
  },
  {
    'id': 20,
    'code': 'acc_tcItC2Saf7n',
    'accountNumber': 500015,
    'firstName': 'Matthias Rudolf',
    'lastName': 'Kudryavtsev',
    'email': 'Ethelyn44@hotmail.com',
    'gender': 'male',
    'street': 'Oliver-lißy-straße',
    'houseNumber': '95',
    'zipCode': '66128',
    'city': 'Saarbrücken',
    'birthDate': dayjs( '01.01.1970', defaultDateFormat ),
    'phone': '+4924664582459',
    'navigation_path': '/customers/acc_tcItC2Saf7n',
    'mandateReference': 'n9C1Z193zsYvIFZE',
    'accountName': 'Matthias Rudolf Kudryavtsev',
    'iban': 'DE12897104671991416157',
    'paymentMethod': 'bankTransfer',
    'type': 'customer',
    'index': 'acc_tcItC2Saf7n 500015 Matthias Rudolf Kudryavtsev 01.01.1970 66128 Oliver-lißy-straße 95 Saarbrücken',
    'version': 1,
  },
  {
    'id': 21,
    'code': 'acc_g9FIeBVZxtM',
    'accountNumber': 500017,
    'firstName': 'Sigrid Uta',
    'lastName': 'Orlowski',
    'email': 'Zoie8@hotmail.com',
    'gender': 'male',
    'street': 'Landstraße',
    'houseNumber': '49',
    'zipCode': '16247',
    'city': 'Joachimsthal',
    'birthDate': dayjs( '01.11.1996', defaultDateFormat ),
    'phone': '+4978263153836',
    'navigation_path': '/customers/acc_g9FIeBVZxtM',
    'mandateReference': 'n9C1Z193zsYvIFZE',
    'accountName': 'Sigrid Uta Orlowski',
    'iban': 'DE12897104671991416157',
    'paymentMethod': 'bankTransfer',
    'type': 'customer',
    'index': 'acc_g9FIeBVZxtM 500017 Sigrid Uta Orlowski 01.11.1996 16247 Landstraße 49 Joachimsthal',
    'version': 1,
  },
  {
    'id': 22,
    'code': 'acc_RzeoDHzrnae',
    'accountNumber': 500023,
    'firstName': 'Michael Willi',
    'lastName': 'Kempe',
    'email': 'Mariano_Nitzsche93@hotmail.com',
    'gender': 'male',
    'street': 'Dr.-max-herold-straße',
    'houseNumber': '44',
    'zipCode': '83026',
    'city': 'Rosenheim',
    'birthDate': dayjs( '01.01.1970', defaultDateFormat ),
    'phone': '+4951154386316',
    'navigation_path': '/customers/acc_RzeoDHzrnae',
    'mandateReference': 'n9C1Z193zsYvIFZE',
    'accountName': 'Michael Willi Kempe',
    'iban': 'DE12897104671991416157',
    'paymentMethod': 'bankTransfer',
    'type': 'customer',
    'index': 'acc_RzeoDHzrnae 500023 Michael Willi Kempe 01.01.1970 83026 Dr.-max-herold-straße 44 Rosenheim',
    'version': 1,
  },
  {
    'id': 23,
    'code': 'acc_qs_c3QYHy2P',
    'accountNumber': 500024,
    'firstName': 'Heribert',
    'lastName': 'Fick',
    'email': 'Arturo_Armstrong@hotmail.com',
    'gender': 'male',
    'street': 'Blumberger Damm',
    'houseNumber': '18',
    'zipCode': '36304',
    'city': 'Alsfeld',
    'birthDate': dayjs( '01.01.1970', defaultDateFormat ),
    'phone': '+4973974322755',
    'navigation_path': '/customers/acc_qs_c3QYHy2P',
    'mandateReference': 'n9C1Z193zsYvIFZE',
    'accountName': 'Heribert Fick',
    'iban': 'DE12897104671991416157',
    'paymentMethod': 'bankTransfer',
    'type': 'customer',
    'index': 'acc_qs_c3QYHy2P 500024 Heribert Fick 01.01.1970 36304 Blumberger Damm 18 Alsfeld',
    'version': 1,
  },
  {
    'id': 24,
    'code': 'acc_0hqEc4G5x_W',
    'accountNumber': 500022,
    'firstName': 'Franziska',
    'lastName': 'Hanstein',
    'email': 'Lulu.Spencer79@hotmail.com',
    'gender': 'male',
    'street': 'Ziegelhüttenstraße',
    'houseNumber': '3',
    'zipCode': '31226',
    'city': 'Peine',
    'birthDate': dayjs( '01.01.1970', defaultDateFormat ),
    'phone': '+4972518091881',
    'navigation_path': '/customers/acc_0hqEc4G5x_W',
    'mandateReference': 'n9C1Z193zsYvIFZE',
    'accountName': 'Franziska Hanstein',
    'iban': 'DE12897104671991416157',
    'paymentMethod': 'bankTransfer',
    'type': 'customer',
    'index': 'acc_0hqEc4G5x_W 500022 Franziska Hanstein 01.01.1970 31226 Ziegelhüttenstraße 3 Peine',
    'version': 1,
  },
  {
    'id': 25,
    'code': 'acc_hKHl8SgsNCd',
    'accountNumber': 500029,
    'firstName': 'Großmann',
    'lastName': 'La Porte',
    'email': 'Lauryn47@yahoo.com',
    'gender': 'male',
    'street': 'Straupitzer Straße',
    'houseNumber': '9',
    'zipCode': '67722',
    'city': 'Winnweiler',
    'birthDate': dayjs( '01.01.1970', defaultDateFormat ),
    'phone': '+496563725954',
    'navigation_path': '/customers/acc_hKHl8SgsNCd',
    'mandateReference': 'n9C1Z193zsYvIFZE',
    'accountName': 'Großmann La Porte',
    'iban': 'DE12897104671991416157',
    'paymentMethod': 'bankTransfer',
    'type': 'customer',
    'index': 'acc_hKHl8SgsNCd 500029 Großmann La Porte 01.01.1970 67722 Straupitzer Straße 9 Winnweiler',
    'version': 1,
  },
  {
    'id': 26,
    'code': 'acc_Xxl1xorwUdZ',
    'accountNumber': 500021,
    'firstName': 'Rita',
    'lastName': 'De La Cruz Aranda',
    'email': 'Carmella_Pollich@hotmail.com',
    'gender': 'male',
    'street': 'Bersenbrücker Straße',
    'houseNumber': '81',
    'zipCode': '8289',
    'city': 'Schneeberg',
    'birthDate': dayjs( '01.01.1970', defaultDateFormat ),
    'phone': '+4920164114013',
    'navigation_path': '/customers/acc_Xxl1xorwUdZ',
    'mandateReference': 'n9C1Z193zsYvIFZE',
    'accountName': 'Rita De La Cruz Aranda',
    'iban': 'DE12897104671991416157',
    'paymentMethod': 'bankTransfer',
    'type': 'customer',
    'index': 'acc_Xxl1xorwUdZ 500021 Rita De La Cruz Aranda 01.01.1970 8289 Bersenbrücker Straße 81 Schneeberg',
    'version': 1,
  },
  {
    'id': 27,
    'code': 'acc_EiTOwwRRBi9',
    'accountNumber': 500025,
    'firstName': 'Ullrich Hans-werner',
    'lastName': 'Hoinka',
    'email': 'Bryana58@yahoo.com',
    'gender': 'male',
    'street': 'Pappelteich',
    'houseNumber': '9',
    'zipCode': '6905',
    'city': 'Bad Schmiedeberg',
    'birthDate': dayjs( '01.01.1970', defaultDateFormat ),
    'phone': '+4961883454421',
    'navigation_path': '/customers/acc_EiTOwwRRBi9',
    'mandateReference': 'n9C1Z193zsYvIFZE',
    'accountName': 'Ullrich Hans-werner Hoinka',
    'iban': 'DE12897104671991416157',
    'paymentMethod': 'bankTransfer',
    'type': 'customer',
    'index': 'acc_EiTOwwRRBi9 500025 Ullrich Hans-werner Hoinka 01.01.1970 6905 Pappelteich 9 Bad Schmiedeberg',
    'version': 1,
  },
  {
    'id': 29,
    'code': 'acc_yJs24vdcEcX',
    'accountNumber': 500028,
    'firstName': 'Heidi Jutta',
    'lastName': 'Boersch',
    'email': 'Arlo71@gmail.com',
    'gender': 'male',
    'street': 'Theodor-heuss-ring',
    'houseNumber': '77',
    'zipCode': '1920',
    'city': 'Crostwitz',
    'birthDate': dayjs( '12.06.1985', defaultDateFormat ),
    'phone': '+4979392517670',
    'navigation_path': '/customers/acc_yJs24vdcEcX',
    'mandateReference': 'n9C1Z193zsYvIFZE',
    'accountName': 'Heidi Jutta Boersch',
    'iban': 'DE12897104671991416157',
    'paymentMethod': 'bankTransfer',
    'type': 'customer',
    'index': 'acc_yJs24vdcEcX 500028 Heidi Jutta Boersch 12.06.1985 1920 Theodor-heuss-ring 77 Crostwitz',
    'version': 1,
  },
  {
    'id': 30,
    'code': 'acc_zLLbH0l5x6S',
    'accountNumber': 500030,
    'firstName': 'Dorothy',
    'lastName': 'Marx',
    'email': 'Ayla45@gmail.com',
    'gender': 'male',
    'street': 'Neuer Friedberg',
    'houseNumber': '75',
    'zipCode': '91301',
    'city': 'Forchheim',
    'birthDate': dayjs( '08.08.1957', defaultDateFormat ),
    'phone': '+4933941628710',
    'navigation_path': '/customers/acc_zLLbH0l5x6S',
    'mandateReference': 'n9C1Z193zsYvIFZE',
    'accountName': 'Dorothy Marx',
    'iban': 'DE12897104671991416157',
    'paymentMethod': 'bankTransfer',
    'type': 'customer',
    'index': 'acc_zLLbH0l5x6S 500030 Dorothy Marx 08.08.1957 91301 Neuer Friedberg 75 Forchheim',
    'version': 1,
  },
  {
    'id': 31,
    'code': 'acc_ntRd0Oh_hSe',
    'accountNumber': 500026,
    'firstName': 'Claudia Beate',
    'lastName': 'Koppe',
    'email': 'Eldred.Emard@hotmail.com',
    'gender': 'male',
    'street': 'Gänsweidestraße',
    'houseNumber': '9',
    'zipCode': '6217',
    'city': 'Merseburg',
    'birthDate': dayjs( '01.01.1970', defaultDateFormat ),
    'phone': '+4949335453642',
    'navigation_path': '/customers/acc_ntRd0Oh_hSe',
    'mandateReference': 'n9C1Z193zsYvIFZE',
    'accountName': 'Claudia Beate Koppe',
    'iban': 'DE12897104671991416157',
    'paymentMethod': 'bankTransfer',
    'type': 'customer',
    'index': 'acc_ntRd0Oh_hSe 500026 Claudia Beate Koppe 01.01.1970 6217 Gänsweidestraße 9 Merseburg',
    'version': 1,
  },
  {
    'id': 32,
    'code': 'acc_PDoPzc9vVfY',
    'accountNumber': 500031,
    'firstName': 'Christian Steffen',
    'lastName': 'Pfeiffer',
    'email': 'Chaz_Boyle67@gmail.com',
    'gender': 'male',
    'street': 'Willem-barents-str.',
    'houseNumber': '60',
    'zipCode': '1616',
    'city': 'Strehla',
    'birthDate': dayjs( '08.11.1959', defaultDateFormat ),
    'phone': '+4951712632619',
    'navigation_path': '/customers/acc_PDoPzc9vVfY',
    'mandateReference': 'n9C1Z193zsYvIFZE',
    'accountName': 'Christian Steffen Pfeiffer',
    'iban': 'DE12897104671991416157',
    'paymentMethod': 'bankTransfer',
    'type': 'customer',
    'index': 'acc_PDoPzc9vVfY 500031 Christian Steffen Pfeiffer 08.11.1959 1616 Willem-barents-str. 60 Strehla',
    'version': 1,
  },
  {
    'id': 34,
    'code': 'acc_3wOSF0Y9lUU',
    'accountNumber': 500036,
    'firstName': 'Waltraud Maria',
    'lastName': 'Bettray',
    'email': 'Godfrey96@gmail.com',
    'gender': 'male',
    'street': 'Illinger Straße',
    'houseNumber': '34',
    'zipCode': '70193',
    'city': 'Stuttgart',
    'birthDate': dayjs( '01.01.1970', defaultDateFormat ),
    'phone': '+4957491087673',
    'navigation_path': '/customers/acc_3wOSF0Y9lUU',
    'mandateReference': 'n9C1Z193zsYvIFZE',
    'accountName': 'Waltraud Maria Bettray',
    'iban': 'DE12897104671991416157',
    'paymentMethod': 'bankTransfer',
    'type': 'customer',
    'index': 'acc_3wOSF0Y9lUU 500036 Waltraud Maria Bettray 01.01.1970 70193 Illinger Straße 34 Stuttgart',
    'version': 1,
  },
  {
    'id': 33,
    'code': 'acc_JmAQgZs7zDz',
    'accountNumber': 500035,
    'firstName': 'Quang',
    'lastName': 'Musehold',
    'email': 'Mortimer_Mayert@yahoo.com',
    'gender': 'male',
    'street': 'Auweg',
    'houseNumber': '36',
    'zipCode': '97990',
    'city': 'Weikersheim',
    'birthDate': dayjs( '01.01.1979', defaultDateFormat ),
    'phone': '+4970621726389',
    'navigation_path': '/customers/acc_JmAQgZs7zDz',
    'mandateReference': 'n9C1Z193zsYvIFZE',
    'accountName': 'Quang Musehold',
    'iban': 'DE12897104671991416157',
    'paymentMethod': 'bankTransfer',
    'type': 'customer',
    'index': 'acc_JmAQgZs7zDz 500035 Quang Musehold Invalid date 97990 Auweg 36 Weikersheim',
    'version': 1,
  },
  {
    'id': 3,
    'code': 'acc_Jnfwo5NEFF',
    'accountNumber': 500009,
    'firstName': 'Oleg',
    'lastName': 'Sadlik',
    'email': 'Allen_Lesch38@yahoo.com',
    'gender': 'male',
    'street': 'Am Flachsteich',
    'houseNumber': '17',
    'zipCode': '10367',
    'city': 'Berlin',
    'birthDate': dayjs( '01.02.1970', defaultDateFormat ),
    'phone': '+4996621766052',
    'navigation_path': '/customers/acc_Jnfwo5NEFF',
    'mandateReference': 'n9C1Z193zsYvIFZE',
    'accountName': 'Oleg Sadlik',
    'iban': 'DE12897104671991416157',
    'paymentMethod': 'bankTransfer',
    'type': 'customer',
    'index': 'acc_Jnfwo5NEFF 500009 Oleg Sadlik Invalid date 10367 Am Flachsteich 17 Berlin',
    'version': 1,
  },
  {
    'id': 7,
    'code': 'acc_L886dfX1QM',
    'accountNumber': 500004,
    'firstName': 'Büsra Zeynep test',
    'lastName': 'Sader',
    'email': 'Bettye92@gmail.com',
    'gender': 'male',
    'street': 'Emscherstraße',
    'houseNumber': '71',
    'zipCode': '40186',
    'city': 'Bad Iburg',
    'birthDate': dayjs( '01.12.1987', defaultDateFormat ),
    'phone': '+4978018732761',
    'navigation_path': '/customers/acc_L886dfX1QM',
    'mandateReference': 'n9C1Z193zsYvIFZE',
    'accountName': 'Büsra Zeynep test Sader',
    'iban': 'DE12897104671991416157',
    'paymentMethod': 'bankTransfer',
    'type': 'customer',
    'index': 'acc_L886dfX1QM 500004 Büsra Zeynep test Sader 01.12.1987 40186 Emscherstraße 71 Bad Iburg',
    'version': 1,
  },
  {
    'id': 28,
    'code': 'acc_MaqBLPd8H1m',
    'accountNumber': 500027,
    'firstName': 'Kenan',
    'lastName': 'Grajewski-lucas',
    'email': 'Daphnee_Gulgowski@yahoo.com',
    'gender': 'male',
    'street': 'Holzmeiers Hof',
    'houseNumber': '3',
    'zipCode': '58239',
    'city': 'Schwerte',
    'birthDate': dayjs( '01.12.1987', defaultDateFormat ),
    'phone': '+4955546978023',
    'navigation_path': '/customers/acc_MaqBLPd8H1m',
    'mandateReference': 'n9C1Z193zsYvIFZE',
    'accountName': 'Kenan Grajewski-lucas',
    'iban': 'DE12897104671991416157',
    'paymentMethod': 'bankTransfer',
    'type': 'customer',
    'index': 'acc_MaqBLPd8H1m 500027 Kenan Grajewski-lucas 01.12.1987 58239 Holzmeiers Hof 3 Schwerte',
    'version': 1,
  },
  {
    'id': 5,
    'code': 'acc_8CskF06y4I',
    'accountNumber': 500005,
    'firstName': 'Gerhard',
    'lastName': 'Schulten',
    'email': 'Kara.Zboncak@hotmail.com',
    'gender': 'male',
    'street': 'Taubenweg',
    'houseNumber': '104',
    'zipCode': '84172',
    'city': 'Buch Am Erlbach',
    'birthDate': dayjs( '01.02.2002', defaultDateFormat ),
    'phone': '+4984315014447',
    'navigation_path': '/customers/acc_8CskF06y4I',
    'mandateReference': 'n9C1Z193zsYvIFZE',
    'accountName': 'Gerhard Schulten',
    'iban': 'DE12897104671991416157',
    'paymentMethod': 'bankTransfer',
    'type': 'customer',
    'index': 'acc_8CskF06y4I 500005 Gerhard Schulten Invalid date 84172 Taubenweg 104 Buch Am Erlbach',
    'version': 1,
  },
  {
    'id': 36,
    'code': 'acc_faHe9bO3pZe',
    'accountNumber': 500039,
    'firstName': 'Eduard',
    'lastName': 'Moster',
    'email': 'Mollie7@gmail.com',
    'gender': 'male',
    'street': 'Kamp',
    'houseNumber': '41',
    'zipCode': '30823',
    'city': 'Garbsen',
    'birthDate': dayjs( '03.05.1927', defaultDateFormat ),
    'phone': '+4952103004005',
    'navigation_path': '/customers/acc_faHe9bO3pZe',
    'mandateReference': 'n9C1Z193zsYvIFZE',
    'accountName': 'Eduard Moster',
    'iban': 'DE12897104671991416157',
    'paymentMethod': 'bankTransfer',
    'type': 'customer',
    'index': 'acc_faHe9bO3pZe 500039 Eduard Moster 03.05.1927 30823 Kamp 41 Garbsen',
    'version': 1,
  },
  {
    'id': 47,
    'code': 'acc_mDvY7jYDBt',
    'accountNumber': 500049,
    'firstName': 'Tonio',
    'lastName': 'Souto',
    'email': 'Beth_Hickle@gmail.com',
    'gender': 'male',
    'street': 'Hauptstr.',
    'houseNumber': '17',
    'zipCode': '16515',
    'city': 'Oranienburg',
    'birthDate': dayjs( '01.01.1970', defaultDateFormat ),
    'phone': '+4917056178576',
    'navigation_path': '/customers/acc_mDvY7jYDBt',
    'mandateReference': 'n9C1Z193zsYvIFZE',
    'accountName': 'Tonio Souto',
    'iban': 'DE12897104671991416157',
    'paymentMethod': 'bankTransfer',
    'type': 'customer',
    'index': 'acc_mDvY7jYDBt 500049 Tonio Souto 01.01.1970 16515 Hauptstr. 17 Oranienburg',
    'version': 1,
  },
  {
    'id': 37,
    'code': 'acc_D_1TjvUX4bR',
    'accountNumber': 500032,
    'firstName': 'Lothar',
    'lastName': 'Pansow',
    'email': 'Adolph_Dickinson@yahoo.com',
    'gender': 'male',
    'street': 'Neue Straße',
    'houseNumber': '5',
    'zipCode': '51469',
    'city': 'Bergisch Gladbach',
    'birthDate': dayjs( '01.01.1970', defaultDateFormat ),
    'phone': '+499592785608',
    'navigation_path': '/customers/acc_D_1TjvUX4bR',
    'mandateReference': 'n9C1Z193zsYvIFZE',
    'accountName': 'Lothar Pansow',
    'iban': 'DE12897104671991416157',
    'paymentMethod': 'bankTransfer',
    'type': 'customer',
    'index': 'acc_D_1TjvUX4bR 500032 Lothar Pansow 01.01.1970 51469 Neue Straße 5 Bergisch Gladbach',
    'version': 1,
  },
  {
    'id': 46,
    'code': 'acc_LjjEQu1WJV',
    'accountNumber': 500044,
    'firstName': 'Annemarie',
    'lastName': 'Bedekovic',
    'email': 'Violette.Roob@yahoo.com',
    'gender': 'male',
    'street': 'Holzmeiers Hof',
    'houseNumber': '8',
    'zipCode': '76767',
    'city': 'Hagenbach',
    'birthDate': dayjs( '01.01.1970', defaultDateFormat ),
    'phone': '+4953694497900',
    'navigation_path': '/customers/acc_LjjEQu1WJV',
    'mandateReference': 'n9C1Z193zsYvIFZE',
    'accountName': 'Annemarie Bedekovic',
    'iban': 'DE12897104671991416157',
    'paymentMethod': 'bankTransfer',
    'type': 'customer',
    'index': 'acc_LjjEQu1WJV 500044 Annemarie Bedekovic 01.01.1970 76767 Holzmeiers Hof 8 Hagenbach',
    'version': 1,
  },
  {
    'id': 38,
    'code': 'acc_e8wqG1hUYqY',
    'accountNumber': 500033,
    'firstName': 'Juliane',
    'lastName': 'Terjung',
    'email': 'Judy.Hudson@hotmail.com',
    'gender': 'male',
    'street': 'Hampesweg',
    'houseNumber': '35',
    'zipCode': '23617',
    'city': 'Krumbeck',
    'birthDate': dayjs( '01.01.1970', defaultDateFormat ),
    'phone': '+4933695084574',
    'navigation_path': '/customers/acc_e8wqG1hUYqY',
    'mandateReference': 'n9C1Z193zsYvIFZE',
    'accountName': 'Juliane Terjung',
    'iban': 'DE12897104671991416157',
    'paymentMethod': 'bankTransfer',
    'type': 'customer',
    'index': 'acc_e8wqG1hUYqY 500033 Juliane Terjung 01.01.1970 23617 Hampesweg 35 Krumbeck',
    'version': 1,
  },
  {
    'id': 45,
    'code': 'acc_bUuWxG6ZEv',
    'accountNumber': 500042,
    'firstName': 'Christian Tobias',
    'lastName': 'Brem',
    'email': 'Erik82@hotmail.com',
    'gender': 'male',
    'street': 'Wittelsbacherplatz',
    'houseNumber': '8',
    'zipCode': '89555',
    'city': 'Steinheim Am Albuch',
    'birthDate': dayjs( '02.12.1935', defaultDateFormat ),
    'phone': '+4918838490836',
    'navigation_path': '/customers/acc_bUuWxG6ZEv',
    'mandateReference': 'n9C1Z193zsYvIFZE',
    'accountName': 'Christian Tobias Brem',
    'iban': 'DE12897104671991416157',
    'paymentMethod': 'bankTransfer',
    'type': 'customer',
    'index': 'acc_bUuWxG6ZEv 500042 Christian Tobias Brem 02.12.1935 89555 Wittelsbacherplatz 8 Steinheim Am Albuch',
    'version': 1,
  },
  {
    'id': 39,
    'code': 'acc_zhJG3oArD4b',
    'accountNumber': 500034,
    'firstName': 'Wera',
    'lastName': 'Hämmerle',
    'email': 'Taylor.Flatley69@gmail.com',
    'gender': 'male',
    'street': 'Im Bubenstück',
    'houseNumber': '103',
    'zipCode': '71384',
    'city': 'Weinstadt',
    'birthDate': dayjs( '01.01.1970', defaultDateFormat ),
    'phone': '+4941402659161',
    'navigation_path': '/customers/acc_zhJG3oArD4b',
    'mandateReference': 'n9C1Z193zsYvIFZE',
    'accountName': 'Wera Hämmerle',
    'iban': 'DE12897104671991416157',
    'paymentMethod': 'bankTransfer',
    'type': 'customer',
    'index': 'acc_zhJG3oArD4b 500034 Wera Hämmerle 01.01.1970 71384 Im Bubenstück 103 Weinstadt',
    'version': 1,
  },
  {
    'id': 43,
    'code': 'acc__7w-H3QLXH',
    'accountNumber': 500043,
    'firstName': 'Endrik',
    'lastName': 'Röding',
    'email': 'Santa.Zboncak57@gmail.com',
    'gender': 'male',
    'street': 'Kronacher Straße',
    'houseNumber': '42',
    'zipCode': '4416',
    'city': 'Markkleeberg',
    'birthDate': dayjs( '10.04.1923', defaultDateFormat ),
    'phone': '+4978582462142',
    'navigation_path': '/customers/acc__7w-H3QLXH',
    'mandateReference': 'n9C1Z193zsYvIFZE',
    'accountName': 'Endrik Röding',
    'iban': 'DE12897104671991416157',
    'paymentMethod': 'bankTransfer',
    'type': 'customer',
    'index': 'acc__7w-H3QLXH 500043 Endrik Röding 10.04.1923 4416 Kronacher Straße 42 Markkleeberg',
    'version': 1,
  },
  {
    'id': 40,
    'code': 'acc_31LC9gkxiJy',
    'accountNumber': 500040,
    'firstName': 'Anna',
    'lastName': 'Elsäßer',
    'email': 'Eddie_Stroman@yahoo.com',
    'gender': 'male',
    'street': 'Ziegelhüttenstraße',
    'houseNumber': '36',
    'zipCode': '97647',
    'city': 'Willmars',
    'birthDate': dayjs( '01.01.1970', defaultDateFormat ),
    'phone': '+4935026932769',
    'navigation_path': '/customers/acc_31LC9gkxiJy',
    'mandateReference': 'n9C1Z193zsYvIFZE',
    'accountName': 'Anna Elsäßer',
    'iban': 'DE12897104671991416157',
    'paymentMethod': 'bankTransfer',
    'type': 'customer',
    'index': 'acc_31LC9gkxiJy 500040 Anna Elsäßer 01.01.1970 97647 Ziegelhüttenstraße 36 Willmars',
    'version': 1,
  },
  {
    'id': 42,
    'code': 'acc_Fp8YKo--E',
    'accountNumber': 500047,
    'firstName': 'Oliver',
    'lastName': 'Kaiser',
    'email': 'Wilmer.Jaskolski@hotmail.com',
    'gender': 'male',
    'street': 'Paul-greifzu-straße',
    'houseNumber': '101',
    'zipCode': '59939',
    'city': 'Olsberg',
    'birthDate': dayjs( '01.01.1970', defaultDateFormat ),
    'phone': '+4984499856627',
    'navigation_path': '/customers/acc_Fp8YKo--E',
    'mandateReference': 'n9C1Z193zsYvIFZE',
    'accountName': 'Oliver Kaiser',
    'iban': 'DE12897104671991416157',
    'paymentMethod': 'bankTransfer',
    'type': 'customer',
    'index': 'acc_Fp8YKo--E 500047 Oliver Kaiser 01.01.1970 59939 Paul-greifzu-straße 101 Olsberg',
    'version': 1,
  },
  {
    'id': 51,
    'code': 'acc_CzDX3G_cUt',
    'accountNumber': 500050,
    'firstName': 'Frank-michael',
    'lastName': 'Schoth',
    'email': 'Mona6@yahoo.com',
    'gender': 'male',
    'street': 'Zur Bockmühle',
    'houseNumber': '3',
    'zipCode': '26409',
    'city': 'Wittmund',
    'birthDate': dayjs( '01.01.1970', defaultDateFormat ),
    'phone': '+4991436400127',
    'navigation_path': '/customers/acc_CzDX3G_cUt',
    'mandateReference': 'n9C1Z193zsYvIFZE',
    'accountName': 'Frank-michael Schoth',
    'iban': 'DE12897104671991416157',
    'paymentMethod': 'bankTransfer',
    'type': 'customer',
    'index': 'acc_CzDX3G_cUt 500050 Frank-michael Schoth 01.01.1970 26409 Zur Bockmühle 3 Wittmund',
    'version': 1,
  },
  {
    'id': 41,
    'code': 'acc_coB52WYUZJh',
    'accountNumber': 500037,
    'firstName': 'Jörg Herbert Walter',
    'lastName': 'Kurbjuhn',
    'email': 'Bo49@hotmail.com',
    'gender': 'male',
    'street': 'Gottschalkstraße',
    'houseNumber': '83',
    'zipCode': '77767',
    'city': 'Appenweier',
    'birthDate': dayjs( '02.05.1991', defaultDateFormat ),
    'phone': '+497594807854',
    'navigation_path': '/customers/acc_coB52WYUZJh',
    'mandateReference': 'n9C1Z193zsYvIFZE',
    'accountName': 'Jörg Herbert Walter Kurbjuhn',
    'iban': 'DE12897104671991416157',
    'paymentMethod': 'bankTransfer',
    'type': 'customer',
    'index': 'acc_coB52WYUZJh 500037 Jörg Herbert Walter Kurbjuhn 02.05.1991 77767 Gottschalkstraße 83 Appenweier',
    'version': 1,
  },
  {
    'id': 44,
    'code': 'acc_WwAUE2nlOX',
    'accountNumber': 500048,
    'firstName': 'Franz Xaver',
    'lastName': 'Seidel',
    'email': 'May88@hotmail.com',
    'gender': 'male',
    'street': 'Schellenberger Straße',
    'houseNumber': '14',
    'zipCode': '12529',
    'city': 'Schönefeld',
    'birthDate': dayjs( '01.01.1970', defaultDateFormat ),
    'phone': '+49924373195',
    'navigation_path': '/customers/acc_WwAUE2nlOX',
    'mandateReference': 'n9C1Z193zsYvIFZE',
    'accountName': 'Franz Xaver Seidel',
    'iban': 'DE12897104671991416157',
    'paymentMethod': 'bankTransfer',
    'type': 'customer',
    'index': 'acc_WwAUE2nlOX 500048 Franz Xaver Seidel 01.01.1970 12529 Schellenberger Straße 14 Schönefeld',
    'version': 1,
  },
  {
    'id': 49,
    'code': 'acc_E0WQQydLic',
    'accountNumber': 500041,
    'firstName': 'Hasan',
    'lastName': 'Kapski',
    'email': 'Gia.Hermann@hotmail.com',
    'gender': 'male',
    'street': 'Goebenstraße',
    'houseNumber': '15',
    'zipCode': '79682',
    'city': 'Todtmoos',
    'birthDate': dayjs( '01.01.1970', defaultDateFormat ),
    'phone': '+4962822536654',
    'navigation_path': '/customers/acc_E0WQQydLic',
    'mandateReference': 'n9C1Z193zsYvIFZE',
    'accountName': 'Hasan Kapski',
    'iban': 'DE12897104671991416157',
    'paymentMethod': 'bankTransfer',
    'type': 'customer',
    'index': 'acc_E0WQQydLic 500041 Hasan Kapski 01.01.1970 79682 Goebenstraße 15 Todtmoos',
    'version': 1,
  },
  {
    'id': 50,
    'code': 'acc_ormx6c6u-0',
    'accountNumber': 500046,
    'firstName': 'Karsten Adolf',
    'lastName': 'Frobarth',
    'email': 'Darrion12@yahoo.com',
    'gender': 'male',
    'street': 'Kierdorferstr.',
    'houseNumber': '24',
    'zipCode': '21629',
    'city': 'Neu Wulmstorf',
    'birthDate': dayjs( '01.01.1970', defaultDateFormat ),
    'phone': '+4975917606375',
    'navigation_path': '/customers/acc_ormx6c6u-0',
    'mandateReference': 'n9C1Z193zsYvIFZE',
    'accountName': 'Karsten Adolf Frobarth',
    'iban': 'DE12897104671991416157',
    'paymentMethod': 'bankTransfer',
    'type': 'customer',
    'index': 'acc_ormx6c6u-0 500046 Karsten Adolf Frobarth 01.01.1970 21629 Kierdorferstr. 24 Neu Wulmstorf',
    'version': 1,
  },
  {
    'id': 2,
    'code': 'acc_meJKCNIaN',
    'accountNumber': 500003,
    'firstName': 'Stefan-andre',
    'lastName': 'Papenfuß',
    'email': 'Montana.Reilly@yahoo.com',
    'gender': 'male',
    'street': 'Lerchenweg',
    'houseNumber': '77',
    'zipCode': '31749',
    'city': 'Auetal',
    'birthDate': dayjs( '01.01.1970', defaultDateFormat ),
    'phone': '+4923294501944',
    'navigation_path': '/customers/acc_meJKCNIaN',
    'mandateReference': 'n9C1Z193zsYvIFZE',
    'accountName': 'Stefan-andre Papenfuß',
    'iban': 'DE12897104671991416157',
    'paymentMethod': 'bankTransfer',
    'type': 'customer',
    'index': 'acc_meJKCNIaN 500003 Stefan-andre Papenfuß 01.01.1970 31749 Lerchenweg 77 Auetal',
    'version': 1,
  },
  {
    'id': 35,
    'code': 'acc_Ncc0H75B1xN',
    'accountNumber': 500038,
    'firstName': 'Marvin',
    'lastName': 'Bettray',
    'email': 'Kaitlin_Bruen55@hotmail.com',
    'gender': 'male',
    'street': 'Gittertor',
    'houseNumber': '33',
    'zipCode': '32361',
    'city': 'Preußisch Oldendorf',
    'birthDate': dayjs( '04.06.1920', defaultDateFormat ),
    'phone': '+4960556699447',
    'navigation_path': '/customers/acc_Ncc0H75B1xN',
    'mandateReference': 'n9C1Z193zsYvIFZE',
    'accountName': 'Marvin Bettray',
    'iban': 'DE12897104671991416157',
    'paymentMethod': 'bankTransfer',
    'type': 'customer',
    'index': 'acc_Ncc0H75B1xN 500038 Marvin Bettray 04.06.1920 32361 Gittertor 33 Preußisch Oldendorf',
    'version': 1,
  },
  {
    'id': 48,
    'code': 'acc_lI5EecMir-',
    'accountNumber': 500045,
    'firstName': 'Sofia Sibille',
    'lastName': 'Näffgen',
    'email': 'Velda.Metz91@yahoo.com',
    'gender': 'male',
    'street': 'Mörschgasse',
    'houseNumber': '3',
    'zipCode': '94148',
    'city': 'Kirchham',
    'birthDate': dayjs( '01.01.1970', defaultDateFormat ),
    'phone': '+4984644115281',
    'navigation_path': '/customers/acc_lI5EecMir-',
    'mandateReference': 'n9C1Z193zsYvIFZE',
    'accountName': 'Sofia Sibille Näffgen',
    'iban': 'DE12897104671991416157',
    'paymentMethod': 'bankTransfer',
    'type': 'customer',
    'index': 'acc_lI5EecMir- 500045 Sofia Sibille Näffgen 01.01.1970 94148 Mörschgasse 3 Kirchham',
    'version': 1,
  },
];

export const mockedCustomers: CustomersResponse = {
  items: customersData,
};
