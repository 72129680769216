import React, { useContext } from 'react';

import { ApiContext } from 'Services/api-context';
import { IUserService, UserServiceContext, UsersServiceContext, IUsersService } from 'Services/users';
import { ServiceProviderProps } from './common';

export const UserServiceProvider: React.FC<ServiceProviderProps<IUserService>> = ( props, context ) => {

  const api = useContext( ApiContext );

  // Create usersService that depends on api. To create service we are using useClass implementation
  const usersService: IUserService = new props.useClass( api );

  return (
    <UserServiceContext.Provider value={ usersService }>
      { props.children }
    </UserServiceContext.Provider>
  );
};

export const UsersServiceProvider: React.FC<ServiceProviderProps<IUsersService>> = ( props, context ) => {
  const api = useContext( ApiContext );
  const usersService: IUsersService = new props.useClass( api );

  return (
    <UsersServiceContext.Provider value={ usersService }>
      { props.children }
    </UsersServiceContext.Provider>
  );
};
