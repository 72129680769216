import { BaseRequest, PagingRequest } from '../base';
import { IApiListResponse } from 'Services/policies/new/service';
import { ClaimStatusItem } from './interfaces';
import { getQueryParamsForListAPI } from 'Services/api';

export class ListClaimStatusesRequest extends BaseRequest<IApiListResponse<ClaimStatusItem>> {
  constructor(
    paging: PagingRequest,
  ) {
    super( {
      method: 'GET',
      url: '/v1/claim-statuses',
      responseType: 'json',
      params: getQueryParamsForListAPI( paging ),
    } );
  }
}
