import dayjs from 'dayjs';
import { BaseRequest, PagingRequest } from 'Services/base';
import { IApiListResponse } from 'Services/policies/new/service';

export class ListVwbMessagesRequest extends BaseRequest<IApiListResponse<IApiVwbMessageItem>> {
  constructor(
    paging: PagingRequest,
    searchValue: string,
    expand?: string,
  ) {

    const queryParams = new URLSearchParams();
    queryParams.set( 'pageSize', paging.pageSize.toString() );

    if ( paging.pageToken > 1 ) {
      queryParams.set( 'pageToken', paging.pageToken.toString() );
    }

    if ( paging.filter.length > 0 ) {
      const filterPars = paging.filter.map( ( item ) => `${item.id}=${encodeURIComponent( item.value )}` ).join( '&' );
      queryParams.set( 'filter', filterPars );
    }

    if ( paging.orderBy.length > 0 ) {
      const orderPars = paging.orderBy.map( ( item ) => `${item.id} ${ item.desc ? 'DESC' : 'ASC' }` ).join( '&' );
      queryParams.set( 'order', orderPars );
    }

    if ( searchValue.length > 0 ) {
      queryParams.set( 'query', searchValue );
    }

    if ( expand ) {
      queryParams.set( 'expand', expand );
    }

    super( {
      method: 'GET',
      url: '/gdvservice/v1/vwb/messages',
      responseType: 'json',
      params: queryParams,
    } );
  }
}

export class VwbPositiveAnswerRequest extends BaseRequest {
  constructor(
    answer: VwbPositiveAnswer,
  ) {

    super( {
      method: 'POST',
      url: '/gdvservice/v1/vwb/positive_answers',
      data: answer,
      responseType: 'json',
    } );
  }
}

export class VwbNegativeAnswerRequest extends BaseRequest {
  constructor(
    answer: VwbNegativeAnswer,
  ) {

    super( {
      method: 'POST',
      url: '/gdvservice/v1/vwb/negative-answers',
      data: answer,
      responseType: 'json',
    } );
  }
}

export class VwbMessageUpdateRequest extends BaseRequest {
  constructor(
    message: VwbMessageUpdate,
  ) {

    super( {
      method: 'PUT',
      url: `/gdvservice/v1/vwb/${message.messageId}`,
      data: message,
      responseType: 'json',
    } );
  }
}

export interface IVwbMessageType {
  id: number;
  slug: string;
}

export interface IVwbPolicyDetails {
  id: number;
  policyCode: string;
  policyNumber: string;
  messageId: number;
  createdAt: string;
  updatedAt: string;
}

export interface IApiVwbMessageItem {
  id: number;
  destination: string;
  serializedData: string;
  policy?: IVwbPolicyDetails;
  type: IVwbMessageType;
  createdAt: dayjs.Dayjs;
  answers?: MessageAnswer[];
  details: IVwbMessageDetails[];
}

export interface MessageAnswer {
  messageAnswerId: number;
  messageEnquiryId: number;
  answerType: VwbResponse;
}

export type VwbResponse = 'negative_answer' | 'positive_answer' | 'correction_negative_answer' | 'enquiry' |
'enquiry_without_answer' | 'sfr_return' | 'correction_positive_answer';

export interface IVwbMessageItem {
  id: number;
  destination: string;
  serializedData: string;
  policy?: IVwbPolicyDetails;
  type: IVwbMessageType;
  createdAt: dayjs.Dayjs;
  answers?: MessageAnswer[];
  details: IVwbMessageDetails[];
}

export interface IVwbMessageDetails {
  id: number;
  messageId: number;
  answerReferenceId: number;
  recordTypeCode: string;
  transactionNumber: string;
  subsequentInsurerId: string;
  subsequentInsurerPolicyNumber: string;
  enquiryReasonCode: string;
  vehicleIdentificationNumber: string;
  subsequentInsurerPolicyStartDate: string;
  salutationCode: string;
  lastNamePart1: string;
  lastNamePart2: string;
  firstName: string;
  street: string;
  countryCode: string;
  postCode: string;
  city: string;
  previousInsurerId: string;
  previousInsurerPolicyNumber: string;
  licensePlate: string;
  hasCertificateAfterTermination: string;
  nthReminder: string;
  positiveAnswerCreatedDate: string;
  vehicleTypeCode: string;
  previousInsurerPolicyStartDate: string;
  previousInsurerPolicyEndDate: string;
  previousInsurerPolicyStatus: string;
  discountBaseYear: number;
  sfClass: string;
  claimsWithoutEffectOnSfClass: number;
  claimsWithEffectOnSfClass: number;
  suspensionDaysInTerminationYear: number;
  negativeAnswerReasonCode: string;
  negativeAnswerDate: string;
  comment: string;
  internalReferenceId: string;
  createdAt?: Date;
  updatedAt?: Date;
  reference?: AnswerReference;
  negativeAnswerReason?: NegativeAnswerReason;
  additionalAnswer?: AdditionalPositiveAnswer;
  enquiryReason?: EnquiryReason;
  correctionDate?: string;
  correctionType?: string;
  filler?: string;
  returnReason?: string;
  claims?: Claim[];
}

export interface AnswerReference {
  id: number;
  messageId: number;
  recordTypeCode: string;
  transactionNumber: string;
  subsequentInsurerId: string;
  subsequentInsurerPolicyNumber: string;
  enquiryReasonCode: string;
  vehicleIdentificationNumber: string;
  subsequentInsurerPolicyStartDate: string;
  salutationCode: string;
  lastNamePart1: string;
  lastNamePart2: string;
  firstName: string;
  street: string;
  countryCode: string;
  postCode: string;
  city: string;
  previousInsurerId: string;
  previousInsurerPolicyNumber: string;
  licensePlate: string;
  hasCertificateAfterTermination: string;
  nthReminder: number;
  createdAt?: Date;
  updatedAt?: Date;
}

export interface NegativeAnswerReason {
  id: number;
  reason: string;
  reasonCode: string;
}

export interface EnquiryReason {
  id: number;
  reason: string;
  reasonCode: string;
}

export interface AdditionalPositiveAnswer {
  id: number;
  messageId: number;
  positiveAnswerId: number;
  recordTypeCode: string;
  transactionNumber: string;
  previousInsurerId: string;
  claims: Claim[];
}
export interface Claim {
  id: number;
  messageId: number;
  additionalPositiveAnswerId: number;
  coverageType: string;
  claimReportedYear: string;
  damageDate: string;
  exemption: string;
  filler: string;
}

export interface VwbPositiveAnswer {
  policyCode: string;
  enquiryId: number;
}

export interface VwbMessageUpdate {
  policyCode: string;
  messageId: number;
}

export interface VwbNegativeAnswer {
  policyCode: string;
  enquiryId: number;
  negativeAnswerReasonCode: string;
  comment?: string;
}

export interface VwbCategoryAndStatus {
  name: string;
  value: VwbResponse;
}

export interface FzvCategoryAndStatus {
  name: string;
  value: string;
}

export const vwbInboxCategories: VwbCategoryAndStatus[] = [
  { name: 'positive', value: 'positive_answer' },
  { name: 'negative', value: 'negative_answer' },
  { name: 'correctionPositive', value: 'correction_positive_answer' },
  { name: 'correctionNegative', value: 'correction_negative_answer' },
  { name: 'enquiry', value: 'enquiry' },
  { name: 'enquiryWithoutAnswer', value: 'enquiry_without_answer' },
  { name: 'sfReturn', value: 'sfr_return' },
];

export const vwbInboxStatuses: VwbCategoryAndStatus[] = [
  { name: 'negative', value: 'negative_answer' },
  { name: 'positive', value: 'positive_answer' },
];
