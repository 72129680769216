import React, { useContext } from 'react';
import { ApiContext } from 'Services/api-context';
import { ServiceProviderProps } from './common';
import { PartnerTypeServiceContext, IPartnerTypeService } from '../../../../Services/partner-type';

export const PartnerTypeServiceProvider: React.FC<ServiceProviderProps<IPartnerTypeService>> =
( props, context ) => {
  const api = useContext( ApiContext );

  // Create PartnerTypeService that depends on api. To create service we are using useClass implementation
  const partnerTypeService: IPartnerTypeService = new props.useClass( api );

  return (
    <PartnerTypeServiceContext.Provider value={ partnerTypeService }>
      { props.children }
    </PartnerTypeServiceContext.Provider>
  );
};
