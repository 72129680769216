import React, { useContext } from 'react';
import { ApiContext } from 'Services/api-context';
import { ServiceProviderProps } from './common';
import { IPaymentService, PaymentServiceContext } from 'Services/payments';

export const PaymentServiceProvider:
React.FC<ServiceProviderProps<IPaymentService>> = ( props, context ) => {
  const api = useContext( ApiContext );
  const widgetService: IPaymentService = new props.useClass( api );

  return (
    <PaymentServiceContext.Provider value={ widgetService }>
      { props.children }
    </PaymentServiceContext.Provider>
  );
};
