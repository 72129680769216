import React from 'react';
import { PagingRequest, IApiService, ApiResponse } from './base';
import { Logger } from 'loglevel';
import { IPluginItem, StatusType } from './plugin/interfaces';

export interface IPluginService {
  getInstallPluginItems( paging: PagingRequest ): Promise<IPluginItem[]>;
  getNotInstallPluginItems( paging: PagingRequest, searchQuery: string, currentLng: string ): Promise<IPluginItem[]>;
  installPlugin( plugin: IPluginItem, statusPlugin: StatusType ): Promise<ApiResponse>;
  uninstallPlugin( plugin: IPluginItem, statusPlugin: StatusType ): Promise<ApiResponse>;
}

export class PluginService implements IPluginService {
  protected api: IApiService;
  protected logger: Logger;

  constructor( api: IApiService, logger: Logger ) {
    this.api = api;
    this.logger = logger;
  }

  getInstallPluginItems( paging: PagingRequest ): Promise<IPluginItem[]> {
    throw new Error( 'Method getInstallPluginItems not implemented.' );
  }

  getNotInstallPluginItems( paging: PagingRequest, searchQuery: string, currentLng: string ): Promise<IPluginItem[]> {
    throw new Error( 'Method getNotInstallPluginItems not implemented.' );
  }

  installPlugin( plugin: IPluginItem, statusPlugin: StatusType ): Promise<ApiResponse> {
    throw new Error( 'Method InstallPlugin not implemented.' );
  }

  uninstallPlugin( plugin: IPluginItem, statusPlugin: StatusType ): Promise<ApiResponse> {
    throw new Error( 'Method UninstallPlugin not implemented.' );
  }

};

export const PluginServiceContext: React.Context<IPluginService> = React.createContext( undefined as any );

export const usePluginService = (): IPluginService => {
  return React.useContext( PluginServiceContext );
};
