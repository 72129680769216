import React from 'react';
import { withRouter, RouteComponentProps } from 'react-router';

export interface ScrollToTopProps {}

type ScrollToTopType = ScrollToTopProps & RouteComponentProps<any>;

class ScrollToTopInner extends React.Component<ScrollToTopType> {
  componentDidUpdate( prevProps: ScrollToTopType ): void {
    if ( this.props.location !== prevProps.location && this.props.location.state !== 'onClickTab' ) {
      window.scrollTo( 0, 0 );
    }
  }

  render(): React.ReactNode {
    return this.props.children;
  }
}

export const ScrollToTop = withRouter( ScrollToTopInner );
