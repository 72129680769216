import React, { useContext } from 'react';
import { ApiContext } from 'Services/api-context';
import { ServiceProviderProps } from './common';
import { ProductServiceContext, IProductService } from 'Services/product';

export const ProductServiceProvider: React.FC<ServiceProviderProps<IProductService>> = ( props, context ) => {
  const api = useContext( ApiContext );
  const productService: IProductService = new props.useClass( api );

  return (
    <ProductServiceContext.Provider value={ productService }>
      { props.children }
    </ProductServiceContext.Provider>
  );
};
