export enum AlertType {
  Price = 'price',
  Ranking = 'ranking',
  Tariff = 'tariff',
}

export enum FieldTypeSystem {
  Channels = 'channels',
  Insurers = 'insurers'
}
