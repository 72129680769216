import React from 'react';
import i18n, { InitOptions } from 'i18next';
import { I18nContext } from 'react-i18next';
import { Loader } from 'App/layout/Loader';
import { I18NextPreInitCallbackType } from 'i18n';

interface Props {
  preInit: I18NextPreInitCallbackType;
  initOptions: InitOptions;
}

export const I18NextProvider: React.FC<Props> = ( {
  preInit,
  initOptions,
  children,
} ) => {
  const [ isLoading, setLoading ] = React.useState<boolean>( true );
  React.useEffect( () => {
    let isMounted = true;
    preInit( i18n ); // Run pre init callback just before init
    i18n.init( initOptions ).then( () => {
      if ( isMounted ) {
        setLoading( false );
      }
    } );
    return () => {
      isMounted = false;
    };
  }, [ preInit, initOptions ] );

  if ( isLoading ) {
    return <Loader />;
  }
  return (
    <I18nContext.Provider value={ { i18n: i18n } }>
      { children }
    </I18nContext.Provider>
  );
};
