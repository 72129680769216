import { BaseRequest } from 'Services/base';
import { CreateBankAccount, CreateBankAccountResponse } from './interfaces';

export class CreateBankAccountRequest extends BaseRequest<CreateBankAccountResponse> {
  constructor(
    paymentData: CreateBankAccount,
  ) {
    super( {
      headers: { 'Content-Type': 'application/json' },
      method: 'POST',
      url: 'https://pages-test.emil.de/create-bank-account',
      data: paymentData,
      responseType: 'json',
    }, false );
  }
}
