export enum WorkflowInstanceStatus {
  InProgress= 'in_progress',
  Done = 'done',
  Failed = 'failed',
  Cancelled = 'cancelled',
}

export enum WorkflowInstanceStatusUnselected {
  Unselected = 'Unselected',
}
