import { AxiosRequestConfig, Method, AxiosResponse } from 'axios';
import { IToken } from './api/auth/base';
import dayjs from 'dayjs';

export interface IRequestDescriptor extends AxiosRequestConfig {
  method: Method; // make this property required
  accessTokenRequired?: boolean;
  loginRequest?: boolean;
};

export interface IBaseResponse<T> extends AxiosResponse<T> {};

export abstract class BaseRequest<ResponseType = any> {
  public readonly descriptor: IRequestDescriptor;
  private readonly _responseType?: ResponseType; // it is used only response type for type help

  constructor( descriptor: IRequestDescriptor, accessTokenRequired: boolean = true ) {
    this.descriptor = { ...descriptor, accessTokenRequired: accessTokenRequired };
  }
}

export class ApiError<T = any> {
  message: string;
  reason?: T;

  constructor( msg: string, reason?: T ) {
    this.message = msg;
    this.reason = reason;
  }
}

export class BaseApiError<T = any> {
  private readonly error: T;
  readonly wasHandled: boolean;

  constructor( error: T, wasHandled: boolean = false ) {
    this.error = error;
    this.wasHandled = wasHandled;
  }
  // here we can other properties or methods to our errors if needed
}

export interface IApiService {
  accessToken: React.MutableRefObject<IToken | null>;
  request<T>( config: BaseRequest<T> ): Promise<T>;
}

export interface IConstructor<T> {
  new ( ...args: any[] ): T;
}

export type OperatorType = '>=' | '<=' | '>' | '<' | '=';

export interface FilterPage {
  id: string;
  value: string;
  operator?: OperatorType;
}

export interface OrderByPage {
  id: string;
  desc: boolean;
}

export interface PagingRequest {
  pageSize: number;
  pageToken: number;
  filter: FilterPage[];
  orderBy: OrderByPage[];
}

export interface ApiResponse {
  message: string;
  success: boolean;
}

export interface ISubscription {
  entity: string;
  entityId: number;
  isActive: boolean;
}

export interface ISubscriptionItem extends ISubscription {
  id: number;
  userId: number;
  createdAt?: dayjs.Dayjs;
  updatedAt?: dayjs.Dayjs;
}

export interface ISubscriptionResponse {
  subscription: ISubscriptionItem;
}

export class UpdateSubscriptionsRequest extends BaseRequest<ISubscriptionResponse> {
  constructor(
    isActive: boolean,
    entity: string,
    entityId: number,
  ) {

    super( {
      method: 'POST',
      url: `/tenantservice/v1/subscriptions/${entity}/${entityId}`,
      data: { isActive: isActive },
    } );
  }
}
