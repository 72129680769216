import React, { useContext } from 'react';

import { ApiContext } from 'Services/api-context';
import { ServiceProviderProps } from './common';
import { IProductDocumentService, ProductDocumentServiceContext } from 'Services/product-document';

export const ProductDocumentProvider: React.FC<ServiceProviderProps<IProductDocumentService>> = ( props, context ) => {

  const api = useContext( ApiContext );
  const productDocumentService: IProductDocumentService = new props.useClass( api );

  return (
    <ProductDocumentServiceContext.Provider value={ productDocumentService }>
      { props.children }
    </ProductDocumentServiceContext.Provider>
  );
};
