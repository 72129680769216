import React from 'react';
import { IApiService, PagingRequest } from './base';
import { ListItems } from './api';

import { ListPartnerTypesRequest } from './partner-types/requests';
import { PartnerType } from './partner-types/interfaces';
export interface IPartnerTypeService {
  listPartnerTypes( pageParams: PagingRequest ): Promise<ListItems<PartnerType>>;
}

export class PartnerTypeService implements IPartnerTypeService {
  protected api: IApiService;

  constructor( api: IApiService ) {
    this.api = api;
  }

  async listPartnerTypes( pageParams: PagingRequest ): Promise<ListItems<PartnerType>> {
    const response = await this.api.request( new ListPartnerTypesRequest( pageParams ) );

    return response;
  }
}

export const PartnerTypeServiceContext: React.Context<IPartnerTypeService> = React.createContext( undefined as any );

export const usePartnerTypeService = (): IPartnerTypeService => {
  return React.useContext( PartnerTypeServiceContext );
};
