import React from 'react';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { UserDataItem } from '../steps/UserDataForm';

export const useRegistrtionFormValidationSchema = (): yup.ObjectSchema<UserDataItem> => {
  const { t } = useTranslation( [ 'registration', 'base' ] );

  const schema = React.useMemo( () => {
    const customFieldSchema = yup.object<UserDataItem>( {
      email: yup.string().required ( t( 'base:forms.messages.fieldRequired', { fieldLabel: t( 'fields.email' ) },
      ) ).email( t ( 'base:forms.messages.invalidEmailFormat' ) ),
      firstName: yup.string().required( t( 'base:forms.messages.fieldRequired', { fieldLabel: t( 'fields.firstName' ) },
      ) ),
      lastName: yup.string().required( t( 'base:forms.messages.fieldRequired', { fieldLabel: t( 'fields.lastName' ) },
      ) ),
      newPassword: yup.string().required( t( 'base:forms.messages.fieldRequired',
        { fieldLabel: t( 'fields.newPassword' ) } ) )
        .min( 8, () => ( t( 'base:forms.messages.passwordLengthError' ) ) )
        .test( 'isValidPass', t( 'fields.passwordError' ), ( value ) => {
          const hasUpperCase = /[A-Z]/.test( value! );
          const hasLowerCase = /[a-z]/.test( value! );
          const hasNumber = /[0-9]/.test( value! );
          let validConditions = 0;
          const conditions = [ hasLowerCase, hasUpperCase, hasNumber ];
          conditions.forEach( ( condition ) =>
            condition ? validConditions+=1 : null,
          );
          if ( validConditions === 3 ) {
            return true;
          }
          return false;
        } ),
      confirmPassword: yup.string().required( t( 'base:forms.messages.fieldRequired',
        { fieldLabel: t( 'fields.newConfirmPassword' ) },
      ) ).test( 'passwords-match', () => ( t( 'base:forms.messages.notMatchPasswordError' ) ), function( value ) {
        return this.parent.newPassword === value;
      } ),
      isEulaAccepted: yup.boolean().oneOf( [ true ], t( 'base:forms.messages.fieldRequired',
        { fieldLabel: t( 'fields.isEula' ) } ) ),
    } ).required();

    return customFieldSchema;
  }, [ t ] );

  return schema;
};
