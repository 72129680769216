import { RoleItem, RolesResponse, IRoleItem, IGroupDetails } from './base';

const RolesData: RoleItem[] = [
  {
    roleId: 1,
    nameRole: 'admin',
    label: 'Administrator',
    description: 'Explanation text Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod',
    permissions: [
      { valueComponent: 'users', permissionList: [ 'add', 'edit', 'view' ] },
      { valueComponent: 'roles', permissionList: [ 'add', 'edit', 'view' ] },
      { valueComponent: 'customers', permissionList: [ 'add', 'edit', 'view' ] },
      { valueComponent: 'businessSettings', permissionList: [ 'edit', 'view' ] },
      { valueComponent: 'policies', permissionList: [ 'approve', 'approveEdit', 'editSchwebe', 'edit', 'view' ] },
    ],
    membersCount: 2,
    isBuildin: true,
  },
  {
    roleId: 2,
    nameRole: 'developer',
    label: 'Entwickler',
    description: 'Explanation text Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod',
    permissions: [
      { valueComponent: 'users', permissionList: [ 'add', 'view' ] },
      { valueComponent: 'roles', permissionList: [ 'add', 'view' ] },
      { valueComponent: 'customers', permissionList: [ 'add', 'view' ] },
      { valueComponent: 'businessSettings', permissionList: [ 'edit', 'view' ] },
      { valueComponent: 'policies', permissionList: [ 'approve', 'approveEdit', 'editSchwebe', 'edit', 'view' ] },
    ],
    membersCount: 1,
  },
  {
    roleId: 3,
    nameRole: 'insurer',
    label: 'Versicherer',
    description: 'Explanation text Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod',
    permissions: [
      { valueComponent: 'users', permissionList: [ 'view' ] },
      { valueComponent: 'roles', permissionList: [ 'view' ] },
      { valueComponent: 'customers', permissionList: [ 'view' ] },
      { valueComponent: 'businessSettings', permissionList: [ 'view' ] },
      { valueComponent: 'policies', permissionList: [ 'edit', 'view', 'approve', 'approveEdit', 'editSchwebe' ] },
    ],
    membersCount: 1,
  },
  {
    roleId: 4,
    nameRole: 'showOnly',
    label: 'Nur anzeigen',
    description: 'Explanation text Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod',
    permissions: [
      { valueComponent: 'users', permissionList: [ 'view' ] },
      { valueComponent: 'roles', permissionList: [ 'view' ] },
      { valueComponent: 'customers', permissionList: [ 'view' ] },
      { valueComponent: 'businessSettings', permissionList: [ 'view' ] },
      { valueComponent: 'policies', permissionList: [ 'view' ] },
    ],
    membersCount: 3,
  },
];

const RoleData: RoleItem = {
  roleId: 1,
  nameRole: 'admin',
  label: 'Administrator',
  description: 'Explanation text Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod',
  permissions: [
    { valueComponent: 'users', permissionList: [ 'add', 'edit', 'view' ] },
    { valueComponent: 'roles', permissionList: [ 'add', 'edit', 'view' ] },
    { valueComponent: 'customers', permissionList: [ 'add', 'edit', 'view' ] },
    { valueComponent: 'businessSettings', permissionList: [ 'edit', 'view' ] },
    { valueComponent: 'policies', permissionList: [ 'approve', 'approveEdit', 'editSchwebe', 'edit', 'view' ] },
  ],
  membersCount: 2,
};

export const mockedRolesData: IRoleItem[] = [
  {
    id: 1,
    roleName: 'administrator',
    roleDescription: 'Create or update a role with selected permissions that you can assign to your users and groups.',
    permissionSummary: [ 'policies view', 'policies approve', 'policies edit', 'dashboard edit', 'dashboard view' ],
    permissions: [
      { module: 'eis',
        modulePermissions: [ {
          category: 'policies',
          permissions: [ 'view', 'approve', 'edit' ],
        },
        {
          category: 'policyholders',
          permissions: [],
        },
        {
          category: 'FZV',
          permissions: [],
        },
        {
          category: 'VWB',
          permissions: [ ],
        } ],
      },
      { module: 'homepage',
        modulePermissions: [ {
          category: 'homepage',
          permissions: [],
        } ],
      },
      { module: 'pbm',
        modulePermissions: [ {
          category: 'dashboard',
          permissions: [ 'edit', 'view' ],
        } ],
      },
      { module: 'analyticsDashboard',
        modulePermissions: [
          {
            category: 'analyticsDashboard',
            permissions: [],
          },
        ],
      },
      { module: 'generalSettings',
        modulePermissions: [
          {
            category: 'accountInfo',
            permissions: [],
          },
          {
            category: 'general',
            permissions: [],
          },
          {
            category: 'branding',
            permissions: [],
          },
          {
            category: 'user',
            permissions: [],
          },
          {
            category: 'roles',
            permissions: [],
          },
          {
            category: 'groups',
            permissions: [],
          },
          {
            category: 'marketplace',
            permissions: [],
          },
          {
            category: 'importData',
            permissions: [],
          },
          {
            category: 'exportData',
            permissions: [],
          },
          {
            category: 'apiTokens',
            permissions: [],
          },
          {
            category: 'webhooks',
            permissions: [],
          },
          {
            category: 'securityData',
            permissions: [],
          },
          {
            category: 'twoFA',
            permissions: [],
          },
          {
            category: 'otherSecuritySettings',
            permissions: [],
          },
          {
            category: 'paymentProvider',
            permissions: [],
          },
          {
            category: 'underwriting',
            permissions: [],
          },
          {
            category: 'documentCenter',
            permissions: [],
          },
          {
            category: 'businessProcesses',
            permissions: [],
          },
        ],
      },
      {
        module: 'productSettings',
        modulePermissions: [
          {
            category: 'productList',
            permissions: [],
          },
          {
            category: 'products',
            permissions: [],
          },
          {
            category: 'prices',
            permissions: [],
          },
          {
            category: 'businessLogic',
            permissions: [],
          },
          {
            category: 'documents',
            permissions: [],
          },
          {
            category: 'documentTemplates',
            permissions: [],
          },
          {
            category: 'email',
            permissions: [],
          },
          {
            category: 'emailTemplates',
            permissions: [],
          },
        ],
      },
      {
        module: 'editor',
        modulePermissions: [
          {
            category: 'emailEditor',
            permissions: [],
          },
          {
            category: 'documentEditor',
            permissions: [],
          },
        ],
      },
    ],
    users: [ 1, 2 ],
    groups: [ 1, 2 ],
  },
  {
    id: 2,
    roleName: 'product manager',
    permissionSummary: [ 'policyholders view', 'policyholders edit', 'vwb edit' ],
    roleDescription: 'Create or update a role with selected permissions that you can assign to your users and groups.',
    permissions: [
      { module: 'eis',
        modulePermissions: [ {
          category: 'policies',
          permissions: [],
        },
        {
          category: 'policyholders',
          permissions: [ 'view', 'edit' ],
        },
        {
          category: 'FZV',
          permissions: [ ],
        },
        {
          category: 'VWB',
          permissions: [ 'edit' ],
        } ],
      },
      { module: 'homepage',
        modulePermissions: [ {
          category: 'homepage',
          permissions: [],
        } ],
      },
      { module: 'pbm',
        modulePermissions: [ {
          category: 'dashboard',
          permissions: [],
        } ],
      },
      { module: 'analyticsDashboard',
        modulePermissions: [
          {
            category: 'analyticsDashboard',
            permissions: [],
          },
        ],
      },
      { module: 'generalSettings',
        modulePermissions: [
          {
            category: 'accountInfo',
            permissions: [],
          },
          {
            category: 'general',
            permissions: [],
          },
          {
            category: 'branding',
            permissions: [],
          },
          {
            category: 'user',
            permissions: [],
          },
          {
            category: 'roles',
            permissions: [],
          },
          {
            category: 'groups',
            permissions: [],
          },
          {
            category: 'marketplace',
            permissions: [],
          },
          {
            category: 'importData',
            permissions: [],
          },
          {
            category: 'exportData',
            permissions: [],
          },
          {
            category: 'apiTokens',
            permissions: [],
          },
          {
            category: 'webhooks',
            permissions: [],
          },
          {
            category: 'securityData',
            permissions: [],
          },
          {
            category: 'twoFA',
            permissions: [],
          },
          {
            category: 'otherSecuritySettings',
            permissions: [],
          },
          {
            category: 'paymentProvider',
            permissions: [],
          },
          {
            category: 'underwriting',
            permissions: [],
          },
          {
            category: 'documentCenter',
            permissions: [],
          },
          {
            category: 'businessProcesses',
            permissions: [],
          },
        ],
      },
      {
        module: 'productSettings',
        modulePermissions: [
          {
            category: 'productList',
            permissions: [],
          },
          {
            category: 'products',
            permissions: [],
          },
          {
            category: 'prices',
            permissions: [],
          },
          {
            category: 'businessLogic',
            permissions: [],
          },
          {
            category: 'documents',
            permissions: [],
          },
          {
            category: 'documentTemplates',
            permissions: [],
          },
          {
            category: 'email',
            permissions: [],
          },
          {
            category: 'emailTemplates',
            permissions: [],
          },
        ],
      },
      {
        module: 'editor',
        modulePermissions: [
          {
            category: 'emailEditor',
            permissions: [],
          },
          {
            category: 'documentEditor',
            permissions: [],
          },
        ],
      },
    ],
    users: [ 1, 3, 4 ],
    groups: [ 2, 3 ],
  },
  {
    id: 3,
    roleName: 'customer care',
    permissionSummary: [ 'FZV view', 'FZV edit', 'FZV approve' ],
    roleDescription: 'Create or update a role with selected permissions that you can assign to your users and groups.',
    permissions: [
      { module: 'eis',
        modulePermissions: [ {
          category: 'policies',
          permissions: [],
        },
        {
          category: 'policyholders',
          permissions: [],
        },
        {
          category: 'FZV',
          permissions: [ 'view', 'approve', 'edit' ],
        },
        {
          category: 'VWB',
          permissions: [],
        } ],
      },
      { module: 'homepage',
        modulePermissions: [ {
          category: 'homepage',
          permissions: [],
        } ],
      },
      { module: 'pbm',
        modulePermissions: [ {
          category: 'dashboard',
          permissions: [],
        } ],
      },
      { module: 'analyticsDashboard',
        modulePermissions: [
          {
            category: 'analyticsDashboard',
            permissions: [],
          },
        ],
      },
      { module: 'generalSettings',
        modulePermissions: [
          {
            category: 'accountInfo',
            permissions: [],
          },
          {
            category: 'general',
            permissions: [],
          },
          {
            category: 'branding',
            permissions: [],
          },
          {
            category: 'user',
            permissions: [],
          },
          {
            category: 'roles',
            permissions: [],
          },
          {
            category: 'groups',
            permissions: [],
          },
          {
            category: 'marketplace',
            permissions: [],
          },
          {
            category: 'importData',
            permissions: [],
          },
          {
            category: 'exportData',
            permissions: [],
          },
          {
            category: 'apiTokens',
            permissions: [],
          },
          {
            category: 'webhooks',
            permissions: [],
          },
          {
            category: 'securityData',
            permissions: [],
          },
          {
            category: 'twoFA',
            permissions: [],
          },
          {
            category: 'otherSecuritySettings',
            permissions: [],
          },
          {
            category: 'paymentProvider',
            permissions: [],
          },
          {
            category: 'underwriting',
            permissions: [],
          },
          {
            category: 'documentCenter',
            permissions: [],
          },
          {
            category: 'businessProcesses',
            permissions: [],
          },
        ],
      },
      {
        module: 'productSettings',
        modulePermissions: [
          {
            category: 'productList',
            permissions: [],
          },
          {
            category: 'products',
            permissions: [],
          },
          {
            category: 'prices',
            permissions: [],
          },
          {
            category: 'businessLogic',
            permissions: [],
          },
          {
            category: 'documents',
            permissions: [],
          },
          {
            category: 'documentTemplates',
            permissions: [],
          },
          {
            category: 'email',
            permissions: [],
          },
          {
            category: 'emailTemplates',
            permissions: [],
          },
        ],
      },
      {
        module: 'editor',
        modulePermissions: [
          {
            category: 'emailEditor',
            permissions: [],
          },
          {
            category: 'documentEditor',
            permissions: [],
          },
        ],
      },
    ],
    users: [ 2, 4 ],
    groups: [ 1, 2 ],
  },
];

export const mockedGroupsData: IGroupDetails[] = [
  {
    id: 1,
    groupName: 'management',
    roles: [ { id: 1, roleName:'administrator' }, { id: 3, roleName: 'customer care' } ],
  },
  {
    id: 2,
    groupName: 'customerCare',
    roles: [ { id: 1, roleName: 'administrator' }, { id: 2, roleName: 'product manager' } ],
  },
  {
    id: 3,
    groupName: 'bi',
    roles: [ { id: 3, roleName: 'customer care' }, { id: 2, roleName: 'product manager' } ],
  },
  {
    id: 4,
    groupName: 'sales',
    roles: [],
  },
  {
    id: 5,
    groupName: 'development',
    roles: [],
  },
];

export const mockedRoles: RolesResponse = {
  items: RolesData,
};

export const mockedRole: RoleItem = RoleData;
