import { BaseRequest } from '../base';
import {
  SummaryLabelSettingsRequest,
  ISummaryLabelSettingsResponse,
  ITerminatePolicyRequest,
} from './interfaces';
import { IApiPolicyItem } from './new/service';

const insuranceServiceApiServer = process.env.REACT_APP_INSURANCE_SERVICE_API_SERVER ?? '';
const insuranceService = `${insuranceServiceApiServer}/insuranceservice`;

export class SetSummaryLabelSettingsRequest extends BaseRequest {
  constructor(
    settings: SummaryLabelSettingsRequest,
  ) {

    super( {
      method: 'POST',
      url: '/tenantservice/v1/settings/tenant',
      data: settings,
      responseType: 'json',
    } );
  }
}

export class GetSummaryLabelSettingsRequest extends BaseRequest<ISummaryLabelSettingsResponse> {
  constructor(
    productCode: string,
  ) {

    const params = new URLSearchParams();
    params.set( 'keys', `summary_label_setting_${productCode}` );

    super( {
      method: 'GET',
      url: '/tenantservice/v1/settings/tenant',
      responseType: 'json',
      params: params,
    } );
  }
}

export class TerminatePolicyRequest extends BaseRequest<{ policy: IApiPolicyItem }> {
  constructor(
    policyCode: string,
    fromData: ITerminatePolicyRequest,
  ) {

    super( {
      method: 'POST',
      url: `${insuranceService}/v1/policies/${policyCode}/terminate`,
      data: fromData,
      responseType: 'json',
    } );
  }
}

export class WithdrawtPolicyRequest extends BaseRequest<{ policy: IApiPolicyItem }> {
  constructor(
    policyCode: string,
  ) {

    super( {
      method: 'POST',
      url: `${insuranceService}/v1/policies/${policyCode}/withdraw`,
      responseType: 'json',
    } );
  }
}
