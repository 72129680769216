import React, { ReactElement, LazyExoticComponent, FunctionComponent, Suspense } from 'react';
import { useRouteMatch, Switch, Route } from 'react-router-dom';
import { AppLayout } from 'App/layout/AppLayout';
import { AppAlertServiceProvider } from './utils/alerts/AppAlertService';
import mainRoutes from 'main-routes';
import { RouteDescriptor } from 'types';
import { Loader } from 'App/layout/Loader';
import { LandingPageResolver } from './utils/routing/LandingPageResolver';
import { ProtectedRoute } from './utils/routing/ProtectedRoute';
import { NoAccess } from './NoAccess';
import {
  AppCurrentProductContext,
  AppSecurityProvider,
  IAppProductDescriptor,
  useAppUserProducts,
} from './utils/providers/AppProductProvider';
import { AppMenuProvider } from 'App/menu-items';

const NotFound: LazyExoticComponent<FunctionComponent> = React.lazy( () => import( './NotFound' ) );
const SignOut: LazyExoticComponent<FunctionComponent> = React.lazy( () => import( './auth/SignOut' ) );
const ChangePassword: LazyExoticComponent<FunctionComponent> =
  React.lazy( () => import( 'App/components/auth/ChangePassword' ) );

export const AppWithUserContext: React.FC = () => {
  const { path: urlPrefix } = useRouteMatch();
  const userProducts = useAppUserProducts();
  const routes: ReactElement[] = React.useMemo<ReactElement[]>( () => {
    return mainRoutes.map<ReactElement>( ( route: RouteDescriptor, index: number ) => {
      const { component: Component, componentProps, path, productId, ...rest } = route;
      const currentProduct: IAppProductDescriptor = userProducts.find( ( up ) => up.id === productId )!;
      return (
        <ProtectedRoute
          { ...rest }
          key={ index }
          path={ urlPrefix + path }
          render={ ( props, hasAccess ) => {
            return (
              <AppCurrentProductContext.Provider value={ currentProduct }>
                <AppSecurityProvider>
                  <AppMenuProvider>
                    <AppAlertServiceProvider>
                      <AppLayout { ...props } >
                        { hasAccess
                          ? <Component { ...props } { ...componentProps } />
                          : <NoAccess />
                        }
                      </AppLayout>
                    </AppAlertServiceProvider>
                  </AppMenuProvider>
                </AppSecurityProvider>
              </AppCurrentProductContext.Provider>
            );
          } }
        />
      );
    } );
  }, [ urlPrefix, userProducts ] );
  return (
    <Suspense fallback={ <Loader /> }>
      <Switch>
        <Route path="/u/:accountIndex" exact><LandingPageResolver /></Route>
        { routes }
        <Route path="/u/:accountIndex/changepassword" exact>
          <AppAlertServiceProvider>
            <ChangePassword />
          </AppAlertServiceProvider>
        </Route>
        <Route path="/u/:accountIndex/logout" exact><SignOut /></Route>
        <Route><NotFound /></Route>
      </Switch>
    </Suspense>
  );
};
