import { BaseRequest, PagingRequest } from 'Services/base';
import { IApiListResponse } from 'Services/policies/new/service';
import {
  CreateDashboardReqBody,
  IAlertDashboardSettingsResponse,
  DashboardSettingsResponse,
  UpdateDashboardReqBody,
  DashboardEmbedResponse,
  DashboardResponse,
  IApiDashboard,
  IDashboardResponse,
  IDataSettings,
  ISettingsDashboardResponse,
  ExportDashboardDataReqBody,
  ExportDashboardDataResponse,
} from './interfaces';
import { Category, Provider } from './enums';

const pbmServiceApiServer = process.env.REACT_APP_PBM_SERVICE_API_SERVER ?? '';

export class DashboardRequest extends BaseRequest<DashboardResponse> {
  constructor( productType: string ) {

    const params = new URLSearchParams();
    params.set( 'product_type', productType );

    super( {
      method: 'GET',
      url: '/tenantservice/v1/tenants/quicksight',
      responseType: 'json',
      params: params,
      withCredentials: false,
    } );
  }
}

export class SettingsDashboardRequest extends BaseRequest<ISettingsDashboardResponse> {
  constructor( dashboardId: string ) {

    const params = new URLSearchParams();
    params.set( 'keys', `pbm_${dashboardId}` );

    super( {
      method: 'GET',
      url: '/tenantservice/v1/settings/user',
      responseType: 'json',
      params: params,
    } );
  }
}

export class SaveSettingsDashboardRequest extends BaseRequest<IDataSettings> {
  constructor( settings: IDataSettings ) {

    super( {
      method: 'POST',
      url: '/tenantservice/v1/settings/user',
      data: settings,
      responseType: 'json',
    } );
  }
}

export class GetDashboardRequest extends BaseRequest<IDashboardResponse> {
  constructor(
    dashboardCode: string,
  ) {

    super( {
      method: 'GET',
      url: `/pbmservice/v1/dashboards/${dashboardCode}`,
      responseType: 'json',
    } );
  }
}

export class CreateDashboardRequest extends BaseRequest<IDashboardResponse> {
  constructor( payload: CreateDashboardReqBody ) {

    super( {
      method: 'POST',
      url: '/pbmservice/v1/dashboards',
      data: {
        category: Category.PremiumBenchmarking,
        provider: Provider.QuickSight,
        ...payload,
      },
      responseType: 'json',
    } );
  }
}

export class UpdateDashboardRequest extends BaseRequest<IDashboardResponse> {
  constructor( payload: UpdateDashboardReqBody ) {

    super( {
      method: 'PUT',
      url: `/pbmservice/v1/dashboards/${payload.dashboardCode}`,
      data: {
        category: Category.PremiumBenchmarking,
        provider: Provider.QuickSight,
        name: payload.name,
        description: payload.description,
        product: payload.product,
        providerId: payload.providerId,
      },
      responseType: 'json',
    } );
  }
}

export class DeleteDashboardRequest extends BaseRequest<IDashboardResponse> {
  constructor( dashboardCode: string ) {

    super( {
      method: 'DELETE',
      url: `/pbmservice/v1/dashboards/${dashboardCode}`,
      responseType: 'json',
    } );
  }
}

export class DashboardEmbeddedUrlRequest extends BaseRequest<DashboardEmbedResponse> {
  constructor( dashboardId: string ) {

    super( {
      method: 'GET',
      url: `/pbmservice/v1/dashboards/${dashboardId}/embedded-url`,
      responseType: 'json',
    } );
  }
}

export class ListDashboardsRequest extends BaseRequest<IApiListResponse<IApiDashboard>> {
  constructor(
    searchValue: string,
    paging: PagingRequest,
    expand?: string[],
  ) {

    const queryParams = new URLSearchParams();
    queryParams.set( 'pageSize', paging.pageSize.toString() );
    queryParams.set( 'pageToken', paging.pageToken.toString() );

    if ( paging.filter.length ) {
      const filterParams = paging.filter
        .map( ( item ) => `${item.id}=${encodeURIComponent( item.value )}` )
        .join( '&' );
      queryParams.set( 'filter', filterParams );
    }

    if ( paging.orderBy.length ) {
      const orderParams = paging.orderBy
        .map( ( item ) => `${item.id}:${ item.desc ? 'DESC' : 'ASC' }` )
        .join( '&' );
      queryParams.set( 'order', orderParams );
    }

    if ( searchValue ) {
      queryParams.set( 'query', searchValue );
    }

    if ( expand && expand.length > 0 ) {
      const expandParams = expand.map( ( item ) => `${encodeURIComponent( item )}` ).join( ',' );
      queryParams.set( 'expand', expandParams );
    }

    super( {
      method: 'GET',
      url: '/pbmservice/v1/dashboards',
      responseType: 'json',
      params: queryParams,
    } );
  }
}

export class AlertDashboardSettingsRequest extends BaseRequest<IAlertDashboardSettingsResponse> {
  constructor( ) {

    const params = new URLSearchParams();
    params.set( 'keys', 'dashboardAlert' );

    super( {
      method: 'GET',
      url: '/tenantservice/v1/settings/tenant',
      responseType: 'json',
      params: params,
    } );
  }
}

export class DashboardSettingsRequest extends BaseRequest<DashboardSettingsResponse> {
  constructor(
    dashboardKey: string,
  ) {

    const params = new URLSearchParams();
    params.set( 'keys', dashboardKey );

    super( {
      method: 'GET',
      url: '/tenantservice/v1/settings/tenant',
      responseType: 'json',
      params: params,
    } );
  }
}

export class ExportDashboardDataRequest extends BaseRequest<ExportDashboardDataResponse> {
  constructor( payload: ExportDashboardDataReqBody ) {

    super( {
      method: 'POST',
      url: `${pbmServiceApiServer}/pbmservice/v1/dashboards/data-export`,
      data: {
        category: Category.PremiumBenchmarking,
        provider: Provider.QuickSight,
        ...payload,
      },
      responseType: 'json',
    } );
  }
}
