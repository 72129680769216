
import React from 'react';

export interface LoaderProps {}

export const Loader: React.FC<LoaderProps> = ( props: LoaderProps ) => {
  return (
    <div className="loader-bg">
      <div className="loader-track">
        <div className="loader-fill" />
      </div>
    </div>
  );
};
export default Loader;
