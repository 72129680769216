import React, { Suspense, LazyExoticComponent, FunctionComponent, ReactElement } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import 'assets/main.scss';

import { Loader } from './layout/Loader';
import { ScrollToTop } from './layout/ScrollToTop';
import { AppUserProvider } from './components/utils/providers/AppUserProvider';
import { AppChangePassword } from './components/utils/providers/AppChangePassword';
import { AppWithUserContext } from './components/AppWithUserContext';
import { AppAlertServiceProvider } from './components/utils/alerts/AppAlertService';

import { RouteDescriptor } from 'types';
import AppAuthTestPage from './components/examples/AuthTest';
import { AuthServiceProvider } from './components/utils/providers/AuthProvider';
import { AuthService } from 'Services/auth';
import { ApiProvider } from 'Services/api';
import { useAppLogger } from 'Services/logger';
import mainRoutes from 'main-routes';
import { MockedTicketServiceProvider } from 'Services/tickets/mock-service';
import { MainLandingPageResolver } from './components/utils/routing/MainLandingPageResolver';
import { AppUserProductsProvider } from './components/utils/providers/AppProductProvider';
import { AppTenantProvider } from './components/utils/providers/AppTenantProvider';
import { NotificationServiceProvider } from './components/utils/notifications/service';
import SAMLPage from './components/auth/SAMLPage';
import DownloadPage from './components/other/download/DownloadPage';
import OrganizationRegistration from './components/organization-registration/OrganizationRegistration';

// Components without layout
const UserRegistration: LazyExoticComponent<FunctionComponent> =
  React.lazy( () => import( 'App/components/user-registration/UserRegistration' ) );
const ExpiredTokenScreen: LazyExoticComponent<FunctionComponent> =
  React.lazy( () => import( 'App/components/user-registration/screens/ExpiredTokenScreen' ) );
const SuccessScreenRegistration: LazyExoticComponent<FunctionComponent> =
  React.lazy( () => import( 'App/components/user-registration/screens/SuccessScreen' ) );
const SignIn: LazyExoticComponent<FunctionComponent> = React.lazy( () => import( 'App/components/auth/SignIn' ) );
const SignInReadme: LazyExoticComponent<FunctionComponent> =
  React.lazy( () => import( 'App/components/auth/SignInReadme' ) );
const ResetPassword: LazyExoticComponent<FunctionComponent> =
  React.lazy( () => import( 'App/components/auth/ResetPassword' ) );
const NotFound: LazyExoticComponent<FunctionComponent> = React.lazy( () => import( './components/NotFound' ) );
const PaymentForm: LazyExoticComponent<FunctionComponent> =
React.lazy( () => import( 'App/components/payment-form/PaymentForm' ) );
const SignInToSAML: LazyExoticComponent<FunctionComponent> = React.lazy(
  () => import( 'App/components/auth/SignInToSAML' ) );

export const App: React.FC = () => {
  // Create redirections from /path to default account (0) /u/0/path
  const redirections: ReactElement[] = React.useMemo<ReactElement[]>( () => {
    return mainRoutes.map<ReactElement>( ( route: RouteDescriptor, index: number ) => {
      return (
        <Redirect key={ index } from={ route.path } to={ `/u/0${route.path}` } exact />
      );
    } );
  }, [] );

  const logger = useAppLogger();
  logger.info( 'App Started' );

  return (
    <ScrollToTop>
      <Suspense fallback={ <Loader /> }>
        <Switch>
          <Route path="/u/:accountIndex">
            <AppUserProvider>
              <AppTenantProvider>
                <NotificationServiceProvider>
                  <AppChangePassword>
                    <AppUserProductsProvider>
                      <MockedTicketServiceProvider>
                        <AppWithUserContext />
                      </MockedTicketServiceProvider>
                    </AppUserProductsProvider>
                  </AppChangePassword>
                </NotificationServiceProvider>
              </AppTenantProvider>
            </AppUserProvider>
          </Route>
          { redirections }
          <Route path="/login" exact>
            <AppAlertServiceProvider>
              <SignIn />
            </AppAlertServiceProvider>
          </Route>
          <Route path="/login/readme" exact>
            <AppAlertServiceProvider>
              <SignInReadme />
            </AppAlertServiceProvider>
          </Route>
          <Route path="/login/saml" exact>
            <SAMLPage />
          </Route>
          <Route path="/login/:tenantName">
            <AppAlertServiceProvider>
              <SignInToSAML />
            </AppAlertServiceProvider>
          </Route>
          <Route path="/auth-test" exact>
            <ApiProvider>
              <AuthServiceProvider useClass={ AuthService }>
                <AppAuthTestPage />
              </AuthServiceProvider>
            </ApiProvider>
          </Route>
          <Route path="/account/resetpassword" exact>
            <AppAlertServiceProvider>
              <ResetPassword />
            </AppAlertServiceProvider>
          </Route>
          <Route path="/registration" exact>
            <AppAlertServiceProvider>
              <UserRegistration />
            </AppAlertServiceProvider>
          </Route>
          <Route path="/organization/registration" exact>
            <AppAlertServiceProvider>
              <OrganizationRegistration />
            </AppAlertServiceProvider>
          </Route>
          <Route path="/expired-token"><ExpiredTokenScreen /></Route>
          <Route path="/success-registration"><SuccessScreenRegistration /></Route>
          <Route path="/download/:s3key">
            <AppAlertServiceProvider>
              <DownloadPage />
            </AppAlertServiceProvider>
          </Route>
          <Route path="/forms" exact>
            <AppAlertServiceProvider>
              <PaymentForm />
            </AppAlertServiceProvider>
          </Route>
          <Route path="/" exact><MainLandingPageResolver /></Route>
          <Route><NotFound /></Route>
        </Switch>
      </Suspense>
    </ScrollToTop>
  );
};
