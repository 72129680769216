import React, { Fragment, useEffect } from 'react';
import { useLocation, useHistory, useRouteMatch } from 'react-router-dom';
import { createBackUrl, createPath, useAppUser, useAppUserPathPrefix } from './AppUserProvider';

const isChangePasswordPage = ( pageUrl: string ): boolean => {
  return pageUrl.indexOf( 'changepassword' ) > 0 ? true : false;
};

/**
 * Responsibilities:
 * - Redirect to login page when user has newPasswordRequired=true
 *
 * @param props
 */
export const AppChangePassword: React.FC = ( props ) => {
  const user = useAppUser();
  const userPrefix = useAppUserPathPrefix();
  const fullPath = React.useRef( '' );
  const location = useLocation();
  const history = useHistory();
  const { url } = useRouteMatch();
  const { pathname, search, hash } = location;

  fullPath.current = pathname + search + hash;

  const pathWithoutUserContext = pathname.substring ( url.length + 1 );

  // We need to display children only when we are on changepassword page or user don need to change the password
  const canRenderChildren = pathWithoutUserContext === 'changepassword' || user.isRequiredToChangePassword === false;

  useEffect( () => {
    const backUrl = createBackUrl( fullPath.current );
    if ( user.isRequiredToChangePassword ) {
      const params = new URLSearchParams( search );
      const backUrlFromParam = params.get( 'backUrl' );
      let redirectChangePassUrl = `${userPrefix}/changepassword`;

      if ( backUrlFromParam !== null ) {
        redirectChangePassUrl = createPath( redirectChangePassUrl, backUrlFromParam );
      } else {
        if ( !isChangePasswordPage( backUrl ) ) {
          redirectChangePassUrl = createPath( redirectChangePassUrl, backUrl );
        }
      }
      history.push( redirectChangePassUrl );
    }
  }, [ history, search, user, userPrefix ] );

  return (
    <Fragment>
      { canRenderChildren && props.children }
    </Fragment>
  );
};
