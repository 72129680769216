import {
  RoleItem,
  DataResponse,
  RoleResponse,
  IRoleItem,
  IModule,
  IUpdateRoleItem,
  IGroupDetails,
} from './base';
import { IRoleService } from 'Services/role';
import { sleep } from 'App/utils';
import { mockedRolesData, mockedRole, mockedGroupsData } from './mock-data';
import { PagingRequest, OrderByPage, ApiResponse, ApiError } from 'Services/base';
import { IApiListResponse } from 'Services/policies/new/service';
import { orderBy, cloneDeep } from 'lodash';
import { IUserDetailsItem } from '../users/base';
import { mockedUsersData } from '../users/mock-data';

// We need to define special mocked RolesService because we have static data in localStorage
// So for every request we need to filter response based on searchText
// This implementation of service should be only used with mockedApi that is using localStorage
export class MockedRoleService implements IRoleService {
  private roles: IRoleItem[];
  private groups: IGroupDetails[];
  private users: IUserDetailsItem[];
  private nextRoleId: number = 0;

  constructor() {
    this.roles = mockedRolesData;
    this.groups = mockedGroupsData;
    this.users = mockedUsersData;

    this.calculateNextIdsForService();
  }

  private calculateNextIdsForService(): void {
    const roleIds = this.roles.map( ( r ) => r.id );
    this.nextRoleId = Math.max( ...roleIds ) + 1;
  }

  getRolesData( moduleFilter: IModule[], roleFilter: string, paging: PagingRequest ):
  Promise<IApiListResponse<IRoleItem>> {
    const result = sleep ( 500 )
      .then( () => {
        let roles: IRoleItem[] = this.roles
          .filter( ( role ) => {
            if ( moduleFilter.includes( 'all' ) ) {
              return true;
            } else {
              return role.permissions.length !== 0 && role.permissions.find( ( c ) =>
                moduleFilter.includes( c.module ) && c.modulePermissions.find( ( p ) => p.permissions.length > 0 ) );
            } } )
          .filter( ( role ) => {
            if ( roleFilter ) {
              return role.roleName === roleFilter.toLowerCase();
            } else {
              return true;
            }
          } );

        roles = this.sortRoles( paging.orderBy, roles );
        roles = roles.slice( paging.pageToken, paging.pageToken + paging.pageSize );
        roles = cloneDeep ( roles );

        const res: IApiListResponse<IRoleItem> = {
          items: roles,
          nextPageToken: roles.length < paging.pageSize ? '0' : ( paging.pageToken + paging.pageSize ).toString(),
        };
        return res;
      } );

    return result;
  }

  getRoleGroupsData( paging: PagingRequest, groupIds: number[] ): Promise<IApiListResponse<IGroupDetails>> {
    const result = sleep ( 500 )
      .then( () => {
        let groups: IGroupDetails[] = [];

        if ( groupIds.length > 0 ) {
          groups = this.groups.filter( ( f ) => groupIds.includes( f.id ) );
        }

        groups = this.sortGroups( paging.orderBy, groups );
        groups = groups.slice( paging.pageToken, paging.pageToken + paging.pageSize );
        groups = cloneDeep ( groups );

        const res: IApiListResponse<IGroupDetails> = {
          items: groups,
          nextPageToken: groups.length < paging.pageSize ? '0' : ( paging.pageToken + paging.pageSize ).toString(),
        };
        return res;
      } );

    return result;
  }

  getRoleUsersData( searchQuery: string, userIds: number[], paging: PagingRequest ):
  Promise<IApiListResponse<IUserDetailsItem>> {
    const result = sleep ( 500 )
      .then( () => {
        let users: IUserDetailsItem[] = [];

        if ( userIds.length > 0 ) {
          users = this.users.filter( ( user ) => userIds.includes( user.id ) );
        }

        const input = searchQuery.toLowerCase();

        if ( input.length > 0 ) {
          // Filtering based on searchQuery - case insensitive
          users = users.filter( ( t ) => {
            const index = `${t.userName} ${t.email}`;
            return index.toLowerCase().includes ( input );
          } );
        }

        users = this.sortUsers( paging.orderBy, users );
        users = users.slice( paging.pageToken, paging.pageToken + paging.pageSize );
        users = cloneDeep ( users );

        const res: IApiListResponse<IUserDetailsItem> = {
          items: users,
          nextPageToken: users.length < paging.pageSize ? '0' : ( paging.pageToken + paging.pageSize ).toString(),
        };
        return res;
      } );

    return result;
  }

  getRole( roleId: number ): Promise<IRoleItem> {
    return sleep ( 500 )
      .then( () => {
        let foundRole = this.roles.find( ( r ) => r.id === roleId );
        if ( foundRole !== undefined ) {
          return cloneDeep ( foundRole );
        } else {
          throw new ApiError( `Role with id: ${roleId} not found` );
        }
      } );
  }

  addRole( role: RoleItem ): Promise<RoleResponse> {
    const res: RoleResponse = {
      item: mockedRole,
      message: 'Ok',
      success: true,
    };

    // This is just to simulate loading time
    const ad = sleep( 500 ).then( () => {
      return res;
    } );

    return ad;
  }

  updateRole( updateRole: IUpdateRoleItem, roleId: number ): Promise<IRoleItem> {
    return sleep ( 500 )
      .then( () => {
        const foundIndex = this.roles.findIndex( ( item ) => item.id === roleId );
        if ( foundIndex >= 0 ) {
          let permissionSummary: string[] = [];

          updateRole.permissions.map( ( { modulePermissions } ) => {
            return modulePermissions.forEach( ( c ) => {
              if ( c.permissions.length > 0 ) {
                c.permissions.forEach( ( p ) => {
                  permissionSummary.push( c.category + ' ' + p );
                } );
              }
            } );
          } );

          const update = cloneDeep( this.roles[foundIndex] );
          update.permissionSummary = permissionSummary;
          update.permissions = updateRole.permissions;
          update.roleName = updateRole.roleName;
          update.roleDescription = updateRole.roleDescription;

          const updateGroup = cloneDeep( this.groups );

          updateGroup.map( ( group ) => {
            return group.roles.forEach( ( role ) => {
              if ( update.groups.includes( group.id ) && role.id === update.id ) {
                role.roleName = update.roleName;
              }
            } );
          } );

          this.groups = updateGroup;
          this.roles[foundIndex] = update;

          return update;
        } else {
          throw new ApiError( `Failed to update role: Role id ${roleId} not found` );
        }
      } );
  }

  createRole( createRole: IUpdateRoleItem ): Promise<IRoleItem> {
    let permissionSummary: string[] = [];

    createRole.permissions.map( ( { modulePermissions } ) => {
      return modulePermissions.forEach( ( c ) => {
        if ( c.permissions.length > 0 ) {
          c.permissions.forEach( ( p ) => {
            permissionSummary.push( c.category + ' ' + p );
          } );
        }
      } );
    } );

    const role: IRoleItem = {
      id: this.nextRoleId,
      permissionSummary: permissionSummary,
      groups: [],
      users: [],
      ...createRole,
    };

    this.nextRoleId += 1;
    this.roles.push( role );

    const r = sleep( 500 ).then( () => {
      return role;
    } );

    return r;
  }

  deleteRole( roleId: number ): Promise<DataResponse> {
    return sleep ( 500 )
      .then( () => {
        this.roles = this.roles.filter( ( r ) => r.id !== roleId );
        const apiRes: ApiResponse = {
          message: 'Ok',
          success: true,
        };

        return apiRes;
      } );
  }

  deleteRoleGroup( groupId: number, roleId: number ): Promise<DataResponse> {
    return sleep ( 500 )
      .then( () => {
        const foundIndex = this.roles.findIndex( ( item ) => item.id === roleId );

        if ( foundIndex >= 0 ) {
          const update = cloneDeep( this.roles[foundIndex] );
          update.groups = update.groups.filter( ( gId ) => gId !== groupId );
          this.roles[foundIndex] = update;
        }

        const apiRes: ApiResponse = {
          message: 'Ok',
          success: true,
        };

        return apiRes;
      } );
  }

  deleteRoleUser( userId: number, roleId: number ): Promise<DataResponse> {
    return sleep ( 500 )
      .then( () => {
        const foundIndex = this.roles.findIndex( ( item ) => item.id === roleId );

        if ( foundIndex >= 0 ) {
          const update = cloneDeep( this.roles[foundIndex] );
          update.users = update.users.filter( ( uId ) => uId !== userId );
          this.roles[foundIndex] = update;
        }

        const apiRes: ApiResponse = {
          message: 'Ok',
          success: true,
        };

        return apiRes;
      } );
  }

  deleteRoleUsers( userIds: number[], roleId: number ): Promise<ApiResponse> {
    return sleep ( 500 )
      .then( () => {
        const foundIndex = this.roles.findIndex( ( item ) => item.id === roleId );

        if ( foundIndex >= 0 ) {
          const update = cloneDeep( this.roles[foundIndex] );
          update.users = update.users.filter( ( uId ) => !userIds.includes( uId ) );
          this.roles[foundIndex] = update;
        }

        const apiRes: ApiResponse = {
          message: 'Ok',
          success: true,
        };

        return apiRes;
      } );
  }

  moveTo( userIds: number[], newRoleId: number, oldRoleId: number ): Promise<ApiResponse>{
    return sleep ( 500 )
      .then( () => {
        const foundNewIndex = this.roles.findIndex( ( item ) => item.id === newRoleId );
        const foundOldIndex = this.roles.findIndex( ( item ) => item.id === oldRoleId );

        if ( foundNewIndex >= 0 ) {
          const update = cloneDeep( this.roles[foundNewIndex] );
          update.users = [ ...update.users, ...userIds ];
          this.roles[foundNewIndex] = update;
        }

        if ( foundOldIndex >= 0 ) {
          const update = cloneDeep( this.roles[foundOldIndex] );
          update.users = update.users.filter( ( uId ) => !userIds.includes( uId ) );
          this.roles[foundOldIndex] = update;
        }

        const apiRes: ApiResponse = {
          message: 'Ok',
          success: true,
        };

        return apiRes;
      } );
  }

  private sortRoles( sorted: OrderByPage[], items: IRoleItem[] ): IRoleItem[] {
    if ( !sorted.length ) {
      return items;
    }

    return orderBy(
      items,
      sorted.map( ( sort ) => {
        return ( item ) => {
          if ( item[sort.id] === null || item[sort.id] === undefined ) {
            return -Infinity;
          }
          return typeof item[sort.id] === 'string'
            ? item[sort.id].toLowerCase()
            : item[sort.id];
        };
      } ),
      sorted.map( ( d ) => ( d.desc ? 'desc' : 'asc' ) ),
    );
  }

  private sortGroups( sorted: OrderByPage[], items: IGroupDetails[] ): IGroupDetails[] {
    if ( !sorted.length ) {
      return items;
    }

    return orderBy(
      items,
      sorted.map( ( sort ) => {
        return ( item ) => {
          if ( item[sort.id] === null || item[sort.id] === undefined ) {
            return -Infinity;
          }
          return typeof item[sort.id] === 'string'
            ? item[sort.id].toLowerCase()
            : item[sort.id];
        };
      } ),
      sorted.map( ( d ) => ( d.desc ? 'desc' : 'asc' ) ),
    );
  }

  private sortUsers( sorted: OrderByPage[], items: IUserDetailsItem[] ): IUserDetailsItem[] {
    if ( !sorted.length ) {
      return items;
    }

    return orderBy(
      items,
      sorted.map( ( sort ) => {
        return ( item ) => {
          if ( item[sort.id] === null || item[sort.id] === undefined ) {
            return -Infinity;
          }
          return typeof item[sort.id] === 'string'
            ? item[sort.id].toLowerCase()
            : item[sort.id];
        };
      } ),
      sorted.map( ( d ) => ( d.desc ? 'desc' : 'asc' ) ),
    );
  }
};
