import { BaseRequest } from '../base';
import { GetLeadVersionResponse } from './interfaces';

export class GetLeadVersionRequest extends BaseRequest<GetLeadVersionResponse> {
  constructor(
    leadCode: string,
    leadVersion: number,
  ) {
    super( {
      method: 'GET',
      url: `/insuranceservice/v1/leads/${leadCode}/versions/${leadVersion}`,
      responseType: 'json',
    } );
  }
}
