import React from 'react';
import { useWindowSize } from 'App/ui-utils';
import { useAppRootActions, useAppRootState } from 'App/root/context';

export const OutsideClick: React.FC = ( props ) => {
  const { menu: menuActions } = useAppRootActions();
  const { menu: menuState } = useAppRootState();
  const { width: windowWidth } = useWindowSize();
  const ref = React.useRef<HTMLDivElement>( null );
  const shouldCollapseMenu = windowWidth < 992 && menuState.collapseMenu;

  const handleOutsideClick = React.useCallback( ( event: MouseEvent ) => {
    if ( ref.current && !ref.current.contains( event.target as Node ) ) {
      if ( shouldCollapseMenu ) {
        menuActions.collapseMenu();
      }
    }
  }, [ shouldCollapseMenu, menuActions ] );

  React.useEffect( () => {
    document.addEventListener( 'mousedown', handleOutsideClick );
    return () => {
      document.removeEventListener( 'mousedown', handleOutsideClick );
    };
  }, [ handleOutsideClick ] );

  return (
    <div className="nav-outside" ref={ ref }>{ props.children }</div>
  );

};
