import React from 'react';
import { useAppRealApi } from 'Services/api-context';
import { IUserApiService, UserApiServiceContext } from 'Services/user';
import { ServiceProviderProps } from './common';

export const UserApiServiceProvider: React.FC<ServiceProviderProps<IUserApiService>> = ( props, context ) => {

  const api = useAppRealApi();

  // Create userApiService that depends on api. To create service we are using useClass implementation
  const userApiService: IUserApiService = new props.useClass( api );

  return (
    <UserApiServiceContext.Provider value={ userApiService }>
      { props.children }
    </UserApiServiceContext.Provider>
  );
};
