import {
  UsersResponse,
  UsersRequest,
  UserRequest,
  UserItem,
  DataResponse,
  UserResponse,
  NotificationsItem,
  NotificationsResponse,
  IUserDetailsItem,
  IUpdateUsers,
} from './base';
import { UserService, IUsersService } from 'Services/users';
import { sleep } from 'App/utils';
import { mockedUser, mockedNotification, mockedUsersData } from './mock-data';
import { PagingRequest, OrderByPage, ApiResponse, ApiError } from 'Services/base';
import { IApiListResponse } from 'Services/policies/new/service';
import { cloneDeep, orderBy } from 'lodash';


// We need to define special mocked usersService because we have static data in localStorage
// So for every request we need to filter response based on searchText
// This implementation of service should be only used with mockedApi that is using localStorage
export class MockedUserService extends UserService {
  getUsersData( searchUser: string, roleName: string ): Promise<UserItem[]> {
    const result = sleep ( 500 )
      .then( () => {
        return this.api.request<UsersResponse>( new UsersRequest( searchUser, roleName ) );
      } )
      .then( ( response ) => {
        const { items } = response;
        let users = this.searchUser( searchUser, items );

        if ( roleName ) {
          users = this.filterByRole( roleName, users );
        }

        return users;
      } );

    return result;
  }

  getUser( userId: number ): Promise<UserItem> {
    const result = sleep ( 500 )
      .then( () => {
        return this.api.request<UserItem>( new UserRequest( userId ) );
      } ).then( ( user ) => {
        return user;
      } );

    return result;
  }

  getNotifications( userId: number ): Promise<NotificationsItem> {
    const notification = mockedNotification;

    // This is just to simulate loading time
    const result = sleep( 500 ).then( () => {
      return notification;
    } );

    return result;
  }

  updateNotifications( notifications: NotificationsItem ): Promise<NotificationsResponse> {
    const notificationsRes: NotificationsResponse = {
      item: mockedNotification,
      message: 'Ok',
      success: true,
    };

    // This is just to simulate loading time
    const result = sleep( 500 ).then( () => {
      return notificationsRes;
    } );

    return result;
  }

  private async findUserByEmail( userEmail: string, items: UserItem[] ): Promise<UserItem | null> {
    const userData: UserItem | undefined = items.find( ( item ) => item.user_email === userEmail );

    if ( userData === undefined ) {
      return null;
    }

    return userData;
  }

  private searchUser( searchInput: string, items: UserItem[] ): UserItem[] {
    const input = searchInput.toLowerCase();
    return items.filter( ( item ) =>
      item.user_name.toLowerCase().includes ( input ) || item.user_email.toLowerCase().includes ( input ) );
  }

  private filterByRole( roleName: string, items: UserItem[] ): UserItem[] {
    return items.filter( ( item ) => item.role === roleName );
  }

  addUser( user: UserItem ): Promise<UserResponse> {
    const res: UserResponse = {
      item: mockedUser,
      message: 'Ok',
      success: true,
    };

    // This is just to simulate loading time
    const ad = sleep( 500 ).then( () => {
      return res;
    } );

    return ad;
  }

  updateUser( user: UserItem ): Promise<UserResponse> {
    const res: UserResponse = {
      item: mockedUser,
      message: 'Ok',
      success: true,
    };

    // This is just to simulate loading time
    const ud = sleep( 500 ).then( () => {
      return res;
    } );

    return ud;
  }

  deleteUser( userId: number ): Promise<DataResponse> {
    const result: DataResponse = {
      message: 'Ok',
      success: true,
    };

    // This is just to simulate loading time
    const du = sleep( 500 ).then( () => {
      return result;
    } );

    return du;
  }

  getUserByEmail( userEmail: string ): Promise<UserItem | null> {
    const result = sleep ( 500 )
      .then( () => {
        return this.api.request<UsersResponse>( new UsersRequest( userEmail, '' ) );
      } )
      .then( ( response ) => {
        const { items } = response;
        let user = this.findUserByEmail( userEmail, items );
        return user;
      } );

    return result;
  }
};

export class MockedUsersService implements IUsersService {
  private users: IUserDetailsItem[];
  private nextUserId: number = 0;


  constructor() {
    this.users = mockedUsersData;
    this.calculateNextIdsForService();
  }

  private calculateNextIdsForService(): void {
    const userIds = this.users.map( ( u ) => u.id );
    this.nextUserId = Math.max( ...userIds ) + 1;
  }

  getUsers( searchQuery: string, paging: PagingRequest ): Promise<IApiListResponse<IUserDetailsItem>> {
    const result = sleep ( 500 )
      .then( () => {
        let users: IUserDetailsItem[] = this.users;
        const input = searchQuery.toLowerCase();

        if ( input.length > 0 ) {
          // Filtering based on searchQuery - case insensitive
          users = users.filter( ( u ) => {
            const index = `${u.userName} ${u.email}`;
            return index.toLowerCase().includes ( input );
          } );
        }

        users = this.sortUsers( paging.orderBy, users );
        users = users.slice( paging.pageToken, paging.pageToken + paging.pageSize );
        users = cloneDeep ( users );

        const res: IApiListResponse<IUserDetailsItem> = {
          items: users,
          nextPageToken: users.length < paging.pageSize ? '0' : ( paging.pageToken + paging.pageSize ).toString(),
        };
        return res;
      } );

    return result;
  }

  deleteUsers( userIds: number[] ): Promise<ApiResponse> {
    return sleep ( 500 )
      .then( () => {
        this.users = this.users.filter( ( t ) => !userIds.includes( t.id ) );

        const apiRes: ApiResponse = {
          message: 'Ok',
          success: true,
        };

        return apiRes;
      } );
  }

  disableUsers( userIds: number[] ): Promise<ApiResponse> {
    return sleep ( 500 )
      .then( () => {
        const updatedUsers = this.users.filter( ( user ) => userIds.includes( user.id ) );

        if ( updatedUsers.length > 0 ) {
          updatedUsers.forEach( ( user ) => {
            user.status = 'disabled';
          } );

          this.users.concat( updatedUsers );

          const apiRes: ApiResponse = {
            message: 'Ok',
            success: true,
          };

          return apiRes;
        } else {
          throw new ApiError( `Failed to disabke users: users with id ${userIds} not found` );
        }
      } );
  }

  createUsers( newUsers: IUpdateUsers ): Promise<ApiResponse> {
    return sleep ( 500 )
      .then( () => {
        const updatedUsers: IUserDetailsItem[] = newUsers.users.map( ( user ) => {
          const update: IUserDetailsItem = {
            id: this.nextUserId,
            email: user,
            status: 'invited',
            userName: '',
            mfaAuthentication: newUsers.mfaAuthentication!,
          };
          this.nextUserId += 1;

          if ( newUsers.groups ) {
            update.groups = newUsers.groups;
          }

          if ( newUsers.roles ) {
            update.roles = newUsers.roles;
          }

          return update;
        } );

        this.users = [ ...this.users, ...updatedUsers ];

        const apiRes: ApiResponse = {
          message: 'Ok',
          success: true,
        };

        return apiRes;
      } );
  }

  getUser( userid: number ): Promise<IUserDetailsItem> {
    return sleep ( 500 )
      .then( () => {
        let foundUser = this.users.find( ( u ) => u.id === userid );
        if ( foundUser !== undefined ) {
          return cloneDeep ( foundUser );
        } else {
          throw new ApiError( `User with id: ${userid} not found` );
        }
      } );
  }

  updateUser( update: IUserDetailsItem ): Promise<IUserDetailsItem> {
    return sleep ( 500 )
      .then( () => {
        const foundIndex = this.users.findIndex( ( item ) => item.id === update.id );

        if ( foundIndex >= 0 ) {
          this.users[foundIndex] = update;

          return cloneDeep ( update );
        } else {
          throw new ApiError( `Failed to update user: User id ${update.id} not found` );
        }
      } );
  }

  updateUsers( users: IUpdateUsers ): Promise<ApiResponse> {
    return sleep ( 500 )
      .then( () => {
        const updatedUsers = this.users.filter( ( user ) => (
          users.users.includes( user.userName || user.email )
        ) ).map( ( item ) => {
          const user = item;

          if ( users.groups ) {
            user.groups = users.groups;
          }

          if ( users.roles ) {
            user.roles = users.roles;
          }

          return user;
        } );

        this.users.concat( updatedUsers );

        const apiRes: ApiResponse = {
          message: 'Ok',
          success: true,
        };

        return apiRes;
      } );
  }

  private sortUsers( sorted: OrderByPage[], items: IUserDetailsItem[] ): IUserDetailsItem[] {
    if ( !sorted.length ) {
      return items;
    }

    return orderBy(
      items,
      sorted.map( ( sort ) => {
        return ( item ) => {
          if ( item[sort.id] === null || item[sort.id] === undefined ) {
            return -Infinity;
          }
          return typeof item[sort.id] === 'string'
            ? item[sort.id].toLowerCase()
            : item[sort.id];
        };
      } ),
      sorted.map( ( d ) => ( d.desc ? 'desc' : 'asc' ) ),
    );
  }
};
