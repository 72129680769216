import React, { useContext } from 'react';

import { ApiContext } from 'Services/api-context';
import { IRoleService, RoleServiceContext } from 'Services/role';
import { ServiceProviderProps } from './common';

export const RoleServiceProvider: React.FC<ServiceProviderProps<IRoleService>> = ( props, context ) => {

  const api = useContext( ApiContext );

  // Create RoleService that depends on api. To create service we are using useClass implementation
  const rolesService: IRoleService = new props.useClass( api );

  return (
    <RoleServiceContext.Provider value={ rolesService }>
      { props.children }
    </RoleServiceContext.Provider>
  );
};
