import React from 'react';
import { Spinner, Row } from 'react-bootstrap';

export const LoadingSpinner: React.FC = () => {
  return (
    <Row className="justify-content-center loading-spinner" >
      <Spinner variant="primary" animation="border" className="justify-content-center" />
    </Row>
  );
};

export const useLoadingSpinner = (): React.ReactElement => {
  const spinner = React.useMemo<React.ReactElement>( () => {
    return <LoadingSpinner />;
  }, [] );
  return spinner;
};

export const useLoadingSpinnerOnFullContainer = (): React.ReactElement => {
  const spinner = React.useMemo<React.ReactElement>( () => {
    return (
      <div>
        <div className="modal-full-container show"></div>
        <div className="modal-full-container">
          <LoadingSpinner />
        </div>
      </div> );
  }, [] );
  return spinner;
};
