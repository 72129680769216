import React from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { NavGroup } from './NavGroup';
import { MenuItem } from 'App/menu-items';

export interface NavContentProps {
  navigation: MenuItem[];
}

export const NavContent: React.FC<NavContentProps> = ( props ) => {

  const navItems: React.ReactNode = props.navigation.map( ( item ) => {
    switch ( item.type ) {
      case 'group':
        return <NavGroup key={ item.id } group={ item } />;
      default:
        return false;
    }
  } );

  return (
    <div className="navbar-content datta-scroll">
      <PerfectScrollbar>
        <ul id="left-menu-navigation" className="nav pcoded-inner-navbar">
          { navItems }
        </ul>
      </PerfectScrollbar>
    </div>
  );
};
