import React, { Fragment, useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { MenuItem } from 'App/menu-items';
import { config } from 'config';
import { blankLink } from 'config';
import { AppLink } from 'App/components/utils/routing/AppLink';
import { useAppUserPathPrefix } from 'App/components/utils/providers/AppUserProvider';
import { useAppCurrentProduct } from 'App/components/utils/providers/AppProductProvider';

export interface BreadcrumbProps {
  menuItems: MenuItem[];
}

type BreadcrumbPropsType = BreadcrumbProps;

export interface BreadcrumbState {
  main: MenuItem | null;
  item: MenuItem | null;
}

export const Breadcrumb: React.FC<BreadcrumbPropsType> = ( props ) => {
  const { t } = useTranslation( [ 'base' ] );
  const { name: productTitle } = useAppCurrentProduct();
  const { menuItems } = props;
  const pathPrefix = useAppUserPathPrefix();
  const [ state, setState ] = useState<BreadcrumbState>( {
    main: null,
    item: null,
  } );

  const getCollapse = useCallback( ( item: MenuItem ) => {
    if ( item.children ) {
      ( item.children ).filter( ( collapse ) => {
        if ( collapse.type && collapse.type === 'collapse' ) {
          getCollapse( collapse );
        } else if ( collapse.type && collapse.type === 'item' ) {
          const itemUrl: string = pathPrefix + config.basename + collapse.url;
          if ( document.location.pathname === itemUrl ) {
            setState( { item: collapse, main: item } );
          }
        }
        return false;
      } );
    }
  }, [ pathPrefix ] );

  useEffect( () => {
    const generateBreadcrumb = () => {
      ( menuItems ).map( ( item ) => {
        if ( item.type && item.type === 'group' ) {
          getCollapse( item );
        }
        return false;
      } );
    };

    generateBreadcrumb();
  }, [ getCollapse, menuItems ] );

  let main: React.ReactNode;
  let item: React.ReactNode;
  let breadcrumb: React.ReactNode = '';
  let title: string = t( 'messages.welcome' );
  if ( state.main && state.main.type === 'collapse' ) {
    main = (
      <li className="breadcrumb-item">
        <a href={ blankLink }>{ state.main.title }</a>
      </li>
    );
  }

  if ( state.item && state.item.type === 'item' ) {
    title = state.item.title;
    item = (
      <li className="breadcrumb-item">
        <AppLink to={ state.item.url! }>{ title }</AppLink>
      </li>
    );

    if( state.item.breadcrumbs !== false ) {
      breadcrumb = (
        <div className="page-header">
          <div className="page-block">
            <div className="row align-items-center">
              <div className="col-md-12">
                <div className="page-header-title">
                  <h5 className="m-b-10">{ title }</h5>
                </div>
                <ul id="breadcrumb-list" className="breadcrumb">
                  <li className="breadcrumb-item">
                    <AppLink to="/"><i className="feather icon-home" /></AppLink>
                  </li>
                  { main }
                  { item }
                </ul>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }

  document.title = title + ` | ${ productTitle }`;

  return (
    <Fragment>
      { breadcrumb }
    </Fragment>
  );
};
