import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { ErrorMessageProps, IValidationError, formsTranslationNamespace } from './base';
import { ensureArray } from 'App/utils';
import { Interpolator, TOptions } from 'i18next';
import { interpolationOptions } from 'i18n';

export const ErrorMessages = ( props: ErrorMessageProps ): ReactElement | null => {
  const validationErrors = ensureArray( props.error );
  const errors = validationErrors.map( ( err, index ) => {
    return (
      <ErrorMessage key={ index } error={ err } meta={ props.meta } />
    );
  } );
  return <ul className='list-unstyled small text-danger mb-0'>{ errors }</ul>;
};

const ErrorMessage = ( props: ErrorMessageProps<IValidationError> ): ReactElement | null => {

  const { i18n } = useTranslation( formsTranslationNamespace );
  const { error, meta } = props;

  const options: TOptions = {
    ns: formsTranslationNamespace,
    nsSeparator: false as unknown as string,
    interpolation: interpolationOptions,
    ui: meta,
  };

  // Spread metadata across options so we can use metadata in translation strings
  if ( error.context !== undefined ) {
    options.data = error.context;
  }

  const keyExists = i18n.exists( error.message, options );
  let validationMsg: string;
  validationMsg = i18n.t( error.message, options );
  if ( keyExists ) {
    validationMsg = i18n.t( error.message, options );
  } else {
    // Use internal interpolator to interpolate pure string without translation.
    const interpolator: Interpolator = i18n.services.interpolator;
    validationMsg = interpolator.interpolate( error.message, options, i18n.language, interpolationOptions );
  }

  return (
    <li className='form-text'>{ validationMsg }</li>
  );
};
