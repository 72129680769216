import React from 'react';
import { IApiService, PagingRequest } from './base';
import { ListItems } from './api';

import { ListPartnersRequest } from './partners/requests';
import { Partner } from './partners/interfaces';
export interface IPartnerService {
  listPartners( pageParams: PagingRequest, searchValue: string ): Promise<ListItems<Partner>>;
}

export class PartnerService implements IPartnerService {
  protected api: IApiService;

  constructor( api: IApiService ) {
    this.api = api;
  }

  async listPartners( pageParams: PagingRequest, searchValue: string ): Promise<ListItems<Partner>> {
    const response = await this.api.request( new ListPartnersRequest( pageParams, searchValue ) );

    return response;
  }
}

export const PartnerServiceContext: React.Context<IPartnerService> = React.createContext( undefined as any );

export const usePartnerService = (): IPartnerService => {
  return React.useContext( PartnerServiceContext );
};
