import React from 'react';
import { IApiService, PagingRequest, ApiResponse, ISubscriptionItem, UpdateSubscriptionsRequest } from './base';
import { IApiListResponse } from './policies/new/service';
import { IAlertItem, IAlertHistoryItem, INewAlertItem } from './alerts/interfaces';
import {
  CreateAlertRequest,
  DeleteAlertRequest,
  GetAlertRequest,
  ListAlertHistoryRequest,
  ListAlertRequest,
  UpdateAlertRequest,
} from './alerts/requests';

export interface IAlertService {
  createAlert( newAlert: INewAlertItem ): Promise<IAlertItem>;
  getAlert( alertCode: string ): Promise<IAlertItem>;
  updateAlert( alertUpdate: IAlertItem ): Promise<IAlertItem>;
  getAlerts( searchValue: string, paging: PagingRequest, expand?: string[] ): Promise<IApiListResponse<IAlertItem>>;
  deleteAlert( alertCode: string ): Promise<ApiResponse>;
  updateSubscriptionAlert( isActive: boolean, entity: string, entityId: number ): Promise<ISubscriptionItem>;
  getAlertHistoryByCode(
    alertCode: string, paging: PagingRequest, expand?: string[]
  ): Promise<IApiListResponse<IAlertHistoryItem>>;
}

export class AlertService implements IAlertService {
  protected api: IApiService;

  constructor( api: IApiService ) {
    this.api = api;
  }

  createAlert( alert: INewAlertItem ): Promise<IAlertItem> {
    return this.api.request( new CreateAlertRequest( alert ) )
      .then( ( response ) => {
        const alertData = {
          ...response.alert,
        };

        return alertData;
      } );
  }

  getAlert( alertCode: string ): Promise<IAlertItem> {
    return this.api.request( new GetAlertRequest( alertCode ) )
      .then( ( response ) => {
        const alert = {
          ...response.alert,
        };

        return alert;
      } );
  }

  updateAlert( alertUpdate: IAlertItem ): Promise<IAlertItem> {
    return this.api.request( new UpdateAlertRequest( alertUpdate.code!, alertUpdate ) )
      .then( ( response ) => {
        const alert = {
          ...response.alert,
        };

        return alert;
      } );
  }

  getAlerts( searchValue: string, paging: PagingRequest, expand?: string[] ): Promise<IApiListResponse<IAlertItem>> {
    return this.api.request( new ListAlertRequest( searchValue, paging, expand ) )
      .then( ( response ) => {
        let alerts: IAlertItem[] = [];
        const { items, nextPageToken } = response;

        if ( items ) {
          alerts = items.map( ( item, index ) => {
            const alert: IAlertItem = {
              ...item,
              createdAt: new Date( item.createdAt! ),
              updatedAt: new Date( item.updatedAt! ),
            };

            return alert;
          } );
        }

        const res: IApiListResponse<IAlertItem> = {
          items: alerts,
          nextPageToken: alerts ? alerts.length < paging.pageSize ? '1' : nextPageToken : nextPageToken,
        };

        return res;
      } );
  }

  deleteAlert( alertCode: string ): Promise<ApiResponse> {
    return this.api.request( new DeleteAlertRequest( alertCode ) )
      .then( ( response ) => {
        return response;
      } );
  }

  updateSubscriptionAlert( isActive: boolean, entity: string, entityId: number ): Promise<ISubscriptionItem> {
    return this.api.request( new UpdateSubscriptionsRequest( isActive, entity, entityId ) )
      .then( ( response ) => {
        const subscription = {
          ...response.subscription,
        };

        return subscription;
      } );
  }

  getAlertHistoryByCode(
    alertCode: string, paging: PagingRequest, expand?: string[],
  ): Promise<IApiListResponse<IAlertHistoryItem>> {
    return this.api.request( new ListAlertHistoryRequest( alertCode, paging, expand ) )
      .then( ( response ) => {
        let alertHistory: IAlertHistoryItem[] = [];
        const { items, nextPageToken } = response;

        if ( items ) {
          alertHistory = items.map( ( item, index ) => {
            const history: IAlertHistoryItem = {
              ...item,
              createdAt: new Date( item.createdAt! ),
              updatedAt: new Date( item.updatedAt! ),
            };

            return history;
          } );
        }

        const res: IApiListResponse<IAlertHistoryItem> = {
          items: alertHistory,
          nextPageToken: items ? items.length < paging.pageSize ? '1' : nextPageToken : nextPageToken,
        };

        return res;
      } );
  }
};

export const AlertServiceContext: React.Context<IAlertService> = React.createContext( undefined as any );

export const useAlertService = (): IAlertService => {
  return React.useContext( AlertServiceContext );
};
