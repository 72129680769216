import React, { Suspense } from 'react';
import Fullscreen from 'react-full-screen';
import { NavBar } from './NavBar';
import { Breadcrumb } from './Breadcrumb';

import { Navigation } from './Navigation';
import Loader from '../Loader';
import { useAppMenu } from 'App/menu-items';
import { AppFooter } from './AppFooter';
import { useAppRootActions, useAppRootState } from 'App/root/context';
import { useWindowSize } from 'App/ui-utils';
import { AppEnv, AppEnvMode, appEnv } from 'App/utils';
import { useAppUser } from 'App/components/utils/providers/AppUserProvider';
import { useTranslation } from 'react-i18next';

const prodEnvList: string[] = [ AppEnv.prod, AppEnv.prodfunk, AppEnv.prodzurich ];

export const AppLayout: React.FC = ( props ) => {
  const { t } = useTranslation( [ 'base' ] );
  const currentAccount = useAppUser();
  const windowSize = useWindowSize();
  const menuItems = useAppMenu();
  const { menu: menuActions } = useAppRootActions();
  const { menu, screen } = useAppRootState();

  let environmentMode: string | undefined = undefined;

  const mobileOutClickHandler = React.useCallback( () => {
    if ( windowSize.width < 992 && menu.collapseMenu ) {
      menuActions.collapseMenu();
    }
  }, [ menu.collapseMenu, menuActions, windowSize.width ] );

  if ( appEnv === AppEnv.local ) {
    environmentMode = `${appEnv}-mode`;
  }

  if ( appEnv === AppEnv.dev ) {
    environmentMode = `${appEnv}-mode`;
  }

  if ( appEnv === AppEnv.test ) {
    environmentMode = `${appEnv}-mode`;
  }

  if ( prodEnvList.includes( appEnv ) ) {
    const tenantSlug = currentAccount.tenantSlug;
    if ( tenantSlug && tenantSlug.includes( 'sandbox' ) ) {
      environmentMode = 'sandbox-mode';
    }
  }

  return (
    <div className={ environmentMode ? `app-mode ${environmentMode}` : '' }>
      { environmentMode && (
        <div className="notification-env-mode">
          <p>{ t( 'environmentMode', { environment: AppEnvMode[appEnv] } ) }</p>
        </div>
      ) }
      <Fullscreen enabled={ screen.isFullScreen }>
        <Navigation menuItems={ menuItems } />
        <NavBar />
        <div className="pcoded-main-container" onClick={ mobileOutClickHandler }>
          <div className="pcoded-wrapper app-vertical-container">
            <div className="pcoded-content pt-0">
              <div className="pcoded-inner-content">
                <Breadcrumb menuItems={ menuItems } />
                <div className="main-body">
                  <div className="page-wrapper">
                    <Suspense fallback={ <Loader /> }>
                      { props.children }
                    </Suspense>
                  </div>
                </div>
              </div>
            </div>
            <AppFooter />
          </div>
        </div>
      </Fullscreen>
    </div>
  );
};
