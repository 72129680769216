import React, { useContext } from 'react';

import { ApiContext } from 'Services/api-context';
import { IPolicyService, PolicyServiceContext } from 'Services/policies/new/service';
import { ServiceProviderProps } from './common';

export const PolicyServiceProvider: React.FC<ServiceProviderProps<IPolicyService>> = ( props, context ) => {

  const api = useContext( ApiContext );

  // Create CustomerService that depends on api. To create service we are using useClass implementation
  const policyService: IPolicyService = new props.useClass( api );

  return (
    <PolicyServiceContext.Provider value={ policyService }>
      { props.children }
    </PolicyServiceContext.Provider>
  );
};
