import { IHubSpotTicketResponse, IHubSpotTicket } from './interfaces';
import { BaseRequest } from 'Services/base';
import { hubSpotApiUrl } from 'App/utils';

export class CreateTicketRequest extends BaseRequest<IHubSpotTicketResponse> {
  constructor(
    ticket: IHubSpotTicket,
  ) {
    super( {
      headers: { 'Content-Type': 'application/json' },
      method: 'POST',
      url: hubSpotApiUrl,
      // Need to revert when using the api
      // url: '/ticketingservice/v1/hubspot/tickets',
      data: ticket,
      responseType: 'json',
    } );
  }
}
