import React, { Fragment } from 'react';
import * as qs from 'query-string';
import { NavLink, useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { AuthServiceContext } from 'Services/auth';
import { appUserPathPrefix, useAppUserPathPrefix } from '../utils/providers/AppUserProvider';
import { useAppLogger } from 'Services/logger';
import { useLoadingSpinnerOnFullContainer } from '../utils/LoadingSpinner';
import { AuthResponseError, SamlCredentials } from 'Services/api/auth/base';
import { getReturnUrl } from 'main-routes';

export const SAMLPage: React.FC = () => {
  const { t } = useTranslation( [ 'auth', 'base' ] );
  const logger = useAppLogger();
  const authService = React.useContext( AuthServiceContext );
  const userPrefix = useAppUserPathPrefix();
  const loadingOnFullContainer = useLoadingSpinnerOnFullContainer();
  const history = useHistory();
  const location = useLocation();

  const [ errorMessage, setErrorMessage ] = React.useState<string | null>( null );

  const getUrlParams = React.useCallback( (): SamlCredentials | undefined => {
    const parsedUrl = qs.parse( location.search, { decode: false } );

    if ( parsedUrl['tenantSlug'] && parsedUrl['code'] ) {
      const credentials: SamlCredentials = {
        tenantSlug: parsedUrl['tenantSlug'],
        code: parsedUrl['code'],
      };

      return credentials;
    }

    return undefined;
  }, [ location.search ] );

  React.useEffect( () => {
    const credentials = getUrlParams();

    if ( credentials === undefined ) {
      setErrorMessage( t( 'base:forms.messages.error' ) );
      return;
    }

    let isMounted = true;
    const loginSaml = async () => {
      try {
        const authInfo = await authService.loginSaml( credentials );

        if ( isMounted ) {
          if ( authInfo ) {
            // notify segment of logged in user
            analytics?.identify(
              authInfo.userMeta.sub,
              { tenantId: authInfo.userMeta.tenantId },
            );

            const accountIdx: number = authInfo.accountIndex;
            const returnUrl = getReturnUrl( location.search );
            const redirectUrl = appUserPathPrefix( accountIdx ) + returnUrl;
            history.push( redirectUrl );
          }
        }
      } catch( err ) {
        isMounted = false;

        const error = err as any;

        if ( error.wasHandled ) {
          const errData = error.error && error.error.response ? error.error.response.data : error.error;
          const authError: AuthResponseError = errData;
          const errorMessageKey = authError.message === 'UNAUTHORIZED'
            ? 'base:forms.messages.loginError'
            : 'base:forms.messages.changePasswordError';

          setErrorMessage( t( errorMessageKey ) );
          return;
        }
        // other error
        logger.error( 'Error during login', err );
      }
    };

    loginSaml();

    return () => {
      isMounted = false;
    };
  }, [ authService, getUrlParams, history, location, location.search, logger, t ] );

  if ( errorMessage !== null ) {
    return (
      <div className="auth-wrapper offline">
        <div className="text-center">
          <h1 className="mb-4">{ t( 'base:messages.error' ) }</h1>
          <p className="mb-4">{ errorMessage }</p>
          <NavLink
            to={ `${userPrefix}/` }
            className="btn btn-primary mb-4 active"
          >
            <i className="feather icon-home" />
            { t( 'base:messages.backToHome' ) }
          </NavLink>
        </div>
      </div>
    );
  }

  return (
    <Fragment>
      <div className="loading-box">
        { loadingOnFullContainer }
      </div>
    </Fragment>
  );
};
export default SAMLPage;
