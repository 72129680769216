import { ApiResponse, BaseRequest, PagingRequest } from 'Services/base';
import {
  INewEmailLayout,
  IEmailLayoutResponse,
  IUpdateEmailLayout,
  IListEmailLayoutsResponse,
  INewEmailTemplate,
  IEmailTemplateResponse,
  IUpdateEmailTemplate,
  IApiEmailTemplate,
} from './interfaces';
import { IApiListResponse } from 'Services/policies/new/service';

export class CreateEmailLayoutRequest extends BaseRequest<IEmailLayoutResponse> {
  constructor(
    layout: INewEmailLayout,
  ) {

    super( {
      method: 'POST',
      url: '/notificationservice/v1/layouts',
      data: layout,
      responseType: 'json',
    } );
  }
}

export class GetEmailLayoutRequest extends BaseRequest<IEmailLayoutResponse> {
  constructor(
    layoutId: number,
  ) {

    super( {
      method: 'GET',
      url: `/notificationservice/v1/layouts/${layoutId}`,
      responseType: 'json',
    } );
  }
}

export class UpdateEmailLayoutRequest extends BaseRequest<IEmailLayoutResponse> {
  constructor(
    layoutId: number,
    layout: IUpdateEmailLayout,
  ) {

    super( {
      method: 'PUT',
      url: `/notificationservice/v1/layouts/${layoutId}`,
      data: layout,
    } );
  }
}

export class ListEmailLayoutsRequest extends BaseRequest<IListEmailLayoutsResponse> {
  constructor(
    searchValue: string,
    paging: PagingRequest,
    expand?: string[],
  ) {

    const queryParams = new URLSearchParams();
    queryParams.set( 'pageSize', paging.pageSize.toString() );
    queryParams.set( 'pageToken', paging.pageToken.toString() );

    if ( paging.filter.length ) {
      const filterPars = paging.filter.map( ( item ) => `${item.id}=${encodeURIComponent( item.value )}` ).join( '&' );
      queryParams.set( 'filter', filterPars );
    }

    if ( paging.orderBy.length ) {
      const orderPars = paging.orderBy.map( ( item ) => `${item.id} ${ item.desc ? 'DESC' : 'ASC' }` ).join( '&' );
      queryParams.set( 'order', orderPars );
    } else {
      queryParams.set( 'order', 'createdAt ASC' );
    }

    if ( searchValue ) {
      queryParams.set( 'query', searchValue );
    }

    if ( expand && expand.length > 0 ) {
      const expandPars = expand.map( ( item ) => `${encodeURIComponent( item )}` ).join( ',' );
      queryParams.set( 'expand', expandPars );
    }

    super( {
      method: 'GET',
      url: '/notificationservice/v1/layouts',
      responseType: 'json',
      params: queryParams,
    } );
  }
}

export class DeleteEmailLayoutRequest extends BaseRequest<ApiResponse> {
  constructor(
    layoutId: number,
  ) {

    super( {
      method: 'DELETE',
      url: `/notificationservice/v1/layouts/${layoutId}`,
      responseType: 'json',
    } );
  }
}

export class CreateEmailTemplateRequest extends BaseRequest<IEmailTemplateResponse> {
  constructor(
    template: INewEmailTemplate,
  ) {

    super( {
      method: 'POST',
      url: '/notificationservice/v1/notification-templates',
      data: template,
      responseType: 'json',
    } );
  }
}

export class GetEmailTemplateRequest extends BaseRequest<IEmailTemplateResponse> {
  constructor(
    templateId: number,
  ) {

    super( {
      method: 'GET',
      url: `/notificationservice/v1/notification-templates/${templateId}`,
      responseType: 'json',
    } );
  }
}

export class UpdateEmailTemplateRequest extends BaseRequest<IEmailTemplateResponse> {
  constructor(
    templateId: number,
    templateUpdate: IUpdateEmailTemplate,
  ) {

    super( {
      method: 'PUT',
      url: `/notificationservice/v1/notification-templates/${templateId}`,
      data: templateUpdate,
    } );
  }
}

export class ListEmailTemplateRequest extends BaseRequest<IApiListResponse<IApiEmailTemplate>> {
  constructor(
    searchValue: string,
    paging: PagingRequest,
    expand?: string[],
  ) {

    const queryParams = new URLSearchParams();
    queryParams.set( 'pageSize', paging.pageSize.toString() );
    queryParams.set( 'pageToken', paging.pageToken.toString() );

    if ( paging.filter.length ) {
      const filterPars = paging.filter.map( ( item ) => `${item.id}=${encodeURIComponent( item.value )}` ).join( '&' );
      queryParams.set( 'filter', filterPars );
    }

    if ( paging.orderBy.length ) {
      const orderPars = paging.orderBy.map( ( item ) => `${item.id} ${ item.desc ? 'DESC' : 'ASC' }` ).join( '&' );
      queryParams.set( 'order', orderPars );
    }

    if ( searchValue ) {
      queryParams.set( 'search', searchValue );
    }

    if ( expand && expand.length > 0 ) {
      const expandPars = expand.map( ( item ) => `${encodeURIComponent( item )}` ).join( ',' );
      queryParams.set( 'expand', expandPars );
    }

    super( {
      method: 'GET',
      url: '/notificationservice/v1/notification-templates',
      responseType: 'json',
      params: queryParams,
    } );
  }
}

export class DeleteTemplateRequest extends BaseRequest<ApiResponse> {
  constructor(
    templateId: number,
  ) {

    super( {
      method: 'DELETE',
      url: `/notificationservice/v1/notification-templates/${templateId}`,
      responseType: 'json',
    } );
  }
}
