import React, { ReactNode } from 'react';
import { InputGroup, FormControl } from 'react-bootstrap';
import { FixDateTime } from '../Fixes';
import { DatetimepickerProps } from 'react-datetime';
import calendarIcon from 'assets/widget-images/fa-calendar.svg';

export interface CalendarInputState {}

export interface CalendarInputProps {
  isRenderInputIcon: boolean;
  onChangeDate: ( data: string ) => void;
  isShowingTimeFormat: boolean;
  dateFormat?: string;
  isCloseOnSelect: boolean;
  valueProps: string | undefined;
  className?: string;
  inputProps?: React.HTMLProps<HTMLInputElement>;
}

type CalendarPropsType = CalendarInputProps & DatetimepickerProps;

class CalendarInput extends React.Component<CalendarPropsType, CalendarInputState> {
  defaultDateFormat: string = 'DD.MM.YYYY';

  constructor( props: CalendarPropsType ) {
    super( props );
    this.renderDateInput = this.renderDateInput.bind( this );
  }

  renderDateInput( props, openCalendar: () => void, closeCalendar: () => void ) {
    if ( !this.props.isRenderInputIcon ) {
      return ( <FormControl type="text" { ...props } /> );
    }
    return (
      <div>
        <InputGroup>
          <FormControl type="text" { ...props } />
          <InputGroup.Append>
            <InputGroup.Text onClick={ openCalendar } style={ { cursor: 'pointer' } }>
              <img src={ calendarIcon } width="16px" height="16px" alt="Calendar" />
            </InputGroup.Text>
          </InputGroup.Append>
        </InputGroup>
      </div>
    );
  }

  render(): ReactNode {
    const dateValue = this.props.valueProps ? new Date( this.props.valueProps as string ) : undefined;
    return (
      <FixDateTime
        renderInput={ this.renderDateInput }
        timeFormat={ this.props.isShowingTimeFormat }
        dateFormat={ this.props.dateFormat ? this.props.dateFormat : this.defaultDateFormat }
        className={ `date-control mt-0 ${this.props.className}` }
        closeOnSelect={ this.props.isCloseOnSelect }
        value={ dateValue }
        onChange={ ( value ) => { this.props.onChangeDate( value as string ); } }
        inputProps={ this.props.inputProps }
        isValidDate={ this.props.isValidDate }
      />
    );
  }
};

export default CalendarInput;
