import React from 'react';
import { IApiService, PagingRequest } from './base';
import { LeadStatusItem } from './leads/interfaces';
import { IApiListResponse } from './policies/new/service';
import { ListLeadStatusesRequest } from './lead-statuses/lead-statuses';

export interface ILeadStatusService {
  listLeadStatuses( paging: PagingRequest ): Promise<IApiListResponse<LeadStatusItem>>;
}
export class LeadStatusService {
  protected api: IApiService;
  constructor( api: IApiService ) {
    this.api = api;
  }
  async listLeadStatuses( paging: PagingRequest ): Promise<IApiListResponse<LeadStatusItem>> {
    const response = await this.api.request( new ListLeadStatusesRequest( paging ) );
    const { items, nextPageToken } = response;

    const res: IApiListResponse<LeadStatusItem> = {
      items: items ? items : [],
      nextPageToken: nextPageToken ? nextPageToken : '1',
    };

    return res;
  }
}

export const LeadStatusServiceContext: React.Context<ILeadStatusService> = React.createContext( undefined as any );

export const useLeadStatusService = (): ILeadStatusService => {
  return React.useContext( LeadStatusServiceContext );
};

