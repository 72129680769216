import { ProductIdType } from 'App/components/utils/providers/AppProductProvider';
import React from 'react';
import { PermissionType } from 'Services/permissions/interfaces';

// For dynamic loading modules mainly
export type AnyComponentType = React.ComponentType<any>;
export type DynamicImportType = () => Promise<{ default: AnyComponentType }>;
export type LazyComponentType = React.LazyExoticComponent<AnyComponentType>;
export type MessageType = 'success' | 'danger' | 'info';

export interface AppReducerAction {
  type: string;
  payload?: object;
}

// This is temporary solution because typescript does not have support for FullScreenApi in Document
export interface Document {
  mozCancelFullScreen(): void;
  msCancelFullScreen(): void;
  webkitCancelFullScreen(): void;
  requestPointerLock(): void;
  exitPointerLock(): void;
  mozFullScreen: boolean;
  msIsFullScreen: boolean;
  readonly webkitIsFullScreen: boolean;
  readonly pointerLockElement: Element;
  mozPointerLockElement: HTMLElement;
  msPointerLockElement: HTMLElement;
  webkitPointerLockElement: HTMLElement;
}

export interface RouteDescriptor {
  path: string;
  exact: boolean;
  hasLayout: boolean;
  component: LazyComponentType;
  requiredPermissions?: PermissionType[] | PermissionType[][];
  componentProps?: any;
  productId: ProductIdType;
  doNotDecodeOnReturn?: boolean;
}

export interface ITranslatable {
  key: string;
  values?: Record<string, any>;
}

export type TranslatableErrors<Values> = {
  [K in keyof Values]?: ITranslatable;
};

export const isTranslatable = ( obj: any ): obj is ITranslatable =>
  isObject( obj ) && isString( obj.key );

export const isString = ( obj: any ): obj is string =>
  Object.prototype.toString.call( obj ) === '[object String]';

export const isObject = ( obj: any ): obj is Object =>
  obj !== null && typeof obj === 'object';

// React currently throws a warning when using useLayoutEffect on the server.
// To get around it, we can conditionally useEffect on the server (no-op) and
// useLayoutEffect in the browser.
// @see https://gist.github.com/gaearon/e7d97cdf38a2907924ea12e4ebdf3c85
export const useIsomorphicLayoutEffect =
  typeof window !== 'undefined' &&
  typeof window.document !== 'undefined' &&
  typeof window.document.createElement !== 'undefined'
    ? React.useLayoutEffect
    : React.useEffect;

export const useEventCallback = <T extends ( ...args: any[] ) => any>( fn: T ): T => {
  const ref: any = React.useRef( fn );

  // we copy a ref to the callback scoped to the current state/props on each render
  useIsomorphicLayoutEffect( () => {
    ref.current = fn;
  } );

  return React.useCallback(
    ( ...args: any[] ) => ref.current.apply( void 0, args ),
    [],
  ) as T;
};
