
import React, { useContext } from 'react';

import { ApiContext } from 'Services/api-context';
import { ServiceProviderProps } from './common';
import { IFormService, FormServiceContext } from 'Services/forms/base';

export const FormServiceProvider: React.FC<ServiceProviderProps<IFormService>> = ( props, context ) => {

  const api = useContext( ApiContext );
  const formService = new props.useClass( api );
  formService.initialize();

  return (
    <FormServiceContext.Provider value={ formService }>
      { props.children }
    </FormServiceContext.Provider>
  );
};
