import logoImage from 'assets/images/EMIL_logo.png';
export type LayoutType = 'vertical' | 'horizontal';

export interface Config {
  defaultPath: string;
  basename: string; // Only at build time to set, like /datta-able
  layout: LayoutType; // Vertical, horizontal
  preLayout: string; // Null, layout-2, layout-2-2, layout-3, layout-4, layout-4-2, layout-6, layout-8
  collapseMenu: boolean; // Mini-menu
  layoutType: 'menu-dark' | 'menu-light' | 'dark'; // Menu-dark, menu-light, dark
  navIconColor: boolean;
  headerBackColor: string; // Header-default, header-blue, header-red, header-purple, header-lightblue, header-dark
  navBackColor: string; // Navbar-default, navbar-blue, navbar-red, navbar-purple, navbar-lightblue, navbar-dark
  navBrandColor: string; // Brand-default, brand-blue, brand-red, brand-purple, brand-lightblue, brand-dark
  navBackImage: boolean | string;
  logoImageSite: string;
  titleSite: string;
  rtlLayout: boolean;
  navFixedLayout: boolean;
  headerFixedLayout: boolean;
  boxLayout: boolean;
  navDropdownIcon: string; // Style1, style2, style3
  navListIcon: string; // Style1, style2, style3, style4, style5, style6
  navActiveListColor: string; // Active-default, active-blue, active-red, active-purple, active-lightblue, active-dark
  navListTitleColor: string; // Title-default, title-blue, title-red, title-purple, title-lightblue, title-dark
  navListTitleHide: boolean;
  configBlock: boolean;
  layout6Background : string; // Used only for pre-layout = layout-6
  layout6BackSize : string; // Used only for pre-layout = layout-6
  isFullScreen: boolean;
}

export const websiteTitle = 'EMIL Insurance Suite';

export const blankLink: string = '#';

export const config: Config = {
  defaultPath: '/dashboard',
  basename: '', // Only at build time to set, like /datta-able
  layout: 'vertical', // Vertical, horizontal
  preLayout: '', // Null, layout-2, layout-2-2, layout-3, layout-4, layout-4-2, layout-6, layout-8
  collapseMenu: false, // Mini-menu
  layoutType: 'menu-dark', // Menu-dark, menu-light, dark
  navIconColor: false,
  // Header-default, header-blue, header-red, header-purple, header-lightblue, header-dark
  headerBackColor: 'header-default',
  // Navbar-default, navbar-blue, navbar-red, navbar-purple, navbar-lightblue, navbar-dark
  navBackColor: 'navbar-default',
  navBrandColor: 'brand-default', // Brand-default, brand-blue, brand-red, brand-purple, brand-lightblue, brand-dark
  navBackImage: false, // False, navbar-image-1, navbar-image-2, navbar-image-3, navbar-image-4, navbar-image-5
  logoImageSite: logoImage,
  titleSite: websiteTitle,
  rtlLayout: false,
  navFixedLayout: true,
  headerFixedLayout: false,
  boxLayout: false,
  navDropdownIcon: 'style1', // Style1, style2, style3
  navListIcon: 'style1', // Style1, style2, style3, style4, style5, style6
  // Active-default, active-blue, active-red, active-purple, active-lightblue, active-dark
  navActiveListColor: 'active-custom',
  navListTitleColor: 'title-default', // Title-default, title-blue, title-red, title-purple, title-lightblue, title-dark
  navListTitleHide: false,
  configBlock: false,
  // Used only for pre-layout = layout-6
  layout6Background: 'linear-gradient(to right, #A445B2 0%, #D41872 52%, #FF0066 100%)',
  layout6BackSize: '', // Used only for pre-layout = layout-6
  isFullScreen: false,
};
export default config;
