import React from 'react';
import { IApiService, PagingRequest } from './base';
import { ClaimStatusItem } from './claim-statuses/interfaces';
import { IApiListResponse } from './policies/new/service';
import { ListClaimStatusesRequest } from './claim-statuses/claim-statuses';

export interface IClaimStatusService {
  listClaimStatuses( paging: PagingRequest ): Promise<IApiListResponse<ClaimStatusItem>>;
}
export class ClaimStatusService {
  protected api: IApiService;
  constructor( api: IApiService ) {
    this.api = api;
  }
  async listClaimStatuses( paging: PagingRequest ): Promise<IApiListResponse<ClaimStatusItem>> {
    const response = await this.api.request( new ListClaimStatusesRequest( paging ) );
    const { items, nextPageToken } = response;

    const res: IApiListResponse<ClaimStatusItem> = {
      items: items ? items.sort( ( a, b ) => {
        return a.name.localeCompare( b.name );
      } ) : [],
      nextPageToken: nextPageToken ? nextPageToken : '1',
    };

    return res;
  }
}

export const ClaimStatusServiceContext: React.Context<IClaimStatusService> = React.createContext( undefined as any );

export const useClaimStatusService = (): IClaimStatusService => {
  return React.useContext( ClaimStatusServiceContext );
};

