import { AppFieldMetaProps, generateSchema } from './base';
import React, { ReactNode } from 'react';
import { AppField } from './AppField';
import { Form } from 'formik';
import { ObjectSchema } from 'yup';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';

export const generateForm = ( formId: string, fields: AppFieldMetaProps[] ): ReactNode => {
  return (
    <Form id={ formId }>
      {
        fields.map( ( field ) => (
          <AppField
            key={ field.fieldName }
            { ...field }
            renderType="horizontal"
          />
        ) )
      }
    </Form>
  );
};

export const useFormGenerator = <FM extends object>(
  formId: string,
  fields: AppFieldMetaProps[],
): [ObjectSchema<FM>, ReactNode] => {
  const validationSchema = React.useMemo( () => generateSchema<FM>( fields ), [ fields ] );
  const form = React.useMemo<ReactNode>( () => generateForm( formId, fields ), [ fields, formId ] );
  return [ validationSchema, form ];
};

export const useFormTranslation = (): TFunction => {
  const { t } = useTranslation( [ 'forms' ] );
  return t;
};

export const usePolicyTranslation = (): TFunction => {
  const { t } = useTranslation( [ 'policy' ] );
  return t;
};

export const useLeadTranslation = (): TFunction => {
  const { t } = useTranslation( [ 'lead' ] );
  return t;
};
