import React from 'react';
import microsoftIcon from 'assets/images/login-icons/microsoft_icon.png';
import { Form, Button, Row, FormGroup, Col, FormControl } from 'react-bootstrap';
import { useTranslation, Trans } from 'react-i18next';
import { websiteTitle } from 'config';
import { SubmitHandler, useForm, FormProvider, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useRegistrtionFormValidationSchema } from '../validation/validation';
import { AuthServiceContext } from 'Services/auth';
import { useAppAlertService, AppAlertUI } from '../../utils/alerts/AppAlertService';
import { useHistory } from 'react-router-dom';
import { isMicrosoftLogin } from 'App/utils';
import { ActiveStep, OrganizationState, useOrgActions } from '../OrganizationRegistration';
import { preparePayload } from '../hooks/organization-hooks';
import { useAppLogger } from 'Services/logger';

export interface UserDataItem {
  email: string;
  firstName: string;
  lastName: string;
  newPassword: string;
  confirmPassword: string;
  isEulaAccepted?: boolean;
}

interface UserDataFormProps {
  initData: OrganizationState;
}

const agb: string = 'https://www.emil.de/agb';
const datenschutz: string = 'https://www.emil.de/datenschutz';

const styleHideBox = {
  height: '0px',
  overflow: 'hidden',
  background: 'transparent',
};

export const UserDataForm: React.FC<UserDataFormProps> = ( { initData } ) => {
  const { t } = useTranslation( [ 'registration', 'auth' ] );
  const logger = useAppLogger();
  const history = useHistory();
  const actions = useOrgActions();
  const validationSchema = useRegistrtionFormValidationSchema();
  const formValues: UserDataItem = initData[ActiveStep.UserData];
  const authService = React.useContext( AuthServiceContext );
  const { showAlert } = useAppAlertService();

  let infoSteps = '2/2';
  let previousStep = ActiveStep.OrgData;
  if ( initData.customSchema ) {
    previousStep = ActiveStep.CustomData;
    infoSteps = '3/3';
  }

  const formContext = useForm( {
    mode: 'onChange',
    shouldUnregister: false,
    resolver: yupResolver( validationSchema ) },
  );

  const { control, handleSubmit, formState, getValues, errors } = formContext;
  const onSubmit: SubmitHandler<UserDataItem> = React.useCallback( async ( fields ) => {
    const payload = preparePayload( fields, initData );

    try {
      const result = await authService.createOrganization( payload );
      if ( result ) {
        history.push( '/success-registration' );
      }
    } catch ( error ) {
      showAlert( {
        message: t( 'base:forms.messages.errorSave' ),
        type: 'danger',
      } );

      // other error
      logger.error( 'Error registration', error );
    }

  }, [ authService, history, initData, logger, showAlert, t ] );

  const goBack = React.useCallback( (): void => {
    let fields = getValues();
    if( !formState.isValid ) {
      fields = formValues;
    }

    actions.goToStep( previousStep, ActiveStep.UserData, fields );
  }, [ actions, formState.isValid, formValues, getValues, previousStep ] );

  return (
    <div className="card">
      <div className="card-body">
        <div className="mb-4 text-center">
          <i className="feather icon-unlock auth-icon" />
        </div>
        <h3 className="mb-4 text-center">{ websiteTitle }</h3>
        <p className="mb-4 text-center f-14">{ t( 'registration:organizations.navTitle' ) } { infoSteps }</p>
        <p className="mb-4 text-muted f-14">{ t( 'registration:organizations.steps.registrationPageDescription' ) }</p>
        <AppAlertUI />
        { isMicrosoftLogin && initData.samlIdpLoginUrl && (
          <div className="text-center">
            <a
              href={ initData.samlIdpLoginUrl }
              className="microsoft-btn mb-4 mx-0 btn btn-outline-secondary"
              rel="noopener noreferrer"
            >
              <img src={ microsoftIcon } alt="Microsoft icon" />
              { t( 'auth:login.microsoftTitle' ) }
            </a>
            <h6 className="info-box mb-4">
              <span>{ t( 'auth:login.or' ) }</span>
            </h6>
          </div>
        ) }
        <FormProvider { ...formContext }>
          <Form noValidate onSubmit={ handleSubmit( onSubmit ) }>
            <Row>
              <Col sm={ 12 }>
                <Controller
                  name="email"
                  control={ control }
                  defaultValue={ formValues.email }
                  render={ ( props ) => (
                    <FormGroup controlId={ props.name }>
                      <Form.Label className="m-0">
                        { t( `fields.${props.name}` ) }
                      </Form.Label>
                      <Form.Control
                        { ...props }
                        type="email"
                        autoComplete={ props.name }
                        placeholder={ t( `fields.${props.name}` ) }
                        isInvalid={ !!errors[props.name] }
                        disabled={ true }
                      />
                      <FormControl.Feedback type="invalid">
                        { errors.email?.message }
                      </FormControl.Feedback>
                      { /* Fix for disabled autocomplete for input type email of form  */ }
                      <div style={ styleHideBox }>
                        <input
                          type="email"
                          required={ false }
                        />
                      </div>
                    </FormGroup>
                  ) }
                />
              </Col>
            </Row>
            <Row>
              <Col sm={ 12 }>
                <Controller
                  name="firstName"
                  control={ control }
                  defaultValue={ formValues.firstName }
                  render={ ( props ) => (
                    <FormGroup controlId={ props.name }>
                      <Form.Label className="m-0">
                        { t( `fields.${props.name}` ) }
                      </Form.Label>
                      <Form.Control
                        { ...props }
                        type="text"
                        autoComplete={ props.name }
                        placeholder={ t( `fields.${props.name}` ) }
                        isInvalid={ !!errors[props.name] }
                      />
                      <FormControl.Feedback type="invalid">
                        { errors[props.name]?.message }
                      </FormControl.Feedback>
                      { /* Fix for disabled autocomplete for input type text of form  */ }
                      <div style={ styleHideBox }>
                        <input
                          type="text"
                          required={ false }
                        />
                      </div>
                    </FormGroup>
                  ) }
                />
              </Col>
            </Row>
            <Row>
              <Col sm={ 12 }>
                <Controller
                  name="lastName"
                  control={ control }
                  defaultValue={ formValues.lastName }
                  render={ ( props ) => (
                    <FormGroup controlId={ props.name }>
                      <Form.Label className="m-0">
                        { t( `fields.${props.name}` ) }
                      </Form.Label>
                      <Form.Control
                        { ...props }
                        type="text"
                        autoComplete={ props.name }
                        placeholder={ t( `fields.${props.name}` ) }
                        isInvalid={ !!errors.lastName }
                      />
                      <FormControl.Feedback type="invalid">
                        { errors[props.name]?.message }
                      </FormControl.Feedback>
                      { /* Fix for disabled autocomplete for input type text of form  */ }
                      <div style={ styleHideBox }>
                        <input
                          type="text"
                          required={ false }
                        />
                      </div>
                    </FormGroup>
                  ) }
                />
              </Col>
            </Row>
            <Row>
              <Col sm={ 12 }>
                <Controller
                  name="newPassword"
                  control={ control }
                  defaultValue={ formValues.newPassword }
                  render={ ( props ) => (
                    <FormGroup controlId={ props.name }>
                      <Form.Label className="m-0">
                        { t( `fields.${props.name}` ) }
                      </Form.Label>
                      <Form.Control
                        { ...props }
                        type="password"
                        autoComplete={ props.name }
                        placeholder={ t( `fields.${props.name}` ) }
                        isInvalid={ errors.password !== undefined }
                      />
                      <FormControl.Feedback type="invalid">
                        { errors.password?.message }
                      </FormControl.Feedback>
                      { /* Fix for disabled autocomplete for input type password of form  */ }
                      <div style={ styleHideBox }>
                        <input
                          type="password"
                          required={ false }
                        />
                      </div>
                    </FormGroup>
                  ) }
                />
              </Col>
            </Row>
            <Row>
              <Col sm={ 12 }>
                <Controller
                  name="confirmPassword"
                  control={ control }
                  defaultValue={ formValues.confirmPassword }
                  render={ ( props ) => (
                    <FormGroup controlId={ props.name }>
                      <Form.Label className="m-0">
                        { t( 'fields.newConfirmPassword' ) }
                      </Form.Label>
                      <Form.Control
                        { ...props }
                        type="password"
                        autoComplete={ props.name }
                        placeholder={ t( 'fields.newConfirmPassword' ) }
                        isInvalid={ errors.confirmPassword !== undefined }
                      />
                      <FormControl.Feedback type="invalid">
                        { errors.confirmPassword?.message }
                      </FormControl.Feedback>
                      { /* Fix for disabled autocomplete for input type password of form  */ }
                      <div style={ styleHideBox }>
                        <input
                          type="password"
                          required={ false }
                        />
                      </div>
                    </FormGroup>
                  ) }
                />
                <p className="text-muted f-11">{ t( 'passwordHint' ) }</p>
              </Col>
            </Row>
            <Row className="mt-2">
              <Col sm={ 12 }>
                <Controller
                  name="isEulaAccepted"
                  control={ control }
                  defaultValue={ formValues.isEulaAccepted }
                  render={ ( props ) => (
                    <FormGroup controlId={ props.name }>
                      <Row noGutters>
                        <Col md={ 1 } className="checkbox d-inline checkbox-fill">
                          <FormControl
                            { ...props }
                            type="checkbox"
                            onChange={ ( e: React.ChangeEvent<HTMLInputElement> ) =>
                              props.onChange( e.target.checked ) }
                            checked={ props.value }
                            onBlur={ props.onBlur }
                            isInvalid={ !!errors[props.name] }
                          />
                          <Form.Label className="cr"></Form.Label>
                        </Col>
                        <Col className="ml-2">
                          <Trans
                            i18nKey="isEulaText"
                            ns="registration"
                            components={ {
                              linkToAgb:
                                <a rel="noopener noreferrer" target="_blank" href={ agb }>
                                  Link
                                </a>,
                              linkTodatenschutz:
                                <a rel="noopener noreferrer" target="_blank" href={ datenschutz }>
                                  Link
                                </a>,
                            } }
                          />
                        </Col>
                      </Row>
                      <FormControl.Feedback type="invalid">
                        { errors[props.name]?.message }
                      </FormControl.Feedback>
                    </FormGroup>
                  ) }
                />
              </Col>
            </Row>
            <Row className="mt-2">
              <Col md={ 12 } className="d-flex justify-content-center m-0 p-0 mb-2">
                <Button
                  variant="primary"
                  type="submit"
                  className="mr-0"
                  disabled={ formState.isSubmitting || !formState.isValid }
                >
                  { t( formState.isSubmitting ? 'button.registering' : 'button.registration' ) }
                </Button>
              </Col>
              <Col md={ 12 } className="d-flex justify-content-center m-0 p-0">
                <Button
                  type="button"
                  variant="link"
                  className="m-0"
                  onClick={ goBack }
                >
                  { t( 'organizations.navButton.previousBtn' ) }
                </Button>
              </Col>
            </Row>
          </Form>
        </FormProvider>
      </div>
    </div>
  );
};
