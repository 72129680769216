import { BaseRequest } from 'Services/base';
import { CustomerMockItem } from 'Services/customers/interfaces';
import { PolicyItem } from 'Services/policies/policies';

export class SearchRequest extends BaseRequest {
  constructor( searchText: string ) {

    const params = new URLSearchParams();
    params.set( 'q', searchText );

    super( {
      method: 'GET',
      url: '/search',
      responseType: 'json',
      params: params,
    } );
  }
}

export interface SearchResponse {
  policies: PolicyItem[];
  customers: CustomerMockItem[];
  history: PolicyItem[] | CustomerMockItem[];
}
