import React from 'react';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { IApiService, ApiResponse } from './base';
import {
  Credentials,
  IBaseAuth,
  IFullAuth,
  AuthRequest,
  RefreshTokenRequest,
  TokenResponse,
  parseTokenResponse,
  LogoutRequest,
  IToken,
  IUserInfo,
  ChangePassword,
  VerifyInviteRequest,
  CreateUserRequest,
  IUser,
  VerifyInviteResponse,
  CreateUser,
  ResetPasswordRequest,
  ForgotPasswordRequest,
  ResetPassword,
  VerifyResetPasswordResponse,
  VerifyResetPasswordRequest,
  SamlCredentials,
  LoginSamlRequest,
  SamlLoginLinkRequest,
  SamlLoginLinkResponse,
  VerifyOrgInvitationRequest,
  InvitationResponse,
  CreateOrganization,
  CreateOrganizationRequest,
  CreateOrganizationResponse,
  AuthReadmeRequest,
} from './api/auth/base';
import { IStorage } from 'App/components/utils/providers/AppStorageCtx';
import { accountsStorageKey, appTokenStorageKey } from 'App/components/utils/providers/AppUserProvider';
import { IAppUserMeta } from 'App/types';
import { CreateBankAccount, CreateBankAccountResponse } from './api/payment/interfaces';
import { CreateBankAccountRequest } from './api/payment/requests';

dayjs.extend( utc );

export interface IAuthService {
  login( credentials: Credentials ): Promise<IFullAuth>;
  loginReadme( token: string ): Promise<{ token: string }>;
  refreshToken ( userName: string ): Promise<IFullAuth>;
  logout( userName: string ): Promise<void>;
  clearToken(): Promise<void>;
  resetPassword( request: ResetPassword ): Promise<ApiResponse>;
  changePassword( newPassword: ChangePassword ): Promise<void>;
  createUser( user: CreateUser ): Promise<IUser>;
  forgotPassword( email: string ): Promise<ApiResponse>;
  verifyInvite( token: string ): Promise<VerifyInviteResponse>;
  verifyResetPassword( token: string ): Promise<VerifyResetPasswordResponse>;
  loginSaml( credentials: SamlCredentials ): Promise<IFullAuth>;
  samlLoginLink( tenantSlug: string ): Promise<SamlLoginLinkResponse>;
  createBankAccount( paymentData: CreateBankAccount ): Promise<CreateBankAccountResponse>;
  verifyOrgInvitation( token: string ): Promise<InvitationResponse>;
  createOrganization( payload: CreateOrganization ): Promise<CreateOrganizationResponse>;
}

export interface NewPasswordRequiredError {
  newPasswordRequired: boolean;
}

export class AuthService implements IAuthService {
  protected api: IApiService;
  protected storage: IStorage;
  protected userHolderDict: Record<string, IFullAuth>;

  constructor( api: IApiService, storage: IStorage ) {
    this.api = api;
    this.storage = storage;
    this.userHolderDict = {};
  }

  login( credentials: Credentials ): Promise<IFullAuth> {
    return this.api.request<TokenResponse>( new AuthRequest( credentials ) )
      .then( ( response ) => {
        if ( response.newPasswordRequired ) {
          const error: NewPasswordRequiredError = { newPasswordRequired: true };
          throw error;
        } else {
          return parseTokenResponse( response );
        }
      } )
      .then( async ( auth ) => {
        this.updateAccessToken( auth.token );
        return this.updateAccountStorage( auth );
      } )
      .then( ( auth ) => {
        this.userHolderDict[auth.token.userName] = auth;
        return auth;
      } );
  }

  loginReadme( token: string ): Promise<{ token: string }> {
    return this.api.request( new AuthReadmeRequest( token ) )
      .then( ( response ) => response );
  }

  refreshToken( userName: string ): Promise<IFullAuth> {
    if ( this.userHolderDict[userName] !== undefined ) {
      const auth = this.userHolderDict[userName];
      if ( !this.accessTokenRefreshRequired( auth.token ) ) {
        // we can reuse token from dict without real refresh
        return Promise.resolve( auth );
      }
    }

    return this.api.request<TokenResponse>( new RefreshTokenRequest( userName ) )
      .then( ( token ) => parseTokenResponse( token ) )
      .then( ( auth ) => {
        this.updateAccessToken( auth.token );
        return this.updateAccountStorage( auth );
      } )
      .then( ( auth ) => {
        this.userHolderDict[auth.token.userName] = auth;
        return auth;
      } );
  }

  logout( userName: string ): Promise<void> {
    return this.api.request<void>( new LogoutRequest( userName ) )
      .then( () => {
        this.clearToken();
        return this.removeAccountFromStorage( userName );
      } )
      .then( () => {
        delete this.userHolderDict[userName];
      } );
  }

  clearToken(): Promise<void> {
    this.api.accessToken.current = null;
    return Promise.resolve();
  }

  async resetPassword( passwordDetails: ResetPassword ): Promise<ApiResponse> {
    const response = await this.api.request( new ResetPasswordRequest( passwordDetails ) );

    return response;
  }

  async forgotPassword( email: string ): Promise<ApiResponse> {
    const response = await this.api.request( new ForgotPasswordRequest( email ) );

    return response;
  }

  async verifyResetPassword( token: string ): Promise<VerifyResetPasswordResponse> {
    const response = await this.api.request( new VerifyResetPasswordRequest( token ) );

    return response;
  }

  changePassword( newPassword: ChangePassword ): Promise<void> {
    throw new Error( 'Method changePassword not implemented.' );
  }

  async verifyInvite( token: string ): Promise<VerifyInviteResponse> {
    const response = await this.api.request( new VerifyInviteRequest( token ) );

    return response;
  }

  async createUser( user: CreateUser ): Promise<IUser> {
    const response = await this.api.request( new CreateUserRequest( user ) );

    return response;
  }

  loginSaml( credentials: SamlCredentials ): Promise<IFullAuth> {
    return this.api.request<TokenResponse>( new LoginSamlRequest( credentials ) )
      .then( ( response ) => {
        if ( response.newPasswordRequired ) {
          const error: NewPasswordRequiredError = { newPasswordRequired: true };
          throw error;
        } else {
          return parseTokenResponse( response );
        }
      } )
      .then( async ( auth ) => {
        this.updateAccessToken( auth.token );
        return this.updateAccountStorage( auth );
      } )
      .then( ( auth ) => {
        this.userHolderDict[auth.token.userName] = auth;
        return auth;
      } );
  }

  async samlLoginLink( tenantSlug: string ): Promise<SamlLoginLinkResponse> {
    const response = await this.api.request( new SamlLoginLinkRequest( tenantSlug ) );

    return response;
  }

  async createBankAccount( paymentData: CreateBankAccount ): Promise<CreateBankAccountResponse> {
    const response = await this.api.request( new CreateBankAccountRequest( paymentData ) );

    return response;
  }

  async verifyOrgInvitation( token: string ): Promise<InvitationResponse> {
    const response = await this.api.request( new VerifyOrgInvitationRequest( token ) );

    return response;
  }

  async createOrganization( payload: CreateOrganization ): Promise<CreateOrganizationResponse> {
    const response = await this.api.request( new CreateOrganizationRequest( payload ) );

    return response;
  }

  private accessTokenRefreshRequired( token: IToken ): boolean {
    const now = dayjs().utc();
    const timeToInvalidateToken = token.exp.diff ( now ) - 10 * 1000;
    // we can reuse existing token only if currect token is valid at least for 10 seconds.
    // if less than 10 seconds we will need to refresh token before calling any api request.
    return timeToInvalidateToken < 0;
  }

  private updateAccountStorage( auth : IBaseAuth ): IFullAuth {
    let users = this.storage.get<IAppUserMeta[]>( accountsStorageKey );
    if ( users === null ) {
      users = [];
    }
    const userInfo: IUserInfo = auth.userInfo;
    const userMeta: IAppUserMeta = {
      userName: userInfo.email,
      userFullName: userInfo.given_name && userInfo.family_name ? userInfo.given_name + ' ' + userInfo.family_name : '',
      tenantId: userInfo.tenant_id,
      tenantName: userInfo.tenant_name,
      tenantSlug: userInfo.tenant_slug,
      sub: userInfo.sub,
    };

    let accountIndex = users.findIndex( ( item ) => item.userName === auth.userInfo.email );
    if ( accountIndex === -1 ) {
      // no user in local storage. Create one and put it to the store
      users.push( userMeta );
      accountIndex = users.length - 1;
    } else {
      // replace at index / update eser metadata
      users[accountIndex] = userMeta;
    }

    this.storage.set( accountsStorageKey, users );
    this.storage.set(
      appTokenStorageKey,
      {
        'username': userInfo.email,
        'accessToken': auth.token.value,
      },
    );
    return {
      ...auth,
      accountIndex: accountIndex,
      userMeta: userMeta,
    };
  }

  private removeAccountFromStorage( accountName : string ): void {
    const users = this.storage.get<IAppUserMeta[]>( accountsStorageKey );
    if ( users === null ) {
      return;
    }
    const accountIndex = users.findIndex( ( item ) => item.userName === accountName );
    users.splice( accountIndex, 1 );
    this.storage.set( accountsStorageKey, users );
  }

  private updateAccessToken( token: IToken ): void {
    this.api.accessToken.current = token;
  }
};

//const defaultAuthService: IAuthService = new AuthService( apiService  );
export const AuthServiceContext: React.Context<IAuthService> = React.createContext( undefined as any );
