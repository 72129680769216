import { OptionTypeBase } from 'react-select';

export type SearchItemType = 'policy' | 'customer' | 'view-all-policy' | 'view-all-customer';

export const PERSON_ICON: string = 'feather icon-user text-c-green f-16';
export const CAR_ICON: string = 'fa fa-car text-c-green f-16';
export const HOUSE_ICON: string = 'feather icon-home text-c-purple f-16';
export const SEARCH_ICON: string = 'feather icon-search f-16';

export interface OptionBase extends OptionTypeBase {
  navigation_path: string;
  index: string;
  type: SearchItemType;
}

export interface ViewAllOption extends OptionBase {
  label: string;
}

export interface CustomerInfo {
  first_name: string;
  last_name: string;
  customer_number: number;
  customer_code: string | undefined;
  email: string;
}

export type CustomerInfoType = CustomerInfo;

export interface PolicyOption extends OptionBase {
  policy_number: number;
  product: string;
  status: string;
  customer_info: CustomerInfoType;
}

export type PolicyOptionType = PolicyOption | ViewAllOption;

export interface CustomerOption extends OptionBase {
  customer_number: number;
  first_name: string;
  last_name: string;
  birth_date: string;
  street: string;
  zip_code: string;
  city: string;
  email: string;
}

export type CustomerOptionType = CustomerOption | ViewAllOption;
