import { ApiResponse, BaseRequest, PagingRequest } from 'Services/base';
import { IApiListResponse } from 'Services/policies/new/service';
import {
  IWFItem,
  IWFResponse,
  IWFInstanceResponse,
  IWFVersion,
  IWFVersionResponse,
  IWFPatchRequest,
  ICreateWorkflowRequest,
  IServiceEventRequest,
  IServiceEventResponse,
  IWFInstance,
  IWFInstanceVariablesResponse,
} from './interfaces';

export class ListWFRequest extends BaseRequest<IApiListResponse<IWFItem>> {
  constructor(
    searchValue: string,
    paging: PagingRequest,
    expand?: string[],
  ) {

    const queryParams = new URLSearchParams();
    queryParams.set( 'pageSize', paging.pageSize.toString() );
    queryParams.set( 'pageToken', paging.pageToken.toString() );

    if ( paging.filter.length ) {
      const filterPars = paging.filter.map( ( item ) => `${item.id}=${encodeURIComponent( item.value )}` ).join( '&' );
      queryParams.set( 'filter', filterPars );
    }

    if ( paging.orderBy.length ) {
      const orderPars = paging.orderBy.map( ( item ) => `${item.id}:${ item.desc ? 'DESC' : 'ASC' }` ).join( '&' );
      queryParams.set( 'order', orderPars );
    }

    if ( searchValue ) {
      queryParams.set( 'search', searchValue );
    }

    if ( expand && expand.length > 0 ) {
      const expandPars = expand.map( ( item ) => `${encodeURIComponent( item )}` ).join( ',' );
      queryParams.set( 'expand', expandPars );
    }

    super( {
      method: 'GET',
      url: '/processmanager/v1/workflows',
      responseType: 'json',
      params: queryParams,
    } );
  }
}

export class ListWFVersionRequest extends BaseRequest<IApiListResponse<IWFVersion>> {
  constructor(
    paging: PagingRequest,
    expand?: string[],
  ) {

    const queryParams = new URLSearchParams();
    queryParams.set( 'pageSize', paging.pageSize.toString() );
    queryParams.set( 'pageToken', paging.pageToken.toString() );

    if ( paging.filter.length ) {
      const filterPars = paging.filter.map( ( item ) => `${item.id}=${encodeURIComponent( item.value )}` ).join( '&' );
      queryParams.set( 'filter', filterPars );
    }

    if ( paging.orderBy.length ) {
      const orderPars = paging.orderBy.map( ( item ) => `${item.id}:${ item.desc ? 'DESC' : 'ASC' }` ).join( '&' );
      queryParams.set( 'order', orderPars );
    }

    if ( expand && expand.length > 0 ) {
      const expandPars = expand.map( ( item ) => `${encodeURIComponent( item )}` ).join( ',' );
      queryParams.set( 'expand', expandPars );
    }

    super( {
      method: 'GET',
      url: '/processmanager/v1/workflow_versions',
      responseType: 'json',
      params: queryParams,
    } );
  }
}

export class ListWFInstancesRequest extends BaseRequest<IApiListResponse<IWFInstance>> {
  constructor(
    searchValue: string,
    paging: PagingRequest,
    expand?: string[],
  ) {

    const queryParams = new URLSearchParams();
    queryParams.set( 'pageSize', paging.pageSize.toString() );
    queryParams.set( 'pageToken', paging.pageToken.toString() );

    if ( paging.filter.length ) {
      const filterPars = paging.filter.map( ( item ) => {
        const operator = item.operator || '=';
        return `${item.id}${operator}${item.value}`;
      } ).join( '&' );
      queryParams.set( 'filter', filterPars );
    }

    if ( paging.orderBy.length ) {
      const orderPars = paging.orderBy.map( ( item ) => `${item.id}:${ item.desc ? 'DESC' : 'ASC' }` ).join( '&' );
      queryParams.set( 'order', orderPars );
    }

    if ( expand && expand.length > 0 ) {
      const expandPars = expand.map( ( item ) => `${encodeURIComponent( item )}` ).join( ',' );
      queryParams.set( 'expand', expandPars );
    }

    if ( searchValue ) {
      queryParams.set( 'search', searchValue );
    }

    super( {
      method: 'GET',
      url: '/processmanager/v1/workflow-instances',
      responseType: 'json',
      params: queryParams,
    } );
  }
}

export class GetWFRequest extends BaseRequest<IWFResponse> {
  constructor(
    workFlowCode: string,
    expand?: string[],
  ) {

    const queryParams = new URLSearchParams();

    if ( expand && expand.length > 0 ) {
      const expandPars = expand.map( ( item ) => `${encodeURIComponent( item )}` ).join( ',' );
      queryParams.set( 'expand', expandPars );
    }

    super( {
      method: 'GET',
      url: `/processmanager/v1/workflows/${ workFlowCode }`,
      responseType: 'json',
      params: queryParams,
    } );
  }
}

export class GetWFVersionRequest extends BaseRequest<IWFVersionResponse> {
  constructor(
    versionCode: string,
    expand?: string[],
  ) {
    const queryParams = new URLSearchParams();

    if ( expand && expand.length > 0 ) {
      const expandPars = expand.map( ( item ) => `${encodeURIComponent( item )}` ).join( ',' );
      queryParams.set( 'expand', expandPars );
    }

    super( {
      method: 'GET',
      url: `/processmanager/v1/workflow_versions/${ versionCode }`,
      responseType: 'json',
      params: queryParams,
    } );
  }
}

export class GetWFInstancesRequest extends BaseRequest<IWFResponse> {
  constructor(
    workFlowCode: string,
    paging: PagingRequest,
    expand?: string[],
  ) {

    const queryParams = new URLSearchParams();
    queryParams.set( 'pageSize', paging.pageSize.toString() );
    queryParams.set( 'pageToken', paging.pageToken.toString() );

    if ( paging.filter.length ) {
      const filterPars = paging.filter.map( ( item ) => `${item.id}=${encodeURIComponent( item.value )}` ).join( '&' );
      queryParams.set( 'filter', filterPars );
    }

    if ( paging.orderBy.length ) {
      const orderPars = paging.orderBy.map( ( item ) => `${item.id} ${ item.desc ? 'DESC' : 'ASC' }` ).join( '&' );
      queryParams.set( 'order', orderPars );
    }

    if ( expand && expand.length > 0 ) {
      const expandPars = expand.map( ( item ) => `${encodeURIComponent( item )}` ).join( ',' );
      queryParams.set( 'expand', expandPars );
    }

    super( {
      method: 'GET',
      url: `/processmanager/v1/workflows/${ workFlowCode }`,
      responseType: 'json',
      params: queryParams,
    } );
  }
}

export class CancelInstanceRequest extends BaseRequest<ApiResponse> {
  constructor(
    instanceCode: string,
  ) {

    super( {
      method: 'POST',
      url: `/processmanager/v1/workflow-instances/${ instanceCode }/cancel`,
      responseType: 'json',
    } );
  }
}

export class RetryInstanceRequest extends BaseRequest<ApiResponse> {
  constructor(
    instanceCode: string,
  ) {

    super( {
      method: 'POST',
      url: `/processmanager/v1/workflow-instances/${ instanceCode }/retry`,
      responseType: 'json',
    } );
  }
}

export class GetWFInstanceRequest extends BaseRequest<IWFInstanceResponse> {
  constructor(
    instanceCode: string,
    expand?: string[],
  ) {

    const queryParams = new URLSearchParams();

    if ( expand && expand.length > 0 ) {
      const expandPars = expand.map( ( item ) => `${encodeURIComponent( item )}` ).join( ',' );
      queryParams.set( 'expand', expandPars );
    }

    super( {
      method: 'GET',
      url: `/processmanager/v1/workflow-instances/${ instanceCode }`,
      responseType: 'json',
      params: queryParams,
    } );
  }
}

export class GetWFInstanceVariables extends BaseRequest<IWFInstanceVariablesResponse> {
  constructor(
    workflowCode: string,
    taskKey: string,
  ) {

    super( {
      method: 'GET',
      url: `/processmanager/v1/workflow-instances/${workflowCode}/variables/${taskKey}`,
      responseType: 'json',
    } );
  }
}

export class PatchWFRequest extends BaseRequest<IWFResponse> {
  constructor(
    patchData: IWFPatchRequest,
  ) {
    const { id, ...data } = patchData;
    const formData = new FormData();
    Object.entries<any>( data ).forEach( ( [ k, v ] ) => {
      if ( v ) {
        const value = v instanceof File ? v : v.toString();
        formData.append( k, value );
      }
    } );

    super( {
      method: 'PATCH',
      url: `/processmanager/v1/workflows/${ id }`,
      responseType: 'json',
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' },
    } );
  }
}

export class CreateWFRequest extends BaseRequest<IWFResponse> {
  constructor(
    data: ICreateWorkflowRequest,
  ) {
    const formData = new FormData();
    Object.entries<any>( data ).forEach( ( [ k, v ] ) => {
      if ( v ) {
        const value = v instanceof File || v instanceof Blob ? v : v.toString();
        formData.append( k, value );
      }
    } );

    super( {
      method: 'POST',
      url: '/processmanager/v1/workflows',
      responseType: 'json',
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' },
    } );
  }
}

export class DefineServiceEventRequest extends BaseRequest<IServiceEventResponse> {
  constructor(
    data: IServiceEventRequest,
  ) {

    super( {
      method: 'POST',
      url: '/processmanager/v1/service-event/define',
      responseType: 'json',
      data: data,
    } );
  }
}
