import { BaseRequest, FilterPage, OrderByPage, PagingRequest, ApiResponse } from '../base';
import { IPaymentProviderApiKeys } from 'App/components/management/payment-processing/ui/payment-providers';
import { IApiListResponse } from 'Services/policies/new/service';
import { IApiScenarioAnalysisItem, IScenarioAnalysisItemUpdate,
  ScenarioAnalysisResponse,
  InsuranceCompaniesResponse,
  DatesResponse,
  ChannelListResponse } from './scenario-analysis-interfaces';
import {
  IApiUserDetailsItem, IUserResponse, IInviteUsers, IRoleDetails,
  IApiInvitedUserDetailsItem,
  IUpdateRoles,
  IUserDetailsItem, Organization, IInviteOrganization, IReInviteOrganization, IUpdateOrganization, IOrganizationDetails,
} from './user-interfaces';
import { getQueryParamsForListAPI } from '../api';

// You also need to have request object defined. For all list of props look for the
// IRequestDescriptor interface properties
export class ListUsersRequest extends BaseRequest<UsersResponse> {
  constructor(
    paging: PagingRequest,
    searchValue: string,
  ) {
    super( {
      method: 'GET',
      url: '/tenantservice/v1/users',
      responseType: 'json',
      params: getQueryParamsForListAPI( paging, searchValue ),
    } );
  }
}

export class AddUserRequest extends BaseRequest<UserResponse> {
  constructor(
    user: IAddUserItem,
  ) {

    super( {
      method: 'POST',
      url: '/tenantservice/v1/users',
      data: user,
      responseType: 'json',
    } );
  }
}

export class SetTenantPaymentSettingsRequest extends BaseRequest {
  constructor(
    settings: TenantPaymentSettingsRequest,
  ) {

    super( {
      method: 'POST',
      url: '/tenantservice/v1/settings/tenant',
      data: settings,
      responseType: 'json',
    } );
  }
}

export class GetTenantPaymentSettingsRequest extends BaseRequest<GetTenantPaymentSettingsResponse> {
  constructor(
    key: string,
  ) {

    const params = new URLSearchParams();
    params.set( 'keys', key );

    super( {
      method: 'GET',
      url: '/tenantservice/v1/settings/tenant',
      responseType: 'json',
      params: params,
    } );
  }
}

export class GetTenantSettingsRequest extends BaseRequest<GetTenantSettingsResponse> {
  constructor(
    key: string,
  ) {

    const params = new URLSearchParams();
    params.set( 'keys', key );

    super( {
      method: 'GET',
      url: '/tenantservice/v1/settings/tenant',
      responseType: 'json',
      params: params,
    } );
  }
}

export class SetTenantSettingsRequest extends BaseRequest {
  constructor(
    settings: TenantSettingsRequest,
  ) {

    super( {
      method: 'POST',
      url: '/tenantservice/v1/settings/tenant',
      data: settings,
      responseType: 'json',
    } );
  }
}

export class ListScenarioAnalysisRequest extends BaseRequest<IApiListResponse<IApiScenarioAnalysisItem>> {
  constructor(
    paging: PagingRequest,
    searchValue: string,
  ) {
    super( {
      method: 'GET',
      url: '/pbmservice/v1/scenario-analyses',
      responseType: 'json',
      params: getQueryParamsForListAPI( paging, searchValue ),
    } );
  }
}

export class DeleteScenarioAnalysisRequest extends BaseRequest<ApiResponse> {
  constructor(
    code: string,
  ) {

    super( {
      method: 'DELETE',
      url: `/pbmservice/v1/scenario-analyses/${code}`,
      responseType: 'json',
    } );
  }
}

export class GetScenarioAnalysisRequest extends BaseRequest<ScenarioAnalysisResponse> {
  constructor(
    code: string,
  ) {

    super( {
      method: 'GET',
      url: `/pbmservice/v1/scenario-analyses/${code}`,
      responseType: 'json',
    } );
  }
}

export class CreateScenarioAnalysisRequest extends BaseRequest<ScenarioAnalysisResponse> {
  constructor(
    analysisDetails: IScenarioAnalysisItemUpdate,
  ) {

    super( {
      method: 'POST',
      url: '/pbmservice/v1/scenario-analyses',
      data: analysisDetails,
      responseType: 'json',
    } );
  }
}

export class UpdateScenarioAnalysisRequest extends BaseRequest<ScenarioAnalysisResponse> {
  constructor(
    analysisUpdate: IScenarioAnalysisItemUpdate,
    code: string,
  ) {

    super( {
      method: 'PUT',
      url: `/pbmservice/v1/scenario-analyses/${code}`,
      data: analysisUpdate,
      responseType: 'json',
    } );
  }
}

export class GetInsuranceCompaniesRequest extends BaseRequest<InsuranceCompaniesResponse> {
  constructor(
    productName?: string,
  ) {

    const queryParams = new URLSearchParams();

    if ( productName ) {
      queryParams.set( 'product', productName );
    }

    super( {
      method: 'GET',
      url: '/pbmservice/v1/scenario-analyses/insurance-companies',
      responseType: 'json',
      params: queryParams,
    } );
  }
}

export class GetDatesRequest extends BaseRequest<DatesResponse> {
  constructor(
    productName?: string,
  ) {
    const queryParams = new URLSearchParams();

    if ( productName ) {
      queryParams.set( 'product', productName );
    }

    super( {
      method: 'GET',
      url: '/pbmservice/v1/scenario-analyses/dates',
      responseType: 'json',
      params: queryParams,
    } );
  }
}

export class ListUserRequest extends BaseRequest<IApiListResponse<IApiUserDetailsItem>> {
  constructor(
    paging: PagingRequest,
    searchValue: string,
    expand: string[] = [],
  ) {

    const queryParams = getQueryParamsForListAPI( paging, searchValue, 'search' );

    expand.push( 'roles' );
    const expandPars = expand.map( ( item ) => `${encodeURIComponent( item )}` ).join( ',' );
    queryParams.set( 'expand', expandPars );

    super( {
      method: 'GET',
      url: '/tenantservice/v1/users',
      responseType: 'json',
      params: queryParams,
    } );
  }
}

export class ListOrganizationRequest extends BaseRequest<IApiListResponse<Organization>> {
  constructor(
    paging: PagingRequest,
    searchValue: string,
  ) {

    super( {
      method: 'GET',
      url: '/tenantservice/v1/organizations',
      responseType: 'json',
      params: getQueryParamsForListAPI( paging, searchValue, 'search' ),
    } );
  }
}

export class ListOrganizationInvitationRequest extends BaseRequest<IApiListResponse<Organization>> {
  constructor(
    paging: PagingRequest,
    searchValue: string,
  ) {

    super( {
      method: 'GET',
      url: '/tenantservice/v1/invitations/organizations?expand=roles',
      responseType: 'json',
      params: getQueryParamsForListAPI( paging, searchValue, 'search' ),
    } );
  }
}

export class InviteOrganizationRequest extends BaseRequest<Organization> {
  constructor(
    organization: IInviteOrganization,
  ) {

    super( {
      method: 'POST',
      url: '/tenantservice/v1/invitations/organizations',
      data: organization,
      responseType: 'json',
    } );
  }
}

export class ReInviteOrganizationRequest extends BaseRequest<Organization> {
  constructor(
    data: IReInviteOrganization,
  ) {

    super( {
      method: 'PATCH',
      url: '/tenantservice/v1/invitations/organizations',
      data,
      responseType: 'json',
    } );
  }
}

export class UpdateOrganizationRequest extends BaseRequest<Organization> {
  constructor(
    organization: IUpdateOrganization,
    code: string,
  ) {

    super( {
      method: 'PUT',
      url: `/tenantservice/v1/organizations/${code}`,
      data: organization,
      responseType: 'json',
    } );
  }
}

export class GetOrganizationRequest extends BaseRequest<IOrganizationDetails> {
  constructor(
    code: string,
    expand: string[] = [],
  ) {

    const queryParams = new URLSearchParams();

    if ( expand && expand.length > 0 ) {
      const expandPars = expand.map( ( item ) => `${encodeURIComponent( item )}` ).join( ',' );
      queryParams.set( 'expand', expandPars );
    }

    super( {
      method: 'GET',
      url: `/tenantservice/v1/organizations/${code}`,
      responseType: 'json',
      params: queryParams,
    } );
  }
}

export class ListInvitedUserRequest extends BaseRequest<IApiListResponse<IApiInvitedUserDetailsItem>> {
  constructor(
    paging: PagingRequest,
    searchValue: string,
    expand: string[] = [],
  ) {

    const queryParams = getQueryParamsForListAPI( paging, searchValue, 'search' );

    expand.push( 'roles' );
    const expandPars = expand.map( ( item ) => `${encodeURIComponent( item )}` ).join( ',' );
    queryParams.set( 'expand', expandPars );

    super( {
      method: 'GET',
      url: '/tenantservice/v1/users/invites',
      responseType: 'json',
      params: queryParams,
    } );
  }
}

export class GetUserRequest extends BaseRequest<IUserResponse> {
  constructor(
    code: string,
    expand: string[] = [ 'roles' ],
  ) {

    const queryParams = new URLSearchParams();

    if ( expand && expand.length > 0 ) {
      const expandPars = expand.map( ( item ) => `${encodeURIComponent( item )}` ).join( ',' );
      queryParams.set( 'expand', expandPars );
    }

    super( {
      method: 'GET',
      url: `/tenantservice/v1/users/${code}`,
      responseType: 'json',
      params: queryParams,
    } );
  }
}

export class GetRolesRequest extends BaseRequest<IApiListResponse<IRoleDetails>> {
  constructor(
    pageSize: number = 50,
  ) {
    const queryParams = new URLSearchParams();

    queryParams.set( 'pageSize', `${pageSize}` );

    super( {
      method: 'GET',
      url: '/tenantservice/v1/roles',
      responseType: 'json',
      params: queryParams,
    } );
  }
}

export class InviteUsersRequest extends BaseRequest<ApiResponse> {
  constructor(
    users: IInviteUsers,
  ) {
    super( {
      method: 'POST',
      url: '/tenantservice/v1/users/invites/batch',
      data: users,
      responseType: 'json',
    } );
  }
}

export class DeleteUserRequest extends BaseRequest {
  constructor(
    userId: number,
  ) {

    super( {
      method: 'DELETE',
      url: `/tenantservice/v1/users/${userId}`,
      responseType: 'json',
    } );
  }
}

export class DeleteInviteRequest extends BaseRequest {
  constructor(
    inviteId: number,
  ) {

    super( {
      method: 'DELETE',
      url: `/tenantservice/v1/users/invites/${inviteId}`,
      responseType: 'json',
    } );
  }
}

export class DeleteUsersRequest extends BaseRequest {
  constructor(
    ids: number[],
  ) {

    super( {
      method: 'POST',
      url: '/tenantservice/v1/users/delete-batch',
      responseType: 'json',
      data: {
        ids: ids,
      },
    } );
  }
}

export class DeleteInvitesRequest extends BaseRequest {
  constructor(
    ids: number[],
  ) {

    super( {
      method: 'POST',
      url: '/tenantservice/v1/users/invites/delete-batch',
      responseType: 'json',
      data: {
        ids: ids,
      },
    } );
  }
}

export class AssignRolesRequest extends BaseRequest<IUserResponse> {
  constructor(
    roles: IUpdateRoles,
  ) {
    super( {
      method: 'POST',
      url: `/tenantservice/v1/users/${roles.userId}/assign-roles`,
      data: roles,
      responseType: 'json',
    } );
  }
}

export class GetChannelListRequest extends BaseRequest<ChannelListResponse> {
  constructor(
    productName?: string,
  ) {

    const queryParams = new URLSearchParams();

    if ( productName ) {
      queryParams.set( 'product', productName );
    }

    super( {
      method: 'GET',
      url: '/pbmservice/v1/scenario-analyses/channels',
      responseType: 'json',
      params: queryParams,
    } );
  }
}

export class DisableUserRequest extends BaseRequest<IUserDetailsItem> {
  constructor(
    userId: number,
  ) {

    super( {
      method: 'POST',
      url: `/tenantservice/v1/users/${ userId }/disable`,
      responseType: 'json',
    } );
  }
}

export class DisableUsersRequest extends BaseRequest {
  constructor(
    ids: number[],
  ) {

    super( {
      method: 'POST',
      url: '/tenantservice/v1/users/disable-batch',
      responseType: 'json',
      data: {
        ids: ids,
      },
    } );
  }
}

export class EnableUserRequest extends BaseRequest<IUserDetailsItem> {
  constructor(
    userId: number,
  ) {

    super( {
      method: 'POST',
      url: `/tenantservice/v1/users/${ userId }/enable`,
      responseType: 'json',
    } );
  }
}

export class EnableUsersRequest extends BaseRequest {
  constructor(
    ids: number[],
  ) {

    super( {
      method: 'POST',
      url: '/tenantservice/v1/users/enable-batch',
      responseType: 'json',
      data: {
        ids: ids,
      },
    } );
  }
}

export interface UsersResponse {
  users: IUserItem[];
  nextPageToken: string;
}

export interface IUserItem {
  username: string;
  email: string;
  firstName: string;
  lastName: string;
  sub: string;
  role: string;
  status: string;
}

export interface IAddUserItem {
  email: string;
  firstName: string;
  lastName: string;
  role: string;
  messageAction?: string;
  tempPassword: string;
}

export interface UserResponse {
  user: IUserItem;
}

export interface UserPagingRequest {
  pageSize: number;
  pageToken: string;
  filter: FilterPage[];
  orderBy: OrderByPage[];
}

export interface GetTenantPaymentSettingsResponse {
  values: ITenantPaymentSettingsValue[];
}

export interface TenantPaymentSettingsRequest {
  key: string;
  value: ITenantPaymentSettingsValue;
}

export interface TenantPaymentSettingsResponse extends TenantPaymentSettingsRequest {
  type: string;
}

export interface GetTenantSettingsResponse {
  values: ITenantSettingsValue[];
}

export interface TenantPaymentSettingsRequest {
  key: string;
  value: ITenantPaymentSettingsValue;
}

export interface TenantSettingsRequest {
  key: string;
  value: ITenantSettingsValue;
}

export interface TenantSettingsResponse extends TenantSettingsRequest {
  type: string;
}

export type PaymentMethods = 'paypal' | 'credit_card' | 'maestro' | 'sepa' | 'visa' | 'masterpass' |
'americanExpress' | 'apple_pay' | 'andriod_pay' | 'samsung_pay' | 'sofort' | 'klarna' | 'venmo';

export interface ITenantPaymentSettingsValue {
  apiKeys: IPaymentProviderApiKeys;
  paymentMethods: PaymentMethods[];
}

export const brainTreeConfigKey: string = 'braintree_config';
export const activePSPKey: string = 'active_psp';
export const brokerManagmentVersionKey = 'brokermanagement_version';

export enum BrokerManagmentVersion {
  versionOne = 'v1',
  versionTwo = 'v2',
}

export interface ITenantSettingsValue {
  [key: string]: number | string;
}

export interface ValidationResponse {
  errorCode: number; // 0 means no error
  errorMessages?: ValidationErrorMessages;
}

export interface ValidationErrorMessages {
  accountError: string;
  tokenError: string;
  paymentError: PaymentMethods[];
}
