import React, { useContext } from 'react';

import { ApiContext } from 'Services/api-context';
import { ILeadVersionService, LeadVersionServiceContext } from 'Services/lead-version';
import { ServiceProviderProps } from './common';

export const LeadVersionServiceProvider: React.FC<ServiceProviderProps<ILeadVersionService>> = ( props, context ) => {

  const api = useContext( ApiContext );

  // Create LeadVersionService that depends on api. To create service we are using useClass implementation
  const leadVersionService: ILeadVersionService = new props.useClass( api );

  return (
    <LeadVersionServiceContext.Provider value={ leadVersionService }>
      { props.children }
    </LeadVersionServiceContext.Provider>
  );
};
