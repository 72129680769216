import React, { useCallback, useRef, useState } from 'react';
import Dropzone from 'react-dropzone';
import { Button, Card } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { ClaimDocument } from 'Services/claims/interfaces';
import { InfiniteTable } from '../../../InfiniteTable';
import { useAppAlertService } from '../../../utils/alerts/AppAlertService';
import { useDocumentService } from 'Services/document';
import { ClaimDocumentsColumnDefinition } from './ClaimDocumentColumn';
import { useAppUser } from '../../../utils/providers/AppUserProvider';
import { permissionDict as p } from 'Services/permissions/permission-dict';

export const CLAIM_DOCUMENT_ENTITY_TYPE = 'ClaimDocument';
const ACCEPTED_FILE_TYPES = [ 'image/png', 'image/jpeg', 'image/jpg', 'application/pdf' ];

export const extractContentTypeFromFile = ( file: Blob ): string => {
  const splittedFileType = file.type.split( '/' );
  const lastPartOfMime = splittedFileType[splittedFileType.length - 1];

  if( lastPartOfMime === 'jpeg' ) {
    return 'jpg';
  }

  return lastPartOfMime;
};

interface ClaimDocWithFile extends ClaimDocument {
  file?: Blob;
}

interface ClaimDocumentsProps {
  policyCode: string | null;
  accountCode: string | null;
  claimId: number | null;
}

export const ClaimDocuments: React.FC<ClaimDocumentsProps> = ( {
  policyCode,
  accountCode,
  claimId,
}: ClaimDocumentsProps ) => {
  const { t } = useTranslation( [ 'claim', 'base' ] );
  const user = useAppUser();
  const documentService = useDocumentService();
  const { showAlert, hideAlert } = useAppAlertService();

  const inputFile = useRef<HTMLInputElement>( null );

  const [ claimDocuments, setClaimDocuments ] = useState<ClaimDocWithFile[]>( [] );

  const handleOnClickDocumentDelete = async ( documentCode: string ): Promise<void> => {
    try {
      await documentService.deleteDocumentByCode( documentCode );
      setClaimDocuments(
        claimDocuments.filter( ( d ) => d.code !== documentCode ),
      );
    } catch ( e ) {
      showAlert( {
        message: t( 'base:forms.messages.error' ),
        type: 'danger',
      } );
    }
  };

  const handleOnClickGetDocumentDownloadUrl = async (
    event: React.MouseEvent<HTMLAnchorElement>,
    documentCode: string,
  ): Promise<void> => {
    try {
      event.preventDefault();

      const response = await documentService.downloadDocumentUrl( documentCode );
      const { url } = response;

      window.open( url, '_blank', 'noreferrer' );
    } catch ( e ) {
      showAlert( {
        message: t( 'base:forms.messages.error' ),
        type: 'danger',
      } );
    }
  };

  React.useEffect( () => {
    let isMounted = true;

    const loadDocuments = async () => {
      try {
        if ( claimId === null ) {
          return;
        }

        const docs = await documentService.listClaimDocuments( claimId, 1, [ { id: 'createdAt', desc: true } ] );

        if ( isMounted && docs && docs.items ) {
          setClaimDocuments( [ ...docs.items ] );
        }
      } catch ( e ) {
        showAlert( {
          message: t( 'base:forms.messages.error' ),
          type: 'danger',
        } );
        isMounted = false;
      }
    };

    loadDocuments();

    return () => {
      isMounted = false;
    };
  }, [ claimId, documentService, showAlert, t ] );


  const uploadFiles = async ( acceptedFiles ) => {
    if ( acceptedFiles.length ) {
      hideAlert();
    }

    if ( !accountCode || !policyCode || !claimId ) {
      showAlert( {
        message: t( 'form.error' ),
        type: 'danger',
      } );
      return;
    }

    try {
      const newClaimDocs: ClaimDocWithFile[] = [];
      await Promise.all(
        acceptedFiles.map( async ( file ) => {
          const response = await documentService.getPreSignedRequestUrl( {
            accountCode,
            policyCode,
            description: 'some-description',
            contentType: extractContentTypeFromFile( file ),
            isoContentType: file.type,
            entityType: 'ClaimDocument',
            entityId: claimId,
            requester: 'claimservice',
            templateSlug: 'ClaimDoc',
            filename: file.name,
          } );

          const {
            fields: {
              filename,
              code,
              description,
              entityId,
              entityType,
              key: s3Key,
              contentType,
              templateSlug,
            },
          } = response;

          newClaimDocs.push( {
            accountCode,
            policyCode,
            contentType,
            s3Key,
            code,
            entityType,
            entityId,
            description,
            filename,
            createdAt: new Date().toISOString(),
            templateSlug,
            file,
          } );

          return documentService.uploadDocumentUsingPreSignedUrl(
            response,
            file,
          );
        } ),
      );

      setClaimDocuments( [ ...newClaimDocs, ...claimDocuments ] );
    } catch ( err ) {
      const { error } = err;
      if ( error && error.response ) {

        showAlert( {
          message: t( 'base:forms.messages.error' ),
          type: 'danger',
        } );
      }
    }
  };

  const onDrop = useCallback(
    uploadFiles,
    [
      accountCode,
      claimDocuments,
      claimId,
      documentService,
      hideAlert,
      policyCode,
      showAlert,
      t,
    ],
  );

  const handleOpenFileInput = async ( event ) => {
    const files: File[] = [];
    for( let i = 0; i < event.target.files.length; i++ ) {
      files.push( event.target.files[i] );
    }
    await uploadFiles( files );
  };

  return (
    <Card className="row">
      <Dropzone
        onDrop={ onDrop }
        accept={ [ 'image/png', 'image/jpeg', 'image/jpg', 'application/pdf' ] }
        onDragEnter={ () => {
          document.body.classList.add( 'drop-file-window' );
        } }
        onDragLeave={ () => {
          document.body.classList.remove( 'drop-file-window' );
        } }
        onDropRejected={ () => {
          showAlert( {
            message: t( 'documents.onRejectFileExtension' ),
            type: 'danger',
          } );
        } }
      >
        { ( { getRootProps, isDragActive, isDragAccept, open } ) => {
          return (
            <Card.Body
              className="claim-documents-container col"
              { ...getRootProps() }
            >
              <div
                className="dropzone"
                style={ { display: isDragActive ? 'block' : 'none' } }
              >
                <div className="dz-default dz-message">
                  <span>
                    { isDragActive && isDragAccept
                      ? t( 'documents.acceptDropZone' )
                      : t( 'documents.textDropZoneForClaimDocs' ) }
                  </span>
                  <button
                    className="btn btn-primary mr-0"
                    type="button"
                    onClick={ open }
                  >
                    { t( 'documents.btnChooseFile' ) }
                  </button>
                </div>
              </div>
              <h5 className="mb-3">{ t( 'documents.header' ) }</h5>
              <InfiniteTable<ClaimDocWithFile>
                rowHeight={ 55 }
                columns={
                  ClaimDocumentsColumnDefinition( handleOnClickDocumentDelete, handleOnClickGetDocumentDownloadUrl )
                }
                data={ claimDocuments }
                loadMore={ () => {} }
                hasNextPage={ false }
                moreItemsLoading={ false }
                onChangeSort={ () => {} }
                onResetFilters={ () => {} }
                onChangeFilters={ () => {} }
                initialFilters={ [] }
                initialSortBy={ [] }
                isShowFilters={ false }
                height={ claimDocuments.length <= 4 ? claimDocuments.length * 55 : 335 }
                dynamicallyCalculateTableHeight={ false }
                footer={ true }
              />
              { user.hasPermissions( [ [ p.eis_claims_write ], [ p.eis_claims_edit ] ] ) && (
                <Button className="m-0 p-0 d-flex align-items-center" onClick={ () => {
                  if( inputFile.current ) {
                    inputFile.current.click();
                  }
                } }
                >
                  <i className="material-icons material-icons-outlined f-21 p-10 m-0 ">note_add</i>
                  <input
                    type='file'
                    id='file'
                    ref={ inputFile }
                    style={ { display: 'none' } }
                    onInput={ handleOpenFileInput }
                    accept={ ACCEPTED_FILE_TYPES.join( ',' ) }
                    multiple
                  />
                </Button>
              ) }
            </Card.Body>
          );
        } }
      </Dropzone>
    </Card>
  );
};
