import { BaseRequest, PagingRequest } from '../base';
import { getQueryParamsForListAPI } from '../api';
import { ListItemsResponse } from '../base/interfaces';
import { PatchLead } from './interfaces';

// You also need to have request object defined. For all list of props look for the
// IRequestDescriptor interface properties
type LeadRequestExpandType = 'versions' | 'timelines' | 'product' | 'premiumFormulas';

export class ListLeadsRequest extends BaseRequest<ListItemsResponse> {
  constructor(
    paging: PagingRequest,
    searchValue: string,
    expand: string[] = [ ],
  ) {

    const queryParams = getQueryParamsForListAPI( paging, searchValue, 'search' );

    if ( expand && expand.length > 0 ) {
      const expandPars = expand.map( ( item ) => `${encodeURIComponent( item )}` ).join( ',' );
      queryParams.set( 'expand', expandPars );
    }

    super( {
      method: 'GET',
      url: '/insuranceservice/v1/leads',
      responseType: 'json',
      params: queryParams,
    } );
  }
}

export class GetLeadRequest extends BaseRequest {
  constructor(
    leadCode: string,
    expand: LeadRequestExpandType[] = [ 'versions' ],
  ) {
    let expandPars = '';
    if ( expand.length > 0 ) {
      expandPars = expand.map( ( item ) => `expand=${encodeURIComponent( item )}` ).join( '&' );
    }
    super( {
      method: 'GET',
      url: `/insuranceservice/v1/leads/${leadCode}`,
      responseType: 'json',
      params: expandPars,
    } );
  }
}

export class PatchLeadRequest extends BaseRequest {
  constructor(
    leadCode: string,
    lead: PatchLead,
  ) {

    super( {
      method: 'PATCH',
      url: `/insuranceservice/v1/leads/${leadCode}`,
      responseType: 'json',
      data: lead,
    } );
  }
}


