import React from 'react';
import { PagingRequest, IApiService, ApiResponse } from './base';
import { Logger } from 'loglevel';
import { IApiListResponse } from './policies/new/service';
import { IWorkFlowItem, IWorkFlowData, IWorkFlowInstanceItem, IWorkFlowInstance,
  InstanceIdType, InstanceStatusType } from './workflow/workflow';

export interface IWorkFlowService {
  getWorkFlowItems( paging: PagingRequest, searchQuery: string ): Promise<IApiListResponse<IWorkFlowItem>>;
  deleteWorkFlow( workFlowId: number ): Promise<ApiResponse>;
  getWorkFlow( workFlowCode: string ): Promise<IWorkFlowData>;
  getWorkFlowInstanceItems( paging: PagingRequest, searchQuery: string ):
  Promise<IApiListResponse<IWorkFlowInstanceItem>>;
  getWorkFlowInstance( workFlowId: number ): Promise<IWorkFlowInstanceItem>;
  getInstanceDetails( instanceId: number ): Promise<IWorkFlowInstance>;
  getWorkFlowInstancesbyId( workFlowId: number, paging: PagingRequest,
    statusFilter: InstanceStatusType[] ): Promise<IApiListResponse<IWorkFlowInstance>>;
  actionOnWorkFlowInstance( workFlowId: number, instanceId: number, newState: InstanceStatusType ):
  Promise<ApiResponse>;
  actionOnWorkFlowInstances( workFlowId: number, instances: InstanceIdType[], newState: InstanceStatusType ):
  Promise<ApiResponse>;
}

export class WorkFLowService implements IWorkFlowService {
  protected api: IApiService;
  protected logger: Logger;

  constructor( api: IApiService, logger: Logger ) {
    this.api = api;
    this.logger = logger;
  }

  getWorkFlowItems( paging: PagingRequest, searchQuery: string ): Promise<IApiListResponse<IWorkFlowItem>> {
    throw new Error( 'Method getWorkFlowItems not implemented.' );
  }

  deleteWorkFlow( workFlowId: number ): Promise<ApiResponse> {
    throw new Error( 'Method deleteWorkFlow not implemented.' );
  }

  getWorkFlow( workFlowCode: string ): Promise<IWorkFlowData> {
    throw new Error( 'Method getWorkFlow not implemented.' );
  }

  getWorkFlowInstanceItems( paging: PagingRequest, searchQuery: string ):
  Promise<IApiListResponse<IWorkFlowInstanceItem>> {
    throw new Error( 'Method getWorkFlowMonitorItems not implemented.' );
  }

  getWorkFlowInstance( workFlowId: number ): Promise<IWorkFlowInstanceItem> {
    throw new Error( 'Method getWorkFlowInstance not implemented.' );
  }

  getWorkFlowInstancesbyId( workFlowId: number, paging: PagingRequest,
    statusFilter: InstanceStatusType[] ): Promise<IApiListResponse<IWorkFlowInstance>>{
    throw new Error( 'Method getWorkFlowInstancesbyId not implemented.' );
  }

  actionOnWorkFlowInstance( workFlowId: number, instanceId: number ): Promise<ApiResponse> {
    throw new Error( 'Method restartWorkFlowInstance not implemented.' );
  }

  actionOnWorkFlowInstances( workFlowId: number, instances: InstanceIdType[], newState: InstanceStatusType ):
  Promise<ApiResponse> {
    throw new Error( 'Method retryWorkFlowInstances not implemented.' );
  }

  getInstanceDetails( instanceId: number ): Promise<IWorkFlowInstance> {
    throw new Error( 'Method getInstanceDetails not implemented.' );
  }
};

export const WorkFlowServiceContext: React.Context<IWorkFlowService> = React.createContext( undefined as any );

export const useWorkFlowService = (): IWorkFlowService => {
  return React.useContext( WorkFlowServiceContext );
};
