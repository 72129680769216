import React from 'react';
import { useHistory } from 'react-router-dom';
import { appUserPathPrefix, useAppAccounts } from '../providers/AppUserProvider';

/**
 * This Component calculates main landing page and redirect to it.
 * Landing page depends on local storage contents.
 * If there is an account in local storage then we are trying to redirect to that account.
 * After redirection to the user aware area LandingPageResolver will resolve url.
 */
export const MainLandingPageResolver: React.FC = () => {
  const accounts = useAppAccounts();
  const history = useHistory();
  const landingPage = accounts.length > 0 ? appUserPathPrefix( 0 ) : '/login';

  React.useEffect( () => {
    history.replace( landingPage );
  }, [ history, landingPage ] );

  return (
    <React.Fragment />
  );
};
