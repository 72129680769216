
import React, { Fragment } from 'react';

import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { AppNavLink } from './utils/routing/AppNavLink';

export const NoAccess: React.FC = () => {
  const { t } = useTranslation( [ 'base' ] );
  const { pathname } = useLocation();

  return (
    <Fragment>
      <div className="d-flex align-items-center justify-content-center min-hei-500">
        <div className="text-center">
          <h1 className="mb-4">{ t( 'messages.error' ) } 403</h1>
          <h5 className="text-muted mb-4">{ t( 'messages.noAccess' ) }: <code>{ pathname }</code></h5>
          <AppNavLink
            to={ '/' }
            className="btn btn-primary mb-4 active"
          >
            <i className="feather icon-home" />
            { t( 'messages.backToHome' ) }
          </AppNavLink>
        </div>
      </div>
    </Fragment>
  );
};
export default NoAccess;
