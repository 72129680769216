import { BaseRequest } from 'Services/base';

export class GeneralSettingsRequest extends BaseRequest {
  constructor( ) {

    super( {
      method: 'GET',
      url: '/general',
      responseType: 'json',
    } );
  }
}

export class UpdateGeneralSettingsRequest extends BaseRequest {
  constructor(
    general: GeneralItem,
  ) {

    super( {
      method: 'PUT',
      url: '/general',
      data: general,
      responseType: 'json',
    } );
  }
}

export class BrandSettingsRequest extends BaseRequest {
  constructor( ) {

    super( {
      method: 'GET',
      url: '/branding',
      responseType: 'json',
    } );
  }
}

export class UpdateBrandSettingsRequest extends BaseRequest {
  constructor(
    branding: BrandItem,
  ) {

    super( {
      method: 'PUT',
      url: '/branding',
      data: branding,
      responseType: 'json',
    } );
  }
}

export interface BrandItem {
  tenant_id?: number;
  name: string;
  street_and_number_house: string;
  zip_code: string;
  city: string;
  country: string;
  logo: string;
  main_color: string;
}

export interface GeneralItem {
  tenant_id?: number;
  time_zone: string;
  language: string;
  currency: string;
  format_hours: string;
  format_dates: string;
}

export interface DataResponse {
  message: string;
  success: boolean;
}

export interface GeneralSettingsResponse extends DataResponse {
  item: GeneralItem;
}

export interface BrandSettingsResponse extends DataResponse {
  item: BrandItem;
}
