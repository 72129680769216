import React, { ReactElement, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { Tabs, Tab, DropdownButton } from 'react-bootstrap';
import dayjs from 'dayjs';
import { useTimeFilterDateFormat } from 'App/components/utils/hooks';
import { cloneDeep } from 'lodash';
import RelativeTimeFilter, { RelativeTimeRange } from './filters/timeFilter/RelativeTimeFilter';
import CustomTimeFilter from './filters/timeFilter/CustomTimeFilter';
import TimeFilterControls from './filters/timeFilter/TimeFilterControls';

export interface DocumentsTypeFilterProps {
  filter: TimeFilter;
  onChangeHandler: ( filter: TimeFilter ) => void;
  isDisabled: boolean;
}

export interface TimeFilter {
  startDate: dayjs.Dayjs | undefined;
  endDate: dayjs.Dayjs | undefined;
}

interface RelativeTabState {
  relativeTitle: string;
  relativeTimeFilter: TimeFilter;
}

interface CustomTabState {
  customTitle: string;
  customTimeFilter: TimeFilter;
}

export const isTimeInFilter = ( time: dayjs.Dayjs | Date, timeFilter: TimeFilter ): boolean => {
  if ( ( timeFilter.startDate && timeFilter.startDate.isAfter( time ) ) ||
      ( timeFilter.endDate && timeFilter.endDate.isBefore( time ) )
  ) {
    return false;
  }

  return true;
};

const DocumentsTimeFilter: React.FC<DocumentsTypeFilterProps> =
( props: DocumentsTypeFilterProps ): ReactElement | null => {
  const { t } = useTranslation( [ 'policy' ] );
  const { titleDateFormat } = useTimeFilterDateFormat();
  const [ isChecked, setIsChecked ] = React.useState<boolean>( true );
  const [ isCustomTab, setIsCustomTab ] = React.useState<boolean>( true );
  const [ isRelativeTab, setIsRelativeTab ] = React.useState<boolean>( true );
  const [ isFiltered, setIsFiltered ] = React.useState<boolean>( false );
  const [ selectedTab, setSelectedTab ] = React.useState<string>( '' );
  const { onChangeHandler, isDisabled } = props;
  const initialTitle = React.useMemo( () => {
    const title = t( 'documents.date' );
    return title;
  }, [ t ] );

  const relativeTabInitialState = React.useMemo<RelativeTabState>( () => {
    const result: RelativeTabState = {
      relativeTitle: initialTitle,
      relativeTimeFilter: {
        startDate: undefined,
        endDate: undefined,
      },
    };
    return result;
  }, [ initialTitle ] );

  const customTabInitialState = React.useMemo<CustomTabState>( () => {
    const result: CustomTabState = {
      customTitle: initialTitle,
      customTimeFilter: {
        startDate: undefined,
        endDate: undefined,
      },
    };
    return result;
  }, [ initialTitle ] );

  const [ relativeTabState, setRelativeTabState ] = React.useState<RelativeTabState>( relativeTabInitialState );
  const { relativeTitle, relativeTimeFilter } = relativeTabState;

  const [ customTabState, setCustomTabState ] = React.useState<CustomTabState>( customTabInitialState );
  const { customTitle, customTimeFilter } = customTabState;

  const relativeFilterHandler = React.useCallback( ( ) => {
    onChangeHandler( relativeTimeFilter );
  }, [ onChangeHandler, relativeTimeFilter ] );

  const customFilterHandler = React.useCallback( ( ) => {
    onChangeHandler( customTimeFilter );
  }, [ onChangeHandler, customTimeFilter ] );

  const tabHandler = React.useCallback( ( key: string ) => {
    setSelectedTab( key );
  }, [] );

  const relativeResetHandler = React.useCallback( ( ) => {
    const newState = cloneDeep( relativeTabInitialState );
    setRelativeTabState( newState );
    setIsChecked ( true );
    setIsRelativeTab( true );
    setIsFiltered( false );
    onChangeHandler( newState.relativeTimeFilter );
  }, [ onChangeHandler, relativeTabInitialState ] );

  const customResetHandler = React.useCallback( ( ) => {
    const newState = cloneDeep( customTabInitialState );
    setCustomTabState( newState );
    setIsCustomTab( true );
    setIsFiltered( false );
    onChangeHandler( newState.customTimeFilter );
  }, [ customTabInitialState, onChangeHandler ] );

  const timeRange : RelativeTimeRange = React.useMemo( () => {
    const now = dayjs();
    const today = now.startOf( 'day' );
    const range = {
      today,
      weekStart: today.subtract( 1, 'weeks' ).startOf( 'week' ),
      weekEnd: today.subtract( 1, 'weeks' ).endOf( 'week' ),
      startOfMonth: today.startOf( 'month' ),
      endOfMonth: today.endOf( 'month' ),
      previousMonthStart: today.subtract( 1, 'month' ).startOf( 'month' ),
      previousMonthEnd: today.subtract( 1, 'month' ).endOf( 'month' ),
    };

    return range;
  }, [] );

  const { today, weekStart, weekEnd, startOfMonth, endOfMonth, previousMonthStart, previousMonthEnd } = timeRange;

  const dateRangeHandler = React.useCallback( ( e: React.ChangeEvent<HTMLInputElement> ) => {
    const fieldValue: string = e.currentTarget.value;

    setIsChecked ( false );
    setIsRelativeTab( false );
    setIsFiltered( true );

    if ( fieldValue === 'today' ) {
      setRelativeTabState( { ...relativeTabState,
        relativeTimeFilter: { startDate: today, endDate: undefined },
        relativeTitle: today.format( titleDateFormat ),
      } );
    }

    if ( fieldValue === 'lastWeek' ) {
      setRelativeTabState( { ...relativeTabState,
        relativeTimeFilter: { startDate: weekStart, endDate: weekEnd },
        relativeTitle: `${ weekStart.format( titleDateFormat ) } - ${ weekEnd.format( titleDateFormat ) }`,
      } );
    }

    if ( fieldValue === 'currentMonth' ) {
      setRelativeTabState( { ...relativeTabState,
        relativeTimeFilter: { startDate: startOfMonth, endDate: endOfMonth },
        relativeTitle: `${ startOfMonth.format( titleDateFormat ) } - ${ today.format( titleDateFormat ) }`,
      } );
    }

    if ( fieldValue === 'lastMonth' ) {
      setRelativeTabState( { ...relativeTabState,
        relativeTimeFilter: { startDate: previousMonthStart, endDate: previousMonthEnd },
        relativeTitle: `${ previousMonthStart.format( titleDateFormat ) }
        - ${ previousMonthEnd.format( titleDateFormat ) }`,
      } );
    }

    if ( fieldValue === 'all' ) {
      setRelativeTabState( { ...relativeTabState,
        relativeTimeFilter: { startDate: undefined, endDate: undefined },
        relativeTitle: `${ t( 'typeDict.all' ) }`,
      } );
      setIsChecked ( true );
    }
  }, [ previousMonthEnd, previousMonthStart, relativeTabState,
    startOfMonth, endOfMonth, t, titleDateFormat, today, weekEnd, weekStart ] );

  const datePickerHandler = React.useCallback( ( sDate: dayjs.Dayjs, eDate?: dayjs.Dayjs ) => {
    const newState = cloneDeep( customTabInitialState );
    if( sDate.isValid() ) {
      setCustomTabState( { ...customTabState,
        customTimeFilter: { startDate: sDate, endDate: eDate },
        customTitle: eDate ? `${ sDate.format( titleDateFormat ) } - ${ eDate.format( titleDateFormat ) }` :
          sDate.format( titleDateFormat ),
      } );
      setIsCustomTab( false );
    } else {
      setCustomTabState( newState );
      setIsCustomTab( true );
    }
    setIsFiltered( true );
  }, [ customTabInitialState, customTabState, titleDateFormat ] );

  return (
    <Fragment>
      <DropdownButton
        id="documents-time-filter"
        className={
          isFiltered ? 'time-filter-button filtered' : 'time-filter-button'
        }
        title={ selectedTab === 'custom' ? customTitle : relativeTitle }
        disabled={ isDisabled && !isFiltered }
      >
        <Tabs
          className="time-filter-container"
          defaultActiveKey="relative"
          id="time-filter-container"
          onSelect={ tabHandler }
        >
          <Tab eventKey="relative" title={ t ( 'documents.relative' ) } >
            <RelativeTimeFilter
              dateRangeHandler={ dateRangeHandler }
              isChecked={ isChecked }
              timeRange={ timeRange }
            />
          </Tab>
          <Tab eventKey="custom" title={ t ( 'documents.custom' ) }>
            <CustomTimeFilter
              datePickerHandler={ datePickerHandler }
              timeFilter={ customTimeFilter }
              isDisabled={ isCustomTab }
            />
          </Tab>
        </Tabs>
        <TimeFilterControls
          selectedTab={ selectedTab }
          isCustomTab={ isCustomTab }
          isRelativeTab={ isRelativeTab }
          customResetHandler={ customResetHandler }
          customFilterHandler={ customFilterHandler }
          relativeResetHandler={ relativeResetHandler }
          relativeFilterHandler={ relativeFilterHandler }
        />
      </DropdownButton>
    </Fragment>
  );
};

export default DocumentsTimeFilter;
