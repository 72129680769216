import p0 from 'assets/avatars/Profile_0.svg';
import p1 from 'assets/avatars/Profile_1.svg';
import p2 from 'assets/avatars/Profile_2.svg';
import p3 from 'assets/avatars/Profile_3.svg';
import p4 from 'assets/avatars/Profile_4.svg';
import p5 from 'assets/avatars/Profile_5.svg';
import p6 from 'assets/avatars/Profile_6.svg';
import p7 from 'assets/avatars/Profile_7.svg';
import p8 from 'assets/avatars/Profile_8.svg';
import p9 from 'assets/avatars/Profile_9.svg';

export const profileAvatars : string[] = [ p0, p1, p2, p3, p4, p5, p6, p7, p8, p9 ];
