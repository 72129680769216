import React, { Fragment } from 'react';
import dayjs from 'dayjs';
import * as yup from 'yup';
import { Form, Button, Row, FormGroup, Col, FormControl } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import { websiteTitle } from 'config';
import { SubmitHandler, useForm, FormProvider, Controller } from 'react-hook-form';
import { ActiveStep, OrganizationState, useOrgActions } from '../OrganizationRegistration';
import { generateCustomField } from '../hooks/organization-hooks';
import Select from 'react-select';
import { useAppDefaultStyles } from '../../../../Services/stylings';
import CalendarInput from 'App/components/utils/calendar/CalendarInput';

export interface CustomDataFormValues {
  email: string;
  firstName: string;
  lastName: string;
  newPassword: string;
  confirmPassword: string;
  isEulaAccepted: boolean | undefined;
}

interface CustomDataFormProps {
  initData: OrganizationState;
}

const defaultValueMap = {
  string: '',
  number: 0,
  boolean: false,
  array: [],
  email: '',
  date: '',
};

const styleHideBox = {
  height: '0px',
  overflow: 'hidden',
  background: 'transparent',
};

export const CustomDataForm: React.FC<CustomDataFormProps> = ( { initData } ) => {
  const { t } = useTranslation( [ 'registration', 'base' ] );
  const actions = useOrgActions();
  const formValues = initData[ActiveStep.CustomData];
  const { customSchema } = initData;

  const typeToValidationSchemaMap = ( fieldLabel, minLen, maxLen ) => ( {
    string: yup.string()
      .nullable()
      .transform( ( v, o ) => ( o === '' ? null : v ) )
      .min( minLen,
        t( 'base:forms.messages.minLenError',
          { fieldLabel: fieldLabel, len: minLen } ) )
      .max( maxLen,
        t( 'base:forms.messages.maxLenError',
          { fieldLabel: fieldLabel, len: maxLen } ) ),
    email: yup.string().email(),
    number: yup.number()
      .notRequired()
      .nullable()
      .transform( ( v ) => Number.isNaN( v ) ? null : v ),
    boolean: yup.boolean(),
    array: yup.array(),
  } );

  const validationSchema = yup.object().shape(
    customSchema?.fields.items?.reduce( ( schema, cf ) => {
      const validationSchemaForType = typeToValidationSchemaMap(
        cf.label || cf.name,
        cf.minLength,
        cf.maxLength,
      )[cf.type] || yup.mixed();

      if ( cf.isRequired ) {
        schema[cf.name] = validationSchemaForType
          .required( t( 'base:forms.messages.fieldRequired',
            { fieldLabel: cf.label || cf.name } ) );

        if ( cf.type === 'number' ) {
          schema[cf.name] = validationSchemaForType
            .required( t( 'base:forms.messages.fieldRequired',
              { fieldLabel: cf.label || cf.name } ) )
            .min( cf.minimum,
              t( 'base:forms.messages.minError',
                { fieldLabel: cf.label || cf.name, count: cf.minimum } ) )
            .max( cf.maximum,
              t( 'base:forms.messages.maxError',
                { fieldLabel: cf.label || cf.name, count: cf.maximum } ) )
            .typeError( t( 'base:forms.messages.typeErrorNumber',
              { fieldLabel: cf.label || cf.name } ) )
            .nullable();
        }
      } else {
        if ( cf.type === 'number' ) {
          schema[cf.name] = validationSchemaForType.notRequired()
            .min( cf.minimum,
              t( 'base:forms.messages.minError',
                { fieldLabel: cf.label || cf.name, count: cf.minimum } ) )
            .max( cf.maximum,
              t( 'base:forms.messages.maxError',
                { fieldLabel: cf.label || cf.name, count: cf.maximum } ) )
            .typeError( t( 'base:forms.messages.typeErrorNumber',
              { fieldLabel: cf.label || cf.name } ) )
            .nullable();
        } else {
          schema[cf.name] = validationSchemaForType.notRequired();
        }
      }

      return schema;
    }, {} ) || {},
  );

  const formContext = useForm( {
    mode: 'onChange',
    shouldUnregister: false,
    defaultValues: initData[ActiveStep.CustomData],
    resolver: yupResolver( validationSchema ),
  } );

  const { control, handleSubmit, formState, getValues, trigger, errors, setValue } = formContext;

  const getOptionValue = React.useCallback( ( option: any ) => {
    return option.key;
  }, [ ] );

  const getOptionLabel = React.useCallback( ( option: any ) => {
    return option.value;
  }, [ ] );

  const onSubmit: SubmitHandler<Object> = React.useCallback( async ( fields ) => {
    await trigger();
    if ( !formState.isValid ) {
      return;
    }

    const data: Object = generateCustomField( fields, customSchema, formState.dirtyFields );
    actions.goToStep( ActiveStep.UserData, ActiveStep.CustomData, data );
  }, [ actions, customSchema, formState.dirtyFields, formState.isValid, trigger ] );

  const defaultStyles = useAppDefaultStyles();
  const customStyles = React.useMemo( () => {
    return {
      control: ( styles, { isDisabled } ) => {
        return {
          ...styles,
          backgroundColor: defaultStyles.bgColorSelected,
          padding: '2.5px',
          cursor: isDisabled ? 'not-allowed' : 'default',
        };
      },
      option: ( styles, { isFocused, isDisabled } ) => {
        return {
          ...styles,
          backgroundColor: isFocused ? defaultStyles.bgColorSelected : null,
          color: isDisabled ? '#888' : defaultStyles.textColor,
          cursor: isDisabled ? 'not-allowed' : 'default',
        };
      },
    };
  }, [ defaultStyles.bgColorSelected, defaultStyles.textColor ] );

  const getDefaultValue = React.useCallback( ( customField ): string => {

    const result = formValues && formValues[customField.name] ?
      formValues[customField.name] : defaultValueMap[customField.type];

    if ( result && customField.type === 'date' ) {
      return dayjs( result ).toISOString();
    }

    return result ? result : '';
  }, [ formValues ] );

  const handleChangeDate = React.useCallback( ( date: string, nameField: string, isRequired: boolean ): void => {
    let valueDate: string = '';
    if ( date ) {
      valueDate = dayjs( date ).toISOString();
    }

    setValue( nameField, valueDate, { shouldValidate: isRequired } );
  }, [ setValue ] );

  const clearDate = React.useCallback( ( nameField: string ) => {
    setValue( nameField, '', { shouldValidate: true } );
  }, [ setValue ] );

  const goBack = React.useCallback( (): void => {
    let fields = getValues();
    if( !formState.isValid ) {
      fields = formValues;
    }

    const data: Object = generateCustomField( fields, customSchema, formState.dirtyFields );
    actions.goToStep( ActiveStep.OrgData, ActiveStep.CustomData, data );
  }, [ actions, customSchema, formState.dirtyFields, formState.isValid, formValues, getValues ] );

  return (
    <div className="card">
      <div className="card-body">
        <div className="mb-4 text-center">
          <i className="feather icon-unlock auth-icon" />
        </div>
        <h3 className="mb-4 text-center">{ websiteTitle }</h3>
        <p className="mb-4 text-center f-14">{ t( 'registration:organizations.navTitle' ) } 2/3</p>
        <p className="mb-4 text-muted f-14">{ t( 'registration:organizations.steps.customPageDescription' ) }</p>
        <FormProvider { ...formContext }>
          <Form noValidate onSubmit={ handleSubmit( onSubmit ) }>
            <Row>
              { customSchema && customSchema.fields && customSchema.fields.items && (
                <Fragment>
                  { customSchema.fields.items.map( ( customField, idx ) => (
                    <Col sm={ 12 } key={ idx }>
                      {
                        customField.type === 'object' ? (
                          <Controller
                            name={ customField.name }
                            control={ control }
                            render={ ( props ) => (
                              <FormGroup controlId={ props.name }>
                                <Form.Label>{ customField.label || customField.name }</Form.Label>
                                <div className="app-field-control">
                                  <Select
                                    { ...props }
                                    className="app-field-select state-new"
                                    classNamePrefix="select"
                                    isClearable={ true }
                                    isMulti={ false }
                                    styles={ customStyles }
                                    isSearchable={ true }
                                    name={ props.name }
                                    options={ customField.options ? customField.options : undefined }
                                    getOptionValue={ getOptionValue }
                                    formatOptionLabel={ getOptionLabel }
                                    placeholder={ t( 'base:forms.choose' ) }
                                  />
                                </div>
                                <Form.Control
                                  type="hidden"
                                  name={ props.name }
                                />
                              </FormGroup>
                            ) }
                          />
                        ) : (
                          <Controller
                            key={ idx }
                            defaultValue={ getDefaultValue( customField ) }
                            name={ customField.name }
                            rules={ { required: customField.isRequired } }
                            control={ control }
                            render={ ( props ) => (
                              <FormGroup
                                className={ customField.type === 'date' ? 'date-picker-field' : '' }
                                controlId={ props.name }
                              >
                                <Form.Label>{ customField.label || customField.name }</Form.Label>
                                { customField.type === 'date' ? (
                                  <>
                                    <CalendarInput
                                      isRenderInputIcon={ true }
                                      onChangeDate={
                                        ( date: string ) => handleChangeDate(
                                          date, customField.name, customField.isRequired,
                                        )
                                      }
                                      isShowingTimeFormat={ false }
                                      isCloseOnSelect={ true }
                                      valueProps={ props.value }
                                      inputProps={
                                        {
                                          placeholder: `${t( 'base:placeholder.date' )}`,
                                          readOnly: true,
                                          name: customField.name,
                                          required: customField.isRequired,
                                        }
                                      }
                                    />
                                    { props.value && (
                                      <Button className="clear-date-button bg-transparent"
                                        type="button" variant="light" onClick={ () => clearDate( customField.name ) }
                                      >
                                        <i className="feather icon-x mr-1"></i>
                                      </Button>
                                    ) }
                                  </>
                                ) : (
                                  <FormControl
                                    { ...props }
                                    className="mt-0"
                                    type={ customField.type }
                                    isInvalid={ !!errors[customField.name] }
                                    placeholder={ customField.label || customField.name }
                                  />
                                ) }
                                <FormControl.Feedback type="invalid">
                                  { errors[customField.name]?.message }
                                </FormControl.Feedback>
                                { /* Fix for disabled autocomplete for input type email of form  */ }
                                <div style={ styleHideBox }>
                                  <input
                                    type={ customField.type }
                                    required={ false }
                                  />
                                </div>
                              </FormGroup>
                            ) }
                          />
                        )
                      }
                    </Col>
                  ) ) }
                </Fragment>
              ) }
            </Row>
            <Row className="mt-2">
              <Col md={ 12 } className="d-flex justify-content-center m-0 p-0 mb-2">
                <Button
                  variant="primary"
                  type="submit"
                  className="m-0"
                  disabled={ formState.isSubmitting }
                >
                  { t( 'registration:organizations.navButton.nextBtn' ) }
                </Button>
              </Col>
              <Col md={ 12 } className="d-flex justify-content-center m-0 p-0">
                <Button
                  type="button"
                  variant="link"
                  className="m-0"
                  onClick={ goBack }
                >
                  { t( 'registration:organizations.navButton.previousBtn' ) }
                </Button>
              </Col>
            </Row>
          </Form>
        </FormProvider>
      </div>
    </div>
  );
};
