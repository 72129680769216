import React, { ReactElement } from 'react';
import { blankLink } from 'config';
import { Dropdown } from 'react-bootstrap';
import { useAppCurrentProduct, useAppUserProducts } from 'App/components/utils/providers/AppProductProvider';
import { useAppUserPathPrefix } from 'App/components/utils/providers/AppUserProvider';
import { useTranslation } from 'react-i18next';

export const NavProductsSelector: React.FC = () => {
  const { t } = useTranslation( [ 'base' ] );
  const userProducts = useAppUserProducts();
  const currentProdct = useAppCurrentProduct();
  const prefix = useAppUserPathPrefix();
  const dropdownItems = React.useMemo<ReactElement[]>( () => {
    return userProducts.map( ( p ): ReactElement => {
      const productHasMenuItems = p.menuItems.length > 0 && p.menuItems[0] !== undefined;
      const isActive = p.id === currentProdct.id;
      const productLandingPage = productHasMenuItems ? prefix + p.menuItems[0].url! : blankLink;
      return (
        <Dropdown.Item
          id={ `product-${p.id}` }
          key={ p.id }
          href={ productLandingPage }
          className="d-flex justify-content-start align-items-center"
          disabled={ !productHasMenuItems }
          active={ isActive }
        >
          <img src='/assets/logos/emil_group.png' alt='' className="dropdown-item-img mr-1" />
          <span>{ p.name }</span>
        </Dropdown.Item>
      );
    } );
  }, [ currentProdct.id, prefix, userProducts ] );

  return (
    <Dropdown className="drp-product-selector">
      <Dropdown.Toggle variant={ 'link' } id="dropdownProductSelector">
        <i className="material-icons material-icons-outlined align-vertical-middle">apps</i>
      </Dropdown.Toggle>
      <Dropdown.Menu alignRight className="profile-notification min-wid-230 emil-styled pb-3" id="productMenu">
        <Dropdown.Header>{ t( 'navigation.switchTo' ) }</Dropdown.Header>
        { dropdownItems }
      </Dropdown.Menu>
    </Dropdown>
  );
};
