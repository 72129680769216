import React from 'react';
import { useAppRealApi } from 'Services/api-context';
import { IDashboardService, DashboardServiceContext } from 'Services/dashboard';
import { ServiceProviderProps } from './common';

export const DashboardServiceProvider: React.FC<ServiceProviderProps<IDashboardService>> = ( props, context ) => {

  const api = useAppRealApi();

  // Create dashboardService that depends on api. To create service we are using useClass implementation
  const dashboardService: IDashboardService = new props.useClass( api );

  return (
    <DashboardServiceContext.Provider value={ dashboardService }>
      { props.children }
    </DashboardServiceContext.Provider>
  );
};
