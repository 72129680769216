import React, { useContext } from 'react';

import { ApiContext } from 'Services/api-context';
import { ILeadStatusService, LeadStatusServiceContext } from 'Services/lead-status';
import { ServiceProviderProps } from './common';

export const LeadStatusServiceProvider: React.FC<ServiceProviderProps<ILeadStatusService>> = ( props, context ) => {

  const api = useContext( ApiContext );

  // Create LeadStatusService that depends on api. To create service we are using useClass implementation
  const leadStatusService: ILeadStatusService = new props.useClass( api );

  return (
    <LeadStatusServiceContext.Provider value={ leadStatusService }>
      { props.children }
    </LeadStatusServiceContext.Provider>
  );
};
