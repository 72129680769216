import o0 from 'assets/avatars/Organization_0.svg';
import o1 from 'assets/avatars/Organization_1.svg';
import o2 from 'assets/avatars/Organization_2.svg';
import o3 from 'assets/avatars/Organization_3.svg';
import o4 from 'assets/avatars/Organization_4.svg';
import o5 from 'assets/avatars/Organization_5.svg';
import o6 from 'assets/avatars/Organization_6.svg';
import o7 from 'assets/avatars/Organization_7.svg';
import o8 from 'assets/avatars/Organization_8.svg';
import o9 from 'assets/avatars/Organization_9.svg';

export const organizationAvatars : string[] = [ o0, o1, o2, o3, o4, o5, o6, o7, o8, o9 ];
