export enum Provider {
  QuickSight = 'quick-sight'
}

export enum Category {
  Management = 'management',
  PremiumBenchmarking = 'premium-benchmarking',
}

export enum DateName {
  TagSingle = 'TagSingle',
  WocheSingle = 'WocheSingle',
  JahrSingle = 'JahrSingle'
}
