import React, { useContext } from 'react';
import { ApiContext } from 'Services/api-context';
import { IClaimStatusService, ClaimStatusServiceContext } from 'Services/claim-status';
import { ServiceProviderProps } from './common';

export const ClaimStatusServiceProvider: React.FC<ServiceProviderProps<IClaimStatusService>> = ( props, context ) => {

  const api = useContext( ApiContext );

  // Create ClaimStatusService that depends on api. To create service we are using useClass implementation
  const claimStatusService: IClaimStatusService = new props.useClass( api );

  return (
    <ClaimStatusServiceContext.Provider value={ claimStatusService }>
      { props.children }
    </ClaimStatusServiceContext.Provider>
  );
};
