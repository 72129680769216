import React from 'react';
import { IAppPermissionDict, PermissionType } from './permissions/interfaces';
import { permissionDict } from './permissions/permission-dict';


export const hasRequiredPermissions = (
  allPermissions: PermissionType[],
  requiredPermissions: PermissionType[] ): boolean => {

  // If user have all Permissions
  const adminIdx = allPermissions.findIndex( ( pr ) => pr === permissionDict.admin );
  if ( adminIdx !== -1 ) {
    return true;
  }

  if ( requiredPermissions.length === 0 ) {
    return true;
  }

  return requiredPermissions.every( ( checkingPermission ) => {
    // split prefix
    const prefixEndsAtIndexOld = checkingPermission.indexOf( ':' );

    if ( prefixEndsAtIndexOld !== -1 ) {
      const prefix = checkingPermission.substring( 0, prefixEndsAtIndexOld );
      const idx = allPermissions
        .filter( ( ptf ) => ptf.startsWith( prefix ) )
        .findIndex( ( ptt ) => {
          return ptt === `${prefix}:*` || ptt === checkingPermission;
        } );
      return idx !== -1;
    }

    const splitPermission = checkingPermission.split( '.' );

    if( splitPermission && splitPermission.length >= 2 ) {
      const module = splitPermission[0];
      const entity = splitPermission[1];
      const permissionIdx = allPermissions
        .filter( ( prs ) => prs.startsWith( module ) )
        .findIndex( ( pr ) => {
          return pr === `${module}.*`
        || pr === `${module}.${entity}.*`
        || pr === checkingPermission;
        } );
      return permissionIdx !== -1;
    }

    return false;
  } );
};

export const AppPermissionDictContext: React.Context<IAppPermissionDict> = React.createContext( permissionDict );

export const useAppPermissionDict = (): IAppPermissionDict => {
  return React.useContext( AppPermissionDictContext );
};
