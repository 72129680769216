/* eslint-disable max-len */
import { BrandItem, GeneralItem } from './base';

const BrandSettingsData: BrandItem = {
  tenant_id: 2,
  name: 'EMIL Admin',
  street_and_number_house: 'Ackerstraße 15',
  zip_code: '10115',
  city: 'Berlin',
  country: 'DE',
  logo: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACMAAAAjCAYAAAAe2bNZAAADT0lEQVRYR+1WS0hUYRg9dx7Xd+n4fhJmWpoIgoG6SAxaFBVktQgswmUQUS7atYyoXYtaRFSbFrroCSaINSOoYItZiKGCiJUP8B2OzjgznW/oDldS770OhIU//HCZO/f7z3/O+R5KgscTDmN3LEV1u3cPmDgyEyIxyi4gR9kDs4UK/xczgVAIYW5IGmhboQNl2+1QbTbTbtwRM+s8NBQMAuvrSE1MRHNWFk6kpaEyKQkuhwPz/P3TwgJax8Ywt7oK1ek0BcgUGL/cXA6XxWeHquJWQQFauTMNDro4NIT26WmoBGm0DMEIkIbUVNzMz8f3tTWcSU9HYVycUdwN7xN7erBGNh0i3TbLGEwggPbKSjRlZFgCoP9zy/Awnk1OQqWHYgPj96O/pgbHUlL+iNMxN4enU1N4PTuLIFkTAx8ki97qaiTpDj7u9cK9uGhoZmNmKFNRfDzelZfjMM2qzw6lowNISICNGaPdOUBvuSjjbG1tBPxngmgYGICTPlNilUkCRgzMm1+gb9oISlt2jyfSRuy6Q8JkR9L9SWkpHlMa7/y8KSAS05AZ7WA/b3yavnlfUREFI8b0MY1l2SiLZtAIIMk+MuYkUCNGtICWwJwlmDc6MA63Gy25uSijVHfHx7EiaW8gRUwG1jPTnJODl2Vlm8YTadTubqgW014fzDwzlKO1qAgPiou3vJzS1fX3wDwsKcFtVt3N1quZGVweHDRd+jeLYZ4ZFr8XzKQr2dnROIX9/fi2soL9lCZI0/oolbTFAJ+tGNe6gQnmQ1UVTrlcUTBKZyccNK82KUaAENAh1qORpSXTKb0jMB8J5qQeDD0iTdP2O4OkHu1jii/W1+MrGTvS22sJkCWZnlOmqzqZztEjb1nYIB1ZZhmCGSOQA6zYsi6xY7fRS0Y9yTozLGJNmZlo11VgCSK96cvyMo5ylrmel7fBl3c4z9xn/TEzPliqwJGqyqYZaGw0Xdjy+/rwg9+YnfZMyyTIxZxS2n/W1SHBYBy4MTqKRxMTllLdEhgNUJi3PU9JrtE/1cnJSKNnpBWM+Hzo5rh5jyCWhRGT46Zlz+jNoHVmMWxkR6Oxh7M5KtIgLQziMYHZrtnF8s6yTLEcZvTtHpitGNpj5p9g5hcgtK8zDdCw8gAAAABJRU5ErkJggg==',
  main_color: '#1dc4e9',
};

export const mockedBrandSettings: BrandItem = BrandSettingsData;

const GeneralSettingsData: GeneralItem = {
  tenant_id: 2,
  time_zone: 'Europe/Berlin',
  language: 'de',
  currency: 'EUR',
  format_hours: 'hh:mm:ss',
  format_dates: 'DD.MM.YYYY',
};

export const mockedGeneralSettings: GeneralItem = GeneralSettingsData;
