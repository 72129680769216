import { BaseRequest } from 'Services/base';

export class UsersRequest extends BaseRequest {
  constructor(
    searchUser: string,
    filterByRole: string,
  ) {

    const params = new URLSearchParams();
    params.set( 'searchUser', searchUser );
    params.set( 'filterByRole', filterByRole );

    super( {
      method: 'GET',
      url: '/users',
      responseType: 'json',
      params: params,
    } );
  }
}

export class AddUserRequest extends BaseRequest {
  constructor(
    user: UserItem,
  ) {

    super( {
      method: 'POST',
      url: '/users',
      data: user,
      responseType: 'json',
    } );
  }
}

export class UpdateUserRequest extends BaseRequest {
  constructor(
    user: UserItem,
  ) {

    super( {
      method: 'PUT',
      url: '/users',
      data: user,
      responseType: 'json',
    } );
  }
}

export class UserRequest extends BaseRequest {
  constructor(
    userId: number,
  ) {

    super( {
      method: 'GET',
      url: `/users/${userId}`,
      responseType: 'json',
    } );
  }
}

export class UserByEmailRequest extends BaseRequest {
  constructor(
    userEmail: string,
  ) {

    const params = new URLSearchParams();
    params.set( 'userEmail', userEmail );

    super( {
      method: 'GET',
      url: '/users',
      responseType: 'json',
      params: params,
    } );
  }
}

export class DeleteUserRequest extends BaseRequest {
  constructor(
    userId: number,
  ) {

    super( {
      method: 'DELETE',
      url: `/users/${userId}`,
      responseType: 'json',
    } );
  }
}

export type EventUser = 'edit' | 'add' | 'save' | 'remove' | 'cancel';
export type StatusUser = 'active' | 'inactive' | 'pending' | 'invited' | 'disabled';

export interface UserItem {
  created_data?: string;
  user_id?: number;
  user_name: string;
  user_email: string;
  role?: string;
  status?: StatusUser;
  lastlogin?: string;
  password?: string;
  avatar?: string;
}

export interface NotificationsItem {
  calendarInvitation: boolean | undefined;
  customerMessage: boolean | undefined;
  newUser: boolean | undefined;
  newTask: boolean | undefined;
  taskBlocked: boolean | undefined;
}

export interface DataResponse {
  message: string;
  success: boolean;
}

export interface UsersResponse {
  items: UserItem[];
}

export interface NotificationsResponse extends DataResponse {
  item: NotificationsItem;
}

export interface ServerResponse {
  data: DataResponse;
}

export interface UserResponse extends DataResponse {
  item: UserItem;
}

export interface IUserDetailsItem {
  id: number;
  userName: string;
  email: string;
  status: StatusUser;
  mfaAuthentication: boolean;
  roles?: IRoleNames[];
  groups?: IGroupNames[];
}

export interface IUpdateUsers {
  users: string[];
  mfaAuthentication?: boolean;
  roles?: IRoleNames[];
  groups?: IGroupNames[];
}

export type IRoleNames = 'admin' | 'productManager' | 'ccAgent' | 'developer' | 'businessAnalyst';
export type IGroupNames = 'admin' | 'management' | 'customerCare' | 'development' | 'sales' | 'bi';
