/* eslint-disable no-template-curly-in-string */
import { Ref, StringSchema, ValidationError, TestContext } from 'yup';
import { ValidationMethodType } from './base';
import { IApiService } from 'Services/base';
import { sleep } from 'App/utils';
import { parsePhoneNumber } from 'libphonenumber-js/max';

export const equalTo: ValidationMethodType<StringSchema> = function (
  ref: Ref,
  message: string = '${path} ${value} is not equal to ${referenceValue}',
) {
  return this.test( {
    name: 'equalTo',
    exclusive: true,
    test: function( value ) {
      const referenceValue = this.resolve( ref );
      if ( value === referenceValue ) {
        return true;
      } else {
        return this.createError( { message: message, params: { referenceValue: referenceValue } } );
      }
    },
  } );
};
/**
 * Create validator that is dependent on external apiService.
 * @param api
 */
export const emailExists: ( api: IApiService ) => ValidationMethodType<StringSchema> = ( api ) => {

  // dynamically create validation method because this method need to use some external context like
  // apiService
  const method: ValidationMethodType<StringSchema> = function (
    message: string = '${value} already exists in our system',
  ) {
    return this.test( {
      name: 'emailExists',
      exclusive: true,
      test: function( value ) {

        // Here we can make request to the backend to check if value exists in api
        // Yup will wait for the results when we return Promise from this method
        /*
        const request = new IsEmailExistValidationRequest( value );
        api.request<IsEmailExistValidationResponse>( request ).then( ( response ) => {
          if ( response.isOk ) {
            return true;
          } else {
            // Create Error based on some response content
            const error = this.createError( {
              message: response.validationMessage || message,
              params: { errorCode: response.errorCode },
            } );
            return error;
          }
        } );
        */

        const validatePromise = new Promise<boolean | ValidationError>( ( resolve ) => {
          // simulate backend validation.
          // suppose backend returned validationMessage and errorCode and we want to use it in our error message
          // under form field instead of message provided in schemaRecipe
          if ( value === 'admin@emil.de' ) {
            const validationMessage: string = 'Email already taken. Error code: ${errorCode}';
            const errorCode: number = 4321;
            const error = this.createError( { message: validationMessage, params: { errorCode: errorCode } } );
            resolve( error );
          } else {
            // email is ok after backend check
            resolve( true );
          }
        } );

        const validatePromiseWithSleep = sleep( 200 ).then( () => {
          return validatePromise;
        } );

        return validatePromiseWithSleep;
      },
    } );
  };

  return method;
};

export const isPhoneNumber = function ( this: any, message: string ) {
  return this.test( {
    name: 'isPhoneNumber',
    message,
    test: function ( value: string ) {
      const { path, createError } = this as TestContext;

      if ( !value ) {
        return true;
      }

      try {
        const parsedPhoneNumber = parsePhoneNumber( value );

        return parsedPhoneNumber.isValid() || createError( { path, message } );
      } catch ( error ) {
        return createError( { path, message } );
      }
    },
  } );
};
