import React from 'react';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { ApiResponse, IApiService, PagingRequest } from './base';
import {
  CustomerPaymentItem,
  AddProfileItem,
  ProfileResponse,
  PaymentCustomerResponse,
  CustomerItem,
  CustomerResponse,
  UpdateCustomerItem,
} from './customers/interfaces';
import { GetCustomerByCodeRequest, ListCustomersRequest, UpdateCustomerRequest } from './customers/requests';
import { pamentMockData, customerMockData } from './customers/mockData';
import { IItemService, ListItemsResponse } from './base/interfaces';
import { ListPaymentMethodsResponse, AccountPayment } from './payment-method/interfaces';
import { ListPaymentMethodRequest } from './payment-method/payment-methods';
import { LeadItem } from './leads/interfaces';
import { GetLeadRequest } from './leads/leads';

dayjs.extend( utc );

export interface ICustomerService extends IItemService {
  getCustomerByCustomerCode( customerCode: string ): Promise<CustomerItem>;
  addCustomer( customer: AddProfileItem ): Promise<ProfileResponse>;
  updateCustomer( updatedCustomer: UpdateCustomerItem, accountCode: string ): Promise<CustomerItem>;
  deleteCustomer( customerId: number ): Promise<ApiResponse>;
  getPaymentByCustomerId( customerId: number ): Promise<CustomerPaymentItem>;
  getPaymentMethods( pageParams: PagingRequest ): Promise<AccountPayment[]>;
  addPayment( customerId: number, payment: CustomerPaymentItem ): Promise<PaymentCustomerResponse>;
  updatePayment( customerId: number, payment: CustomerPaymentItem ): Promise<PaymentCustomerResponse>;
  deletePayment( customerId: number ): Promise<ApiResponse>;
}

export class CustomerService implements ICustomerService {
  protected api: IApiService;
  constructor( api: IApiService ) {
    this.api = api;
  }

  getItemsData( paging: PagingRequest, searchValue: string ): Promise<ListItemsResponse> {
    return this.api.request( new ListCustomersRequest( paging, searchValue ) )
      .then( ( response ) => {
        let customerData : CustomerItem[] = [];
        const items = response.items as CustomerItem[];
        const nextPageToken = response.nextPageToken;

        if ( items ) {
          customerData = items.map( ( item, index ) => {
            if ( item.birthDate ) {
              item.birthDate = dayjs( item.birthDate ).utc();
            }

            return item;
          } );
        }

        const res: ListItemsResponse = {
          items: customerData,
          nextPageToken: items ? items.length < paging.pageSize ? '1' : nextPageToken : nextPageToken,
        };

        return res;
      } );
  }

  async getLead( leadCode: string ): Promise<LeadItem> {
    try {
      const response = await this.api.request( new GetLeadRequest( leadCode, [] ) );
      const { lead } = response;
      if ( lead && lead.account && lead.account.birthDate ) {
        lead.account.birthDate = dayjs( lead.account.birthDate ).utc();
      }

      return lead;
    } catch ( e ) {
      throw new Error( 'not found' );
    }
  }

  getPaymentByCustomerId( customerId: number ): Promise<CustomerPaymentItem> {
    // TODO Implement later because backend is not ready yet
    /*
    const result = this.api.request<CustomerPaymentItem>(
      new GetPaymentByCustomerIdRequest( customerId ) )
      .then( ( response ) => {

      } );
    */
    const resPayment: CustomerPaymentItem = pamentMockData;
    return Promise.resolve( resPayment );
  }
  getCustomerByCustomerCode( customerCode: string ): Promise<CustomerItem> {
    return this.api.request<CustomerResponse>( new GetCustomerByCodeRequest( customerCode ) )
      .then( ( response ) => {
        const { account } = response;
        if ( account.birthDate ) {
          account.birthDate = dayjs( account.birthDate ).utc();
        }
        const customerDetails = {
          ...account,
          birthDate: account.birthDate,
          createdAt: dayjs( account.createdAt ).utc(),
          updatedAt: dayjs( account.updatedAt ).utc(),
        };

        return customerDetails;
      } );
  }

  addCustomer( customer: AddProfileItem ): Promise<ProfileResponse> {
    // TODO Implement later because backend is not ready yet
    /*
    const result = this.api.request<ProfileResponse>( new AddCustomerRequest( customer ) )
      .then( ( response ) => {

      } );
    */
    const res: ProfileResponse = {
      item: customerMockData,
      message: 'Ok',
      success: true,
    };
    return Promise.resolve( res );
  }

  updateCustomer( updatedCustomer: UpdateCustomerItem, accountCode: string ): Promise<CustomerItem> {
    return this.api.request<CustomerResponse>( new UpdateCustomerRequest( updatedCustomer, accountCode ) )
      .then( ( response ) => {
        const { account } = response;

        let customerDetails = {
          ...account,
          createdAt: dayjs( account.createdAt ),
          updatedAt: dayjs( account.updatedAt ),
        };

        if ( account.birthDate ) {
          customerDetails.birthDate = dayjs( account.birthDate );
        }

        return customerDetails;
      } );
  }

  deleteCustomer( customerId: number ): Promise<ApiResponse> {
    // TODO Implement later because backend is not ready yet
    /*
    const result = this.api.request<ApiResponse>(
      new DeleteCustomerRequest( customerId ) )
      .then( ( response ) => {

      } );
    */
    const res: ApiResponse = {
      message: 'Ok',
      success: true,
    };
    return Promise.resolve( res );
  }

  getPaymentMethods( pageParams: PagingRequest ): Promise<AccountPayment[]> {
    const res = this.api.request<ListPaymentMethodsResponse>(
      new ListPaymentMethodRequest( pageParams ) )
      .then( ( response ) => {
        const items = response.items as AccountPayment[];

        let accountPayments: AccountPayment[] = [];

        if ( items && items.length > 0 ) {
          accountPayments = items.map( ( item, index ) => {
            item.createdAt = dayjs( item.createdAt ).utc();

            return item;
          } );
        }

        return accountPayments.sort( ( a, b ) => b.createdAt.unix() - a.createdAt.unix() );
      } );

    return res;
  }

  addPayment( customerId: number, payment: CustomerPaymentItem ): Promise<PaymentCustomerResponse> {
    // TODO Implement later because backend is not ready yet
    /*
    const result = this.api.request<PaymentCustomerResponse>(
      new AddPaymentRequest( customerId, payment ) )
      .then( ( response ) => {

      } );
    */

    const resPayment: PaymentCustomerResponse = {
      item: pamentMockData,
      message: 'Ok',
      success: true,
    };
    return Promise.resolve( resPayment );
  }

  updatePayment( customerId: number, payment: CustomerPaymentItem ): Promise<PaymentCustomerResponse> {
    // TODO Implement later because backend is not ready yet
    /*
    const customerId = customer.customer_id;
    const result = this.api.request<PaymentCustomerResponse>(
      new UpdatePaymentRequest( customerId, payment ) )
      .then( ( response ) => {

      } );
    */
    const resPayment: PaymentCustomerResponse = {
      item: pamentMockData,
      message: 'Ok',
      success: true,
    };
    return Promise.resolve( resPayment );
  }

  deletePayment( customerId: number ): Promise<ApiResponse> {
    // TODO Implement later because backend is not ready yet
    /*
    const result = this.api.request<ApiResponse>(
      new DeletePaymentRequest( customerId ) )
      .then( ( response ) => {

      } );
    */
    const res: ApiResponse = {
      message: 'Ok',
      success: true,
    };
    return Promise.resolve( res );
  }
};

export const CustomerServiceContext: React.Context<ICustomerService> = React.createContext( undefined as any );

// TODO: We need to refactor naming becuase this actuallly AccountService not CustomerService according
// to our naming conventions
export const useCustomerService = (): ICustomerService => {
  return React.useContext( CustomerServiceContext );
};
