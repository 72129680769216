import React from 'react';
import {
  ITicketFilter,
  ITicket,
  TicketFilterType,
  ListTicketsResponse,
  ITicketComment,
  TicketStatusType,
  TicketIdType,
} from './tickets';
import { sleep } from 'App/utils';
import { defaultDateFormat } from 'App/ui-utils';
import { useAppLogger } from 'Services/logger';
import { cloneDeep, orderBy } from 'lodash';
import { useAppUser } from 'App/components/utils/providers/AppUserProvider';
import { TicketServiceContext, ITicketService } from 'Services/ticket';
import { PagingRequest, OrderByPage, ApiError, ApiResponse } from 'Services/base';
import { mockedTickets, mockedFilters, mockedTicketComments } from './mock-data';
import { Logger } from 'loglevel';
import { UserItem } from 'Services/api/users/base';
import { IAppUser } from 'App/types';

export class MockedTicketService implements ITicketService {

  private logger: Logger;
  private currentUser: IAppUser;
  private tickets: ITicket[];
  private ticketComments: ITicketComment[];
  private filters: ITicketFilter[];
  /**
   * Newly created tickets will get this id in this mocked service
   */
  private nextTicketId: TicketIdType = 0;
  /**
   * Newly created comments will get this id in this mocked service
   */
  private nextCommentId: number = 0;

  constructor( logger: Logger, user: IAppUser ) {
    this.logger = logger;
    this.currentUser = user;
    this.tickets = mockedTickets.items;
    this.ticketComments = mockedTicketComments.item;
    this.filters = mockedFilters;
    this.updateFiltersCount();
    this.calculateNextIdsForService();
  }

  private calculateNextIdsForService(): void {
    const ticketIds = this.tickets.map( ( t ) => t.id );
    this.nextTicketId = Math.max( ...ticketIds ) + 1;

    const commentIds = this.ticketComments.map( ( t ) => t.id );
    this.nextCommentId = Math.max( ...commentIds ) + 1;
  }

  getFilters( ): Promise<ITicketFilter[]> {
    return sleep ( 500 )
      .then( () => {
        return cloneDeep( this.filters );
      } );
  }

  getTickets( filterBy: TicketFilterType, searchQuery: string, paging: PagingRequest ): Promise<ListTicketsResponse> {
    const result = sleep ( 500 )
      .then( () => {

        let tickets: ITicket[] = this.filterByStatus( filterBy );
        const input = searchQuery.toLowerCase();

        if ( input.length > 0 ) {
          // Filtering based on searchQuery - case insensitive
          tickets = tickets.filter( ( t ) => {
            const index = `${t.subject} ${t.description} ${t.category} ` +
              `${t.id} ${t.timeCreated.format( defaultDateFormat )}`;
            return index.toLowerCase().includes ( input );
          } );
        }

        tickets = this.orderTickets( paging.orderBy, tickets );
        tickets = tickets.slice( paging.pageToken, paging.pageToken + paging.pageSize );

        tickets = cloneDeep ( tickets );

        const res: ListTicketsResponse = {
          items: tickets,
          next_page_token: tickets.length < paging.pageSize ? '0' : ( paging.pageToken + paging.pageSize ).toString(),
        };
        return res;
      } );

    return result;
  }

  createTicket( newTicket: ITicket ): Promise<ITicket> {
    const ticket = cloneDeep( newTicket );
    ticket.id = this.nextTicketId;

    this.nextTicketId += 1;
    this.tickets.push( ticket );
    this.updateFiltersCount();

    const ct = sleep( 500 ).then( () => {
      return ticket;
    } );

    return ct;
  }

  private updateFiltersCount(): void {
    this.filters.forEach( ( item, index ) => {
      const tickets: ITicket[] = this.filterByStatus( item.name );
      item.count = tickets.length;
    } );
  }

  getTicketById( ticketId: TicketIdType ): Promise<ITicket> {
    return sleep ( 500 )
      .then( () => {
        let foundTicket = this.tickets.find( ( t ) => t.id === ticketId );
        if ( foundTicket !== undefined ) {
          return cloneDeep ( foundTicket );
        } else {
          throw new ApiError( `Ticket with id: ${ticketId} not found` );
        }
      } );
  }

  getCommentsForTicketId( ticketId: TicketIdType ): Promise<ITicketComment[]> {
    return sleep ( 500 )
      .then( () => {
        let foundComments = this.ticketComments.filter( ( t ) => t.ticketId === ticketId );

        return cloneDeep ( foundComments );
      } );
  }

  createComment( newComment: ITicketComment ): Promise<ITicketComment> {
    const comment = cloneDeep( newComment );

    comment.id = this.nextCommentId;
    this.nextCommentId += 1;

    this.ticketComments.push( comment );

    const cc = sleep( 500 ).then( () => {
      return comment;
    } );

    return cc;
  }

  updateTicket( updateTicket: ITicket ): Promise<ITicket> {
    return sleep ( 500 )
      .then( () => {
        const foundIndex = this.tickets.findIndex( ( item ) => item.id === updateTicket.id );

        if ( foundIndex >= 0 ) {
          this.tickets[foundIndex] = updateTicket;
          this.updateFiltersCount();

          return cloneDeep ( updateTicket );
        } else {
          throw new ApiError( `Failed to update ticket: Ticket id ${updateTicket.id} not found` );
        }
      } );
  }

  deleteComment( ticketId: TicketIdType, commentId: number ): Promise<ApiResponse> {
    return sleep ( 500 )
      .then( () => {
        const commentCount = this.ticketComments.length;
        this.ticketComments = this.ticketComments.filter( ( t ) => t.id !== commentId );
        const updatedCommentCount = this.ticketComments.length;

        if ( updatedCommentCount < commentCount ) {
          const apiRes: ApiResponse = {
            message: 'Ok',
            success: true,
          };

          return apiRes;
        } else {
          throw new ApiError( `Failed to delete comment: Ticket with id ${ticketId} not found` );
        }
      } );
  }

  deleteTickets( tickets: TicketIdType[] ): Promise<ApiResponse> {
    return sleep ( 500 )
      .then( () => {
        const ticketsCount = this.tickets.length;
        this.tickets = this.tickets.filter( ( t ) => !tickets.includes( t.id ) );
        const updatedTicketsCount = this.tickets.length;

        if ( updatedTicketsCount < ticketsCount ) {
          this.updateFiltersCount();

          const apiRes: ApiResponse = {
            message: 'Ok',
            success: true,
          };

          return apiRes;
        } else {
          throw new ApiError( `Failed to delete tickets: Ticket with id ${tickets} not found` );
        }
      } );
  }

  assignTo( tickets: TicketIdType[], user: UserItem ): Promise<ApiResponse>{
    return sleep ( 500 )
      .then( () => {
        const foundTicket = this.tickets.filter( ( t ) => tickets.includes( t.id ) );

        if ( foundTicket.length > 0 ) {
          foundTicket.forEach( ( item ) => {
            item.assignedTo = user;
          } );

          this.updateFiltersCount();

          const apiRes: ApiResponse = {
            message: 'Ok',
            success: true,
          };

          return apiRes;
        } else {
          throw new ApiError( `Failed to assign tickets: Ticket with id ${tickets} not found` );
        }
      } );
  }

  markAs( tickets: TicketIdType[], newState: TicketStatusType ): Promise<ApiResponse>{
    return sleep ( 500 )
      .then( () => {
        const foundTicket = this.tickets.filter( ( t ) => tickets.includes( t.id ) );

        if ( foundTicket.length > 0 ) {
          foundTicket.forEach( ( item ) => {
            item.status = newState;
          } );

          this.updateFiltersCount();

          const apiRes: ApiResponse = {
            message: 'Ok',
            success: true,
          };

          return apiRes;
        } else {
          throw new ApiError( `Failed to update status: Ticket with id ${tickets} not found` );
        }
      } );
  }

  moveTo( tickets: TicketIdType[], newCategory: string ): Promise<ApiResponse>{
    return sleep ( 500 )
      .then( () => {
        const foundTicket = this.tickets.filter( ( t ) => tickets.includes( t.id ) );

        if ( foundTicket.length > 0 ) {
          foundTicket.forEach( ( item ) => {
            item.category = newCategory;
          } );

          this.updateFiltersCount();

          const apiRes: ApiResponse = {
            message: 'Ok',
            success: true,
          };

          return apiRes;
        } else {
          throw new ApiError( `Failed to update category: Ticket with id ${tickets} not found` );
        }
      } );
  }

  private filterByStatus( filterBy: TicketFilterType ): ITicket[] {
    if ( !filterBy ) {
      return this.tickets;
    }

    let tickets: ITicket[] = this.tickets;
    tickets = tickets.filter( ( t ) => {
      if ( filterBy === 'all_unsolved' ) {
        return t.status !== 'solved'; // return all tickets that are not solved
      }
      if ( filterBy === 'unassigned' ) {
        return t.assignedTo === undefined; // return all tickets that are not assigned
      }
      if ( filterBy === 'assigned_to_me' ) {
        return t.assignedTo?.user_email === this.currentUser.userName;
      }
      return t.status === filterBy;
    } );
    return tickets;
  }

  private orderTickets( sorted: OrderByPage[], items: ITicket[] ): ITicket[] {
    if ( !sorted.length ) {
      return items;
    }

    return orderBy(
      items,
      sorted.map( ( sort ) => {
        return ( item ) => {
          if ( item[sort.id] === null || item[sort.id] === undefined ) {
            return -Infinity;
          }
          return typeof item[sort.id] === 'string'
            ? item[sort.id].toLowerCase()
            : item[sort.id];
        };
      } ),
      sorted.map( ( d ) => ( d.desc ? 'desc' : 'asc' ) ),
    );
  }

}

export const MockedTicketServiceProvider: React.FC = ( props ) => {
  //const api = useContext( ApiContext );
  const logger = useAppLogger();
  const user = useAppUser();
  const service: ITicketService = new MockedTicketService( logger, user );

  return (
    <TicketServiceContext.Provider value={ service }>
      { props.children }
    </TicketServiceContext.Provider>
  );
};
