import React from 'react';
import { IApiService } from './base';
import { BrandItem, BrandSettingsResponse, GeneralSettingsResponse, GeneralItem } from './api/settings/base';
import { mockedBrandSettings, mockedGeneralSettings } from './api/settings/mock-data';

export interface ISettingsService {
  getGeneralSettings( ): Promise<GeneralItem>;
  updateGeneralSettings( generalSettings: GeneralItem ): Promise<GeneralSettingsResponse>;
  getBrandSettings( ): Promise<BrandItem>;
  updateBrandSettings( brandSettings: BrandItem ): Promise<BrandSettingsResponse>;
}

export class SettingsService implements ISettingsService {

  protected api: IApiService;

  constructor( api: IApiService ) {
    this.api = api;
  }

  getGeneralSettings( ): Promise<GeneralItem> {
    // TODO Implement later because backend is not ready yet
    /*
    const result = this.api.request<GeneralItem>( new GeneralSettingsRequest( ) )
      .then( ( response ) => {

      } );
    */
    return Promise.resolve( mockedGeneralSettings );
  }

  updateGeneralSettings( generalSettings: GeneralItem ): Promise<GeneralSettingsResponse> {
    // TODO Implement later because backend is not ready yet
    /*
    const result = this.api.request<GeneralSettingsResponse>( new GeneralSettingsRequest( generalSettings ) )
      .then( ( response ) => {

      } );
    */
    const res: GeneralSettingsResponse = {
      item: mockedGeneralSettings,
      message: 'Ok',
      success: true,
    };
    return Promise.resolve( res );
  }

  getBrandSettings( ): Promise<BrandItem> {
    // TODO Implement later because backend is not ready yet
    /*
    const result = this.api.request<BrandItem>( new BrandSettingsRequest( ) )
      .then( ( response ) => {

      } );
    */
    return Promise.resolve( mockedBrandSettings );
  }

  updateBrandSettings( brandSettings: BrandItem ): Promise<BrandSettingsResponse> {
    // TODO Implement later because backend is not ready yet
    /*
    const result = this.api.request<BrandSettingsResponse>( new BrandSettingsRequest( brandSettings ) )
      .then( ( response ) => {

      } );
    */
    const res: BrandSettingsResponse = {
      item: mockedBrandSettings,
      message: 'Ok',
      success: true,
    };
    return Promise.resolve( res );
  }

};

export const SettingsServiceContext: React.Context<ISettingsService> = React.createContext( undefined as any );
