import { UserItem, UsersResponse, NotificationsItem, IUserDetailsItem } from './base';

export const UsersData: UserItem[] = [
  {
    user_id: 1,
    user_name: 'Emil Mustermann',
    user_email: 'demo@emil.de',
    password: 'password',
    role: 'admin',
    status: 'active',
    lastlogin: 'Online',
  },
  {
    user_id: 2,
    user_name: 'Emil Mustermann',
    user_email: 'qa@emil.de',
    password: 'password',
    role: 'admin',
    status: 'active',
    lastlogin: 'Online',
  },
  {
    user_id: 3,
    user_name: 'Name Vorname 2',
    user_email: 'emil2@email.com',
    role: 'admin',
    status: 'active',
    lastlogin: 'Online',
  },
  {
    user_id: 4,
    user_name: 'Name Vorname 3',
    user_email: 'mustermann3@email.com',
    role: 'developer',
    status: 'active',
    lastlogin: '02.01.2020',
  },
  {
    user_id: 5,
    user_name: 'Name Vorname 4',
    user_email: 'emil_mustermann@emil.com',
    role: 'insurer',
    status: 'active',
    lastlogin: '03.01.2020',
  },
  {
    user_id: 6,
    user_name: 'Name Vorname 5',
    user_email: 'demo@email.com',
    role: 'showOnly',
    status: 'active',
    lastlogin: '04.01.2020',
  },
  {
    user_id: 7,
    user_name: 'Name Vorname 6',
    user_email: 'emil_muster@emil.de',
    role: 'showOnly',
    status: 'active',
  },
  {
    user_id: 8,
    user_name: 'Name Vorname 7',
    user_email: 'eMuster_7@email.de',
    role: 'showOnly',
    status: 'active',
    lastlogin: '06.01.2020',
  },
];

const UserData: UserItem = {
  user_id: 1,
  user_name: 'Emil Mustermann',
  user_email: 'demo@emil.de',
  role: 'admin',
  status: 'active',
  lastlogin: 'Online',
  password: '123456',
};

export const mockedUsers: UsersResponse = {
  items: UsersData,
};

export const mockedUser: UserItem = UserData;

export const notificationData = {
  calendarInvitation: true,
  customerMessage: false,
  newUser: false,
  newTask: true,
  taskBlocked: false,
};

export const mockedNotification: NotificationsItem = notificationData;

export const mockedUsersData: IUserDetailsItem[] = [
  {
    id: 1,
    userName: 'Emil Mustermann',
    email: 'demo@emil.de',
    status: 'active',
    mfaAuthentication: true,
  },
  {
    id: 2,
    email: 'wilton99@gmail.com',
    userName: 'Frank Huber',
    status: 'active',
    mfaAuthentication: true,
  },
  {
    id: 3,
    email: 'adolph_Quitzon62@hotmail.com',
    userName: 'Viorel Gehrke',
    status: 'active',
    mfaAuthentication: false,
  },
  {
    id: 4,
    email: 'karelle.Johnson@gmail.com',
    userName: 'Dennis Wagenschwanz',
    status: 'disabled',
    mfaAuthentication: false,
  },
  {
    id: 5,
    email: 'george20@gmail.com',
    userName: 'Andree Deniz',
    status: 'active',
    mfaAuthentication: false,
  },
  {
    id: 6,
    userName: 'Betty Ciftci',
    email: 'qa@emil.de',
    status: 'active',
    mfaAuthentication: true,
  },
  {
    id: 7,
    userName: '',
    email: 'emil2@email.com',
    status: 'invited',
    mfaAuthentication: false,
  },
  {
    id: 8,
    userName: '',
    email: 'mustermann3@email.com',
    status: 'invited',
    mfaAuthentication: true,
  },
];
