import i1 from 'assets/dashboard-icons/icon_1.svg';
import i2 from 'assets/dashboard-icons/icon_2.svg';
import i3 from 'assets/dashboard-icons/icon_3.svg';
import i4 from 'assets/dashboard-icons/icon_4.svg';
import i5 from 'assets/dashboard-icons/icon_5.svg';
import i6 from 'assets/dashboard-icons/icon_6.svg';
import i7 from 'assets/dashboard-icons/icon_7.svg';
import i8 from 'assets/dashboard-icons/icon_8.svg';
import i9 from 'assets/dashboard-icons/icon_9.svg';
import i10 from 'assets/dashboard-icons/icon_10.svg';

export const dashboardIcons: string[] = [ i1, i2, i3, i4, i5, i6, i7, i8, i9, i10 ];
