import React, { useContext } from 'react';

import { ApiContext } from 'Services/api-context';
import { ServiceProviderProps } from './common';
import { BillingServiceContext, IBillingService } from 'Services/billing';

export const BillingServiceProvider: React.FC<ServiceProviderProps<IBillingService>> = ( props, context ) => {

  const api = useContext( ApiContext );
  const billingService: IBillingService = new props.useClass( api );

  return (
    <BillingServiceContext.Provider value={ billingService }>
      { props.children }
    </BillingServiceContext.Provider>
  );
};
