import React from 'react';
import { sleep } from 'App/utils';
import { useAppLogger } from 'Services/logger';
import { cloneDeep, orderBy } from 'lodash';
import { PagingRequest, OrderByPage, ApiResponse, ApiError } from 'Services/base';
import { Logger } from 'loglevel';
import { IApiListResponse } from 'Services/policies/new/service';
import { mockedWorkFLowItems, mockedWorkFlowData,
  mockedWorkFlowInstanceItems, mockedWorkFlowInstanceList } from './mock-data';
import { IWorkFlowItem, IWorkFlowData, IWorkFlowInstanceItem, IWorkFlowInstance,
  InstanceStatusType, InstanceIdType } from './workflow';
import { IWorkFlowService, WorkFlowServiceContext } from 'Services/workflow';

export class MockedWorkFlowService implements IWorkFlowService {
  private logger: Logger;
  private workFlowItems: IWorkFlowItem[];
  private workFlowData: IWorkFlowData[];
  private workFlowInstanceItems: IWorkFlowInstanceItem[];
  private workFlowInstanceList: IWorkFlowInstance[];

  constructor( logger: Logger ) {
    this.logger = logger;
    this.workFlowItems = mockedWorkFLowItems;
    this.workFlowData = mockedWorkFlowData;
    this.workFlowInstanceItems = mockedWorkFlowInstanceItems;
    this.workFlowInstanceList = mockedWorkFlowInstanceList;
  }

  getWorkFlowItems( paging: PagingRequest, searchQuery: string ): Promise<IApiListResponse<IWorkFlowItem>> {
    const result = sleep ( 500 )
      .then( () => {
        let workFlowItems: IWorkFlowItem[] = this.workFlowItems;
        const input = searchQuery.toLowerCase();
        if ( input.length > 0 ) {
          // Filtering based on searchQuery - case insensitive
          workFlowItems = workFlowItems.filter( ( v ) => {
            const index = `${v.id} ${v.name} `;
            return index.toLowerCase().includes ( input );
          } );
        }

        workFlowItems = this.orderWorkFlowItems( paging.orderBy, workFlowItems );
        workFlowItems = workFlowItems.slice( paging.pageToken, paging.pageToken + paging.pageSize );
        workFlowItems = cloneDeep ( workFlowItems );

        const res:IApiListResponse<IWorkFlowItem> = {
          items: workFlowItems,
          nextPageToken: workFlowItems.length < paging.pageSize ? '0' :
            ( paging.pageToken + paging.pageSize ).toString(),
        };

        return res;
      } );

    return result;
  }

  deleteWorkFlow( workFlowId: number ): Promise<ApiResponse> {
    return sleep ( 500 )
      .then( () => {
        this.workFlowItems = this.workFlowItems.filter( ( w ) => w.id !== workFlowId );
        const apiRes: ApiResponse = {
          message: 'Ok',
          success: true,
        };

        return apiRes;
      } );
  }

  getWorkFlow( workFlowCode: string ): Promise<IWorkFlowData> {
    return sleep ( 500 )
      .then( () => {
        const foundWorkFlow: IWorkFlowData | undefined = this.workFlowData.find( ( w ) => w.code === workFlowCode );

        if ( foundWorkFlow !== undefined ) {
          return foundWorkFlow;
        } else {
          throw new ApiError( `WorkFlow with code: ${workFlowCode} not found` );
        }
      } );
  }

  getWorkFlowInstanceItems( paging: PagingRequest, searchQuery: string ):
  Promise<IApiListResponse<IWorkFlowInstanceItem>> {
    const result = sleep ( 500 )
      .then( () => {
        let workFlowMonitorItems: IWorkFlowInstanceItem[] = this.workFlowInstanceItems;
        const input = searchQuery.toLowerCase();
        if ( input.length > 0 ) {
          // Filtering based on searchQuery - case insensitive
          workFlowMonitorItems = workFlowMonitorItems.filter( ( v ) => {
            const index = `${v.instanceId} `;
            return index.toLowerCase().includes ( input );
          } );
        }

        workFlowMonitorItems = this.orderWorkFlowMonitorItems( paging.orderBy, workFlowMonitorItems );
        workFlowMonitorItems = workFlowMonitorItems.slice( paging.pageToken, paging.pageToken + paging.pageSize );
        workFlowMonitorItems = cloneDeep ( workFlowMonitorItems );

        const res:IApiListResponse<IWorkFlowInstanceItem> = {
          items: workFlowMonitorItems,
          nextPageToken: workFlowMonitorItems.length < paging.pageSize ? '0' :
            ( paging.pageToken + paging.pageSize ).toString(),
        };

        return res;
      } );

    return result;
  }

  getWorkFlowInstance( workFlowId: number ): Promise<IWorkFlowInstanceItem> {
    return sleep ( 500 )
      .then( () => {
        const foundWorkFlow: IWorkFlowInstanceItem | undefined =
        this.workFlowInstanceItems.find( ( w ) => w.id === workFlowId );

        if ( foundWorkFlow !== undefined ) {
          return foundWorkFlow;
        } else {
          throw new ApiError( `WorkFlow with id: ${workFlowId} not found` );
        }
      } );
  }

  getInstanceDetails( instanceId: number ): Promise<IWorkFlowInstance> {
    return sleep ( 500 )
      .then( () => {
        const foundInstance: IWorkFlowInstance | undefined =
        this.workFlowInstanceList.find( ( w ) => w.id === instanceId );

        if ( foundInstance !== undefined ) {
          return foundInstance;
        } else {
          throw new ApiError( `WorkFlow with id: ${instanceId} not found` );
        }
      } );
  }

  getWorkFlowInstancesbyId( workFlowId: number, paging: PagingRequest, statusFilter: InstanceStatusType[] ):
  Promise<IApiListResponse<IWorkFlowInstance>> {
    const result = sleep ( 500 )
      .then( () => {
        let workFlowInstanceList: IWorkFlowInstance[] =
        this.workFlowInstanceList.filter( ( i ) => i.workFlowId === workFlowId )
          .filter( ( item ) => {
            if ( !statusFilter.length ) {
              return true;
            } else {
              return statusFilter.includes( item.status );
            }
          } );

        workFlowInstanceList = this.orderWorkFlowInstances( paging.orderBy, workFlowInstanceList );
        workFlowInstanceList = workFlowInstanceList.slice( paging.pageToken, paging.pageToken + paging.pageSize );
        workFlowInstanceList = cloneDeep ( workFlowInstanceList );

        const res:IApiListResponse<IWorkFlowInstance> = {
          items: workFlowInstanceList,
          nextPageToken: workFlowInstanceList.length < paging.pageSize ? '0' :
            ( paging.pageToken + paging.pageSize ).toString(),
        };

        return res;
      } );

    return result;
  }

  actionOnWorkFlowInstance( workFlowId: number, instanceId: number, newState: InstanceStatusType ):
  Promise<ApiResponse> {
    return sleep ( 500 )
      .then( () => {
        let workFlowInstanceList: IWorkFlowInstance[] =
        this.workFlowInstanceList.filter( ( i ) => i.workFlowId === workFlowId );

        workFlowInstanceList = workFlowInstanceList.map( ( i ) =>
          ( i.id === instanceId ? { ...i, status: newState } : i ) );

        this.workFlowInstanceList = workFlowInstanceList;

        const apiRes: ApiResponse = {
          message: 'Ok',
          success: true,
        };

        return apiRes;
      } );
  }

  actionOnWorkFlowInstances( workFlowId: number, instances: InstanceIdType[], newState: InstanceStatusType ):
  Promise<ApiResponse>{
    return sleep ( 500 )
      .then( () => {
        const instanceList = this.workFlowInstanceList.filter( ( i ) => i.workFlowId === workFlowId );
        const foundInstances = instanceList.filter( ( i ) => instances.includes( i.id ) );

        if ( foundInstances.length > 0 ) {
          foundInstances.forEach( ( item ) => {
            item.status = newState;
          } );

          const apiRes: ApiResponse = {
            message: 'Ok',
            success: true,
          };

          return apiRes;
        } else {
          throw new ApiError( `Failed to update status: instance with id ${instances} not found` );
        }
      } );
  }

  private orderWorkFlowInstances( sorted: OrderByPage[], items: IWorkFlowInstance[] ): IWorkFlowInstance[] {
    if ( !sorted.length ) {
      return items;
    }

    return orderBy(
      items,
      sorted.map( ( sort ) => {
        return ( item ) => {
          if ( item[sort.id] === null || item[sort.id] === undefined ) {
            return -Infinity;
          }
          return typeof item[sort.id] === 'string'
            ? item[sort.id].toLowerCase()
            : item[sort.id];
        };
      } ),
      sorted.map( ( d ) => ( d.desc ? 'desc' : 'asc' ) ),
    );
  }

  private orderWorkFlowItems( sorted: OrderByPage[], items: IWorkFlowItem[] ): IWorkFlowItem[] {
    if ( !sorted.length ) {
      return items;
    }

    return orderBy(
      items,
      sorted.map( ( sort ) => {
        return ( item ) => {
          if ( item[sort.id] === null || item[sort.id] === undefined ) {
            return -Infinity;
          }
          return typeof item[sort.id] === 'string'
            ? item[sort.id].toLowerCase()
            : item[sort.id];
        };
      } ),
      sorted.map( ( d ) => ( d.desc ? 'desc' : 'asc' ) ),
    );
  }

  private orderWorkFlowMonitorItems( sorted: OrderByPage[], items: IWorkFlowInstanceItem[] ):
  IWorkFlowInstanceItem[] {
    if ( !sorted.length ) {
      return items;
    }

    return orderBy(
      items,
      sorted.map( ( sort ) => {
        return ( item ) => {
          if ( item[sort.id] === null || item[sort.id] === undefined ) {
            return -Infinity;
          }
          return typeof item[sort.id] === 'string'
            ? item[sort.id].toLowerCase()
            : item[sort.id];
        };
      } ),
      sorted.map( ( d ) => ( d.desc ? 'desc' : 'asc' ) ),
    );
  }
}

export const MockedWorkFlowServiceProvider: React.FC = ( props ) => {
  //const api = useContext( ApiContext );
  const logger = useAppLogger();
  const service: IWorkFlowService = new MockedWorkFlowService( logger );

  return (
    <WorkFlowServiceContext.Provider value={ service }>
      { props.children }
    </WorkFlowServiceContext.Provider>
  );
};
