import React from 'react';
import { Route, RouteProps, RouteComponentProps } from 'react-router-dom';
import { useAppUser } from '../providers/AppUserProvider';
import { PermissionType } from 'Services/permissions/interfaces';

export interface ProtectedRouteProps extends Omit<RouteProps, 'render'|'component'|'children'> {
  requiredPermissions?: PermissionType[] | PermissionType[][];
  render: ( props: RouteComponentProps<any>, hasAccess: boolean ) => React.ReactNode;
}

export const ProtectedRoute: React.FC<ProtectedRouteProps> = ( {
  requiredPermissions = [],
  render,
  ...routeProps
} ) => {
  const user = useAppUser();
  const hasAccess = React.useMemo<boolean>( () => {
    return user.hasPermissions( requiredPermissions );
  }, [ requiredPermissions, user ] );

  return (
    <Route { ...routeProps } render={ ( props ) => render( props, hasAccess ) } />
  );
};
