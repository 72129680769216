
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { ConfirmModal } from 'App/components/utils/ConfirmDialog';
import { permissionDict as p } from 'Services/permissions/permission-dict';

import React, { Fragment, useMemo, useState } from 'react';
import { DATETIME_FORMAT_HOUR } from '../../../../ui-utils';
import { useAppUser } from '../../../utils/providers/AppUserProvider';
import { blankLink } from '../../../../../config';

const MAX_LENGTH_OF_FILENAME = 37;

const trimStringToMaxLen = ( item: string, maxLength: number ) => {
  if( item.length <= maxLength ) {
    return item;
  }

  return `${item.slice( 0, maxLength - 3 )}...`;
};

export const ClaimDocumentsColumnDefinition = ( deleteDocumentHandler: ( code: string ) => Promise<void>,
  getDocumentDownloadUrlHandler: ( event: React.MouseEvent<HTMLAnchorElement>, code: string ) => Promise<void> ) => {
  const { t } = useTranslation( [ 'claim', 'base' ] );
  const user = useAppUser();

  return useMemo( () => {
    return [
      {
        Header: t( 'documents.tableHeaders.name' ),
        accessor: 'documentId',
        // eslint-disable-next-line react/display-name
        Cell: ( { row: { original: document } } ) => {
          const fileName = trimStringToMaxLen( document.filename, MAX_LENGTH_OF_FILENAME );
          return (
            <a
              href={ blankLink }
              onClick={ ( e: React.MouseEvent<HTMLAnchorElement> ) => {
                getDocumentDownloadUrlHandler( e, document.code );
              } }
            >
              <i className="material-icons material-icons-outlined f-18 align-vertical-sub c-blue p-r-10">file_download
              </i>
              <span style={ { verticalAlign: 'text-bottom' } }>
                { fileName }
              </span>
            </a>
          );
        },
        width: '44%',
        minWidth: 44,
      },
      {
        Header: t( 'documents.tableHeaders.date' ),
        accessor: 'documentUpdatedAt',
        // eslint-disable-next-line react/display-name
        Cell: ( { row: { original: document } } ) => {
          return <span>{ format( new Date( document.createdAt ), DATETIME_FORMAT_HOUR ) }</span>;
        },
        width: '18.5%',
        minWidth: 18.5,
      },
      {
        Header: '',
        id: 'deleteClaimButton',
        canSort: false,
        // eslint-disable-next-line react/display-name
        Cell: ( { row: { original: document } } ) => {
          const [ showModal, setShowModal ] = useState( false );
          return (
            <Fragment>
              { showModal &&
              <ConfirmModal
                title={ t( 'documents.deleteModal.header' ) }
                type="warning"
                iconColorClass="text-danger"
                cancelButtonLabel={ t( 'actions.cancel' ) }
                confirmButtonLabel={ t( 'actions.delete' ) }
                onClose={ () => {setShowModal( false );} }
                onConfirm={ async () => {
                  await deleteDocumentHandler( document.code );
                } }
              >
                { t( 'documents.deleteModal.message', { filename: document.filename } ) }
              </ConfirmModal>
              }
              { user.hasPermissions( [ p.eis_claims_delete ] ) && (
                <span
                  onClick={ async () => {
                    setShowModal( true );
                  } }
                  className="d-flex justify-content-end pr-1"
                >
                  <i
                    className="material-icons material-icons-outlined f-18 align-vertical-sub c-blue chevron"
                  >delete
                  </i>
                </span> ) }
            </Fragment>
          );
        },
        width: '37.5%',
        minWidth: 37.5,
      },
    ];
  }, [ deleteDocumentHandler, getDocumentDownloadUrlHandler, t, user ] );
};
