import React from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useAppUserProducts } from '../providers/AppProductProvider';

/**
 * This Component calculates landing page and redirect to it.
 * Landing page depends on menu items fetched from user products.
 * This component goal is to redirect to langing page.
 * This component renders nothing.
 */
export const LandingPageResolver: React.FC = () => {
  const userProducts = useAppUserProducts();
  const history = useHistory();
  const { url } = useRouteMatch();
  const landingPage = React.useMemo( () => {
    const menuItems = userProducts
      .map( ( product ) => product.menuItems )
      .filter( ( items ) => items.length > 0 )
      .map( ( items ) => items[0] );
    const prefix = url.replace( /\/+\s*$/, '' );
    const firstMenuItem = menuItems[0];
    const firstItemChildren = firstMenuItem?.children;
    const path = ( firstItemChildren ? firstItemChildren[ 0 ].url! : firstMenuItem.url! )
      .replace( /^\/+/, '' );
    return prefix + '/' + path;
  }, [ url, userProducts ] );

  React.useEffect( () => {
    history.replace( landingPage );
  }, [ history, landingPage ] );

  return (
    <React.Fragment />
  );
};
