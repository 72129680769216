import {
  ITicket,
  TicketsResponse,
  ITicketFilter,
  ITicketComment,
  TicketCommentsResponse } from 'Services/tickets/tickets';
import avatar1 from '../../assets/images/user/avatar-2.jpg';
import avatar2 from '../../assets/images/user/avatar-1.jpg';
import avatar3 from '../../assets/images/user/avatar-3.jpg';
import avatar4 from '../../assets/images/user/avatar-4.jpg';
import avatar5 from '../../assets/images/user/avatar-5.jpg';
import { defaultDateFormat } from 'App/ui-utils';
import dayjs from 'dayjs';

const TicketsData: ITicket[] = [
  {
    id: 1,
    subject: 'Sub 1',
    description: 'Desc 1',
    timeCreated: dayjs( '12.08.2020', defaultDateFormat ),
    category: 'FZV',
    status: 'solved',
    assignedTo: {
      user_name: 'Emil Mustermann',
      user_email: 'demo@emil.de',
      avatar: avatar1,
    },
  },
  {
    id: 2,
    subject: 'Sub 2',
    description: 'Desc 2',
    timeCreated: dayjs( '13.08.2020', defaultDateFormat ),
    category: 'FZV',
    status: 'open',
    assignedTo: {
      user_name: 'Name Vorname 2',
      user_email: 'emil2@email.com',
    },
  },
  {
    id: 3,
    subject: 'Sub 3',
    description: 'Desc 3',
    timeCreated: dayjs( '12.08.2020', defaultDateFormat ),
    category: 'VWB',
    status: 'waiting',
    assignedTo: {
      user_name: 'Name Vorname 3',
      user_email: 'mustermann3@email.com',
      avatar: avatar3,
    },
  },
  {
    id: 4,
    subject: 'Sub 4',
    description: 'Desc 4',
    timeCreated: dayjs( '12.08.2020', defaultDateFormat ),
    category: 'Cat 3',
    status: 'open',
    assignedTo: {
      user_name: 'Name Vorname 4',
      user_email: 'emil_mustermann@emil.com',
      avatar: avatar4,
    },
  },
  {
    id: 5,
    subject: 'Sub 5',
    description: 'Desc 5',
    timeCreated: dayjs( '12.08.2020', defaultDateFormat ),
    category: 'Cat 4',
    status: 'waiting',
    assignedTo: {
      user_name: 'Name Vorname 5',
      user_email: 'demo@email.com',
      avatar: avatar5,
    },
  },
  {
    id: 6,
    subject: 'Sub 6',
    description: 'Desc 6',
    timeCreated: dayjs( '12.08.2020', defaultDateFormat ),
    category: 'Cat 3',
    status: 'blocked',
  },
  {
    id: 7,
    subject: 'Sub 7',
    description: 'Desc 7',
    timeCreated: dayjs( '16.08.2020', defaultDateFormat ),
    category: 'FZV',
    status: 'waiting',
    assignedTo: {
      user_name: 'Name Vorname 6',
      user_email: 'emil_muster@emil.de',
    },
  },
  {
    id: 8,
    subject: 'Sub 8',
    description: 'Desc 8',
    timeCreated: dayjs( '12.08.2020', defaultDateFormat ),
    category: 'FZV',
    status: 'solved',
    assignedTo: {
      user_name: 'Name Vorname 7',
      user_email: 'eMuster_7@email.de',
      avatar: avatar2,
    },
  },
  {
    id: 9,
    subject: 'Sub 9',
    description: 'Desc 9',
    timeCreated: dayjs( '16.08.2020', defaultDateFormat ),
    category: 'FZV',
    status: 'open',
    assignedTo: {
      user_name: 'Emil Mustermann',
      user_email: 'demo@emil.de',
      avatar: avatar1,
    },
  },
  {
    id: 10,
    subject: 'Sub 10',
    description: 'Desc 10',
    timeCreated: dayjs( '19.08.2020', defaultDateFormat ),
    category: 'VWB',
    status: 'waiting',
    assignedTo: {
      user_name: 'Name Vorname 7',
      user_email: 'eMuster_7@email.de',
      avatar: avatar2,
    },
  },
  {
    id: 11,
    subject: 'Sub 11',
    description: 'Desc 11',
    timeCreated: dayjs( '12.08.2020', defaultDateFormat ),
    category: 'Cat 3',
    status: 'open',
    assignedTo: {
      user_name: 'Name Vorname 6',
      user_email: 'emil_muster@emil.de',
    },
  },
  {
    id: 12,
    subject: 'Sub 12',
    description: 'Desc 12',
    timeCreated: dayjs( '13.08.2020', defaultDateFormat ),
    category: 'Cat 4',
    status: 'blocked',
    assignedTo: {
      user_name: 'Name Vorname 5',
      user_email: 'demo@email.com',
      avatar: avatar5,
    },
  },
  {
    id: 13,
    subject: 'Sub 13',
    description: 'Desc 13',
    timeCreated: dayjs( '12.08.2020', defaultDateFormat ),
    category: 'Cat 3',
    status: 'open',
  },
  {
    id: 14,
    subject: 'Sub 14',
    description: 'Desc 14',
    timeCreated: dayjs( '19.08.2020', defaultDateFormat ),
    category: 'FZV',
    status: 'blocked',
  },
  {
    id: 15,
    subject: 'Sub 15',
    description: 'Desc 15',
    timeCreated: dayjs( '16.08.2020', defaultDateFormat ),
    category: 'FZV',
    status: 'solved',
    assignedTo: {
      user_name: 'Emil Mustermann',
      user_email: 'demo@emil.de',
      avatar: avatar1,
    },
  },
  {
    id: 16,
    subject: 'Sub 16',
    description: 'Desc 16',
    timeCreated: dayjs( '13.08.2020', defaultDateFormat ),
    category: 'FZV',
    status: 'open',
    assignedTo: {
      user_name: 'Name Vorname 2',
      user_email: 'emil2@email.com',
    },
  },
  {
    id: 17,
    subject: 'Sub 17',
    description: 'Desc 17',
    timeCreated: dayjs( '12.08.2020', defaultDateFormat ),
    category: 'VWB',
    status: 'waiting',
    assignedTo: {
      user_name: 'Name Vorname 3',
      user_email: 'mustermann3@email.com',
      avatar: avatar3,
    },
  },
  {
    id: 18,
    subject: 'Sub 18',
    description: 'Desc 18',
    timeCreated: dayjs( '12.08.2020', defaultDateFormat ),
    category: 'Cat 3',
    status: 'open',
    assignedTo: {
      user_name: 'Name Vorname 4',
      user_email: 'emil_mustermann@emil.com',
      avatar: avatar4,
    },
  },
  {
    id: 19,
    subject: 'Sub 19',
    description: 'Desc 19',
    timeCreated: dayjs( '13.08.2020', defaultDateFormat ),
    category: 'Cat 4',
    status: 'waiting',
    assignedTo: {
      user_name: 'Name Vorname 5',
      user_email: 'demo@email.com',
      avatar: avatar5,
    },
  },
  {
    id: 20,
    subject: 'Sub 20',
    description: 'Desc 20',
    timeCreated: dayjs( '12.08.2020', defaultDateFormat ),
    category: 'Cat 3',
    status: 'blocked',
  },
  {
    id: 21,
    subject: 'Sub 21',
    description: 'Desc 21',
    timeCreated: dayjs( '13.08.2020', defaultDateFormat ),
    category: 'FZV',
    status: 'waiting',
    assignedTo: {
      user_name: 'Name Vorname 6',
      user_email: 'emil_muster@emil.de',
    },
  },
  {
    id: 22,
    subject: 'Sub 22',
    description: 'Desc 22',
    timeCreated: dayjs( '12.08.2020', defaultDateFormat ),
    category: 'FZV',
    status: 'solved',
    assignedTo: {
      user_name: 'Name Vorname 7',
      user_email: 'eMuster_7@email.de',
      avatar: avatar2,
    },
  },
  {
    id: 23,
    subject: 'Sub 23',
    description: 'Desc 23',
    timeCreated: dayjs( '19.08.2020', defaultDateFormat ),
    category: 'FZV',
    status: 'open',
    assignedTo: {
      user_name: 'Emil Mustermann',
      user_email: 'demo@emil.de',
      avatar: avatar1,
    },
  },
  {
    id: 24,
    subject: 'Sub 24',
    description: 'Desc 24',
    timeCreated: dayjs( '13.08.2020', defaultDateFormat ),
    category: 'VWB',
    status: 'waiting',
    assignedTo: {
      user_name: 'Name Vorname 7',
      user_email: 'eMuster_7@email.de',
      avatar: avatar2,
    },
  },
  {
    id: 25,
    subject: 'Sub 25',
    description: 'Desc 25',
    timeCreated: dayjs( '16.08.2020', defaultDateFormat ),
    category: 'Cat 3',
    status: 'open',
    assignedTo: {
      user_name: 'Name Vorname 6',
      user_email: 'emil_muster@emil.de',
    },
  },
  {
    id: 26,
    subject: 'Sub 26',
    description: 'Desc 26',
    timeCreated: dayjs( '12.08.2020', defaultDateFormat ),
    category: 'Cat 4',
    status: 'blocked',
    assignedTo: {
      user_name: 'Name Vorname 5',
      user_email: 'demo@email.com',
      avatar: avatar5,
    },
  },
  {
    id: 27,
    subject: 'Sub 27',
    description: 'Desc 27',
    timeCreated: dayjs( '12.08.2020', defaultDateFormat ),
    category: 'Cat 3',
    status: 'open',
  },
  {
    id: 28,
    subject: 'Sub 28',
    description: 'Desc 28',
    timeCreated: dayjs( '12.08.2020', defaultDateFormat ),
    category: 'FZV',
    status: 'blocked',
  },
  {
    id: 29,
    subject: 'Sub 29',
    description: 'Desc 29',
    timeCreated: dayjs( '12.08.2020', defaultDateFormat ),
    category: 'FZV',
    status: 'solved',
    assignedTo: {
      user_name: 'Emil Mustermann',
      user_email: 'demo@emil.de',
      avatar: avatar1,
    },
  },
  {
    id: 30,
    subject: 'Sub 30',
    description: 'Desc 30',
    timeCreated: dayjs( '12.08.2020', defaultDateFormat ),
    category: 'FZV',
    status: 'open',
    assignedTo: {
      user_name: 'Name Vorname 2',
      user_email: 'emil2@email.com',
    },
  },
  {
    id: 31,
    subject: 'Sub 31',
    description: 'Desc 31',
    timeCreated: dayjs( '13.08.2020', defaultDateFormat ),
    category: 'VWB',
    status: 'waiting',
    assignedTo: {
      user_name: 'Name Vorname 3',
      user_email: 'mustermann3@email.com',
      avatar: avatar3,
    },
  },
  {
    id: 32,
    subject: 'Sub 32',
    description: 'Desc 32',
    timeCreated: dayjs( '12.08.2020', defaultDateFormat ),
    category: 'Cat 3',
    status: 'open',
    assignedTo: {
      user_name: 'Name Vorname 4',
      user_email: 'emil_mustermann@emil.com',
      avatar: avatar4,
    },
  },
  {
    id: 33,
    subject: 'Sub 33',
    description: 'Desc 33',
    timeCreated: dayjs( '12.08.2020', defaultDateFormat ),
    category: 'Cat 4',
    status: 'waiting',
    assignedTo: {
      user_name: 'Name Vorname 5',
      user_email: 'demo@email.com',
      avatar: avatar5,
    },
  },
  {
    id: 34,
    subject: 'Sub 34',
    description: 'Desc 34',
    timeCreated: dayjs( '19.08.2020', defaultDateFormat ),
    category: 'Cat 3',
    status: 'blocked',
  },
  {
    id: 35,
    subject: 'Sub 35',
    description: 'Desc 35',
    timeCreated: dayjs( '16.08.2020', defaultDateFormat ),
    category: 'FZV',
    status: 'waiting',
    assignedTo: {
      user_name: 'Name Vorname 6',
      user_email: 'emil_muster@emil.de',
    },
  },
  {
    id: 36,
    subject: 'Sub 36',
    description: 'Desc 36',
    timeCreated: dayjs( '13.08.2020', defaultDateFormat ),
    category: 'FZV',
    status: 'solved',
    assignedTo: {
      user_name: 'Name Vorname 7',
      user_email: 'eMuster_7@email.de',
      avatar: avatar2,
    },
  },
  {
    id: 37,
    subject: 'Sub 37',
    description: 'Desc 37',
    timeCreated: dayjs( '12.08.2020', defaultDateFormat ),
    category: 'FZV',
    status: 'open',
    assignedTo: {
      user_name: 'Emil Mustermann',
      user_email: 'demo@emil.de',
      avatar: avatar1,
    },
  },
  {
    id: 38,
    subject: 'Sub 38',
    description: 'Desc 38',
    timeCreated: dayjs( '12.08.2020', defaultDateFormat ),
    category: 'VWB',
    status: 'waiting',
    assignedTo: {
      user_name: 'Name Vorname 7',
      user_email: 'eMuster_7@email.de',
      avatar: avatar2,
    },
  },
  {
    id: 39,
    subject: 'Sub 39',
    description: 'Desc 39',
    timeCreated: dayjs( '12.08.2020', defaultDateFormat ),
    category: 'Cat 3',
    status: 'open',
    assignedTo: {
      user_name: 'Name Vorname 6',
      user_email: 'emil_muster@emil.de',
    },
  },
  {
    id: 40,
    subject: 'Sub 40',
    description: 'Desc 40',
    timeCreated: dayjs( '12.08.2020', defaultDateFormat ),
    category: 'Cat 4',
    status: 'blocked',
    assignedTo: {
      user_name: 'Name Vorname 5',
      user_email: 'demo@email.com',
      avatar: avatar5,
    },
  },
  {
    id: 41,
    subject: 'Sub 41',
    description: 'Desc 41',
    timeCreated: dayjs( '19.08.2020', defaultDateFormat ),
    category: 'Cat 3',
    status: 'open',
  },
  {
    id: 42,
    subject: 'Sub 42',
    description: 'Desc 42',
    timeCreated: dayjs( '16.08.2020', defaultDateFormat ),
    category: 'FZV',
    status: 'blocked',
  },
];

const TicketCommentsData: ITicketComment[] = [
  {
    id: 1,
    ticketId: 1,
    body: 'This is my first comment',
    timeCreated: dayjs( '13.08.2020', defaultDateFormat ),
    author: {
      user_name: 'Emil Mustermann',
      user_email: 'demo@emil.de',
      avatar: avatar1,
    },
  },
  {
    id: 2,
    ticketId: 1,
    body: 'This is my second comment',
    timeCreated: dayjs( '16.08.2020', defaultDateFormat ),
    author: {
      user_name: 'Emil Mustermann',
      user_email: 'demo@emil.de',
      avatar: avatar2,
    },
  },
  {
    id: 3,
    ticketId: 2,
    body: 'This is my first comment',
    timeCreated: dayjs( '13.09.2020', defaultDateFormat ),
    author: {
      user_name: 'Name Vorname',
      user_email: 'demo2@emil.de',
      avatar: avatar2,
    },
  },
];

export const mockedTicketComments: TicketCommentsResponse = {
  item: TicketCommentsData,
};

export const ticketMockData: ITicket = TicketsData[0];

export const mockedTickets: TicketsResponse = {
  items: TicketsData,
};

export const mockedFilters: ITicketFilter[] = [
  { name: 'all_unsolved', count: 5 },
  { name: 'unassigned', count: 2 },
  { name: 'assigned_to_me', count: 0 },
  { name: 'open', count: 2 },
  { name: 'waiting', count: 1 },
  { name: 'solved', count: 2 },
];
