import {
  CreateClaimReqBody,
  Claim,
  NewSettlementItem,
  SettlementItem,
  SettlementResponse,
  IUpdateSettlementItem,
  PatchClaimReqBody, LinkClaimPartnerPayload, PartnerRelation, ClaimPartnerRole,
} from './interfaces';
import { ApiResponse, BaseRequest, PagingRequest } from 'Services/base';
import { getQueryParamsForListAPI, ListItems } from 'Services/api';

export class CreateClaimsRequest extends BaseRequest {
  constructor( data: CreateClaimReqBody ) {
    super( {
      method: 'POST',
      url: '/v1/claims',
      data,
      responseType: 'json',
    } );
  }
}

export class GetClaimRequest extends BaseRequest {
  constructor( claimCode: string ) {
    super( {
      method: 'GET',
      url: `/v1/claims/${claimCode}`,
      responseType: 'json',
    } );
  }
}

export class UpdateClaimRequest extends BaseRequest {
  constructor( claimCode: string, data: CreateClaimReqBody ) {
    super( {
      method: 'PUT',
      url: `/v1/claims/${claimCode}`,
      responseType: 'json',
      data,
    } );
  }
}

export class PatchClaimRequest extends BaseRequest {
  constructor(
    claimCode: string,
    claim: PatchClaimReqBody,
  ) {

    super( {
      method: 'PATCH',
      url: `/v1/claims/${claimCode}`,
      responseType: 'json',
      data: claim,
    } );
  }
}

// ListClaimsIsAlready declared in policy - don't know why
// that's why the name is funny
export class GetListOfClaimsRequest extends BaseRequest<ListItems<Claim>> {
  constructor( pageParameters: PagingRequest, searchValue: string ) {
    super( {
      method: 'GET',
      url: '/v1/claims',
      responseType: 'json',
      params: getQueryParamsForListAPI( pageParameters, searchValue, 'search' ),
    } );
  }
}
export class DeleteClaimRequest extends BaseRequest<ApiResponse> {
  constructor( claimCode: string ) {
    super( {
      method: 'DELETE',
      url: `/v1/claims/${claimCode}`,
      responseType: 'json',
    } );
  }
}

export class ListCustomSchemasRequest extends BaseRequest {
  constructor( pageParameters: PagingRequest ) {
    super( {
      method: 'GET',
      url: '/tenantservice/v1/custom-schemas',
      responseType: 'json',
      params: getQueryParamsForListAPI( pageParameters ),
    } );
  }
}

export class CreateSettlementRequest extends BaseRequest<SettlementResponse> {
  constructor(
    settlement: NewSettlementItem,
  ) {

    super( {
      method: 'POST',
      url: '/v1/settlements',
      data: settlement,
      responseType: 'json',
    } );
  }
}

export class GetSettlementRequest extends BaseRequest<SettlementResponse> {
  constructor(
    claimCode: string,
    settlementCode: string,
  ) {

    super( {
      method: 'GET',
      url: `/v1/settlements/${settlementCode}`,
      responseType: 'json',
    } );
  }
}

export class UpdateSettlementRequest extends BaseRequest<SettlementResponse> {
  constructor(
    settlementCode: string,
    settlement: IUpdateSettlementItem,
  ) {

    super( {
      method: 'PUT',
      url: `/v1/settlements/${settlementCode}`,
      data: settlement,
    } );
  }
}

export class ListSettlementsRequest extends BaseRequest<ListItems<SettlementItem>> {
  constructor(
    pageParameters: PagingRequest,
  ) {

    super( {
      method: 'GET',
      url: '/v1/settlements',
      responseType: 'json',
      params: getQueryParamsForListAPI( pageParameters ),
    } );
  }
}

export class DeleteSettlementRequest extends BaseRequest<ApiResponse> {
  constructor(
    claimCode: string,
    settlementCode: string,
  ) {

    super( {
      method: 'DELETE',
      url: `/v1/settlements/${settlementCode}`,
      responseType: 'json',
    } );
  }
}


export class ListClaimPartnerRoles extends BaseRequest<ListItems<ClaimPartnerRole>> {
  constructor(
    pageParameters: PagingRequest,
  ) {

    const queryParams = new URLSearchParams();
    queryParams.set( 'pageSize', pageParameters.pageSize.toString() );
    queryParams.set( 'pageToken', pageParameters.pageToken.toString() );

    if ( pageParameters.filter.length ) {
      const filterPars = pageParameters.filter
        .map( ( item ) =>
          `${item.id}=${item.value ? encodeURIComponent( item.value ) : null}` ).join( '&' );
      queryParams.set( 'filter', filterPars );
    }

    if ( pageParameters.orderBy.length ) {
      const orderPars = pageParameters.orderBy
        .map( ( item ) => `${item.id}:${ item.desc ? 'DESC' : 'ASC' }` ).join( '&' );
      queryParams.set( 'order', orderPars );
    }

    super( {
      method: 'GET',
      url: '/v1/claim-partner-roles',
      responseType: 'json',
      params: queryParams,
    } );
  }
}


export class ListClaimPartners extends BaseRequest<ListItems<PartnerRelation>> {
  constructor(
    pageParameters: PagingRequest,
    expand: string[],
  ) {
    let expandPars = '';
    if ( expand.length > 0 ) {
      expandPars = expand.map( ( item ) => `${encodeURIComponent( item )}` ).join( ',' );
    }
    const params = getQueryParamsForListAPI( pageParameters );
    if ( expandPars ) {
      params.set( 'expand', expandPars );
    }

    super( {
      method: 'GET',
      url: '/v1/claim-partners',
      responseType: 'json',
      params: params,
    } );
  }
}


export class LinkClaimPartner extends BaseRequest<PartnerRelation> {
  constructor(
    payload: LinkClaimPartnerPayload,
  ) {

    super( {
      method: 'POST',
      url:  `/v1/claims/${payload.claimCode}/partner`,
      data: payload,
      responseType: 'json',
    } );
  }
}


export class UnLinkClaimPartner extends BaseRequest<{ }> {
  constructor(
    id: number,
  ) {

    super( {
      method: 'DELETE',
      url:  `/v1/claim-partners/${id}`,
      responseType: 'json',
    } );
  }
}
