import React, { ReactElement, Fragment, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import Joyride, {
  CallBackProps,
  STATUS,
  ACTIONS,
  Step,
  Locale,
} from 'react-joyride';
import { blankLink } from 'config';
import { StateTour } from 'App/layout/AppLayout/NavBar/NavHelp';

export interface TourProps {
  run: boolean;
  steps: Step[];
  showStart: boolean;
  onUpdate: ( data: StateTour ) => void;
}

type ETPropsType = TourProps;

const defaulColor = '#9575CD';

const defaulStyles = {
  options: {
    zIndex: 10000,
    primaryColor: '#000',
    spotlightShadow: '0 0 15px rgba(0, 0, 0, 0.5)',
  },
  buttonBack: {
    color: defaulColor,
  },
  buttonNext: {
    backgroundColor: defaulColor,
  },
  buttonSkip: {
    color: defaulColor,
    fontSize: '17px',
  },
  spotlight: {
    backgroundColor: 'rgba(250, 250, 250, 0.2)',
  },
};

const ExplanatoryTour: React.FC<ETPropsType> = ( props: ETPropsType ): ReactElement | null => {
  const { t } = useTranslation( [ 'tour', 'base' ] );
  const localeBtn: Locale = React.useMemo( () => {
    return t( 'locale', { returnObjects: true } );
  }, [ t ] );

  const handleClickStart = ( e: React.MouseEvent<HTMLElement> ) => {
    e.preventDefault();
    props.onUpdate( { ...props, run: true } );
  };

  const handleJoyrideCallback = ( data: CallBackProps ) => {
    const { status, action } = data;
    if ( action === ACTIONS.CLOSE ) {
      props.onUpdate( { ...props, run: false } );
      return;
    }
    const finishedStatuses: string[] = [ STATUS.FINISHED, STATUS.SKIPPED ];
    if ( finishedStatuses.includes( status ) ) {
      props.onUpdate( { ...props, run: false } );
      return;
    }
  };

  let btnStart: ReactNode;
  if ( props.showStart ) {
    btnStart = (
      <a href={ blankLink } className="dropdown-item"
        id="startTour"
        onClick={ handleClickStart } title={ t( 'explanatoryTour' ) }
      >{ t( 'explanatoryTour' ) }
      </a>
    );
  }

  return (
    <Fragment>
      { btnStart }
      <Joyride
        callback={ handleJoyrideCallback }
        continuous={ true }
        scrollToFirstStep={ true }
        showProgress={ true }
        showSkipButton={ true }
        locale={ localeBtn }
        spotlightClicks={ true }
        floaterProps={ { disableAnimation: true } }
        styles={ defaulStyles }
        { ...props }
      />
    </Fragment>
  );
};

export default ExplanatoryTour;
