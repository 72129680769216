import { BaseRequest, PagingRequest } from '../base';
import { CreatePaymentReqBody, CreatePaymentResponse, Payment } from './interface';
import { getQueryParamsForListAPI } from '../api';
import { IApiListResponse } from '../policies/new/service';

export class CreatePaymentRequest extends BaseRequest<CreatePaymentResponse> {
  constructor(
    data: CreatePaymentReqBody,
    idempotencyKey: string,
  ) {
    super( {
      method: 'POST',
      url: '/paymentservice/v1/payments',
      responseType: 'json',
      headers: {
        'Idempotency-Key': idempotencyKey,
      },
      data,
    } );
  }
}

export class ListPaymentsRequest extends BaseRequest<IApiListResponse<Payment>> {
  constructor(
    paging: PagingRequest,
  ) {
    super( {
      method: 'GET',
      url: 'paymentservice/v1/payments',
      responseType: 'json',
      params: getQueryParamsForListAPI( paging ),
    } );
  }
}
