import React from 'react';
import { IApiService, PagingRequest } from './base';
import { StatusTransitionRuleItem } from './status-transition-rules/interfaces';
import { IApiListResponse } from './policies/new/service';
import { ListStatusTransitionRulesRequest } from './status-transition-rules/status-transition-rules';

export interface IStatusTransitionRuleService {
  listStatusTransitionRules( paging: PagingRequest ): Promise<IApiListResponse<StatusTransitionRuleItem>>;
}

export class StatusTransitionRuleService {
  protected api: IApiService;
  constructor( api: IApiService ) {
    this.api = api;
  }
  async listStatusTransitionRules( paging: PagingRequest ): Promise<IApiListResponse<StatusTransitionRuleItem>> {
    const response = await this.api.request( new ListStatusTransitionRulesRequest( paging ) );
    const { items, nextPageToken } = response;

    const res: IApiListResponse<StatusTransitionRuleItem> = {
      items: items ? items : [],
      nextPageToken: nextPageToken ? nextPageToken : '1',
    };

    return res;
  }
}

export const StatusTransitionRuleServiceContext: React.Context<IStatusTransitionRuleService>
  = React.createContext( undefined as any );

export const useStatusTransitionRuleService = (): IStatusTransitionRuleService => {
  return React.useContext( StatusTransitionRuleServiceContext );
};
