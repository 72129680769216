import { getLanguage } from 'App/ui-utils';
import React from 'react';

export const impressumLink: string = 'https://www.emil.de/impressum';
export const privacyPolicyLink: string = 'https://www.emil.de/datenschutz';
export const pbHandbookLink: string = '/assets/docs/PremiumBenchmarking_Handbuch_Allgemein.pdf';
export const contactSupportLink: string = '/support';
export const pbGiveFeedbackLink: string = 'https://www.emil.de/feedback-geben';

const lng = getLanguage();

let keyDoc = lng.toLocaleUpperCase();

if ( lng === 'pl' ) {
  keyDoc = 'EN';
}

export const releaseNotesEisLink: string = '/release-notes';
export const releaseNotesPbmLink: string = `/assets/docs/Release_Notes_PBM_${keyDoc}.pdf`;

// Later we can build on top of it and expand with more links that application will need.
export interface IAppLinks {
  impressum: string;
  privacyPolicy: string;
  pbHandbook: string;
  pbGiveFeedback: string;
  releaseNotesEisLink: string;
  releaseNotesPbmLink: string;
  contactSupportLink: string;
}

export const defaultAppLinks: IAppLinks = {
  impressum: impressumLink,
  privacyPolicy: privacyPolicyLink,
  pbHandbook: pbHandbookLink,
  pbGiveFeedback: pbGiveFeedbackLink,
  releaseNotesEisLink,
  releaseNotesPbmLink,
  contactSupportLink,
};
export const AppLinksContext: React.Context<IAppLinks> = React.createContext( defaultAppLinks );

export const useAppLinks = (): IAppLinks => {
  return React.useContext( AppLinksContext );
};

