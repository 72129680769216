import { BaseRequest, PagingRequest } from '../base';
import { getQueryParamsForListAPI } from '../api';
import { ListPaymentMethodsResponse } from './interfaces';

export class ListPaymentMethodRequest extends BaseRequest<ListPaymentMethodsResponse> {
  constructor(
    paging: PagingRequest,
  ) {
    super( {
      method: 'GET',
      url: '/paymentservice/v1/bank-accounts',
      responseType: 'json',
      params: getQueryParamsForListAPI( paging ),
    } );
  }
}
