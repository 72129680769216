import React from 'react';
import NavSearch from './NavSearch';
// import { permissionDict as p } from 'Services/permissions/permission-dict';
// import { useAppSecurity } from 'App/components/utils/providers/AppProductProvider';
// import { useAppLinks } from 'App/components/utils/providers/AppLinks';
// import { useLocation } from 'react-router';

export const NavLeft: React.FC = () => {
  // const user = useAppSecurity();
  // const location = useLocation();
  // const links = useAppLinks();
  // const isSupportPage = location.pathname.lastIndexOf( links.contactSupportLink ) > -1;
  // TODO: Disable Search
  const shouldDisplaySearch = false; // user.hasPermissions( [ p.eis_all ] ) && !isSupportPage;

  return (
    <ul className="navbar-nav mr-auto">
      { shouldDisplaySearch && <li className="nav-item p-3"><NavSearch /></li> }
    </ul>
  );
};
