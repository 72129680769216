import React from 'react';
import dayjs from 'dayjs';
import { IApiService, PagingRequest, ApiResponse } from 'Services/base';
import { IApiListResponse } from 'Services/policies/new/service';
import { ListVwbMessagesRequest, IVwbMessageItem, VwbPositiveAnswer,
  VwbPositiveAnswerRequest, VwbNegativeAnswerRequest, VwbNegativeAnswer,
  VwbMessageUpdate, VwbMessageUpdateRequest } from './gdv/gdv';
import { FzvMessageItem, FzvMessageUpdate } from './gdv/fzv.message';
import { FzvDemoOrchestrator } from './demo/fzv-demo';

export interface IGdvService {
  getVwbMessages( paging: PagingRequest, searchValue: string, expand?: string ):
  Promise<IApiListResponse<IVwbMessageItem>>;
  getFzvMessages( paging: PagingRequest, searchValue: string, expand?: string ):
  Promise<IApiListResponse<FzvMessageItem>>;
  createVwbPositiveAnswer( answer: VwbPositiveAnswer ): Promise<ApiResponse>;
  createVwbNegativeAnswer( answer: VwbPositiveAnswer ): Promise<ApiResponse>;
  updateVwbMessage( update: VwbMessageUpdate ): Promise<ApiResponse>;
  updateFzvMessage( update: FzvMessageUpdate ): Promise<ApiResponse>;
}

export class GdvService implements IGdvService {
  private api: IApiService;
  constructor( api: IApiService ) {
    this.api = api;
  }

  async getFzvMessages( paging: PagingRequest, searchValue: string, expand?: string | undefined )
    : Promise<IApiListResponse<FzvMessageItem>> {

    if ( paging.pageToken !== 1 ) {
      return {
        items: [],
        nextPageToken: '1',
      };
    }

    let messages = FzvDemoOrchestrator.getInstance().messages;

    const idFilter = paging.filter.find( ( f ) => f.id === 'id' );

    if ( idFilter ) {
      messages = messages.filter( ( m ) => m.id === +idFilter.value );
    }

    const policyFilter = paging.filter.find( ( f ) => f.id === 'policyNumber' );

    if ( policyFilter ) {
      messages = messages.filter( ( m ) => m.policy && m.policy.policyNumber === policyFilter.value );
    }

    return {
      items: messages,
      nextPageToken: '1',
    };
  }

  async updateFzvMessage( update: FzvMessageUpdate ): Promise<ApiResponse> {
    const message = FzvDemoOrchestrator.getInstance().messages.find( ( m ) => m.id === update.messageId );

    if ( !message ) {
      throw new Error( 'Message not found' );
    }

    message.policy = update.policy;
    message.processed = true;

    return {
      message: 'OK',
      success: true,
    };
  }


  getVwbMessages( paging: PagingRequest, searchValue: string, expand?: string ):
  Promise<IApiListResponse<IVwbMessageItem>> {
    return this.api.request( new ListVwbMessagesRequest( paging, searchValue, expand ) )
      .then( ( response ) => {
        let vwbData : IVwbMessageItem[] = [];
        const { items, nextPageToken } = response;

        if ( items ) {
          vwbData = items.map( ( item, index ) => {
            const vwbItem: IVwbMessageItem = {
              ...item,
              createdAt: dayjs( item.createdAt ),
            };
            return vwbItem;
          } );
        }

        const res: IApiListResponse<IVwbMessageItem> = {
          items: vwbData,
          nextPageToken: vwbData ? vwbData.length < paging.pageSize ? '1' : nextPageToken : nextPageToken,
        };

        return res;
      } );
  }

  createVwbPositiveAnswer( answer: VwbPositiveAnswer ): Promise<ApiResponse> {
    return this.api.request<ApiResponse>( new VwbPositiveAnswerRequest( answer ) )
      .then( ( response ) => {
        return response;
      } );
  }

  createVwbNegativeAnswer( answer: VwbNegativeAnswer ): Promise<ApiResponse> {
    return this.api.request<ApiResponse>( new VwbNegativeAnswerRequest( answer ) )
      .then( ( response ) => {
        return response;
      } );
  }

  updateVwbMessage( message: VwbMessageUpdate ): Promise<ApiResponse> {
    return this.api.request<ApiResponse>( new VwbMessageUpdateRequest( message ) )
      .then( ( response ) => {
        return response;
      } );
  }
}

export const GdvServiceContext: React.Context<IGdvService> = React.createContext( undefined as any );

export const useGdvService = (): IGdvService => {
  return React.useContext( GdvServiceContext );
};
