
import React, { Fragment } from 'react';

import { NavLink, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useAppUserPathPrefix } from './utils/providers/AppUserProvider';

export const NotFound: React.FC = ( props ) => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const userPrefix = useAppUserPathPrefix();

  return (
    <Fragment>
      <div className="auth-wrapper offline">
        <div className="text-center">
          <h1 className="mb-4">{ t( 'messages.error' ) } 404</h1>
          <h5 className="text-muted mb-4">{ t( 'messages.badRequest' ) }: <code>{ pathname }</code></h5>
          <NavLink
            to={ `${userPrefix}/` }
            className="btn btn-primary mb-4 active"
          >
            <i className="feather icon-home" />
            { t( 'messages.backToHome' ) }
          </NavLink>
        </div>
      </div>
    </Fragment>
  );
};
export default NotFound;
