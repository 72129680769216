import React from 'react';
import { IApiService } from './base';
import { IHubSpotTicket, IHubSpotTicketResponse } from './help-center/interfaces';
import { CreateTicketRequest } from './help-center/requests';

export interface IHelpCenterService {
  createTicket( ticket: IHubSpotTicket ): Promise<IHubSpotTicketResponse>;
}

export class HelpCenterService implements IHelpCenterService {
  protected api: IApiService;

  constructor( api: IApiService ) {
    this.api = api;
  }

  createTicket( ticket: IHubSpotTicket ): Promise<IHubSpotTicketResponse> {
    return this.api.request( new CreateTicketRequest( ticket ) )
      .then( ( response ) => {
        return response;
      } )
      .catch( ( e ) => {
        // Need to revert when using the api
        //if ( e?.error?.response?.data?.errors ) {
        if ( e?.error?.response?.data ) {
          return e.error.response.data;
        }
        throw new Error( e );
      } );
  }
};

export const HelpCenterServiceContext: React.Context<IHelpCenterService> = React.createContext( undefined as any );

export const useHelpCenterService = (): IHelpCenterService => {
  return React.useContext( HelpCenterServiceContext );
};
