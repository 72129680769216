import React, { useContext } from 'react';
import { ApiContext } from 'Services/api-context';
import { ServiceProviderProps } from './common';
import { IAlertService, AlertServiceContext } from 'Services/alert';

export const AlertServiceProvider: React.FC<ServiceProviderProps<IAlertService>> = ( props, context ) => {
  const api = useContext( ApiContext );
  const alertService: IAlertService = new props.useClass( api );

  return (
    <AlertServiceContext.Provider value={ alertService }>
      { props.children }
    </AlertServiceContext.Provider>
  );
};
