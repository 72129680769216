import React, { Context } from 'react';

export interface IStorageConverter<D, SD> {
  toStorageData( data: D ): SD;
  fromStorageData( storageData: SD ): D;
}

export interface IStorage {
  get<T>( key: string, converter?: IStorageConverter<T, any> ): T | null;
  set<T>( key: string, value: T, converter?: IStorageConverter<T, any> ): void;
}

export class LocalStorage implements IStorage {

  readonly storage: Storage;

  constructor() {
    this.storage = localStorage;
  }

  get<T>( key: string, converter?: IStorageConverter<T, any> ): T | null {
    const jsonValue = this.storage.getItem( key );
    if ( jsonValue === null ) {
      return null;
    }
    const value = JSON.parse( jsonValue );
    if ( converter !== undefined ) {
      return converter.fromStorageData( value );
    } else {
      return value as T;
    }
  }

  set<T>( key: string, value: T, converter?: IStorageConverter<T, any> ): void {
    let valueToStore: any = value;
    if ( converter !== undefined ) {
      valueToStore = converter.toStorageData( value );
    }
    const jsonValue = JSON.stringify( valueToStore );
    this.storage.setItem( key, jsonValue );
  }
}

export const defaultStorage: IStorage = new LocalStorage();
export const AppStorageContext: Context<IStorage> = React.createContext( defaultStorage );
