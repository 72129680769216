import { Partner } from './interfaces';
import { BaseRequest, PagingRequest } from 'Services/base';
import { getQueryParamsForListAPI, ListItems } from 'Services/api';

export class ListPartnersRequest extends BaseRequest<ListItems<Partner>> {
  constructor(
    pageParameters: PagingRequest,
    searchValue: string,
  ) {
    super( {
      method: 'GET',
      url: '/partnerservice/v1/partners',
      responseType: 'json',
      params: getQueryParamsForListAPI( {
        ...pageParameters,
      }, searchValue, 'search' ),
    } );
  }
}
