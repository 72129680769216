import React, { useContext } from 'react';

import { ApiContext } from 'Services/api-context';
import { ServiceProviderProps } from './common';
import { IGdvService, GdvServiceContext } from 'Services/gdv';

export const GdvServiceProvider: React.FC<ServiceProviderProps<IGdvService>> = ( props ) => {
  const api = useContext( ApiContext );
  const gdvService: IGdvService = new props.useClass( api );

  return (
    <GdvServiceContext.Provider value={ gdvService }>
      { props.children }
    </GdvServiceContext.Provider>
  );
};
