import { BaseRequest } from '../base';

// You also need to have request object defined. For all list of props look for the
// IRequestDescriptor interface properties
export class PoliciesRequest extends BaseRequest {
  constructor() {
    super( {
      method: 'GET',
      url: '/policies',
      responseType: 'json',
    } );
  }
}

// Originally we are only fetching policy related documents but this is for mock service
// to show the documents in table
export class DocumentsRequest extends BaseRequest {
  constructor() {
    super( {
      method: 'GET',
      url: '/documents',
      responseType: 'json',
    } );
  }
}

// Originally we are only fetching policy related payments but this is for mock service
// to show the payments in table
export class PaymentsRequest extends BaseRequest {
  constructor() {
    super( {
      method: 'GET',
      url: '/payments',
      responseType: 'json',
    } );
  }
}

export interface PolicyItem {
  policy_id: number;
  index: string;
  product_type: string;
  policy_code?: string;
  policy_number: number;
  company_name: string;
  customer_id: number;
  product: string;
  start_date: string;
  end_date: string;
  contract_length: string;
  payment_frequency: string;
  tariff: string;
  deductable: string;
  has_exemption: boolean;
  has_inability: boolean;
  has_liability: boolean;
  liability_group?: string;
  is_home_owner: boolean;
  has_bad_debit: boolean;
  has_extra_protection: boolean;
  has_rental_vehicle_protection: boolean;
  status: string;
  statusText?: string;
  type?: string;
  icon?: string;
  label?: string;
  navigation_path: string;
  valid_from?: string;
  valid_to?: string;
  holder_family_info: string;
  holder_birth_date: string;
  holder_zip_code: string;
}

export interface PaymentItem {
  date: string;
  dueDate: string;
  id: number;
  debit?: number;
  credit?: number;
  status: string;
  description: string;
  total?: number;
}

export interface ListPaymentsResponse {
  items: PaymentItem[];
  next_page_token: string;
}

export interface PaymentsResponse {
  items: PaymentItem[];
}
