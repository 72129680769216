import React, { useContext } from 'react';
import { ApiContext } from 'Services/api-context';
import { IPluginService, PluginServiceContext } from 'Services/plugin';
import { ServiceProviderProps } from './common';

export const PluginServiceProvider:
React.FC<ServiceProviderProps<IPluginService>> = ( props, context ) => {
  const api = useContext( ApiContext );
  const widgetService: IPluginService = new props.useClass( api );

  return (
    <PluginServiceContext.Provider value={ widgetService }>
      { props.children }
    </PluginServiceContext.Provider>
  );
};
