import dayjs from 'dayjs';
import { CreateOrganization, CustomSchemaItem } from 'Services/api/auth/base';
import { UserDataItem } from '../steps/UserDataForm';
import { ActiveStep, OrganizationState } from '../OrganizationRegistration';
import { cloneDeep } from 'lodash';
import { apiDateFilterFormat } from 'App/ui-utils';

export const generateCustomField = (
  customFields: Object, customSchema: CustomSchemaItem, dirtyFields: Object,
): Object => {
  const data = cloneDeep( customFields );
  const fields = customSchema.fields.items;

  if ( !fields ) {
    return data;
  }

  for ( let dataKey in data ) {
    const foundField = fields.find( ( item ) => item.name === dataKey );

    if ( foundField && foundField.type === 'date' && data[dataKey] ) {
      data[dataKey] = dayjs( data[dataKey] ).format( apiDateFilterFormat );
    }

    const isRequired = foundField?.isRequired;
    if ( !( dataKey in dirtyFields ) && !data[dataKey] && !isRequired ) {
      data[dataKey] = undefined;
    }

    if ( data[dataKey] === null ) {
      data[dataKey] = undefined;
    }
  }

  return data;
};

export const preparePayload = ( userData: UserDataItem, stateData: OrganizationState ): CreateOrganization => {
  const organizationState = stateData[ActiveStep.OrgData];

  const payload: CreateOrganization = {
    firstName: userData.firstName,
    lastName: userData.lastName,
    password: userData.newPassword,
    street: organizationState.street,
    houseNumber: organizationState.houseNumber,
    zipCode: organizationState.zipCode,
    city: organizationState.city,
    country: organizationState.country,
    isEulaAccepted: userData.isEulaAccepted ? userData.isEulaAccepted : false,
    customFields: stateData[ActiveStep.CustomData],
    invitationToken: stateData.invitationToken,
  };

  return payload;
};
