import { BaseRequest, PagingRequest } from '../base';
import { getQueryParamsForListAPI } from '../api';
import { StatusTransitionRuleItem } from './interfaces';

export interface ListStatusTransitionRuleItemsResponse {
  items: StatusTransitionRuleItem[];
  nextPageToken: string;
}

export class ListStatusTransitionRulesRequest extends BaseRequest<ListStatusTransitionRuleItemsResponse> {
  constructor(
    paging: PagingRequest,
  ) {

    const queryParams = getQueryParamsForListAPI( paging );

    super( {
      method: 'GET',
      url: '/insuranceservice/v1/status-transitions/rules',
      responseType: 'json',
      params: queryParams,
    } );
  }
}
