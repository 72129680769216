import { ApiResponse, BaseRequest, PagingRequest } from '../base';
import { IApiListResponse } from 'Services/policies/new/service';
import {
  BookingFunnelSettingsResponse,
  CreateFactorsResponse,
  CustomSettingsRequest,
  DefaultProductFieldsResponse,
  ICreateInsuredObject,
  ICreateProductPremiumFormula,
  CustomTenantSettingsResponse,
  IInsuranceTypeItem,
  IInsuredObjectItem,
  IInsuredObjectResponse,
  IProductCreateData,
  IProductField,
  IProductFieldType,
  IProductVariable,
  IUpdateInsuredObject,
  IUpdateVersion,
  ListProductsResponse,
  ProductFactorsResponse,
  ProductFieldResponse,
  ProductFieldsResponse,
  ProductPremiumFormulaResponse,
  ProductPremiumFormulasResponse,
  ProductResponse,
  ValidateFactorsResponse,
  VersionResponse, CreateProductLanguagePayload, UpdateProductLanguagePayload,
  DownloadTranslationsDocumentPayload, UploadTranslationsDocumentPayload,
  CreateNamedRangeRequest,
  CreateNamedRangeResponse,
  UpdateNamedRangeRequest,
  UpdateNamedRangeResponse,
  ListNamedRangesResponse,
  GetNamedRangeResponse,
  BookingFunnelItem,
  IPatchBookingFunnel,
  ICreateBookingFunnel,
  BookingFunnelGenericResponse,
  IGetBookingFunnelVersion,
  BookingFunnelGenericVersionResponse,
  IUpdateBookingFunnel,
} from './interfaces';
import { ProductFactorValuesEnum } from 'Services/products/enums';
import { getQueryParamsForListAPI } from 'Services/api';
import { ProductLanguage } from '../../App/components/management/products/shared/types';

const insuranceServiceApiServer = process.env.REACT_APP_INSURANCE_SERVICE_API_SERVER ?? '';
const insuranceService = `${insuranceServiceApiServer}/insuranceservice`;
const translationService = `${insuranceServiceApiServer}/translationservice`;

export class ListProductsRequest extends BaseRequest<ListProductsResponse> {
  constructor(
    paging: PagingRequest,
    searchValue?: string,
  ) {

    super( {
      method: 'GET',
      url: `${insuranceService}/v1/products?expand=versions`,
      responseType: 'json',
      params: getQueryParamsForListAPI( paging, searchValue, 'search' ),
    } );
  }
}

export class GetProductByIdRequest extends BaseRequest<ProductResponse> {
  constructor(
    productId: number,
  ) {

    super( {
      method: 'GET',
      url: `${insuranceService}/v1/products/${productId}?expand=versions`,
      responseType: 'json',
    } );
  }
}

export class GetProductFactorsRequest extends BaseRequest<ProductFactorsResponse> {
  constructor(
    versionId: number,
    values: ProductFactorValuesEnum,
  ) {
    const queryParams = new URLSearchParams( {
      productVersionId: `${versionId}`,
      values,
    } );

    super( {
      method: 'GET',
      url: `${insuranceService}/v1/product-factors/for-version`,
      responseType: 'json',
      params: queryParams,
    } );
  }
}

export class GetProductFactorsTestRequest extends BaseRequest<ProductFactorsResponse> {
  constructor(
    versionId: number,
  ) {
    const queryParams = new URLSearchParams( {
      filter: `productVersionId=${versionId}`,
    } );

    queryParams.set( 'pageSize', '1' ); // set it to 1 so request is as light as possible

    super( {
      method: 'GET',
      url: `${insuranceService}/v1/product-factors`,
      responseType: 'json',
      params: queryParams,
    } );
  }
}

export class CreateProductRequest extends BaseRequest<ProductResponse> {
  constructor(
    productData: IProductCreateData,
  ) {

    super( {
      method: 'POST',
      url: `${insuranceService}/v1/products`,
      data: productData,
      responseType: 'json',
    } );
  }
}

export class DeleteProductRequest extends BaseRequest<ApiResponse> {
  constructor(
    productId: number,
  ) {

    super( {
      method: 'DELETE',
      url: `${insuranceService}/v1/products/${productId}`,
      responseType: 'json',
    } );
  }
}

export class GetProductFieldsRequest extends BaseRequest<IApiListResponse<IProductVariable>> {
  constructor(
    initialPageToken: string,
    insuredObjectIds?: number[],
  ) {

    const queryParams = new URLSearchParams( );

    // Set default Page size and Page token
    queryParams.set( 'pageSize', '100' );
    if ( initialPageToken && initialPageToken !== '0' ) {
      queryParams.set( 'pageToken', initialPageToken );
    }

    if ( insuredObjectIds && insuredObjectIds.length > 0 ) {
      const filterPars = insuredObjectIds.map( ( item ) => `${encodeURIComponent( item )}` ).join( ',' );
      queryParams.set( 'filter', `insuredObjectId=${filterPars}` );
    }

    super( {
      method: 'GET',
      url: `${insuranceService}/v1/product-fields`,
      responseType: 'json',
      params: queryParams,
    } );
  }
}

export class GetProductFieldsTestRequest extends BaseRequest<ProductFieldsResponse> {
  constructor(
    versionId: number,
  ) {
    const queryParams = new URLSearchParams( {
      filter: `productVersionId=${versionId}`,
    } );

    queryParams.set( 'pageSize', '1' );

    super( {
      method: 'GET',
      url: `${insuranceService}/v1/product-fields`,
      responseType: 'json',
      params: queryParams,
    } );
  }
}

export class SwapProductFieldsOrderRequest extends BaseRequest<ApiResponse> {
  constructor(
    productFieldIdOne: number,
    productFieldIdTwo: number,
  ) {
    const payload = { productFieldIdOne, productFieldIdTwo };
    super( {
      method: 'POST',
      url: `${insuranceService}/v1/product-fields/reorder`,
      responseType: 'json',
      data: payload,
    } );
  }
}

export class GetProductPremiumFormulasRequest extends BaseRequest<ProductPremiumFormulasResponse> {
  constructor(
    versionId: number,
    pageToken?: number,
  ) {

    const queryParams = new URLSearchParams( {
      filter: `productVersionId=${versionId}`,
    } );

    queryParams.set( 'pageSize', '50' );

    if ( pageToken ) {
      queryParams.set( 'pageToken', `${pageToken}` );
    }

    super( {
      method: 'GET',
      url: `${insuranceService}/v1/premium-formulas`,
      responseType: 'json',
      params: queryParams,
    } );
  }
}

export class CreateFactorsRequest extends BaseRequest<CreateFactorsResponse> {
  constructor(
    factorsData: FormData,
  ) {

    super( {
      method: 'POST',
      url: `${insuranceService}/v1/product-factors`,
      responseType: 'json',
      headers: { 'Content-Type': 'multipart/form-data' },
      data: factorsData,
    } );
  }
}

export class ValidateFactorsRequest extends BaseRequest<ValidateFactorsResponse> {
  constructor(
    factorsData: FormData,
  ) {

    super( {
      method: 'POST',
      url: `${insuranceService}/v1/product-factors/validate`,
      responseType: 'json',
      headers: { 'Content-Type': 'multipart/form-data' },
      data: factorsData,
    } );
  }
}

export class CreateVariableRequest extends BaseRequest<ProductFieldResponse> {
  constructor(
    variable: IProductField,
  ) {

    super( {
      method: 'POST',
      url: `${insuranceService}/v1/product-fields`,
      responseType: 'json',
      data: variable,
    } );
  }
}

export class CreatePremiumFormulaRequest extends BaseRequest<ProductPremiumFormulaResponse> {
  constructor(
    premiumFormula: ICreateProductPremiumFormula,
  ) {

    super( {
      method: 'POST',
      url: `${insuranceService}/v1/premium-formulas`,
      responseType: 'json',
      data: premiumFormula,
    } );
  }
}

export class UpdateVariableRequest extends BaseRequest<ProductFieldResponse> {
  constructor(
    variableId: number,
    variable: IProductField,
  ) {

    super( {
      method: 'PUT',
      url: `${insuranceService}/v1/product-fields/${variableId}`,
      responseType: 'json',
      data: variable,
    } );
  }
}

export class UpdatePremiumFormulaRequest extends BaseRequest<ProductPremiumFormulaResponse> {
  constructor(
    premiumFormulaId: number,
    premiumFormula: ICreateProductPremiumFormula,
  ) {
    super( {
      method: 'PUT',
      url: `${insuranceService}/v1/premium-formulas/${premiumFormulaId}`,
      responseType: 'json',
      data: premiumFormula,
    } );
  }
}

export class SwapPremiumFormulasOrderRequest extends BaseRequest<ApiResponse> {
  constructor(
    id1: number,
    id2: number,
  ) {
    const payload = { id1, id2 };
    super( {
      method: 'POST',
      url: `${insuranceService}/v1/premium-formulas/reorder`,
      responseType: 'json',
      data: payload,
    } );
  }
}

export class DeleteVariableRequest extends BaseRequest<ApiResponse> {
  constructor(
    variableId: number,
  ) {

    super( {
      method: 'DELETE',
      url: `${insuranceService}/v1/product-fields/${variableId}`,
      responseType: 'json',
    } );
  }
}

export class DeletePremiumFormulaRequest extends BaseRequest<ApiResponse> {
  constructor(
    pFormulaId: number,
  ) {

    super( {
      method: 'DELETE',
      url: `${insuranceService}/v1/premium-formulas/${pFormulaId}`,
      responseType: 'json',
    } );
  }
}

export class UpdateVersionRequest extends BaseRequest<VersionResponse> {
  constructor(
    version: IUpdateVersion,
  ) {

    super( {
      method: 'PUT',
      url: `${insuranceService}/v1/product-versions/${version.id}`,
      responseType: 'json',
      data: version,
    } );
  }
}

export class ListInsuranceTypesRequest extends BaseRequest<IApiListResponse<IInsuranceTypeItem>> {
  constructor(
  ) {
    const queryParams = new URLSearchParams();

    // Set default Page size and Page token
    queryParams.set( 'pageSize', '30' );

    super( {
      method: 'GET',
      url: `${insuranceService}/v1/insured-object-types`,
      responseType: 'json',
      params: queryParams,
    } );
  }
}

export class GetDefaultProductFieldsRequest extends BaseRequest<DefaultProductFieldsResponse> {
  constructor(
    objectTypeId: number,
  ) {
    const queryParams = new URLSearchParams( {
      filter: `insuredObjectTypeId=${objectTypeId}`,
    } );
    // Set default Page size and Page token
    queryParams.set( 'pageSize', '30' );

    super( {
      method: 'GET',
      url: `${insuranceService}/v1/default-product-fields`,
      responseType: 'json',
      params: queryParams,
    } );
  }
}

export class GetBookingFunnelSettingsRequest extends BaseRequest<BookingFunnelSettingsResponse> {
  constructor(
    settingKey: string,
  ) {

    const params = new URLSearchParams();
    params.set( 'keys', settingKey );

    super( {
      method: 'GET',
      url: '/tenantservice/v1/settings/tenant',
      responseType: 'json',
      params: params,
    } );
  }
}

export class CreateBookingFunnelRequest extends BaseRequest<BookingFunnelGenericResponse> {
  constructor(
    objectData: ICreateBookingFunnel,
  ) {
    super( {
      method: 'POST',
      url: `${insuranceService}/v1/booking-funnels`,
      responseType: 'json',
      data: objectData,
    } );
  }
}
export class PatchBookingFunnelRequest extends BaseRequest<BookingFunnelGenericResponse> {
  constructor(
    { code, ...rest }: IPatchBookingFunnel,
  ) {
    super( {
      method: 'PATCH',
      url: `${insuranceService}/v1/booking-funnels/${code}`,
      responseType: 'json',
      data: rest,
    } );
  }
}
export class UpdateBookingFunnelRequest extends BaseRequest<BookingFunnelGenericResponse> {
  constructor(
    { code, ...rest }: IUpdateBookingFunnel,
  ) {
    super( {
      method: 'PUT',
      url: `${insuranceService}/v1/booking-funnels/${code}`,
      responseType: 'json',
      data: rest,
    } );
  }
}
export class ListBookingFunnelsRequest extends BaseRequest<IApiListResponse<BookingFunnelItem>> {
  constructor(
    paging: PagingRequest,
  ) {

    super( {
      method: 'GET',
      url: `${insuranceService}/v1/booking-funnels`,
      responseType: 'json',
      params: getQueryParamsForListAPI( paging ),
    } );
  }
}
export class DeleteBookingFunnelRequest extends BaseRequest<ApiResponse> {
  constructor(
    code: string,
  ) {
    super( {
      method: 'DELETE',
      url: `${insuranceService}/v1/booking-funnels/${code}`,
      responseType: 'json',
    } );
  }
}

export class GetBookingFunnelVersionRequest extends BaseRequest<BookingFunnelGenericVersionResponse> {
  constructor(
    { code, version }: IGetBookingFunnelVersion,
  ) {
    super( {
      method: 'GET',
      url: `${insuranceService}/v1/booking-funnels/${code}/versions/${version}`,
      responseType: 'json',
    } );
  }
}

export class SetCustomStylesSettingsRequest extends BaseRequest {
  constructor(
    settings: CustomSettingsRequest,
  ) {

    super( {
      method: 'POST',
      url: '/tenantservice/v1/settings/tenant',
      data: settings,
      responseType: 'json',
    } );
  }
}

export class GetCustomStylesSettingsRequest extends BaseRequest<CustomTenantSettingsResponse> {
  constructor(
    productCode: string,
  ) {

    const params = new URLSearchParams();
    params.set( 'keys', `bf_custom_css_${productCode}` );

    super( {
      method: 'GET',
      url: '/tenantservice/v1/settings/tenant',
      responseType: 'json',
      params: params,
    } );
  }
}

export class GetInsuredObjectsRequest extends BaseRequest<IApiListResponse<IInsuredObjectItem>> {
  constructor(
    productVersionId: number,
    expand?: string[],
  ) {
    const queryParams = new URLSearchParams( );

    queryParams.set( 'filter', `productVersionId=${productVersionId}` );
    queryParams.set( 'pageSize', '100' );

    if ( expand && expand.length > 0 ) {
      const expandPars = expand.map( ( item ) => `${encodeURIComponent( item )}` ).join( ',' );
      queryParams.set( 'expand', expandPars );
    }

    super( {
      method: 'GET',
      url: `${insuranceService}/v1/insured-objects`,
      responseType: 'json',
      params: queryParams,
    } );
  }
}

export class GetInsuredObjectRequest extends BaseRequest<IInsuredObjectResponse> {
  constructor(
    insuredObjectId: number,
    expand?: string[],
  ) {
    const queryParams = new URLSearchParams( );

    if ( expand && expand.length > 0 ) {
      const expandPars = expand.map( ( item ) => `${encodeURIComponent( item )}` ).join( ',' );
      queryParams.set( 'expand', expandPars );
    }

    super( {
      method: 'GET',
      url: `${insuranceService}/v1/insured-objects/${insuredObjectId}`,
      responseType: 'json',
      params: queryParams,
    } );
  }
}

export class CreateInsuredObjectRequest extends BaseRequest<IInsuredObjectResponse> {
  constructor(
    objectData: ICreateInsuredObject,
  ) {
    super( {
      method: 'POST',
      url: `${insuranceService}/v1/insured-objects`,
      responseType: 'json',
      data: objectData,
    } );
  }
}

export class UpdateInsuredObjectRequest extends BaseRequest<IInsuredObjectResponse> {
  constructor(
    objectData: IUpdateInsuredObject,
  ) {

    super( {
      method: 'PUT',
      url: `${insuranceService}/v1/insured-objects/${objectData.id!}`,
      responseType: 'json',
      data: objectData,
    } );
  }
}

export class DeleteInsuredObjectRequest extends BaseRequest<ApiResponse> {
  constructor(
    insuredObjectId: number,
  ) {

    super( {
      method: 'DELETE',
      url: `${insuranceService}/v1/insured-objects/${insuredObjectId}`,
      responseType: 'json',
    } );
  }
}

export class GetProductFieldTypesRequest extends BaseRequest<IApiListResponse<IProductFieldType>> {
  constructor( ) {
    const queryParams = new URLSearchParams( );
    queryParams.set( 'pageSize', '100' );

    super( {
      method: 'GET',
      url: `${insuranceService}/v1/product-fields/types`,
      responseType: 'json',
      params: queryParams,
    } );
  }
}

export class CreateProductLanguage extends BaseRequest<ProductLanguage> {
  constructor(
    payload: CreateProductLanguagePayload,
  ) {
    super( {
      method: 'POST',
      url: `${translationService}/v1/languages`,
      responseType: 'json',
      data: payload,
    } );
  }
}

export class UpdateProductLanguage extends BaseRequest<ProductLanguage> {
  constructor(
    payload: UpdateProductLanguagePayload,
  ) {
    super( {
      method: 'PUT',
      url: `${translationService}/v1/languages`,
      responseType: 'json',
      data: payload,
    } );
  }
}

export class GetProductLanguages extends BaseRequest<IApiListResponse<ProductLanguage>> {
  constructor(
    paging: PagingRequest,
  ) {
    super( {
      method: 'GET',
      url: `${translationService}/v1/languages`,
      responseType: 'json',
      params: getQueryParamsForListAPI( paging ),
    } );
  }
}

export class UploadTranslationsDocument extends BaseRequest<any> {
  constructor(
    payload: UploadTranslationsDocumentPayload,
  ) {
    const formData = new FormData();
    formData.append( 'file', payload.translations );

    const queryParams = new URLSearchParams();

    queryParams.set( 'language', payload.language );

    super( {
      method: 'POST',
      url: `${translationService}/v1/product/${payload.productSlug}/upload`,
      responseType: 'json',
      data: formData,
      params: queryParams,
    } );
  }
}

export class DownloadTranslationsDocument extends BaseRequest<Blob> {
  constructor(
    payload: DownloadTranslationsDocumentPayload,
  ) {
    const queryParams = new URLSearchParams();

    queryParams.set( 'language', payload.language );

    super( {
      method: 'GET',
      url: `${translationService}/v1/product/${payload.productSlug}/download`,
      responseType: 'blob',
      params: queryParams,
    } );
  }
}

export class GetProductNamedRangesRequest extends BaseRequest<ListNamedRangesResponse> {
  constructor(
    versionId: number,
  ) {

    const queryParams = new URLSearchParams( {
      filter: `productVersionId=${versionId}`,
    } );

    queryParams.set( 'pageSize', '1000000' );

    super( {
      method: 'GET',
      url: `${insuranceService}/v1/named-ranges`,
      responseType: 'json',
      params: queryParams,
    } );
  }
}

export class GetProductNamedRangeRequest extends BaseRequest<GetNamedRangeResponse> {
  constructor(
    namedRangeId: number,
  ) {

    super( {
      method: 'GET',
      url: `${insuranceService}/v1/named-ranges/${namedRangeId}`,
      responseType: 'json',
    } );
  }
}

export class CreateProductNamedRangeRequest extends BaseRequest<CreateNamedRangeResponse> {
  constructor(
    namedRange: CreateNamedRangeRequest,
  ) {

    super( {
      method: 'POST',
      url: `${insuranceService}/v1/named-ranges`,
      responseType: 'json',
      data: namedRange,
    } );
  }
}

export class UpdateProductNamedRangeRequest extends BaseRequest<UpdateNamedRangeResponse> {
  constructor(
    namedRangeId: number,
    namedRange: UpdateNamedRangeRequest,
  ) {

    super( {
      method: 'PUT',
      url: `${insuranceService}/v1/named-ranges/${namedRangeId}`,
      responseType: 'json',
      data: { ...namedRange, id: undefined },
    } );
  }
}

export class DeleteProductNamedRangeRequest extends BaseRequest<ApiResponse> {
  constructor(
    namedRangeId: number,
  ) {

    super( {
      method: 'DELETE',
      url: `${insuranceService}/v1/named-ranges/${namedRangeId}`,
      responseType: 'json',
    } );
  }
}
