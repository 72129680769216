import { BaseRequest, PagingRequest } from 'Services/base';
import { IApiListResponse } from 'Services/policies/new/service';
import { IPublicUrl, IProductDocument, IStaticDocumentResponse,
  IPreSignedPost, IUploadProductDocument } from './interfaces';
import { getQueryParamsForListAPI } from 'Services/api';

const documentsServiceApiServer = process.env.REACT_APP_DOCUMENT_SERVICE_API_SERVER ?? '';

export class ListProductDocuments extends BaseRequest<IApiListResponse<IProductDocument>> {
  constructor(
    searchValue: string,
    paging: PagingRequest,
    expand?: string[],
  ) {
    const queryParams = getQueryParamsForListAPI( paging, searchValue, 'search', ':' );

    if ( expand && expand.length > 0 ) {
      const expandPars = expand.map( ( item ) => `${encodeURIComponent( item )}` ).join( ',' );
      queryParams.set( 'expand', expandPars );
    }

    super( {
      method: 'GET',
      url: `${documentsServiceApiServer}/documentservice/v1/documents/product`,
      responseType: 'json',
      params: queryParams,
    } );
  }
}

export class GetProductDocumentRequest extends BaseRequest<IStaticDocumentResponse> {
  constructor(
    code: string,
    productSlug: string,
    expand?: string[],
  ) {
    const queryParams = new URLSearchParams();

    if ( expand && expand.length > 0 ) {
      const expandPars = expand.map( ( item ) => `${encodeURIComponent( item )}` ).join( ',' );
      queryParams.set( 'expand', expandPars );
    }

    super( {
      method: 'GET',
      url: `${documentsServiceApiServer}/documentservice/v1/documents/product/${productSlug}/${code}`,
      responseType: 'json',
      params: queryParams,
    } );
  }
}

export class UploadProductDocumentRequest extends BaseRequest<IPreSignedPost> {
  constructor(
    data: IUploadProductDocument,
  ) {
    super( {
      method: 'POST',
      url: `${documentsServiceApiServer}/documentservice/v1/documents/product/${data.productSlug}`,
      responseType: 'json',
      data: { ...data, requester: 'insuranceservice' },
    } );
  }
}

export class UploadFileWithPreSignedUrlRequest extends BaseRequest<Record<string, string>> {
  constructor(
    formData: FormData,
    url: string,
  ) {
    super( {
      method: 'POST',
      url: url,
      responseType: 'json',
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' },
    }, false );
  }
}

export class GetPublicUrlRequest extends BaseRequest<IPublicUrl> {
  constructor( code: string, productSlug: string ) {
    super( {
      method: 'GET',
      url: `${documentsServiceApiServer}/documentservice/v1/documents/product/${productSlug}/${code}/download-url`,
      responseType: 'json',
    } );
  }
}

export class DeleteProductDocumentRequest extends BaseRequest {
  constructor( code: string, productSlug: string ) {
    super( {
      method: 'DELETE',
      url: `${documentsServiceApiServer}/documentservice/v1/documents/product/${productSlug}/${code}`,
      responseType: 'json',
    } );
  }
}
