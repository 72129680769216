import React, { Fragment } from 'react';
import { AuthServiceContext } from 'Services/auth';
import { useAppLogger } from 'Services/logger';

export const AppAuthTestPage: React.FC = () => {
  const authService = React.useContext( AuthServiceContext );
  const logger = useAppLogger();

  const login1Handler = () => {
    authService.login( { username: 'sanya+small-sass-company-1@cover42.com', password: 'Password1' } )
      .then( ( auth ) => {
        logger.log( 'Response login1Handler', auth );
      } )
      .catch( ( err ) => {
        logger.log( 'Error login1Handler', err );
      } );
  };

  const logoutHandler = ( userName: string ) => {
    authService.logout( userName )
      .then( ( response ) => {
        logger.log( 'Response logoutHandler', userName, response );
      } )
      .catch( ( err ) => {
        logger.log( 'Error logoutHandler', userName, err );
      } );
  };

  const login2Handler = () => {
    authService.login( { username: 'sanya+small-sass-company-2@cover42.com', password: 'Password1' } )
      .then( ( auth ) => {
        logger.log( 'Response login2Handler', auth );
      } )
      .catch( ( err ) => {
        logger.log( 'Error login2Handler', err );
      } );
  };

  const refreshHandler = ( userName: string ) => {
    authService.refreshToken( userName )
      .then( ( auth ) => {
        logger.log( 'Response refreshHandler', userName, auth );
      } )
      .catch( ( err ) => {
        logger.log( 'Error refresh2Handler', userName, err );
      } );
  };

  return (
    <Fragment>
      <div>
        <button onClick={ login1Handler }>login1</button>
        <button onClick={ () => refreshHandler( 'sanya+small-sass-company-1@cover42.com' ) }>refresh1</button>
        <button onClick={ () => logoutHandler( 'sanya+small-sass-company-1@cover42.com' ) }>logout1</button>
      </div>
      <div>
        <button onClick={ login2Handler }>login2</button>
        <button onClick={ () => refreshHandler( 'sanya+small-sass-company-2@cover42.com' ) }>refresh2</button>
        <button onClick={ () => logoutHandler( 'sanya+small-sass-company-2@cover42.com' ) }>logout2</button>
      </div>
    </Fragment>
  );

};

export default AppAuthTestPage;
