import { IApiReportItem, IReportResponse, IApiReportHistoryItem } from './interfaces';
import { BaseRequest, PagingRequest } from 'Services/base';
import { IApiListResponse } from 'Services/policies/new/service';
import { IReportDetails } from 'Services/reports/interfaces';

export class GetReportRequest extends BaseRequest<IReportResponse> {
  constructor(
    reportCode: string,
  ) {

    super( {
      method: 'GET',
      url: `/pbmservice/v1/reports/${reportCode}`,
      responseType: 'json',
    } );
  }
}
export class GetReportTypesRequest extends BaseRequest<IApiListResponse<IReportDetails>> {
  constructor(
  ) {
    super( {
      method: 'GET',
      url: '/pbmservice/v1/reports',
      responseType: 'json',
    } );
  }
}

export class ListReportRequest extends BaseRequest<IApiListResponse<IApiReportItem>> {
  constructor(
    searchValue: string,
    paging: PagingRequest,
    expand?: string[],
  ) {

    const queryParams = new URLSearchParams();
    queryParams.set( 'pageSize', paging.pageSize.toString() );
    queryParams.set( 'pageToken', paging.pageToken.toString() );

    if ( paging.filter.length ) {
      const filterParams = paging.filter
        .map( ( item ) => `${item.id}=${encodeURIComponent( item.value )}` )
        .join( '&' );
      queryParams.set( 'filter', filterParams );
    }

    if ( paging.orderBy.length ) {
      const orderParams = paging.orderBy.map( ( item ) => `${item.id}:${ item.desc ? 'DESC' : 'ASC' }` ).join( '&' );
      queryParams.set( 'order', orderParams );
    }

    if ( searchValue ) {
      queryParams.set( 'query', searchValue );
    }

    if ( expand && expand.length > 0 ) {
      const expandParams = expand.map( ( item ) => `${encodeURIComponent( item )}` ).join( ',' );
      queryParams.set( 'expand', expandParams );
    }

    super( {
      method: 'GET',
      url: '/pbmservice/v1/reports',
      responseType: 'json',
      params: queryParams,
    } );
  }
}

export class ListReportHistoryRequest extends BaseRequest<IApiListResponse<IApiReportHistoryItem>> {
  constructor(
    reportCode: string,
    searchValue: string,
    paging: PagingRequest,
    expand?: string[],
  ) {

    const queryParams = new URLSearchParams();
    queryParams.set( 'pageSize', paging.pageSize.toString() );
    queryParams.set( 'pageToken', paging.pageToken.toString() );

    if ( paging.filter.length ) {
      const filterParams = paging.filter
        .map( ( item ) => `${item.id}=${encodeURIComponent( item.value )}` )
        .join( '&' );
      queryParams.set( 'filter', filterParams );
    }

    if ( paging.orderBy.length ) {
      const orderParams = paging.orderBy.map( ( item ) => `${item.id}:${ item.desc ? 'DESC' : 'ASC' }` ).join( '&' );
      queryParams.set( 'order', orderParams );
    }

    if ( searchValue ) {
      queryParams.set( 'query', searchValue );
    }

    if ( expand && expand.length > 0 ) {
      const expandParams = expand.map( ( item ) => `${encodeURIComponent( item )}` ).join( ',' );
      queryParams.set( 'expand', expandParams );
    }

    super( {
      method: 'GET',
      url: `/pbmservice/v1/reports/${reportCode}/history`,
      responseType: 'json',
      params: queryParams,
    } );
  }
}
