import React from 'react';
import { AppAlert } from './base';
import { Alert } from 'react-bootstrap';

export interface AppAlertService {
  showAlert( alert: AppAlert ): void;
  hideAlert(): void;
}

const AppAlertServiceContext = React.createContext<Readonly<AppAlertService>>( undefined as any );

const AppAlertStateContext = React.createContext<AppAlert | undefined>( undefined );

export const AppAlertServiceProvider: React.FC = ( props ) => {

  const [ alertState, setAlertState ] = React.useState<AppAlert | undefined>( undefined );

  const showAlertHandler = React.useCallback( ( alert: AppAlert ) => {
    setAlertState( alert );
  }, [] );

  const hideAlertHandler = React.useCallback( () => {
    setAlertState( undefined );
  }, [] );

  const service = React.useMemo<AppAlertService>( () => {
    return {
      showAlert: showAlertHandler,
      hideAlert: hideAlertHandler,
    };
  }, [ hideAlertHandler, showAlertHandler ] );

  return (
    <AppAlertServiceContext.Provider value={ service }>
      <AppAlertStateContext.Provider value={ alertState }>
        { props.children }
      </AppAlertStateContext.Provider>
    </AppAlertServiceContext.Provider>
  );
};

export const useAppAlertService: () => Readonly<AppAlertService> = () => {
  const service = React.useContext( AppAlertServiceContext );
  return service;
};

export const useAppAlertState: () => Readonly<AppAlert | undefined> = () => {
  const alertState = React.useContext( AppAlertStateContext );
  return alertState;
};

interface AppAlertUIProps {
  onCloseAlertCallback?: () => void;
}

export const AppAlertUI: React.FC<AppAlertUIProps> = ( { onCloseAlertCallback } ) => {
  const { hideAlert } = useAppAlertService();
  const alertState = useAppAlertState();

  const onClose = React.useCallback( (): void => {
    if ( onCloseAlertCallback ) {
      onCloseAlertCallback();
    } else {
      hideAlert();
    }
  }, [ hideAlert, onCloseAlertCallback ] );

  return alertState !== undefined ? (
    <Alert
      dismissible
      variant={ alertState.type }
      show={ alert !== undefined }
      onClose={ onClose }
    >
      { typeof alertState.message === 'object' ? ( alertState.message ) : (
        <div dangerouslySetInnerHTML={ { __html: `${alertState.message}` } }></div>
      ) }
    </Alert>
  ) : null;
};
