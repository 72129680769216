import React from 'react';
import { Modal, Button, ButtonProps, ModalProps, Spinner, Alert } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useAppLogger } from 'Services/logger';
import { isString } from 'types';

export type ConfirmModalType = 'info' | 'warning' | 'assign' | 'moveTo';

interface ConfirmModalTypeDefinition {
  headerClassName: string;
  headerIcon: string;
  confirmButtonVariant: ButtonProps['variant']; // indexed access types also called lookup types
}

const confirmModalTypes: Record<string, ConfirmModalTypeDefinition> = {
  info: {
    headerClassName: 'd-inline-flex align-items-center f-16',
    headerIcon: 'info',
    confirmButtonVariant: 'primary',
  },
  warning: {
    headerClassName: 'd-inline-flex align-items-center f-16',
    headerIcon: 'warning',
    confirmButtonVariant: 'danger',
  },
  assign: {
    headerClassName: 'd-inline-flex align-items-center f-17',
    headerIcon: 'account_circle',
    confirmButtonVariant: 'primary',
  },
  moveTo: {
    headerClassName: 'd-inline-flex align-items-center f-17',
    headerIcon: 'arrow_forward',
    confirmButtonVariant: 'primary',
  },
};

export interface ConfirmModalProps {
  title: string;
  type?: ConfirmModalType;
  modalProps?: ModalProps;
  cancelButtonProps?: ButtonProps;
  cancelButtonLabel: string;
  confirmButtonProps?: ButtonProps;
  confirmButtonLabel: string;
  confirmButtonDisabled?: boolean;
  onClose: ( isConfirmed: boolean ) => void;
  onConfirm: () => Promise<void>;
  children: React.ReactNode;
  iconColorClass?: string;
}

export const ConfirmModal: React.FC<ConfirmModalProps> = ( {
  title,
  type = 'info',
  modalProps = { size: 'sm', className: 'confirm-modal', centered: true },
  cancelButtonProps = { variant: 'secondary' },
  cancelButtonLabel,
  onClose,
  confirmButtonProps = { variant: 'primary' },
  confirmButtonLabel,
  confirmButtonDisabled = false,
  onConfirm,
  children,
  iconColorClass,
} ) => {

  const logger = useAppLogger();
  const [ actionsDisabled, setActionsDisabled ] = React.useState<boolean>( false );
  const [ showModal, setShowModal ] = React.useState<boolean>( true );
  const style = confirmModalTypes[type];

  const cancelHandler = React.useCallback( () => {
    setActionsDisabled( true );
    setShowModal( false );
    onClose( false );
  }, [ onClose ] );

  const confirmHandler = React.useCallback( () => {
    setActionsDisabled( true );
    onConfirm()
      .then( () => {
        setShowModal( false );
        onClose( true );
      } )
      .catch( ( e ) => {
        logger.error( 'Error in ConfirmDialog during confirming handler', e );
        setShowModal( false );
        onClose( false );
      } );
  }, [ logger, onClose, onConfirm ] );

  return (
    <Modal
      { ...modalProps }
      show={ showModal }
      onHide={ cancelHandler }
    >
      <Modal.Header closeButton>
        <Modal.Title as="h6" className={ style.headerClassName }>
          { type === 'assign' ?
            <i className="material-icons material-icons-outlined mr-3 f-22 text-primary-color">
              { style.headerIcon }
            </i>
            : <i className={ `material-icons f-22 mr-2 ${iconColorClass}` }>{ style.headerIcon }</i>
          }
          { title }
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-b-40 p-t-30">
        { children }
      </Modal.Body>
      <Modal.Footer className="p-b-20 p-t-20">
        <Button
          { ...cancelButtonProps }
          onClick={ cancelHandler }
          disabled={ actionsDisabled }
          id="confirm-modal-cancel-button"
        >
          { cancelButtonLabel }
        </Button>
        <Button
          { ...confirmButtonProps }
          onClick={ confirmHandler }
          variant={ style.confirmButtonVariant }
          disabled={ confirmButtonDisabled || actionsDisabled }
          id="confirm-modal-confirm-button"
        >
          { confirmButtonLabel }
        </Button>
      </Modal.Footer>
    </Modal>
  );

};


export interface IConfirmModalActions {
  performConfirmAction: ( action: IConfirmAction ) => void;
}

export const ConfirmModalActionsContext: React.Context<IConfirmModalActions> = React.createContext( undefined as any );

export const useConfirmModalActions = (): IConfirmModalActions => {
  return React.useContext( ConfirmModalActionsContext );
};

export interface IConfirmAction {
  title?: React.ReactNode;
  body?: React.ReactNode;
  onCancel?: () => Promise<void>;
  onConfirm: () => Promise<void>;
  confirmButtonLabel?: string;
  confirmButtonProps?: ButtonProps;
  cancelButtonLabel?: string;
  cancelButtonProps?: ButtonProps;
}

export const ConfirmModalProvider: React.FC = ( props ) => {

  const { t } = useTranslation( [ 'base' ] );
  const logger = useAppLogger();

  const defaultConfirmAction = React.useMemo<IConfirmAction>( () => {
    return {
      title: t( 'forms.confirm' ) + '?',
      onConfirm: () => { return Promise.resolve(); },
      confirmButtonLabel: t( 'forms.confirm' ),
      confirmButtonProps: { size: 'sm', variant: 'danger' },
      cancelButtonLabel: t( 'forms.cancel' ),
      cancelButtonProps: { size: 'sm', variant: 'secondary' },
    };
  }, [ t ] );

  const [ working, setWorking ] = React.useState<boolean>( false );
  const [ errorMessage, setErrorMessage ] = React.useState<string | null>( null );
  const [ show, showModal ] = React.useState<boolean>( false );
  const [ state, setState ] = React.useState<IConfirmAction>( defaultConfirmAction );
  const { onConfirm, onCancel } = state;

  const cleanAction = React.useCallback( () => {
    setWorking( false );
    showModal( false );
    setErrorMessage( null );
    setState( defaultConfirmAction );
  }, [ defaultConfirmAction ] );

  const cancelHandler = React.useCallback( () => {
    if ( working ) {
      // already working
      return;
    }
    setWorking( true );
    if ( onCancel !== undefined ) {
      onCancel().finally( () => {
        cleanAction();
      } );
    } else {
      cleanAction();
    }
  }, [ cleanAction, onCancel, working ] );

  const confirmHandler = React.useCallback( () => {
    setWorking( true );
    onConfirm().then( () => {
      cleanAction();
    } ).catch( ( e ) => {
      setWorking( false );
      logger.error( 'Error catched in confirm action', e );
      if ( e['errorMessage'] !== undefined && isString( e['errorMessage'] ) ) {
        setErrorMessage( e['errorMessage'] ); // display error to the user if it has errorMessage string properties
      } else {
        setErrorMessage( t( 'forms.messages.error' ) ); // display default error messages if unknown error occurred
      }
    } );
  }, [ cleanAction, logger, onConfirm, t ] );

  const actions = React.useMemo<IConfirmModalActions>( () => {
    return {
      performConfirmAction: ( action ) => {
        setState( ( prevState ) => {
          return { ...prevState, ...action };
        } );
        showModal( true );
      },
    };
  }, [] );

  return (
    <ConfirmModalActionsContext.Provider value={ actions }>
      { props.children }
      <Modal size="sm" className="confirm-modal" centered show={ show } onHide={ cancelHandler }>
        <Modal.Header closeButton>
          <Modal.Title as="h6" className="d-inline-flex align-items-center f-16">
            { state.title }
          </Modal.Title>
          { working && <Spinner animation="border" size="sm" /> }
        </Modal.Header>
        <Modal.Body>
          { errorMessage !== null && <Alert variant="danger">{ errorMessage }</Alert> }
          { state.body }
        </Modal.Body>
        <Modal.Footer>
          <Button
            { ...state.cancelButtonProps }
            disabled={ working }
            onClick={ cancelHandler }
          >
            { state.cancelButtonLabel }
          </Button>
          <Button
            { ...state.confirmButtonProps }
            disabled={ working }
            onClick={ confirmHandler }
          >
            { state.confirmButtonLabel }
          </Button>
        </Modal.Footer>
      </Modal>
    </ConfirmModalActionsContext.Provider>
  );
};
