import React from 'react';
import { useTranslation } from 'react-i18next';
import { useAppCurrentProduct } from '../utils/providers/AppProductProvider';

import { TourItem } from './tourPages';
import { generateDashboardTourItem } from './pages/dashboard';
import { generatePBTourItem } from './pages/pb';

export const useTourData = (): TourItem[] => {
  const { t } = useTranslation( [ 'tour' ] );
  const product = useAppCurrentProduct();
  const tourItems = React.useMemo<TourItem[]>( () => {
    return [
      generateDashboardTourItem( t ),
      generatePBTourItem( t, product ),
    ];
  }, [ t, product ] );
  return tourItems;
};
