import { Dispatch, Reducer } from 'react';
import { clone } from 'lodash';

export interface IMenuState {
  collapseMenu: boolean;
  openItems: string[];
  triggerItems: string[];
}

export const initialMenuState: IMenuState = {
  collapseMenu: false,
  openItems: [],
  triggerItems: [],
};

type MenuActionMessageType =
  | { type: 'COLLAPSE_MENU' }
  | { type: 'NAV_CONTENT_LEAVE' }
  | { type: 'COLLAPSE_TOGGLE'; payload: { menuId: string; menuType: string } }
  | { type: 'NAV_COLLAPSE_LEAVE'; payload: { menuId: string; menuType: string } };

export type MenuReducer = Reducer<IMenuState, MenuActionMessageType>;

export const menuReducer: MenuReducer = ( prevState, action ) => {
  switch ( action.type ) {
    case 'COLLAPSE_MENU': {
      return {
        ...prevState,
        collapseMenu: !prevState.collapseMenu,
      };
    }
    case 'NAV_CONTENT_LEAVE': {
      return {
        ...prevState,
        openItems: [],
        triggerItems: [],
      };
    }
    case 'COLLAPSE_TOGGLE': {
      let openMenuItems = clone( prevState.openItems );
      let triggers = clone ( prevState.triggerItems );
      const { menuId, menuType } = action.payload;
      if ( menuType === 'sub' ) {
        const hasMenuItemTriggered: boolean = triggers.includes( menuId );
        if ( hasMenuItemTriggered ) {
          openMenuItems = openMenuItems.filter( ( item ) => item !== menuId );
          triggers = triggers.filter( ( item ) => item !== menuId );
        } else {
          openMenuItems = [ ...openMenuItems, menuId ];
          triggers = [ ...triggers, menuId ];
        }
      } else {
        const triggerIndex = prevState.triggerItems.indexOf( menuId );
        triggers = triggerIndex === -1 ? [ menuId ] : [];
        openMenuItems = triggerIndex === -1 ? [ menuId ] : [];
      }
      return {
        ...prevState,
        openItems: openMenuItems,
        triggerItems: triggers,
      };
    }
    case 'NAV_COLLAPSE_LEAVE': {
      let openMenuItems = clone( prevState.openItems );
      let triggers = clone ( prevState.triggerItems );
      const { menuId, menuType } = action.payload;
      if ( menuType === 'sub' ) {
        // It seems that no-shadow is raising in switch case false alarm
        // eslint-disable-next-line no-shadow
        const triggerIndex = triggers.indexOf( menuId );
        if ( triggerIndex > -1 ) {
          openMenuItems = openMenuItems.filter( ( item ) => item !== menuId );
          triggers = triggers.filter( ( item ) => item !== menuId );
        }
        return {
          ...prevState,
          openItems: openMenuItems,
          triggerItems: triggers,
        };
      }
      return prevState;
    }
  }
};

export interface IMenuActions {
  collapseMenu(): void;
  navContentLeave(): void;
  collapseToggle( id: string, type: string ): void;
  navCollapseLeave( id: string, type: string ): void;
}

export class MenuActionsExecutor implements IMenuActions {

  private readonly dispatcher: Dispatch<MenuActionMessageType>;

  constructor( dispatcher: Dispatch<MenuActionMessageType> ) {
    this.dispatcher = dispatcher;
    this.collapseMenu = this.collapseMenu.bind( this );
    this.navContentLeave = this.navContentLeave.bind( this );
    this.collapseToggle = this.collapseToggle.bind( this );
    this.navCollapseLeave = this.navCollapseLeave.bind( this );
  }

  collapseMenu(): void {
    this.dispatcher( { type: 'COLLAPSE_MENU' } );
  }

  navContentLeave(): void {
    this.dispatcher( { type: 'NAV_CONTENT_LEAVE' } );
  }

  collapseToggle( id: string, type: string, ids?: string[] ): void {
    this.dispatcher( { type: 'COLLAPSE_TOGGLE', payload: { menuId: id, menuType: type } } );
  }

  navCollapseLeave( id: string, type: string ): void {
    this.dispatcher( { type: 'NAV_COLLAPSE_LEAVE', payload: { menuId: id, menuType: type } } );
  }

}
