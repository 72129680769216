import React from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { useDocumentService } from 'Services/document';
import { useLoadingSpinnerOnFullContainer } from 'App/components/utils/LoadingSpinner';
import { useAppAlertService, AppAlertUI } from 'App/components/utils/alerts/AppAlertService';

export interface DownloadParams {
  s3Key: string;
}

const DownloadPage: React.FC = () => {
  const loadingOnFullContainer = useLoadingSpinnerOnFullContainer();
  const { t } = useTranslation( [ 'tariff-comparison' ] );
  const { showAlert } = useAppAlertService();
  const documentService = useDocumentService();

  const s3Key = decodeURIComponent( useParams<DownloadParams>().s3Key );
  const [ isLoading, setIsLoading ] = React.useState<boolean>( false );

  React.useEffect( () => {
    documentService.getSignedS3Url( s3Key )
      .then( ( result ) => {
        // After starting the file downloading we need to close the tab
        window.location.href = result;
        setTimeout( () => { window.close(); }, 2000 );
      } )
      .catch( ( err ) => {
        setIsLoading( false );
        showAlert( {
          message: t( 'base:forms.messages.error' ),
          type: 'danger',
        } );
      } );
  }, [ documentService, s3Key, showAlert, t ] );

  // CSS ruls to hide the header, footer and the nav bar
  const css = `
    nav.pcoded-navbar,
    header.navbar,
    div.app-footer {
      display: none !important;
    }

    div.pcoded-main-container {
      width: 100%;
      margin-left: 0;
    }

    div.alert {
      margin-top: 100px;
    }
  `;

  return (
    <>
      <style>{ css }</style>

      <AppAlertUI />
      { isLoading && loadingOnFullContainer }
    </>
  );
};

export default DownloadPage;
