
import React from 'react';
import { NavLink, NavLinkProps } from 'react-router-dom';
import { Path } from 'history';

import { useAppUserPathPrefix } from '../providers/AppUserProvider';

export interface AppNavLinkProps extends NavLinkProps {
  to: Path;
  withoutUserContext? : boolean;
}

export const AppNavLink: React.FC<AppNavLinkProps> = ( props ) => {
  const userPrefix = useAppUserPathPrefix();

  const { to, withoutUserContext, ...rest } = props;

  const putPrefix = withoutUserContext === undefined ? true : !withoutUserContext;

  let navigateTo: Path = to;
  if ( to.startsWith( '/' ) && putPrefix ) {
    navigateTo = `${userPrefix}${to}`;
  }

  return (
    <NavLink { ...rest } to={ navigateTo }>
      { props.children }
    </NavLink>
  );
};
