import React from 'react';
import { PbmProduct } from '@cover42/ts-contracts';
import { IApiService } from './base';
import { IOptionSelect } from './tariff-comparisons/interfaces';
import {
  InsuranceTariff,
  SearchableDocumentOwner,
  GetSearchableDocumentsResponse,
  GetSearchKeywordsResponse,
  CompareTariffsResponse,
} from './tariff-comparisons/interfaces';
import {
  GetCompareTariffsRequest,
  GetInsuranceListRequest,
  GetTariffListRequest,
  GetSearchableDocumentOwnersRequest,
  GetSearchableDocumentsRequest,
  GetSearchKeywordsRequest,
} from './tariff-comparisons/requests';

export interface ITariffComparisonService {
  getTariffInsuranceCompanies( product: PbmProduct ): Promise<IOptionSelect[]>;
  getTariffs( product: PbmProduct, insurerName: string ): Promise<IOptionSelect[]>;
  compareTariffs( product: PbmProduct, dataForm: InsuranceTariff[] ): Promise<CompareTariffsResponse>;

  getSearchableDocumentOwners( product: PbmProduct ): Promise<SearchableDocumentOwner[]>;
  getSearchableDocuments(
    searchText: string,
    ownerIds: number[],
    product: PbmProduct
  ): Promise<GetSearchableDocumentsResponse>;
  getSearchKeywords( searchText: string ): Promise<GetSearchKeywordsResponse>;
}

export class TariffComparisonService implements ITariffComparisonService {
  protected api: IApiService;

  constructor( api: IApiService ) {
    this.api = api;
  }

  getTariffInsuranceCompanies( product: PbmProduct ): Promise<IOptionSelect[]> {
    return this.api.request( new GetInsuranceListRequest( product ) )
      .then( ( response ) => {
        let insurerCompanies: IOptionSelect[] = [];
        const { items } = response;

        if ( items ) {
          insurerCompanies = items.map( ( tariff ) => {
            return {
              value: tariff.name,
            };
          } );
        }

        return insurerCompanies;
      } );
  }

  getTariffs( product: PbmProduct, insurerName: string ): Promise<IOptionSelect[]> {
    return this.api.request( new GetTariffListRequest( product, insurerName ) )
      .then( ( response ) => {
        let tariffItems: IOptionSelect[] = [];
        const { items } = response;

        if ( items ) {
          tariffItems = items.map( ( tariff ) => {
            return {
              value: tariff.name,
            };
          } );
        }

        return tariffItems;
      } );
  }

  compareTariffs( product: PbmProduct, dataForm: InsuranceTariff[] ): Promise<CompareTariffsResponse> {
    return this.api.request( new GetCompareTariffsRequest( product, dataForm ) )
      .then( ( response ) => {
        const { items, docInfo } = response;
        return { items, docInfo };
      } );
  }

  getSearchableDocumentOwners( product: PbmProduct ): Promise<SearchableDocumentOwner[]> {
    return this.api.request( new GetSearchableDocumentOwnersRequest( product ) )
      .then( ( response ) => {
        const { owners } = response;
        return owners;
      } );
  }

  getSearchableDocuments(
    searchText: string,
    ownerIds: number[],
    product: PbmProduct,
  ): Promise<GetSearchableDocumentsResponse>{
    return this.api.request( new GetSearchableDocumentsRequest( searchText, ownerIds, product ) )
      .then( ( response ) => {
        return response;
      } );
  }

  getSearchKeywords( searchText: string ): Promise<GetSearchKeywordsResponse>{
    return this.api.request( new GetSearchKeywordsRequest( searchText ) )
      .then( ( response ) => {
        return response;
      } );
  }};

export const TariffComparisonServiceContext: React.Context<ITariffComparisonService> = React.createContext(
  undefined as any,
);

export const useTariffComparisonService = (): ITariffComparisonService => {
  return React.useContext( TariffComparisonServiceContext );
};



