import React, { DetailedHTMLProps, ImgHTMLAttributes } from 'react';
import { useAppLogger } from 'Services/logger';

type ImgAdditionalProps = { fallbackSrc: string };
type ImgProps = DetailedHTMLProps<ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement> & ImgAdditionalProps;

/**
 * This is wrapper around standard Img but it also support fallbackSrc in case main
 * src request fails it request fallbackSrc so it always present some img.
 */
export const Img: React.FC<ImgProps> = ( props ) => {
  const { fallbackSrc, alt, ...imgProps } = props;
  const logger = useAppLogger();
  const imgRef = React.useRef<HTMLImageElement>( null );
  const canFallback = React.useRef<boolean>( true );

  const onErrorCallback = React.useCallback( () => {
    const imgElement = imgRef.current;
    if ( imgElement && canFallback.current ) {
      logger.warn( `Img - Can't load img src: "${imgElement.src}". Falling back to: "${fallbackSrc}"` );
      imgElement.src = fallbackSrc;
      canFallback.current = false;
    }
  }, [ fallbackSrc, logger ] );

  return (
    <img alt={ alt } { ...imgProps } ref={ imgRef } onError={ onErrorCallback } />
  );
};
