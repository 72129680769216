import React, { useContext } from 'react';
import { ApiContext } from 'Services/api-context';
import { ServiceProviderProps } from './common';
import { IReportService, ReportServiceContext } from 'Services/report';

export const ReportServiceProvider: React.FC<ServiceProviderProps<IReportService>> = ( props, context ) => {
  const api = useContext( ApiContext );
  const reportService: IReportService = new props.useClass( api );

  return (
    <ReportServiceContext.Provider value={ reportService }>
      { props.children }
    </ReportServiceContext.Provider>
  );
};
