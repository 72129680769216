import { TourItem } from '../tourPages';
import { TFunction } from 'i18next';

export const generateDashboardTourItem = ( t: TFunction ): TourItem => {
  return {
    pagePath: '/u/:accountIndex/dashboard',
    run: false,
    steps: [
      {
        content: t( 'dashboardPageTour.newPoliciesContent' ),
        placement: 'bottom',
        target: '#statistial-visit-wgt',
        disableBeacon: true,
      },
      {
        content: t( 'dashboardPageTour.newsContent' ),
        placement: 'bottom',
        target: '#news-wgt',
        disableBeacon: true,
      },
      {
        content: t( 'dashboardPageTour.remindersContent' ),
        placement: 'top',
        target: '#reminder-wgt',
        disableBeacon: true,
      },
      {
        content: t( 'dashboardPageTour.ticketStatisticsContent' ),
        placement: 'top',
        target: '#ticket-statistics-wgt',
        disableBeacon: true,
      },
      {
        content: t( 'dashboardPageTour.taskListContent' ),
        placement: 'top',
        target: '#task-list-table-wgt',
        disableBeacon: true,
      },
      {
        content: t( 'dashboardPageTour.searchContent' ),
        placement: 'bottom',
        target: '#global-search-header .emil-box-search__control',
        disableBeacon: true,
      },
    ],
  };
};
