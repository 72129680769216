import React, { Fragment } from 'react';
import { Dropdown } from 'react-bootstrap';
import { blankLink } from 'config';
import { useTranslation } from 'react-i18next';
import { mockedGeneralSettings } from 'Services/api/settings/mock-data';
import { defaultStorage } from '../../../components/utils/providers/AppStorageCtx';
import { GeneralItem } from 'Services/api/settings/base';
import { startCase, toLower } from 'lodash';
import { listLanguages } from 'App/ui-utils';

export const NavLanguage: React.FC = ( ) => {
  const { i18n, t } = useTranslation( [ 'base' ] );
  const currentLng = i18n.language;

  const handleChangeLanguage = React.useCallback( ( lng ) => {
    const generalItemKey: string = 'GET: /general';
    const newGeneralItem: GeneralItem = {
      ...mockedGeneralSettings,
      language: lng,
    };
    defaultStorage.set<GeneralItem>( generalItemKey, newGeneralItem );
    window.location.reload( );
  }, [] );

  return (
    <Fragment>
      <Dropdown className="lng-nav">
        <Dropdown.Toggle className="btn btn-link m-0 px-1 py-0 app-secondary-link" variant={ 'link' } id="dropdownLng">
          { t( `general.lng${startCase( toLower( currentLng ) )}` ) }
        </Dropdown.Toggle>
        <Dropdown.Menu alignRight className="profile-notification emil-styled pb-3" id="lngMenu">
          { listLanguages.map( ( lng, idx ) => (
            <Fragment key={ idx }>
              { currentLng !== lng && (
                <Dropdown.Item
                  href={ blankLink } onClick={ () => handleChangeLanguage( lng ) }
                >
                  { t( `general.lng${startCase( toLower( lng ) )}` ) }
                </Dropdown.Item>
              ) }
            </Fragment>
          ) ) }
        </Dropdown.Menu>
      </Dropdown>
    </Fragment>
  );
};
