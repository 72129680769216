import React, { useEffect } from 'react';
import { NavLogo } from './NavLogo';
import { OutsideClick } from './OutsideClick';
import navImage1 from 'assets/images/nav-bg/navbar-img-1.jpg';
import navImage2 from 'assets/images/nav-bg/navbar-img-2.jpg';
import navImage3 from 'assets/images/nav-bg/navbar-img-3.jpg';
import navImage4 from 'assets/images/nav-bg/navbar-img-4.jpg';
import navImage5 from 'assets/images/nav-bg/navbar-img-5.jpg';
import { config } from 'config';
import { MenuItem } from 'App/menu-items';
import { NavContent } from './NavContent';
import { useAppRootActions, useAppRootState } from 'App/root/context';
import { useWindowSize } from 'App/ui-utils';

export interface NavigationProps {
  menuItems: MenuItem[];
}

export const Navigation: React.FC<NavigationProps> = ( props ) => {
  const { menu: menuActions, layout: layoutActions } = useAppRootActions();
  const { layout: layoutState, menu: menuState } = useAppRootState();
  const windowSize = useWindowSize();

  const { menuItems } = props;
  const widthClient = 977;

  const resizeCallback = React.useCallback( () => {
    const contentWidth = document.getElementById( 'root' )!.clientWidth;
    if ( layoutState.layout === 'horizontal' && contentWidth < 992 ) {
      layoutActions.changeLayout( 'vertical' );
    }
  }, [ layoutActions, layoutState.layout ] );

  useEffect( () => {
    resizeCallback();
    window.addEventListener( 'resize', resizeCallback );
    return () => {
      window.removeEventListener( 'resize', resizeCallback );
    };
  }, [ resizeCallback ] );

  let navClass = [
    'pcoded-navbar',
  ];

  if ( config.preLayout !== null
    && config.preLayout !== ''
    && config.preLayout !== 'layout-6'
    && config.preLayout !== 'layout-8' ) {
    navClass = [ ...navClass, config.preLayout ];
  } else {
    navClass = [
      ...navClass,
      config.layoutType,
      config.navBackColor,
      config.navBrandColor,
      'drp-icon-'+config.navDropdownIcon,
      'menu-item-icon-' + config.navListIcon,
      config.navActiveListColor,
      config.navListTitleColor,
    ];

    if ( config.navBackImage ) {
      const navBackImage = config.navBackImage as string;
      navClass = [ ...navClass, navBackImage ];
    }

    if ( config.navIconColor ) {
      navClass = [ ...navClass, 'icon-colored' ];
    }

    if ( !config.navFixedLayout ) {
      navClass = [ ...navClass, 'menupos-static' ];
    }

    if ( config.navListTitleHide ) {
      navClass = [ ...navClass, 'caption-hide' ];
    }
  }

  if ( windowSize.width < widthClient && menuState.collapseMenu ) {
    navClass = [ ...navClass, 'mob-open' ];
  } else if ( menuState.collapseMenu ) {
    navClass = [ ...navClass, 'navbar-collapsed' ];
  }

  if ( config.preLayout === 'layout-6' ) {
    document.body.classList.add( 'layout-6' );
    document.body.style.backgroundImage = config.layout6Background;
    document.body.style.backgroundSize = config.layout6BackSize;
  }

  if ( config.preLayout === 'layout-8' ) {
    document.body.classList.add( 'layout-8' );
  }

  if ( config.layoutType === 'dark' ) {
    document.body.classList.add( 'datta-dark' );
  } else {
    document.body.classList.remove( 'datta-dark' );
  }

  if ( config.rtlLayout ) {
    document.body.classList.add( 'datta-rtl' );
  } else {
    document.body.classList.remove( 'datta-rtl' );
  }

  if ( config.boxLayout ) {
    document.body.classList.add( 'container' );
    document.body.classList.add( 'box-layout' );
  } else {
    document.body.classList.remove( 'container' );
    document.body.classList.remove( 'box-layout' );
  }

  let backImage, navStyle;
  if ( config.navBackImage ) {
    switch ( config.navBackImage ) {
      case 'navbar-image-1':
        backImage = navImage1;
        break;
      case 'navbar-image-2':
        backImage = navImage2;
        break;
      case 'navbar-image-3':
        backImage = navImage3;
        break;
      case 'navbar-image-4':
        backImage = navImage4;
        break;
      case 'navbar-image-5':
        backImage = navImage5;
        break;
      default:
        backImage = '';
    }
    navStyle = {
      backgroundImage: `url(${backImage})`,
    };
  }

  let navContent = (
    <div className="navbar-wrapper overflow-hidden">
      <NavLogo
        collapseMenu={ menuState.collapseMenu }
        windowWidth={ windowSize.width }
        onToggleNavigation={ menuActions.collapseMenu }
        logoImageSite={ config.logoImageSite }
        titleSite={ config.titleSite }
      />
      <NavContent navigation={ menuItems } />
    </div>
  );

  if ( windowSize.width < widthClient ) {
    navContent = (
      <OutsideClick>
        <div className="navbar-wrapper overflow-hidden">
          <NavLogo
            collapseMenu={ menuState.collapseMenu }
            windowWidth={ windowSize.width }
            onToggleNavigation={ menuActions.collapseMenu }
            logoImageSite={ config.logoImageSite }
            titleSite={ config.titleSite }
          />
          <NavContent navigation={ menuItems } />
        </div>
      </OutsideClick>
    );
  }

  return (
    <nav className={ navClass.join( ' ' ) } style={ navStyle }>
      { navContent }
    </nav>
  );
};
