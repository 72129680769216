/* eslint-disable no-template-curly-in-string */
import { IApiService } from 'Services/base';
import { string as yupString, addMethod, Schema } from 'yup';
import React from 'react';
import * as validators from './validators';

export interface SchemaRecipeObjectType {
  [field: string]: Array<SchemaRecipeArrayType>;
}
export type SchemaRecipeArgBasicType = string | number | boolean;
export type SchemaRecipeArgType = SchemaRecipeArgBasicType | Array<SchemaRecipeArgBasicType>;
export type SchemaRecipeArrayType = Array<SchemaRecipeArgType | SchemaRecipeObjectType>;
export type SchemaRecipeType = Array<SchemaRecipeArrayType>;

export type ValidationMethodType<T extends Schema<any>> = ( this: T, ...args: any[] ) => T;

// For Future use right now
export interface IFormService {
  /**
   * Call this method after creating instance. This method will inject additional custom validators to yup
   */
  initialize(): void;

}

export class FormService implements IFormService {

  private api: IApiService;

  constructor( api: IApiService ) {
    this.api = api;
  }

  public initialize(): void {
    // emailExists shows how to make backend/async validator
    addMethod( yupString, 'emailExists', validators.emailExists( this.api ) );
    // equalTo shows how to compare one form field against other
    addMethod( yupString, 'equalTo', validators.equalTo );
    addMethod( yupString, 'isPhoneNumber', validators.isPhoneNumber );
  }
}

export const FormServiceContext: React.Context<IFormService> = React.createContext( undefined as any );
