import React from 'react';
import { Form, Button, Row, FormGroup, Col, FormControl } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { websiteTitle } from 'config';
import { SubmitHandler, useForm, FormProvider, Controller } from 'react-hook-form';
import { ActiveStep, OrganizationState, useOrgActions } from '../OrganizationRegistration';

export interface OrganizationDataItem {
  name: string;
  street: string;
  houseNumber: string;
  zipCode: string;
  city: string;
  country: string;
}

const fieldList = [
  {
    nameField: 'name',
    label: 'organizations.organizationForm.name',
    isRequired: true,
    disabled: true,
  },
  {
    nameField: 'street',
    label: 'organizations.organizationForm.street',
    isRequired: true,
    disabled: false,
  },
  {
    nameField: 'houseNumber',
    label: 'organizations.organizationForm.houseNumber',
    isRequired: true,
    disabled: false,
  },
  {
    nameField: 'zipCode',
    label: 'organizations.organizationForm.zipCode',
    isRequired: true,
    disabled: false,
  },
  {
    nameField: 'city',
    label: 'organizations.organizationForm.city',
    isRequired: true,
    disabled: false,
  },
  {
    nameField: 'country',
    label: 'organizations.organizationForm.country',
    isRequired: true,
    disabled: false,
  } ];


const styleHideBox = {
  height: '0px',
  overflow: 'hidden',
  background: 'transparent',
};

interface OrganizationFormProps {
  initData: OrganizationState;
}

export const OrganizationDataForm: React.FC<OrganizationFormProps> = ( { initData } ) => {
  const { t } = useTranslation( [ 'base', 'registration' ] );
  const actions = useOrgActions();
  const formValues: OrganizationDataItem = initData[ActiveStep.OrgData];

  let infoSteps = '1/2';
  let nextStep = ActiveStep.UserData;
  if ( initData.customSchema ) {
    nextStep = ActiveStep.CustomData;
    infoSteps = '1/3';
  }

  const formContext = useForm( {
    mode: 'onChange',
    shouldUnregister: false,
  } );

  const { control, handleSubmit, formState, errors } = formContext;

  const onSubmit: SubmitHandler<OrganizationDataItem> = React.useCallback( ( fields ) => {
    actions.goToStep( nextStep, ActiveStep.OrgData, fields );
  }, [ actions, nextStep ] );

  return (
    <div className="card">
      <div className="card-body">
        <div className="mb-4 text-center">
          <i className="feather icon-unlock auth-icon" />
        </div>
        <h3 className="mb-4 text-center">{ websiteTitle }</h3>
        <p className="mb-4 text-center f-14">{ t( 'registration:organizations.navTitle' ) } { infoSteps }</p>
        <p className="mb-4 text-muted f-14">{ t( 'registration:organizations.steps.orgPageDescription' ) }</p>
        <FormProvider { ...formContext }>
          <Form noValidate onSubmit={ handleSubmit( onSubmit ) }>
            <Row>
              { fieldList.map( ( item, idx ) => (
                <Col key={ idx } sm={ 12 }>
                  <Controller
                    name={ item.nameField }
                    rules={ { required: item.isRequired } }
                    control={ control }
                    defaultValue={ formValues[item.nameField] }
                    render={ ( props ) => (
                      <FormGroup controlId={ props.name }>
                        <Form.Label className="m-0">
                          { t( item.label ) }
                        </Form.Label>
                        <Form.Control
                          { ...props }
                          autoComplete={ props.name }
                          placeholder={ t( item.label ) }
                          isInvalid={ !!errors[props.name] }
                          disabled={ item.disabled }
                        />
                        <FormControl.Feedback type="invalid">
                          { t( 'base:forms.messages.fieldRequired', { fieldLabel: t( item.label ) } ) }
                        </FormControl.Feedback>
                        { /* Fix for disabled autocomplete for input type text of form  */ }
                        <div style={ styleHideBox }>
                          <input
                            type="text"
                            required={ false }
                          />
                        </div>
                      </FormGroup>
                    ) }
                  />
                </Col>
              ) ) }
            </Row>
            <Row className="mt-2 text-center">
              <Col md={ 12 }>
                <Button
                  variant="primary"
                  type="submit"
                  className="mr-0"
                  disabled={ formState.isSubmitting || !formState.isValid }
                >
                  { t( 'registration:organizations.navButton.nextBtn' ) }
                </Button>
              </Col>
            </Row>
          </Form>
        </FormProvider>
      </div>
    </div>
  );
};
