import {
  BrandSettingsResponse,
  BrandSettingsRequest,
  BrandItem,
  GeneralSettingsResponse,
  GeneralItem,
  GeneralSettingsRequest,
} from './base';
import { sleep } from 'App/utils';
import { SettingsService } from 'Services/settings';

// We need to define special mocked RolesService because we have static data in localStorage
// So for every request we need to filter response based on searchText
// This implementation of service should be only used with mockedApi that is using localStorage
export class MockedSettingsService extends SettingsService {
  getGeneralSettings( ): Promise<GeneralItem> {
    const result = sleep ( 500 )
      .then( () => {
        return this.api.request<GeneralItem>( new GeneralSettingsRequest() );
      } ).then( ( res ) => {
        return res;
      } );

    return result;
  }

  updateGeneralSettings( generalSettings: GeneralItem ): Promise<GeneralSettingsResponse> {
    const res: GeneralSettingsResponse = {
      item: generalSettings,
      message: 'Ok',
      success: true,
    };

    localStorage.setItem( 'GET: /general', JSON.stringify( generalSettings ) );
    // This is just to simulate loading time
    const ud = sleep( 500 ).then( () => {
      return res;
    } );

    return ud;
  }

  getBrandSettings( ): Promise<BrandItem> {
    const result = sleep ( 500 )
      .then( () => {
        return this.api.request<BrandItem>( new BrandSettingsRequest() );
      } ).then( ( res ) => {
        return res;
      } );

    return result;
  }

  updateBrandSettings( brandSettings: BrandItem ): Promise<BrandSettingsResponse> {
    const res: BrandSettingsResponse = {
      item: brandSettings,
      message: 'Ok',
      success: true,
    };

    localStorage.setItem( 'GET: /branding', JSON.stringify( brandSettings ) );
    // This is just to simulate loading time
    const ud = sleep( 500 ).then( () => {
      return res;
    } );

    return ud;
  }
};
