import React, { useContext } from 'react';

import { ApiContext } from 'Services/api-context';
import { ServiceProviderProps } from './common';
import { IWorkFlowService, WorkFlowServiceContext } from 'Services/workflow';

export const WorkFlowServiceProvider: React.FC<ServiceProviderProps<IWorkFlowService>> = ( props, context ) => {
  const api = useContext( ApiContext );
  const workFlowService: IWorkFlowService = new props.useClass( api );

  return (
    <WorkFlowServiceContext.Provider value={ workFlowService }>
      { props.children }
    </WorkFlowServiceContext.Provider>
  );
};
