import xml2js from 'xml2js';
import { IWFSequences, IWFTask } from './workflow-monitor/interfaces';

export default class ZeebeWorkflowParser {
  static async parseDefinition( definition: string ): Promise<Record<any, any>> {
    const parser = new xml2js.Parser();

    return parser.parseStringPromise( definition );
  }

  static getTasksFromDefinition(
    definitionJSON: Record<any, any>,
  ): IWFTask[] {
    const serviceTasks =
      definitionJSON['bpmn:definitions']['bpmn:process'][0]['bpmn:serviceTask'];

    if ( !serviceTasks ) {
      return [];
    }

    return serviceTasks.map( ( task: Record<any, any> ) => ( {
      key: task.$.id,
      name: task.$.name,
      type: task['bpmn:extensionElements'][0]['zeebe:taskDefinition'][0].$.type,
    } ) );
  }

  static getSequencesDefinition(
    definitionJSON: Record<any, any>,
  ): IWFSequences[] {
    const serviceSequences =
      definitionJSON['bpmn:definitions']['bpmn:process'][0][
        'bpmn:sequenceFlow'
      ];

    return serviceSequences.map( ( sequence: Record<any, any> ) => ( {
      key: sequence.$.id,
      source: sequence.$.sourceRef,
      target: sequence.$.targetRef,
    } ) );
  }
}
