import React from 'react';
import invariant from 'tiny-warning';
import { AppFormikChildrenPropsType } from './base';

export const AppFormikContext = React.createContext<AppFormikChildrenPropsType<any>>(
  undefined as any,
);
export const AppFormikProvider = AppFormikContext.Provider;
export const AppFormikConsumer = AppFormikContext.Consumer;

export function useAppFormikContext<Values = any>() {
  const appFormik = React.useContext<AppFormikChildrenPropsType<Values>>( AppFormikContext );

  invariant(
    !!appFormik,
    `AppFormik context is undefined, please verify you are calling useAppFormikContext()
    as child of a <AppFormik> component.`,
  );

  return appFormik;
}
