import { Dispatch, Reducer } from 'react';

export interface IScreenState {
  isFullScreen: boolean;
}

export const initialScreenState: IScreenState = {
  isFullScreen: false,
};

type ScreenActionMessageType =
  | { type: 'TOGGLE_FULL_SCREEN' };

export type ScreenReducer = Reducer<IScreenState, ScreenActionMessageType>;

export const screenReducer: ScreenReducer = ( prevState, action ) => {
  switch ( action.type ) {
    case 'TOGGLE_FULL_SCREEN': {
      return {
        ...prevState,
        isFullScreen: !prevState.isFullScreen,
      };
    }
  }
};

export interface IScreenActions {
  toggleFullScreen(): void;
}

export class ScreenActionsExecutor implements IScreenActions {

  private readonly dispatcher: Dispatch<ScreenActionMessageType>;

  constructor( dispatcher: Dispatch<ScreenActionMessageType> ) {
    this.dispatcher = dispatcher;
    this.toggleFullScreen = this.toggleFullScreen.bind( this );
  }

  toggleFullScreen(): void {
    this.dispatcher( { type: 'TOGGLE_FULL_SCREEN' } );
  }

}
