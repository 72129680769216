import React, { Fragment, ReactElement } from 'react';
import { Dropdown } from 'react-bootstrap';
import { blankLink } from 'config';
import { useTranslation } from 'react-i18next';
import { AppLink } from 'App/components/utils/routing/AppLink';
import { useAppAccounts, useAppUser, appUserPathPrefix } from 'App/components/utils/providers/AppUserProvider';
import { permissionDict as p } from 'Services/permissions/permission-dict';
import { useCurrentProfileAvatar } from 'App/components/utils/hooks';
import { useAppSecurity } from 'App/components/utils/providers/AppProductProvider';
import { IAppUserMeta } from '../../../types';
import { Img } from 'App/components/utils/Img';

export interface UserItem {
  user_id?: number;
  email: string;
  avatar: string;
  company_name: string;
  themeColor?: string;
}

const fallbackLogoLargeSrc = '/assets/logos/emil-demo-large.png';
const fallbackLogoSrc = '/assets/logos/emil-demo.png';

export const NavProfile: React.FC = () => {
  const { t } = useTranslation( [ 'base' ] );
  const idPrefix: string = 'nav-profile-widget';
  const user = useAppSecurity();
  const currentAccount = useAppUser();
  const accounts = useAppAccounts();
  const userAvatar = useCurrentProfileAvatar();

  const canEditOrAddAccount = user.hasPermissions( [ p.as_GetAccount ] );

  const identifyUserToAnalytics = React.useCallback( ( userData: IAppUserMeta ): void => {
    analytics?.reset();
    analytics?.identify(
      userData.sub,
      { tenantId: userData.tenantId },
    );
  }, [] );

  const otherAccounts: ReactElement[] = [];
  for ( let i = 0; i < accounts.length; i++ ) {
    if ( i !== currentAccount.accountIndex ) {
      const account = accounts[i];
      const accountLink: ReactElement = (
        <li className="other-profile pt-1" key={ `profille-${ i }` }>
          <AppLink
            key={ i }
            to={ appUserPathPrefix( i ) }
            onClick={ () => identifyUserToAnalytics( account ) }
            withoutUserContext
            className="dropdown-item"
            id={ `switchProfile-${ i }` }
          >
            <span className="other-profile-avatar float-left m-l-5 m-r-10">
              <Img
                src={ `/assets/logos/${account.tenantSlug}.png` }
                fallbackSrc={ fallbackLogoSrc }
                className="img-radius user-avatar small-avatar-with-border border-0 m-auto d-block" alt="Tenant Logo"
              />
            </span>
            <span className="other-profile-details float-left">
              <h5 className="f-14 mb-0">{ account.tenantName }</h5>
              <span className=" f-12">{ account.userName }</span>
            </span>
          </AppLink>
        </li>
      );
      otherAccounts.push( accountLink );
    }
  }

  return (
    <Fragment>
      <Dropdown.Toggle variant={ 'link' } id="dropdownProfile" className="p-0 m-0">
        <img src={ userAvatar }
          className="img-radius user-avatar small-avatar" alt="User Profile"
        />
      </Dropdown.Toggle>
      <Dropdown.Menu alignRight className="profile-notification" id={ `${idPrefix}-userProfileMenu` }>
        <div className="text-center pb-3 pt-3">
          <Img
            src={ `/assets/logos/${currentAccount.tenantSlug}-large.png` }
            fallbackSrc={ fallbackLogoLargeSrc }
            className="img-fluid rounded-circle user-avatar tenant-logo"
            alt={ `${currentAccount.tenantName} logo` }
          />
        </div>
        <div className="text-center profile-details pl-2 pr-2">
          { currentAccount.userFullName && (
            <h5 id={ `${idPrefix}-userFullName` } className="mt-3 f-16">{ currentAccount.userFullName }</h5>
          ) }
          { currentAccount.userName && (
            <span id={ `${idPrefix}-userName` } className="d-block f-14">{ currentAccount.userName }</span>
          ) }
        </div>
        <ul className="pro-body">
          { canEditOrAddAccount &&
            <li>
              <AppLink
                id={ `${idPrefix}-profilePage` }
                to={ { pathname: '/profile' } }
                className="dropdown-item"
              >
                <i className="feather icon-user" /> { t( 'profile.profile' ) }
              </AppLink>
            </li>
          }
          { canEditOrAddAccount &&
            <Fragment>
              <li>
                <Dropdown.Item
                  id={ `${idPrefix}-settingPage` }
                  href={ blankLink }
                  role="button"
                >
                  <i className="feather icon-settings" /> { t( 'profile.settings' ) }
                </Dropdown.Item>
              </li>
            </Fragment>
          }
          { otherAccounts.length > 0 && <hr className="mt-3 mb-3"></hr> }
          { otherAccounts }
          { canEditOrAddAccount &&
            <li className="mt-1">
              <AppLink
                id={ `${idPrefix}-addAnotherAccount` }
                to="/login"
                withoutUserContext
                className="dropdown-item"
              >
                <i className="feather icon-user-plus" /> { t( 'profile.addAnotherAccount' ) }
              </AppLink>
            </li>
          }
        </ul>
        <hr className="mt-0 mb-3"></hr>
        <div className="text-center mb-3">
          <AppLink
            id={ `${idPrefix}-logout` }
            to="/logout"
            className="logout-btn m-r-10 m-b-5 btn btn-outline-secondary"
          >
            { t( 'profile.logout' ) }
          </AppLink>
        </div>
      </Dropdown.Menu>
    </Fragment>
  );
};
