import { BaseRequest } from 'Services/base';
import { PermissionItemType, ComponentItemType } from 'App/permissions';

export class RolesRequest extends BaseRequest {
  constructor( ) {

    super( {
      method: 'GET',
      url: '/roles',
      responseType: 'json',
    } );
  }
}

export class AddRoleRequest extends BaseRequest {
  constructor(
    role: RoleItem,
  ) {

    super( {
      method: 'POST',
      url: '/roles',
      data: role,
      responseType: 'json',
    } );
  }
}

export class UpdateRoleRequest extends BaseRequest {
  constructor(
    role: RoleItem,
  ) {

    super( {
      method: 'PUT',
      url: '/roles',
      data: role,
      responseType: 'json',
    } );
  }
}

export class RoleRequest extends BaseRequest {
  constructor(
    roleId: number,
  ) {

    super( {
      method: 'GET',
      url: `/roles/${roleId}`,
      responseType: 'json',
    } );
  }
}

export class DeleteRoleRequest extends BaseRequest {
  constructor(
    roleId: number,
  ) {

    super( {
      method: 'DELETE',
      url: `/roles/${roleId}`,
      responseType: 'json',
    } );
  }
}

export interface PermissionItem {
  valueComponent: ComponentItemType;
  permissionList: PermissionItemType[];
}

export type IModule = 'all' | 'homepage' | 'pbm' | 'eis' | 'management' | 'editor' | 'analyticsDashboard' |
'generalSettings' | 'productSettings';
export type ModuleCategories = 'homepage' | 'dashboard' | 'policyholders' | 'policies' | 'customers' |
'VWB' | 'FZV' | 'analytics' | 'accountInfo' | 'branding' | 'productList' | 'products' | 'emailEditor' |
'documentEditor' | 'analyticsDashboard' | 'generalSettings' | 'general' | 'user' | 'roles' | 'groups' |
'marketplace' | 'importData' | 'exportData' | 'apiTokens' | 'webhooks' | 'securityData' | 'twoFA' |
'otherSecuritySettings' | 'paymentProvider' | 'underwriting' | 'documentCenter' | 'businessProcesses' |
'prices' | 'businessLogic' | 'documents' | 'documentTemplates' | 'email' | 'emailTemplates';

export const generalCategoriesWithApprovePermissions: ModuleCategories[] = [
  'user', 'roles', 'groups', 'paymentProvider', 'underwriting', 'documentCenter', 'businessProcesses',
];

export const generalCategoriesWithoutRemovePermissions: ModuleCategories[] = [
  'marketplace', 'importData', 'exportData', 'apiTokens', 'webhooks', 'securityData', 'twoFA', 'otherSecuritySettings',
];

export interface IPermissionTypes {
  category: ModuleCategories;
  permissions: PermissionItemType[];
}

export interface IModulePermissions {
  module: IModule;
  modulePermissions: IPermissionTypes[];
}

export interface RoleItem {
  roleId?: number;
  nameRole: string;
  label: string;
  description: string;
  permissions: PermissionItem[];
  membersCount: number;
  isBuildin?: boolean;
}

export interface IGroupRoles {
  id: number;
  roleName: string;
}

export interface IGroupDetails {
  id: number;
  groupName: string;
  roles: IGroupRoles[];
}

export interface IRoleItem {
  id: number;
  roleName: string;
  permissionSummary: string[];
  permissions: IModulePermissions[];
  users: number[];
  groups: number[];
  roleDescription: string;
}

export interface IUpdateRoleItem {
  roleName: string;
  permissions: IModulePermissions[];
  roleDescription: string;
}

export interface RolesResponse {
  items: RoleItem[];
}

export interface DataResponse {
  message: string;
  success: boolean;
}

export interface RoleResponse extends DataResponse {
  item: RoleItem;
}
