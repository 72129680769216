import React from 'react';
import { Namespace, useTranslation } from 'react-i18next';
import { TFunctionResult } from 'i18next';
import { useAppUser } from './providers/AppUserProvider';
import { profileAvatars } from '../../profile-avatars';
import md5 from 'md5';
import { dashboardIcons } from 'App/dashboard-icons';
import { useLocation } from 'react-router-dom';
import { appUserPathPrefixRemove } from 'App/components/utils/providers/AppUserProvider';
import { Row } from 'react-table';
import { appEnv, TenantSlugs } from '../../utils';
import { organizationAvatars } from '../../organization-avatars';

interface TimeFilterDateFormat {
  titleDateFormat: string;
  dateFormat: string;
}

export const useCurrencySymbol = (): string => {
  const symbol = React.useMemo( () => {
    return '€';
  }, [] );
  return symbol;
};

export const useTranslationDict = <T extends TFunctionResult>( namespace: Namespace, dictKey: string ): T => {
  const { t } = useTranslation( namespace );
  const result = React.useMemo( () => {
    const dict = t( dictKey, { returnObjects: true } ) as T;
    return dict;
  }, [ dictKey, t ] );
  return result;
};

export const useTicketCommentDateFormat = (): string => {
  const { t } = useTranslation( [ 'ticketing' ] );
  const result = React.useMemo( () => {
    const dateFormat = t( 'dateFormat' );
    return dateFormat;
  }, [ t ] );
  return result;
};

export const useTimeFilterDateFormat = (): TimeFilterDateFormat => {
  const { t } = useTranslation( [ 'policy' ] );
  const result = React.useMemo( () => {
    const format = {
      titleDateFormat: t( 'titleDateFormat' ),
      dateFormat: t( 'dateFormat' ),
    };
    return format;
  }, [ t ] );
  return result;
};

const calculateUserIndex = ( userName: string ): number | undefined => {
  const hashName: string = md5( userName );
  const profileNumber: string = hashName.replace( /\D+/g, '' );
  const userIndex: number = parseInt( profileNumber.slice( profileNumber.length - 1 ) );

  return userIndex;
};

export const useProfileAvatar = ( userEmail ): string | undefined => {
  const index = calculateUserIndex( userEmail );

  const result = React.useMemo( () => {
    let avatar = '';
    const profileAvatar = profileAvatars.find( ( p: string, i: number ) => {
      if ( i === index ) {
        avatar = p;
      }

      return avatar;
    } );

    return profileAvatar;
  }, [ index ] );

  return result;
};

export const useCurrentProfileAvatar = ( userName?: string ): string | undefined => {
  const user = useAppUser();
  const index = calculateUserIndex( userName ? userName : user.userName );

  const result = React.useMemo( () => {
    let avatar = '';
    const profileAvatar = profileAvatars.find( ( p: string, i: number ) => {
      if ( i === index ) {
        avatar = p;
      }
      return avatar;
    } );
    return profileAvatar;
  }, [ index ] );
  return result;
};

export const useCurrentOrganizationAvatar = ( name?: string ): string | undefined => {
  const user = useAppUser();
  const index = calculateUserIndex( name ? name : user.userName );

  const result = React.useMemo( () => {
    let avatar = '';
    const profileAvatar = organizationAvatars.find( ( p: string, i: number ) => {
      if ( i === index ) {
        avatar = p;
      }
      return avatar;
    } );
    return profileAvatar;
  }, [ index ] );
  return result;
};

export type ChangeHandlerCallback = ( value: string | number | boolean, field: string ) => void;
export type ChangeHandlerResult = ( e: React.ChangeEvent<any> ) => void;

/** Return multi select values based on an array of options */
const getSelectedValues = ( options: any[] ) => {
  return Array.from( options )
    .filter( ( el ) => el.selected )
    .map( ( el ) => el.value );
};

export const useChangeHandler = ( handler: ChangeHandlerCallback ): ChangeHandlerResult => {
  const callback = React.useCallback<ChangeHandlerResult>( ( e ) => {
    const target = e.target ? e.target : e.currentTarget;
    let val: string | number | boolean;
    let parsed: string | number;
    const {
      type,
      name,
      id,
      value,
      checked,
      options,
      multiple,
    } = target;

    const field: string = name ? name : id;

    // The code is very simplified version of this:
    // https://github.com/formium/formik/blob/master/packages/formik/src/Formik.tsx
    val = /number|range/.test( type )
      ? ( ( parsed = parseFloat( value ) ), isNaN( parsed ) ? '' : parsed )
      : /checkbox/.test( type ) // checkboxes
        ? checked
        : !!multiple // <select multiple>
          ? getSelectedValues( options )
          : value;

    handler( val, field );

  }, [ handler ] );

  return callback;
};

export const useFireCallbackAfter = ( callback: () => void, ms: number ): () => void => {
  const clearId = React.useRef<number | undefined>( undefined );
  React.useEffect( () => {
    const id = window.setTimeout( callback, ms );
    clearId.current = id;
    return () => {
      window.clearTimeout( id );
      clearId.current = undefined;
    };
  }, [ callback, ms ] );

  const cancelCallback = React.useCallback( () => {
    if ( clearId.current !== undefined ) {
      window.clearTimeout( clearId.current );
    }
  }, [] );
  return cancelCallback;
};

const calculateIconIndex = ( name: string ): number | undefined => {
  const hashName: string = md5( name );
  const iconNumber: string = hashName.replace( /\D+/g, '' );
  const iconIndex: number = parseInt( iconNumber.slice( iconNumber.length - 1 ) );

  return iconIndex;
};

export const useDashboardIcon = ( dashboardName ): string | undefined => {
  const index = calculateIconIndex( dashboardName );

  const result = React.useMemo( () => {
    let icon = '';
    const dashboardIcon = dashboardIcons.find( ( p: string, i: number ) => {
      if ( i === index ) {
        icon = p;
      }

      return icon;
    } );

    return dashboardIcon;
  }, [ index ] );

  return result;
};

export const useIcon = ( text: string, icons: string[] ): string | undefined => {
  const index = calculateIconIndex( text );

  const result = React.useMemo( () => {
    let icon = '';
    const iconItem = icons.find( ( icn: string, i: number ) => {
      if ( i === index ) {
        icon = icn;
      }

      return icon;
    } );

    return iconItem;
  }, [ icons, index ] );

  return result;
};

export const useBaseUrl = ( ): string => {
  const location = useLocation();
  const { pathname } = location;
  const parserUrl = appUserPathPrefixRemove( pathname ).split( '/' );
  const baseUrl = parserUrl[1];

  return baseUrl;
};

export const customSortTable = ( prev: Row, curr: Row, columnId: string ) => {
  const prevValue = prev.original[columnId] ? prev.original[columnId].toLowerCase() : '';
  const currValue = curr.original[columnId] ? curr.original[columnId].toLowerCase() : '';
  if ( prevValue > currValue ) {
    return 1;
  } else if ( prevValue < currValue ) {
    return -1;
  } else {
    return 0;
  }
};

/**
 * Custom hook similar to useState() which checks
 * if the component is still mounted before calling setState().
 *
 * @param initialState initial state to set
 */
export function useSafeState<T>( initialState: T ): [T, React.Dispatch<React.SetStateAction<T>>] {
  const isMountedRef = React.useRef<boolean>( false );

  React.useEffect( () => {
    isMountedRef.current = true;

    return () => {
      isMountedRef.current = false;
    };
  }, [] );

  const [ state, setState ] = React.useState<T>( initialState );
  const setSafeState = React.useCallback( ( newState: T ): void => {
    if ( isMountedRef.current ) {
      setState( newState );
    }
  }, [] ) as React.Dispatch<React.SetStateAction<T>>;

  return [ state, setSafeState ];
}

export const useTenantSlugToLogin = (): string => {
  const tenant = React.useMemo( () => {
    return TenantSlugs[appEnv];
  }, [] );
  return tenant;
};

export const formatPriceValue = ( price: number, locale, currency ): string => {
  if ( price ) {
    return new Intl.NumberFormat( locale,
      { style: 'currency', currency: currency || 'EUR' } )
      .format( price/ 100 );
  }

  return '';
};
