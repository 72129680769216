import { ITicketFilter, ITicket, TicketFilterType,
  ListTicketsResponse,
  TicketStatusType,
  ITicketComment,
  TicketIdType } from 'Services/tickets/tickets';
import { useTranslationDict } from 'App/components/utils/hooks';
import React from 'react';
import { PagingRequest, IApiService, ApiResponse } from './base';
import { Logger } from 'loglevel';
import { UserItem } from 'Services/api/users/base';
import { IAppUser } from 'App/types';

export interface ITicketService {
  getFilters(): Promise<ITicketFilter[]>;
  getTickets( filterBy: TicketFilterType, searchQuery: string, paging: PagingRequest ): Promise<ListTicketsResponse>;
  getTicketById( ticketId: TicketIdType ): Promise<ITicket>;
  getCommentsForTicketId( ticketId: TicketIdType ): Promise<ITicketComment[]>;
  createTicket( newTicket: ITicket ): Promise<ITicket>;
  createComment( comment: ITicketComment ): Promise<ITicketComment>;
  updateTicket( ticket: ITicket ): Promise<ITicket>;
  deleteComment( ticketId: TicketIdType, commentId: number ): Promise<ApiResponse>;
  deleteTickets( tickets: TicketIdType[] ): Promise<ApiResponse>;
  assignTo( tickets: TicketIdType[], user: UserItem ): Promise<ApiResponse>;
  markAs( tickets: TicketIdType[], newState: TicketStatusType ): Promise<ApiResponse>;
  moveTo( tickets: TicketIdType[], newCategory: string ): Promise<ApiResponse>;
}

export class TicketService implements ITicketService {
  protected api: IApiService;
  protected logger: Logger;
  protected currentUser: IAppUser;

  constructor( api: IApiService, logger: Logger, currentUser: IAppUser ) {
    this.api = api;
    this.logger = logger;
    this.currentUser = currentUser;
  }

  getFilters(): Promise<ITicketFilter[]> {
    throw new Error( 'Method getFilters not implemented.' );
  }

  getTickets( filterBy: TicketFilterType, searchQuery: string, paging: PagingRequest ): Promise<ListTicketsResponse> {
    throw new Error( 'Method getTickets not implemented.' );
  }

  getTicketById( ticketId: number ): Promise<ITicket> {
    throw new Error( 'Method getTicketById not implemented.' );
  }

  getCommentsForTicketId( ticketId: number ): Promise<ITicketComment[]> {
    throw new Error( 'Method getCommentsForTicketId not implemented.' );
  }

  createTicket( newTicket: ITicket ): Promise<ITicket> {
    throw new Error( 'Method createTicket not implemented.' );
  }

  createComment( comment: ITicketComment ): Promise<ITicketComment> {
    throw new Error( 'Method createComment not implemented.' );
  }

  updateTicket( ticket: ITicket ): Promise<ITicket> {
    throw new Error( 'Method updateTicket not implemented.' );
  }

  deleteComment( ticketId: TicketIdType, commentId: number ): Promise<ApiResponse>{
    throw new Error( 'Method deleteComment not implemented.' );
  }

  deleteTickets( tickets: TicketIdType[] ): Promise<ApiResponse>{
    throw new Error( 'Method deleteTickets not implemented.' );
  }

  assignTo( tickets: TicketIdType[], user: UserItem ): Promise<ApiResponse>{
    throw new Error( 'Method assignTo not implemented.' );
  }

  markAs( tickets: TicketIdType[], newState: TicketStatusType ): Promise<ApiResponse>{
    throw new Error( 'Method markAs not implemented.' );
  }

  moveTo( tickets: TicketIdType[], newCategory: string ): Promise<ApiResponse>{
    throw new Error( 'Method moveTo not implemented.' );
  }
};

export const TicketServiceContext: React.Context<ITicketService> = React.createContext( undefined as any );

export const useTicketService = (): ITicketService => {
  return React.useContext( TicketServiceContext );
};

export const useTicketFilterTranslationDict = (): Record<TicketFilterType, string> => {
  return useTranslationDict( [ 'ticketing' ], 'filters' );
};
