import { defaultDateFormat, workFlowDateFormat } from 'App/ui-utils';
import dayjs from 'dayjs';
import { IWorkFlowItem, IWorkFlowData, IWorkFlowInstanceItem, IWorkFlowInstance } from './workflow';

const mockedFlow1: string = `<?xml version="1.0" encoding="UTF-8"?>
<bpmn:definitions xmlns:bpmn="http://www.omg.org/spec/BPMN/20100524/MODEL"
 xmlns:bpmndi="http://www.omg.org/spec/BPMN/20100524/DI" xmlns:dc="http://www.omg.org/spec/DD/20100524/DC"
  xmlns:zeebe="http://camunda.org/schema/zeebe/1.0" xmlns:di="http://www.omg.org/spec/DD/20100524/DI"
   id="Definitions_0xgsku2" targetNamespace="http://bpmn.io/schema/bpmn" exporter="Zeebe Modeler" 
exporterVersion="0.11.0">
  <bpmn:process id="emildemo.LEAD_CREATED" name="Business Process for LEAD_CREATED event" isExecutable="true">
    <bpmn:startEvent id="StartEvent_1">
      <bpmn:outgoing>SequenceFlow_1skuj7v</bpmn:outgoing>
    </bpmn:startEvent>
    <bpmn:serviceTask id="ServiceTask_1udqo54" name="Approve Lead">
      <bpmn:extensionElements>
        <zeebe:taskDefinition type="lead:approve" retries="1" />
      </bpmn:extensionElements>
      <bpmn:incoming>SequenceFlow_1skuj7v</bpmn:incoming>
      <bpmn:outgoing>SequenceFlow_1i9jr23</bpmn:outgoing>
    </bpmn:serviceTask>
    <bpmn:sequenceFlow id="SequenceFlow_1skuj7v" sourceRef="StartEvent_1" targetRef="ServiceTask_1udqo54" />
    <bpmn:endEvent id="EndEvent_1o0tx8a">
      <bpmn:incoming>Flow_1lj6th1</bpmn:incoming>
    </bpmn:endEvent>
    <bpmn:sequenceFlow id="SequenceFlow_1i9jr23" sourceRef="ServiceTask_1udqo54" targetRef="Activity_1wy289t" />
    <bpmn:serviceTask id="Activity_1wy289t" name="Create Customer">
      <bpmn:extensionElements>
        <zeebe:taskDefinition type="account:create" retries="1" />
      </bpmn:extensionElements>
      <bpmn:incoming>SequenceFlow_1i9jr23</bpmn:incoming>
      <bpmn:outgoing>Flow_0v4lyd2</bpmn:outgoing>
    </bpmn:serviceTask>
    <bpmn:sequenceFlow id="Flow_0v4lyd2" sourceRef="Activity_1wy289t" targetRef="Activity_1r1qve7" />
    <bpmn:sequenceFlow id="Flow_1lj6th1" sourceRef="Activity_1r1qve7" targetRef="EndEvent_1o0tx8a" />
    <bpmn:serviceTask id="Activity_1r1qve7" name="Create Policy">
      <bpmn:extensionElements>
        <zeebe:taskDefinition type="policy:create" retries="1" />
      </bpmn:extensionElements>
      <bpmn:incoming>Flow_0v4lyd2</bpmn:incoming>
      <bpmn:outgoing>Flow_1lj6th1</bpmn:outgoing>
    </bpmn:serviceTask>
  </bpmn:process>
  <bpmndi:BPMNDiagram id="BPMNDiagram_1">
    <bpmndi:BPMNPlane id="BPMNPlane_1" bpmnElement="emildemo.LEAD_CREATED">
      <bpmndi:BPMNEdge id="Flow_1lj6th1_di" bpmnElement="Flow_1lj6th1">
        <di:waypoint x="720" y="110" />
        <di:waypoint x="802" y="110" />
      </bpmndi:BPMNEdge>
      <bpmndi:BPMNEdge id="Flow_0v4lyd2_di" bpmnElement="Flow_0v4lyd2">
        <di:waypoint x="540" y="110" />
        <di:waypoint x="620" y="110" />
      </bpmndi:BPMNEdge>
      <bpmndi:BPMNEdge id="SequenceFlow_1i9jr23_di" bpmnElement="SequenceFlow_1i9jr23">
        <di:waypoint x="370" y="117" />
        <di:waypoint x="440" y="117" />
      </bpmndi:BPMNEdge>
      <bpmndi:BPMNEdge id="SequenceFlow_1skuj7v_di" bpmnElement="SequenceFlow_1skuj7v">
        <di:waypoint x="215" y="117" />
        <di:waypoint x="270" y="117" />
      </bpmndi:BPMNEdge>
      <bpmndi:BPMNShape id="_BPMNShape_StartEvent_2" bpmnElement="StartEvent_1">
        <dc:Bounds x="179" y="99" width="36" height="36" />
      </bpmndi:BPMNShape>
      <bpmndi:BPMNShape id="ServiceTask_1udqo54_di" bpmnElement="ServiceTask_1udqo54">
        <dc:Bounds x="270" y="77" width="100" height="80" />
      </bpmndi:BPMNShape>
      <bpmndi:BPMNShape id="EndEvent_1o0tx8a_di" bpmnElement="EndEvent_1o0tx8a">
        <dc:Bounds x="802" y="92" width="36" height="36" />
      </bpmndi:BPMNShape>
      <bpmndi:BPMNShape id="Activity_04ywc0x_di" bpmnElement="Activity_1wy289t">
        <dc:Bounds x="440" y="77" width="100" height="80" />
      </bpmndi:BPMNShape>
      <bpmndi:BPMNShape id="Activity_0exmyt1_di" bpmnElement="Activity_1r1qve7">
        <dc:Bounds x="620" y="70" width="100" height="80" />
      </bpmndi:BPMNShape>
    </bpmndi:BPMNPlane>
  </bpmndi:BPMNDiagram>
</bpmn:definitions>`;

const mockedFlow2: string = `<?xml version="1.0" encoding="UTF-8"?>
<bpmn:definitions xmlns:bpmn="http://www.omg.org/spec/BPMN/20100524/MODEL" 
 xmlns:bpmndi="http://www.omg.org/spec/BPMN/20100524/DI" xmlns:dc="http://www.omg.org/spec/DD/20100524/DC"
 xmlns:zeebe="http://camunda.org/schema/zeebe/1.0" xmlns:di="http://www.omg.org/spec/DD/20100524/DI"
  id="Definitions_1g37xuc" targetNamespace="http://bpmn.io/schema/bpmn" exporter="Zeebe Modeler"
   exporterVersion="0.11.0">
  <bpmn:process id="emildemo.POLICY_CREATED" name="Business Process for POLICY_CREATED event" isExecutable="true">
    <bpmn:startEvent id="StartEvent_1" name="Policy Created New Version">
      <bpmn:outgoing>Flow_04a0tyg</bpmn:outgoing>
    </bpmn:startEvent>
    <bpmn:serviceTask id="Activity_0fz8a46" name="Fetch Policy">
      <bpmn:extensionElements>
        <zeebe:taskDefinition type="policy:fetch" retries="1" />
        <zeebe:taskHeaders>
          <zeebe:header key="expand" value="premiumFormulas" />
          <zeebe:header key="idPath" value="policy.code" />
        </zeebe:taskHeaders>
      </bpmn:extensionElements>
      <bpmn:incoming>Flow_04a0tyg</bpmn:incoming>
      <bpmn:outgoing>Flow_1pespgy</bpmn:outgoing>
    </bpmn:serviceTask>
    <bpmn:serviceTask id="Activity_0a05vie" name="Fetch Account">
      <bpmn:extensionElements>
        <zeebe:taskDefinition type="account:fetch" />
        <zeebe:taskHeaders>
          <zeebe:header key="idPath" value="policy.accountCode" />
        </zeebe:taskHeaders>
      </bpmn:extensionElements>
      <bpmn:incoming>Flow_1pespgy</bpmn:incoming>
      <bpmn:outgoing>Flow_19wov76</bpmn:outgoing>
    </bpmn:serviceTask>
    <bpmn:serviceTask id="Activity_1wqks1l" name="Generate Policy Document">
      <bpmn:extensionElements>
        <zeebe:taskDefinition type="document:create" retries="1" />
        <zeebe:taskHeaders>
          <zeebe:header key="templateSlug" value="demo-emil-application" />
          <zeebe:header key="entityType" value="policy_contract" />
          <zeebe:header key="description" value="random description" />
          <zeebe:header key="requester" value="insuranceservice" />
        </zeebe:taskHeaders>
      </bpmn:extensionElements>
      <bpmn:incoming>Flow_1flue4g</bpmn:incoming>
      <bpmn:outgoing>Flow_0ddc3q2</bpmn:outgoing>
    </bpmn:serviceTask>
    <bpmn:serviceTask id="Activity_1t8p4g1" name="Generate Initial Invoice Document">
      <bpmn:extensionElements>
        <zeebe:taskDefinition type="document:create" retries="1" />
        <zeebe:taskHeaders>
          <zeebe:header key="templateSlug" value="demo-emil-invoice" />
          <zeebe:header key="entityType" value="initial_invoice" />
          <zeebe:header key="description" value="random description" />
          <zeebe:header key="requester" value="insuranceservice" />
        </zeebe:taskHeaders>
      </bpmn:extensionElements>
      <bpmn:incoming>Flow_0ddc3q2</bpmn:incoming>
      <bpmn:outgoing>Flow_1wpe5t3</bpmn:outgoing>
    </bpmn:serviceTask>
    <bpmn:sequenceFlow id="Flow_0ddc3q2" sourceRef="Activity_1wqks1l" targetRef="Activity_1t8p4g1" />
    <bpmn:endEvent id="Event_0s0lptd" name="End">
      <bpmn:incoming>Flow_1wpe5t3</bpmn:incoming>
    </bpmn:endEvent>
    <bpmn:sequenceFlow id="Flow_1wpe5t3" sourceRef="Activity_1t8p4g1" targetRef="Event_0s0lptd" />
    <bpmn:sequenceFlow id="Flow_04a0tyg" sourceRef="StartEvent_1" targetRef="Activity_0fz8a46" />
    <bpmn:sequenceFlow id="Flow_1pespgy" sourceRef="Activity_0fz8a46" targetRef="Activity_0a05vie" />
    <bpmn:sequenceFlow id="Flow_19wov76" sourceRef="Activity_0a05vie" targetRef="Activity_1v8slmg" />
    <bpmn:sequenceFlow id="Flow_1flue4g" sourceRef="Activity_1v8slmg" targetRef="Activity_1wqks1l" />
    <bpmn:serviceTask id="Activity_1v8slmg" name="Generate Initial Invoice">
      <bpmn:extensionElements>
        <zeebe:taskDefinition type="initial_invoice:create" retries="1" />
      </bpmn:extensionElements>
      <bpmn:incoming>Flow_19wov76</bpmn:incoming>
      <bpmn:outgoing>Flow_1flue4g</bpmn:outgoing>
    </bpmn:serviceTask>
  </bpmn:process>
  <bpmndi:BPMNDiagram id="BPMNDiagram_1">
    <bpmndi:BPMNPlane id="BPMNPlane_1" bpmnElement="emildemo.POLICY_CREATED">
      <bpmndi:BPMNEdge id="Flow_0ddc3q2_di" bpmnElement="Flow_0ddc3q2">
        <di:waypoint x="910" y="117" />
        <di:waypoint x="970" y="117" />
      </bpmndi:BPMNEdge>
      <bpmndi:BPMNEdge id="Flow_1wpe5t3_di" bpmnElement="Flow_1wpe5t3">
        <di:waypoint x="1070" y="117" />
        <di:waypoint x="1172" y="117" />
      </bpmndi:BPMNEdge>
      <bpmndi:BPMNEdge id="Flow_04a0tyg_di" bpmnElement="Flow_04a0tyg">
        <di:waypoint x="215" y="117" />
        <di:waypoint x="320" y="117" />
      </bpmndi:BPMNEdge>
      <bpmndi:BPMNEdge id="Flow_1pespgy_di" bpmnElement="Flow_1pespgy">
        <di:waypoint x="420" y="117" />
        <di:waypoint x="500" y="117" />
      </bpmndi:BPMNEdge>
      <bpmndi:BPMNEdge id="Flow_19wov76_di" bpmnElement="Flow_19wov76">
        <di:waypoint x="600" y="117" />
        <di:waypoint x="660" y="117" />
      </bpmndi:BPMNEdge>
      <bpmndi:BPMNEdge id="Flow_1flue4g_di" bpmnElement="Flow_1flue4g">
        <di:waypoint x="760" y="117" />
        <di:waypoint x="810" y="117" />
      </bpmndi:BPMNEdge>
      <bpmndi:BPMNShape id="_BPMNShape_StartEvent_2" bpmnElement="StartEvent_1">
        <dc:Bounds x="179" y="99" width="36" height="36" />
        <bpmndi:BPMNLabel>
          <dc:Bounds x="163" y="142" width="72" height="27" />
        </bpmndi:BPMNLabel>
      </bpmndi:BPMNShape>
      <bpmndi:BPMNShape id="Activity_0hacqr2_di" bpmnElement="Activity_0fz8a46">
        <dc:Bounds x="320" y="77" width="100" height="80" />
      </bpmndi:BPMNShape>
      <bpmndi:BPMNShape id="Activity_1p9skmz_di" bpmnElement="Activity_0a05vie">
        <dc:Bounds x="500" y="77" width="100" height="80" />
      </bpmndi:BPMNShape>
      <bpmndi:BPMNShape id="Activity_0w1qf8r_di" bpmnElement="Activity_1t8p4g1">
        <dc:Bounds x="970" y="77" width="100" height="80" />
      </bpmndi:BPMNShape>
      <bpmndi:BPMNShape id="Event_0s0lptd_di" bpmnElement="Event_0s0lptd">
        <dc:Bounds x="1172" y="99" width="36" height="36" />
        <bpmndi:BPMNLabel>
          <dc:Bounds x="1180" y="142" width="20" height="14" />
        </bpmndi:BPMNLabel>
      </bpmndi:BPMNShape>
      <bpmndi:BPMNShape id="Activity_0thocd9_di" bpmnElement="Activity_1wqks1l">
        <dc:Bounds x="810" y="77" width="100" height="80" />
      </bpmndi:BPMNShape>
      <bpmndi:BPMNShape id="Activity_0nx30s9_di" bpmnElement="Activity_1v8slmg">
        <dc:Bounds x="660" y="77" width="100" height="80" />
      </bpmndi:BPMNShape>
    </bpmndi:BPMNPlane>
  </bpmndi:BPMNDiagram>
</bpmn:definitions>`;

const mockedFlow3: string = `<?xml version="1.0" encoding="UTF-8"?>
<bpmn:definitions xmlns:bpmn="http://www.omg.org/spec/BPMN/20100524/MODEL" 
xmlns:bpmndi="http://www.omg.org/spec/BPMN/20100524/DI" xmlns:dc="http://www.omg.org/spec/DD/20100524/DC" 
xmlns:zeebe="http://camunda.org/schema/zeebe/1.0" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" 
xmlns:di="http://www.omg.org/spec/DD/20100524/DI" id="Definitions_0agnc25" 
targetNamespace="http://bpmn.io/schema/bpmn" exporter="Zeebe Modeler" exporterVersion="0.11.0">
  <bpmn:process id="emildemo.POLICY_UPDATED" name="Business Process for POLICY_UPDATED event" isExecutable="true">
    <bpmn:startEvent id="StartEvent_1" name="Policy Updated">
      <bpmn:outgoing>Flow_1vbcrrx</bpmn:outgoing>
    </bpmn:startEvent>
    <bpmn:sequenceFlow id="Flow_1vbcrrx" sourceRef="StartEvent_1" targetRef="Activity_1llabt9" />
    <bpmn:serviceTask id="Activity_1llabt9" name="Correct invoices">
      <bpmn:extensionElements>
        <zeebe:taskDefinition type="invoices:correct" retries="1" />
      </bpmn:extensionElements>
      <bpmn:incoming>Flow_1vbcrrx</bpmn:incoming>
      <bpmn:outgoing>Flow_1if48qu</bpmn:outgoing>
    </bpmn:serviceTask>
    <bpmn:sequenceFlow id="Flow_1if48qu" sourceRef="Activity_1llabt9" targetRef="Gateway_0yb4gkp" />
    <bpmn:serviceTask id="Activity_16ge7zm" name="Generate Correction Invoice Document">
      <bpmn:extensionElements>
        <zeebe:taskDefinition type="document:create" retries="1" />
        <zeebe:taskHeaders>
          <zeebe:header key="templateSlug" value="demo-emil-correction-invoice" />
          <zeebe:header key="entityType" value="correction_invoice" />
          <zeebe:header key="description" value="random words" />
          <zeebe:header key="requester" value="billingservice" />
        </zeebe:taskHeaders>
      </bpmn:extensionElements>
      <bpmn:incoming>Flow_1gkt4vv</bpmn:incoming>
      <bpmn:outgoing>Flow_0yw3jyb</bpmn:outgoing>
    </bpmn:serviceTask>
    <bpmn:sequenceFlow id="Flow_0yw3jyb" sourceRef="Activity_16ge7zm" targetRef="Activity_04hxcqm" />
    <bpmn:serviceTask id="Activity_04hxcqm" name="Generate Policy Addendum Document">
      <bpmn:extensionElements>
        <zeebe:taskDefinition type="document:create" retries="1" />
        <zeebe:taskHeaders>
          <zeebe:header key="templateSlug" value="demo-emil-application" />
          <zeebe:header key="entityType" value="policy_addendum" />
          <zeebe:header key="description" value="random words" />
          <zeebe:header key="requester" value="billingservice" />
        </zeebe:taskHeaders>
      </bpmn:extensionElements>
      <bpmn:incoming>Flow_0yw3jyb</bpmn:incoming>
      <bpmn:incoming>Flow_0xpv8ni</bpmn:incoming>
      <bpmn:outgoing>Flow_06subo7</bpmn:outgoing>
    </bpmn:serviceTask>
    <bpmn:serviceTask id="Activity_0pee4ds" name="Notify Customer">
      <bpmn:extensionElements>
        <zeebe:taskDefinition type="notification:send" />
        <zeebe:taskHeaders>
          <zeebe:header key="attachments" value="correction_invoice,policy_addendum" />
        </zeebe:taskHeaders>
      </bpmn:extensionElements>
      <bpmn:incoming>Flow_06subo7</bpmn:incoming>
    </bpmn:serviceTask>
    <bpmn:sequenceFlow id="Flow_06subo7" sourceRef="Activity_04hxcqm" targetRef="Activity_0pee4ds" />
    <bpmn:sequenceFlow id="Flow_1gkt4vv" name="Invoices exist" sourceRef="Gateway_0yb4gkp" 
    targetRef="Activity_16ge7zm">
      <bpmn:conditionExpression xsi:type="bpmn:tFormalExpression">=is defined(payload.invoices)
      </bpmn:conditionExpression>
    </bpmn:sequenceFlow>
    <bpmn:sequenceFlow id="Flow_0xpv8ni" sourceRef="Gateway_0yb4gkp" targetRef="Activity_04hxcqm" />
    <bpmn:exclusiveGateway id="Gateway_0yb4gkp" default="Flow_0xpv8ni">
      <bpmn:incoming>Flow_1if48qu</bpmn:incoming>
      <bpmn:outgoing>Flow_0xpv8ni</bpmn:outgoing>
      <bpmn:outgoing>Flow_1gkt4vv</bpmn:outgoing>
    </bpmn:exclusiveGateway>
  </bpmn:process>
  <bpmndi:BPMNDiagram id="BPMNDiagram_1">
    <bpmndi:BPMNPlane id="BPMNPlane_1" bpmnElement="emildemo.POLICY_UPDATED">
      <bpmndi:BPMNEdge id="Flow_1vbcrrx_di" bpmnElement="Flow_1vbcrrx">
        <di:waypoint x="208" y="120" />
        <di:waypoint x="320" y="120" />
      </bpmndi:BPMNEdge>
      <bpmndi:BPMNEdge id="Flow_1if48qu_di" bpmnElement="Flow_1if48qu">
        <di:waypoint x="420" y="120" />
        <di:waypoint x="495" y="120" />
      </bpmndi:BPMNEdge>
      <bpmndi:BPMNEdge id="Flow_0yw3jyb_di" bpmnElement="Flow_0yw3jyb">
        <di:waypoint x="710" y="250" />
        <di:waypoint x="820" y="250" />
        <di:waypoint x="820" y="160" />
      </bpmndi:BPMNEdge>
      <bpmndi:BPMNEdge id="Flow_06subo7_di" bpmnElement="Flow_06subo7">
        <di:waypoint x="870" y="120" />
        <di:waypoint x="930" y="120" />
      </bpmndi:BPMNEdge>
      <bpmndi:BPMNEdge id="Flow_1gkt4vv_di" bpmnElement="Flow_1gkt4vv">
        <di:waypoint x="520" y="145" />
        <di:waypoint x="520" y="250" />
        <di:waypoint x="610" y="250" />
        <bpmndi:BPMNLabel>
          <dc:Bounds x="486" y="263" width="67" height="14" />
        </bpmndi:BPMNLabel>
      </bpmndi:BPMNEdge>
      <bpmndi:BPMNEdge id="Flow_0xpv8ni_di" bpmnElement="Flow_0xpv8ni">
        <di:waypoint x="545" y="120" />
        <di:waypoint x="770" y="120" />
      </bpmndi:BPMNEdge>
      <bpmndi:BPMNShape id="Activity_0pee4ds_di" bpmnElement="Activity_0pee4ds">
        <dc:Bounds x="930" y="80" width="100" height="80" />
      </bpmndi:BPMNShape>
      <bpmndi:BPMNShape id="Activity_19tm3tr_di" bpmnElement="Activity_04hxcqm">
        <dc:Bounds x="770" y="80" width="100" height="80" />
      </bpmndi:BPMNShape>
      <bpmndi:BPMNShape id="_BPMNShape_StartEvent_2" bpmnElement="StartEvent_1">
        <dc:Bounds x="172" y="102" width="36" height="36" />
        <bpmndi:BPMNLabel>
          <dc:Bounds x="154" y="145" width="74" height="14" />
        </bpmndi:BPMNLabel>
      </bpmndi:BPMNShape>
      <bpmndi:BPMNShape id="Activity_0iil6yt_di" bpmnElement="Activity_1llabt9">
        <dc:Bounds x="320" y="80" width="100" height="80" />
      </bpmndi:BPMNShape>
      <bpmndi:BPMNShape id="Gateway_0uvgrwm_di" bpmnElement="Gateway_0yb4gkp" isMarkerVisible="true">
        <dc:Bounds x="495" y="95" width="50" height="50" />
      </bpmndi:BPMNShape>
      <bpmndi:BPMNShape id="Activity_15aj5e7_di" bpmnElement="Activity_16ge7zm">
        <dc:Bounds x="610" y="210" width="100" height="80" />
      </bpmndi:BPMNShape>
    </bpmndi:BPMNPlane>
  </bpmndi:BPMNDiagram>
</bpmn:definitions>`;

export const mockedWorkFLowItems: IWorkFlowItem[] = [
  {
    id: 1,
    createdAt: dayjs( '29.09.2020', defaultDateFormat ),
    name: 'Welcome new insuree and contract',
    code: 'wok_rBu7zSFfl5VVZcI137-6q',
  },
  {
    id: 2,
    createdAt: dayjs( '11.09.2020', defaultDateFormat ),
    name: 'Monthly invoice',
    code: 'wok_rBu7zSFfl5VVZcI137-2',
  },
  {
    id: 3,
    createdAt: dayjs( '12.10.2020', defaultDateFormat ),
    name: 'Reminder unpaid invoice',
    code: 'wok_rBu7zSFfl5VVZcI137-3',
  },
];

export const mockedWorkFlowData: IWorkFlowData[] = [
  {
    id: 1,
    name: 'Welcome new insuree and contract',
    code: 'wok_rBu7zSFfl5VVZcI137-6q',
    data: mockedFlow1,
  },
  {
    id: 2,
    name: 'Monthly invoice',
    code: 'wok_rBu7zSFfl5VVZcI137-2',
    data: mockedFlow2,
  },
  {
    id: 3,
    name: 'Reminder unpaid invoice',
    code: 'wok_rBu7zSFfl5VVZcI137-3',
    data: mockedFlow3,
  },
];

export const mockedWorkFlowInstanceList: IWorkFlowInstance[] = [
  {
    id: 2251799813685857,
    status: 'active',
    workFlowId: 1,
    startTime: dayjs( '26.05.2021 18:22:44', workFlowDateFormat	),
    endTime: dayjs( '26.05.2021 18:23:44', workFlowDateFormat	),
    version: 'Version 1',
    instances: [
      {
        eventName: 'Create Policy',
        eventType: 'start',
        status: 'completed',
      },
      {
        eventName: 'Approve lead',
        eventType: 'task',
        status: 'completed',
      },
      {
        eventName: 'Create customer',
        eventType: 'task',
        status: 'completed',
      },
      {
        eventName: 'Create policy',
        eventType: 'task',
        status: 'active',
      },
      {
        eventName: 'Policy created',
        eventType: 'end',
      },
    ],
  },
  {
    id: 2251799813685858,
    status: 'active',
    workFlowId: 1,
    startTime: dayjs( '26.05.2021 18:23:44', workFlowDateFormat	),
    endTime: dayjs( '26.05.2021 18:24:44', workFlowDateFormat	),
    version: 'Version 1',
    instances: [
      {
        eventName: 'Create Policy',
        eventType: 'start',
        status: 'completed',
      },
      {
        eventName: 'Approve lead',
        eventType: 'task',
        status: 'completed',
      },
      {
        eventName: 'Create customer',
        eventType: 'task',
        status: 'active',
      },
      {
        eventName: 'Create policy',
        eventType: 'task',
      },
      {
        eventName: 'Policy created',
        eventType: 'end',
      },
    ],
  },
  {
    id: 2251799813685859,
    status: 'failed',
    workFlowId: 1,
    startTime: dayjs( '26.05.2021 19:25:46', workFlowDateFormat	),
    endTime: dayjs( '26.05.2021 19:26:47', workFlowDateFormat	),
    version: 'Version 1',
    instances: [
      {
        eventName: 'Create Policy',
        eventType: 'start',
        status: 'completed',
      },
      {
        eventName: 'Approve lead',
        eventType: 'task',
        status: 'completed',
      },
      {
        eventName: 'Create customer',
        eventType: 'task',
        status: 'completed',
      },
      {
        eventName: 'Create policy',
        eventType: 'task',
        status: 'failed',
      },
      {
        eventName: 'Policy created',
        eventType: 'end',
      },
    ],
  },
  {
    id: 2251799813685860,
    status: 'failed',
    workFlowId: 1,
    startTime: dayjs( '26.05.2021 20:22:44', workFlowDateFormat	),
    endTime: dayjs( '26.05.2021 20:23:45', workFlowDateFormat	),
    version: 'Version 1',
    instances: [
      {
        eventName: 'Create Policy',
        eventType: 'start',
        status: 'completed',
      },
      {
        eventName: 'Approve lead',
        eventType: 'task',
        status: 'completed',
      },
      {
        eventName: 'Create customer',
        eventType: 'task',
        status: 'failed',
      },
      {
        eventName: 'Create policy',
        eventType: 'task',
      },
      {
        eventName: 'Policy created',
        eventType: 'end',
      },
    ],
  },
  {
    id: 2251799813685861,
    status: 'failed',
    workFlowId: 1,
    startTime: dayjs( '27.05.2021 18:22:44', workFlowDateFormat	),
    endTime: dayjs( '27.05.2021 18:23:45', workFlowDateFormat	),
    version: 'Version 1',
    instances: [
      {
        eventName: 'Create Policy',
        eventType: 'start',
        status: 'completed',
      },
      {
        eventName: 'Approve lead',
        eventType: 'task',
        status: 'failed',
      },
      {
        eventName: 'Create customer',
        eventType: 'task',
      },
      {
        eventName: 'Create policy',
        eventType: 'task',
      },
      {
        eventName: 'Policy created',
        eventType: 'end',
      },
    ],
  },
  {
    id: 2251799813685862,
    status: 'failed',
    workFlowId: 1,
    startTime: dayjs( '27.05.2021 19:22:44', workFlowDateFormat	),
    endTime: dayjs( '27.05.2021 19:23:45', workFlowDateFormat	),
    version: 'Version 1',
    instances: [
      {
        eventName: 'Create Policy',
        eventType: 'start',
        status: 'completed',
      },
      {
        eventName: 'Approve lead',
        eventType: 'task',
        status: 'completed',
      },
      {
        eventName: 'Create customer',
        eventType: 'task',
        status: 'failed',
      },
      {
        eventName: 'Create policy',
        eventType: 'task',
      },
      {
        eventName: 'Policy created',
        eventType: 'end',
      },
    ],
  },
  {
    id: 2251799813685863,
    status: 'failed',
    workFlowId: 1,
    startTime: dayjs( '27.05.2021 20:22:44', workFlowDateFormat	),
    endTime: dayjs( '27.05.2021 20:23:45', workFlowDateFormat	),
    version: 'Version 1',
    instances: [
      {
        eventName: 'Create Policy',
        eventType: 'start',
        status: 'completed',
      },
      {
        eventName: 'Approve lead',
        eventType: 'task',
        status: 'completed',
      },
      {
        eventName: 'Create customer',
        eventType: 'task',
        status: 'completed',
      },
      {
        eventName: 'Create policy',
        eventType: 'task',
        status: 'failed',
      },
      {
        eventName: 'Policy created',
        eventType: 'end',
      },
    ],
  },
  {
    id: 2251799813685864,
    status: 'active',
    workFlowId: 2,
    startTime: dayjs( '26.05.2021 18:22:44', workFlowDateFormat	),
    endTime: dayjs( '26.05.2021 18:23:44', workFlowDateFormat	),
    version: 'Version 1',
    instances: [
      {
        eventName: 'Updating policy',
        eventType: 'start',
        status: 'completed',
      },
      {
        eventName: 'Fetch policy',
        eventType: 'task',
        status: 'completed',
      },
      {
        eventName: 'Fetch account',
        eventType: 'task',
        status: 'completed',
      },
      {
        eventName: 'Generate initial invoice',
        eventType: 'task',
        status: 'active',
      },
      {
        eventName: 'Generate policy document',
        eventType: 'task',
      },
      {
        eventName: 'Generate initial invoice document',
        eventType: 'task',
      },
      {
        eventName: 'Policy updated',
        eventType: 'end',
      },
    ],
  },
  {
    id: 2251799813685865,
    status: 'active',
    workFlowId: 2,
    startTime: dayjs( '26.05.2021 18:23:44', workFlowDateFormat	),
    endTime: dayjs( '26.05.2021 18:24:44', workFlowDateFormat	),
    version: 'Version 1',
    instances: [
      {
        eventName: 'Updating policy',
        eventType: 'start',
        status: 'completed',
      },
      {
        eventName: 'Fetch policy',
        eventType: 'task',
        status: 'completed',
      },
      {
        eventName: 'Fetch account',
        eventType: 'task',
        status: 'completed',
      },
      {
        eventName: 'Generate initial invoice',
        eventType: 'task',
        status: 'active',
      },
      {
        eventName: 'Generate policy document',
        eventType: 'task',
      },
      {
        eventName: 'Generate initial invoice document',
        eventType: 'task',
      },
      {
        eventName: 'Policy updated',
        eventType: 'end',
      },
    ],
  },
  {
    id: 2251799813685866,
    status: 'active',
    workFlowId: 2,
    startTime: dayjs( '26.05.2021 19:25:46', workFlowDateFormat	),
    endTime: dayjs( '26.05.2021 19:26:47', workFlowDateFormat	),
    version: 'Version 1',
    instances: [
      {
        eventName: 'Updating policy',
        eventType: 'start',
        status: 'completed',
      },
      {
        eventName: 'Fetch policy',
        eventType: 'task',
        status: 'completed',
      },
      {
        eventName: 'Fetch account',
        eventType: 'task',
        status: 'completed',
      },
      {
        eventName: 'Generate initial invoice',
        eventType: 'task',
        status: 'active',
      },
      {
        eventName: 'Generate policy document',
        eventType: 'task',
      },
      {
        eventName: 'Generate initial invoice document',
        eventType: 'task',
      },
      {
        eventName: 'Policy updated',
        eventType: 'end',
      },
    ],
  },
  {
    id: 2251799813685867,
    status: 'active',
    workFlowId: 2,
    startTime: dayjs( '26.05.2021 20:22:44', workFlowDateFormat	),
    endTime: dayjs( '26.05.2021 20:23:45', workFlowDateFormat	),
    version: 'Version 1',
    instances: [
      {
        eventName: 'Updating policy',
        eventType: 'start',
        status: 'completed',
      },
      {
        eventName: 'Fetch policy',
        eventType: 'task',
        status: 'completed',
      },
      {
        eventName: 'Fetch account',
        eventType: 'task',
        status: 'completed',
      },
      {
        eventName: 'Generate initial invoice',
        eventType: 'task',
        status: 'active',
      },
      {
        eventName: 'Generate policy document',
        eventType: 'task',
      },
      {
        eventName: 'Generate initial invoice document',
        eventType: 'task',
      },
      {
        eventName: 'Policy updated',
        eventType: 'end',
      },
    ],
  },
  {
    id: 2251799813685868,
    status: 'active',
    workFlowId: 2,
    startTime: dayjs( '27.05.2021 18:22:44', workFlowDateFormat	),
    endTime: dayjs( '27.05.2021 18:23:45', workFlowDateFormat	),
    version: 'Version 1',
    instances: [
      {
        eventName: 'Create policy version',
        eventType: 'start',
        status: 'completed',
      },
      {
        eventName: 'Fetch policy',
        eventType: 'task',
        status: 'completed',
      },
      {
        eventName: 'Fetch account',
        eventType: 'task',
        status: 'completed',
      },
      {
        eventName: 'Generate initial invoice',
        eventType: 'task',
        status: 'active',
      },
      {
        eventName: 'Generate policy document',
        eventType: 'task',
      },
      {
        eventName: 'Generate initial invoice document',
        eventType: 'task',
      },
      {
        eventName: 'Policy version created',
        eventType: 'end',
      },
    ],
  },
  {
    id: 2251799813685869,
    status: 'failed',
    workFlowId: 3,
    startTime: dayjs( '26.05.2021 18:22:44', workFlowDateFormat	),
    endTime: dayjs( '26.05.2021 18:23:44', workFlowDateFormat	),
    version: 'Version 1',
    instances: [
      {
        eventName: 'Policy updated',
        eventType: 'start',
        status: 'completed',
      },
      {
        eventName: 'Correct invoices',
        eventType: 'task',
        status: 'completed',
      },
      {
        eventName: 'Gateway',
        eventType: 'gateway',
        status: 'completed',
      },
      {
        eventName: 'Generate correction invoice',
        eventType: 'task',
        status: 'failed',
      },
      {
        eventName: 'Generate policy addendum document',
        eventType: 'task',
      },
      {
        eventName: 'Notify customer',
        eventType: 'task',
      },
    ],
  },
  {
    id: 2251799813685870,
    status: 'failed',
    workFlowId: 3,
    startTime: dayjs( '26.05.2021 18:23:44', workFlowDateFormat	),
    endTime: dayjs( '26.05.2021 18:24:44', workFlowDateFormat	),
    version: 'Version 1',
    instances: [
      {
        eventName: 'Policy updated',
        eventType: 'start',
        status: 'completed',
      },
      {
        eventName: 'Correct invoices',
        eventType: 'task',
        status: 'completed',
      },
      {
        eventName: 'Gateway',
        eventType: 'gateway',
        status: 'completed',
      },
      {
        eventName: 'Generate correction invoice',
        eventType: 'task',
        status: 'completed',
      },
      {
        eventName: 'Generate policy addendum document',
        eventType: 'task',
        status: 'completed',
      },
      {
        eventName: 'Notify customer',
        eventType: 'task',
        status: 'failed',
      },
    ],
  },
  {
    id: 2251799813685871,
    status: 'failed',
    workFlowId: 3,
    startTime: dayjs( '26.05.2021 19:25:46', workFlowDateFormat	),
    endTime: dayjs( '26.05.2021 19:26:47', workFlowDateFormat	),
    version: 'Version 1',
    instances: [
      {
        eventName: 'Policy updated',
        eventType: 'start',
        status: 'completed',
      },
      {
        eventName: 'Correct invoices',
        eventType: 'task',
        status: 'completed',
      },
      {
        eventName: 'Gateway',
        eventType: 'gateway',
        status: 'completed',
      },
      {
        eventName: 'Generate correction invoice',
        eventType: 'task',
        status: 'completed',
      },
      {
        eventName: 'Generate policy addendum document',
        eventType: 'task',
        status: 'completed',
      },
      {
        eventName: 'Notify customer',
        eventType: 'task',
        status: 'failed',
      },
    ],
  },
  {
    id: 2251799813685872,
    status: 'failed',
    workFlowId: 3,
    startTime: dayjs( '26.05.2021 20:22:44', workFlowDateFormat	),
    endTime: dayjs( '26.05.2021 20:23:45', workFlowDateFormat	),
    version: 'Version 1',
    instances: [
      {
        eventName: 'Policy updated',
        eventType: 'start',
        status: 'completed',
      },
      {
        eventName: 'Correct invoices',
        eventType: 'task',
        status: 'failed',
      },
      {
        eventName: 'Gateway',
        eventType: 'gateway',
      },
      {
        eventName: 'Generate correction invoice',
        eventType: 'task',
      },
      {
        eventName: 'Generate policy addendum document',
        eventType: 'task',
      },
      {
        eventName: 'Notify customer',
        eventType: 'task',
      },
    ],
  },
  {
    id: 2251799813685873,
    status: 'failed',
    workFlowId: 3,
    startTime: dayjs( '27.05.2021 18:22:44', workFlowDateFormat	),
    endTime: dayjs( '27.05.2021 18:23:45', workFlowDateFormat	),
    version: 'Version 1',
    instances: [
      {
        eventName: 'Policy updated',
        eventType: 'start',
        status: 'completed',
      },
      {
        eventName: 'Correct invoices',
        eventType: 'task',
        status: 'completed',
      },
      {
        eventName: 'Gateway',
        eventType: 'gateway',
        status: 'completed',
      },
      {
        eventName: 'Generate correction invoice',
        eventType: 'task',
        status: 'completed',
      },
      {
        eventName: 'Generate policy addendum document',
        eventType: 'task',
        status: 'failed',
      },
      {
        eventName: 'Notify customer',
        eventType: 'task',
      },
    ],
  } ];


export const mockedWorkFlowInstanceItems: IWorkFlowInstanceItem[] = [
  {
    id: 1,
    instanceId: 'Business Process for POLICY_CREATED event',
    version: 'Version 1',
    data: mockedFlow1,
    activeInstances: 2,
    instancesWithIncidents: 5,
  },
  {
    id: 2,
    instanceId: 'Business Process for LEAD_CREATED event',
    version: 'Version 1',
    data: mockedFlow2,
    activeInstances: 5,
    instancesWithIncidents: 0,
  },
  {
    id: 3,
    instanceId: 'Business Process for POLICY_UPDATED event',
    version: 'Version 1',
    data: mockedFlow3,
    activeInstances: 0,
    instancesWithIncidents: 5,
  },
];
