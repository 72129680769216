import React, { ReactElement, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col, Form } from 'react-bootstrap';
import { useTimeFilterDateFormat } from 'App/components/utils/hooks';
import dayjs from 'dayjs';
import { TimeFilter } from '../../DocumentsTimeFilter';

export interface RelativeTimeRange {
  today: dayjs.Dayjs;
  weekStart: dayjs.Dayjs;
  weekEnd: dayjs.Dayjs;
  startOfMonth: dayjs.Dayjs;
  endOfMonth: dayjs.Dayjs;
  previousMonthStart: dayjs.Dayjs;
  previousMonthEnd: dayjs.Dayjs;
}

interface TimeFilterPageState {
  title: string;
  timeFilter: TimeFilter;
}

export interface RelativeTimeFilterProps {
  dateRangeHandler: ( e: React.ChangeEvent<HTMLInputElement> ) => void;
  isChecked: boolean;
  timeRange: RelativeTimeRange;
}

const RelativeTimeFilter: React.FC<RelativeTimeFilterProps> =
( props: RelativeTimeFilterProps ): ReactElement | null => {
  const { t } = useTranslation( [ 'policy' ] );
  const { dateFormat } = useTimeFilterDateFormat();
  const { dateRangeHandler, isChecked, timeRange } = props;

  const { today, weekStart, weekEnd, startOfMonth, previousMonthStart, previousMonthEnd } = timeRange;

  const relativeTimeFilter = React.useMemo( () => {
    return (
      <div>
        <Form.Group as={ Row }>
          <Col md={ 5 } className="p-0">
            <Form.Check
              type="radio"
              value="today"
              label={ t( 'documents.today' ) }
              className="radio radio-primary d-inline text-c-black mb-2"
              name="dateRange"
              id="todayRange"
              onChange={ dateRangeHandler }
            />
          </Col>
          <Col md={ 7 } className="text-c-grey">
            { today.format( dateFormat ) }
          </Col>
        </Form.Group>
        <Form.Group as={ Row }>
          <Col md={ 5 } className="p-0">
            <Form.Check
              type="radio"
              value="lastWeek"
              label={ t( 'documents.lastWeek' ) }
              className="radio radio-primary d-inline text-c-black mb-2"
              name="dateRange"
              id="lastWeekRange"
              onChange={ dateRangeHandler }
            />
          </Col>
          <Col md={ 7 } className="text-c-grey">
            { `${ weekStart.format( dateFormat ) } - ${ weekEnd.format( dateFormat ) }` }
          </Col>
        </Form.Group>
        <Form.Group as={ Row }>
          <Col md={ 5 } className="p-0">
            <Form.Check
              type="radio"
              value="currentMonth"
              label={ t( 'documents.currentMonth' ) }
              className="radio radio-primary d-inline text-c-black mb-2"
              name="dateRange"
              id="currentMonthRange"
              onChange={ dateRangeHandler }
            />
          </Col>
          <Col md={ 7 } className="text-c-grey">
            { `${ startOfMonth.format( dateFormat ) } - ${ today.format( dateFormat ) }` }
          </Col>
        </Form.Group>
        <Form.Group as={ Row }>
          <Col md={ 5 } className="p-0">
            <Form.Check
              type="radio"
              value="lastMonth"
              label={ t( 'documents.lastMonth' ) }
              className="radio radio-primary d-inline text-c-black mb-2"
              name="dateRange"
              id="lastMonthRange"
              onChange={ dateRangeHandler }
            />
          </Col>
          <Col md={ 7 } className="text-c-grey">
            { `${ previousMonthStart.format( dateFormat ) } - ${ previousMonthEnd.format( dateFormat ) }` }
          </Col>
        </Form.Group>
        <Form.Group as={ Row }>
          <Col md={ 5 } className="p-0">
            <Form.Check
              type="radio"
              value="all"
              label={ t( 'documents.allDates' ) }
              name="dateRange"
              className="radio radio-primary d-inline text-c-black mb-2"
              id="allRange"
              checked={ isChecked }
              onChange={ dateRangeHandler }
            />
          </Col>
        </Form.Group>
      </div>
    );
  }, [ dateFormat, dateRangeHandler, isChecked, previousMonthEnd,
    previousMonthStart, startOfMonth, t, today, weekEnd, weekStart ] );

  return (
    <Fragment>
      { relativeTimeFilter }
    </Fragment>
  );
};

export default RelativeTimeFilter;
