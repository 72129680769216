import React, { PropsWithChildren, ReactElement, useCallback } from 'react';
import { Form, Button } from 'react-bootstrap';
import { useTable,
  useFilters,
  useSortBy,
  useRowSelect,
  Column,
  TableColumn,
  useResizeColumns,
  Row,
} from 'react-table';
import { FixedSizeList } from 'react-window';
import { Data } from '../makeData';
import { useTranslation } from 'react-i18next';
import { OrderByPage, FilterPage } from 'Services/base';

import emptyBoxIcon from 'assets/widget-images/empty-box.svg';
import { useLoadingSpinnerOnFullContainer } from './utils/LoadingSpinner';
import { isNumber } from 'lodash';

export const rowsCountForScroll = 5;

export interface DataTableProps<D extends object> {
  columns: Column<D>[];
  data: D[];
  onSelectionChanged?: ( items: any[] ) => void;
  onResetFilters: () => void;
  onChangeSort: ( s: OrderByPage[] ) => void;
  onChangeFilters: ( f: FilterPage ) => void;
  initialFilters;
  initialSortBy;
  loadMore: ( ) => void;
  hasNextPage?: boolean;
  moreItemsLoading?: boolean;
  isShowFilters?: boolean;
  footer?: boolean;
  height?: number;
  dynamicallyCalculateTableHeight?: boolean;
  rowHeight?: number;
  prepareRows?: ( rows: Row<D>[] ) => Row<D>[];
  headerGroupClassName?: string;
}

export const InfiniteTable = <T extends object = Data, >(
  props: PropsWithChildren<DataTableProps<T>>,
  context?: any,
): ReactElement | null => {
  const {
    columns,
    data,
    loadMore,
    moreItemsLoading,
    isShowFilters=true,
    footer = true,
    height = 500,
    dynamicallyCalculateTableHeight = true,
    onSelectionChanged,
    onResetFilters,
    onChangeFilters,
    onChangeSort,
    initialFilters,
    initialSortBy,
    rowHeight = 55,
    prepareRows,
    headerGroupClassName,
  } = props;
  const { t } = useTranslation( [ 'base' ] );
  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows: dataRows,
    prepareRow,
    setAllFilters,
    setFilter,
    selectedFlatRows,
  } = useTable<T>(
    {
      columns,
      data,
      initialState: { sortBy: initialSortBy, filters: initialFilters },
      disableMultiSort: true,
      manualSorting: true,
      manualFilters: true,
    },
    useFilters,
    useSortBy,
    useResizeColumns,
    useRowSelect,
  );

  const rows = ( prepareRows ? prepareRows( dataRows ) : dataRows );
  const loadingOnFullContainer = useLoadingSpinnerOnFullContainer();
  const infiniteList = React.useRef<HTMLDivElement>( null );
  const loadMoreItems = moreItemsLoading ? () => { } : loadMore;
  const scrollCallback = React.useCallback( ( e ) => {
    const el = e.target;
    if( el.scrollTop + el.clientHeight >= ( el.scrollHeight - 20 ) ) {
      loadMoreItems();
    }
  }, [ loadMoreItems ] );

  React.useEffect( () => {
    if ( onSelectionChanged !== undefined ) {
      onSelectionChanged( selectedFlatRows.map( ( row ) => row.original ) );
    }

    const list = infiniteList.current;

    if ( list ) {
      list.addEventListener( 'scroll', scrollCallback );
    }
    return () => {
      if ( list ) {
        list.removeEventListener( 'scroll', scrollCallback );
      }
    };
  }, [ selectedFlatRows, onSelectionChanged, scrollCallback ] );

  const resetFilters = useCallback( () => {
    setAllFilters( [] );
    onResetFilters();
  }, [ onResetFilters, setAllFilters ] );

  const changeFilterData = useCallback( ( columId, updater ) => {
    setFilter( columId, updater );
    const filterField: FilterPage = {
      id: columId,
      value: updater,
    };
    onChangeFilters( filterField );
  }, [ onChangeFilters, setFilter ] );

  const changeSortData = useCallback( ( column ) => {
    if ( !column.canSort ) {
      return;
    }

    if ( !column.isSorted ) {
      column.isSorted = true;
      column.isSortedDesc = false;
    } else {
      column.isSortedDesc = column.isSortedDesc ? undefined : !column.isSortedDesc;
    }

    if ( column.isSortedDesc === undefined ) {
      column.isSorted = false;
      onChangeSort( [ ] );
    } else {
      const sortField: OrderByPage = {
        id: column.id,
        desc: column.isSortedDesc,
      };
      onChangeSort( [ sortField ] );
    }
  }, [ onChangeSort ] );

  // Recalculation table height when the sum of the height of the rows is less than the height of the table
  let tableHeight: number = height;
  if( rows.length > 0 ) {
    const heightRows = rowHeight * rows.length;

    if ( dynamicallyCalculateTableHeight && heightRows < tableHeight ) {
      tableHeight = heightRows;
    }
  }

  const RenderRow = useCallback(
    ( { index, style } ) => {
      const row = rows[index];
      prepareRow( row );

      return (
        <div id={ `table-row-${index}` } className={ index % 2 ? 'table-row-odd' : 'table-row-even' }
          { ...row.getRowProps( {
            style,
          } ) }
        >
          { row.cells.map( ( cell, keyCell ) => {
            const columnMinWidth = !isNumber( cell.column.width ) ? `${cell.column.minWidth}%`: undefined;
            return (
              <div
                key={ keyCell }
                id={ `cell_box_${cell.column.id}_${index}` }
                className={ `custom-table-cell cell_box_${cell.column.id}` }
                { ...cell.getCellProps( {
                  style: {
                    ...( cell.column.width && { width: cell.column.width } ),
                    ...( columnMinWidth && { 'minWidth': columnMinWidth } ),
                  },
                } ) }
              >
                { cell.render( 'Cell' ) }
              </div>
            );
          } ) }
        </div>
      );
    },
    [ prepareRow, rows ],
  );

  // Render the UI for your table
  return (
    <div className="table-list custom-table-infinite">
      <div className="table-wrap">
        { moreItemsLoading && ( <div className="list-loading-box">{ loadingOnFullContainer }</div> ) }
        { isShowFilters ?
          <div { ...getTableProps() } className="custom-table table-filter">
            <div className="custom-table-row table-header">
              { headerGroups.map( ( headerGroup, idX ) => (
                <div key={ idX } className="custom-table-heading" { ...headerGroup.getHeaderGroupProps() }>
                  { headerGroup.headers.map( ( column, index ) => (
                    <div key={ index } className="custom-table-head" { ...column.getHeaderProps() }>
                      <Form.Label id={ 'filter_name_' + column.id }>{ column.render( 'Header' ) }</Form.Label>
                      { column.canFilter ?
                        <Form.Group>
                          <input className="form-control"
                            value={ column.filterValue || '' }
                            name={ column.id }
                            id={ 'filter_input_' + column.id || '' }
                            onChange={ ( e ) => {
                              changeFilterData( column.id, e.target.value );
                            } }
                          />
                        </Form.Group> : '' }
                    </div>
                  ) ) }
                  <Button id="reset-filter" className="float-right filter-reset" variant="secondary"
                    disabled={ !initialFilters.length }
                    onClick={ resetFilters }
                  >
                    { t( 'tables.buttonReset' ) }
                  </Button>
                </div>
              ) ) }
            </div>
          </div> : '' }
        <div { ...getTableProps() } className="custom-table">
          <div className="custom-table-row table-header">
            { headerGroups.map( ( headerGroup, keyH ) => (
              <div
                key={ keyH }
                className={ `custom-table-heading ${headerGroupClassName ? headerGroupClassName : ''}` }
                { ...headerGroup.getHeaderGroupProps() }
              >
                { headerGroup.headers.map( ( c, idX ) => {
                  const column = ( c as unknown ) as TableColumn<Data>;
                  let iconSort: React.ReactNode;
                  if ( column.canSort ) {
                    iconSort = (
                      <span className={ column.isSorted ?
                        column.isSortedDesc ? 'sort-desc' : 'sort-asc' : 'sort-disable' }
                      >
                      </span>
                    );
                  }

                  const columnMinWidth = !isNumber( column.width ) ? `${column.minWidth}%`: undefined;

                  return (
                    <div id={ 'head_box_' + column.id } key={ idX } { ...column.getHeaderProps( {
                      style: {
                        ...( column.width && { width: column.width } ),
                        ...( columnMinWidth && { 'minWidth': columnMinWidth } ) },
                    } ) }
                    className={ column.canSort ? 'custom-table-head sort-cursor' : 'custom-table-head' }
                    onClick={ () => changeSortData( column ) }
                    >
                      <span id={ 'head_name_' + column.id }>{ column.render( 'Header' ) }</span>
                      { iconSort }
                    </div>
                  );
                } ) }
              </div>
            ) ) }
          </div>
          <div className="custom-table-row table-content" { ...getTableBodyProps() }>
            { rows.length > 0 ?
              <FixedSizeList
                outerRef={ infiniteList }
                className="infinite-list"
                height={ tableHeight }
                width='100%'
                itemCount={ rows.length }
                itemSize={ rowHeight }
              >
                { RenderRow }
              </FixedSizeList>
              :
              <div className="text-center mt-4 mb-4">
                <div>
                  <img src={ emptyBoxIcon } alt="No data" />
                </div>
                <div className="mt-3">
                  { t ( 'messages.noData' ) }
                </div>
              </div>
            }
          </div>
          { footer && rows.length > 0 &&
            <div className="custom-table-row table-footer">
              { headerGroups.map( ( headerGroup, keyF ) => (
                <div key={ keyF } className="custom-table-heading" { ...headerGroup.getHeaderGroupProps() }>
                  {
                    headerGroup.headers.map( ( column, columId ) => {
                      const columnMinWidth = !isNumber( column.width ) ? `${column.minWidth}%`: undefined;

                      return (
                        <div id={ 'footer_box_' + column.id } key={ columId }
                          className="custom-table-head" { ...column.getHeaderProps(
                            {
                              style: {
                                ...( column.width && { width: column.width } ),
                                ...( columnMinWidth && { 'minWidth': columnMinWidth } ),
                              },
                            },
                          )
                          }
                        >
                          { column.render( 'Header' ) }
                        </div>
                      );
                    } )
                  }
                </div>
              ) ) }
            </div> }
        </div>
      </div>
    </div>
  );
};
