import React, { Fragment } from 'react';
import { IAppEnvConfig, ConfigType } from './interfaces';
import Loader from 'App/layout/Loader';

export const loadConfig = async ( ): Promise<IAppEnvConfig> => {
  const configName: string = process.env.REACT_APP_CONFIG ? process.env.REACT_APP_CONFIG : 'prod';
  return loadConfigByName( configName as ConfigType );
};

export const loadConfigByName = async ( configName: ConfigType ): Promise<IAppEnvConfig> => {
  const val = await import( `config/env/${configName}` );
  return val.default as IAppEnvConfig;
};

export const AppConfigContext: React.Context<IAppEnvConfig> = React.createContext( undefined as any );

export const AppConfigProvider: React.FC = ( props ) => {
  const [ config, setConfig ] = React.useState<IAppEnvConfig>( undefined as any );
  React.useEffect( () => {
    loadConfig().then( ( c ) => {
      setConfig( c );
    } );
  }, [] );

  return (
    <Fragment>
      { config === undefined
        ? ( <Loader /> )
        : (
          <AppConfigContext.Provider value={ config }>
            { props.children }
          </AppConfigContext.Provider>
        )
      }
    </Fragment>
  );
};

export const useAppConfig = (): IAppEnvConfig => {
  return React.useContext( AppConfigContext );
};
