import React from 'react';
import { IApiService, PagingRequest, ApiResponse } from './base';
import { RoleItem, DataResponse, RoleResponse, IRoleItem, IModule,
  IUpdateRoleItem, IGroupDetails } from './api/role/base';
import { mockedRole } from './api/role/mock-data';
import { IApiListResponse } from './policies/new/service';
import { IUserDetailsItem } from './api/users/base';

export interface IRoleService {
  getRolesData( moduleFilter: IModule[], roleFilter: string, paging: PagingRequest ):
  Promise<IApiListResponse<IRoleItem>>;
  addRole( role: RoleItem ): Promise<RoleResponse>;
  updateRole( updateRole: IUpdateRoleItem, roleId: number ): Promise<IRoleItem>;
  getRoleGroupsData( paging: PagingRequest, groupIds: number[] ): Promise<IApiListResponse<IGroupDetails>>;
  getRoleUsersData( searchQuery: string, userIds: number[], paging: PagingRequest ):
  Promise<IApiListResponse<IUserDetailsItem>>;
  createRole( createRole: IUpdateRoleItem ): Promise<IRoleItem>;
  deleteRole( roleId: number ): Promise<DataResponse>;
  deleteRoleGroup( groupId: number, roleId: number ): Promise<DataResponse>;
  deleteRoleUsers( userIds: number[], roleId: number ): Promise<ApiResponse>;
  deleteRoleUser( userId: number, roleId: number ): Promise<DataResponse>;
  getRole( roleId: number ): Promise<IRoleItem>;
  moveTo( userIds: number[], newRoleId: number, oldRoleId: number ): Promise<ApiResponse>;
}

export class RoleService implements IRoleService {

  protected api: IApiService;

  constructor( api: IApiService ) {
    this.api = api;
  }

  getRolesData( moduleFilter: IModule[], roleFilter: string, paging: PagingRequest ):
  Promise<IApiListResponse<IRoleItem>> {
    throw new Error( 'Method getRolesData not implemented.' );
  }

  getRoleGroupsData( paging: PagingRequest ): Promise<IApiListResponse<IGroupDetails>> {
    throw new Error( 'Method getGroupsData not implemented.' );
  }

  getRoleUsersData( searchQuery: string, userIds: number[], paging: PagingRequest ):
  Promise<IApiListResponse<IUserDetailsItem>> {
    throw new Error( 'Method getRoleUsersData not implemented.' );
  }

  getRole( roleId: number ): Promise<IRoleItem> {
    throw new Error( 'Method getRole not implemented.' );
  }

  getRoles( roleId: number ): Promise<IRoleItem> {
    throw new Error( 'Method getRole not implemented.' );
  }

  moveTo( userIds: number[], newRoleId: number, oldRoleId: number ): Promise<ApiResponse> {
    throw new Error( 'Method moveTo not implemented.' );
  }

  addRole( Role: RoleItem ): Promise<RoleResponse> {
    // TODO Implement later because backend is not ready yet
    /*
    const result = this.api.request<RoleResponse>( new AddRoleRequest( Role ) )
      .then( ( response ) => {

      } );
    */
    const res: RoleResponse = {
      item: mockedRole,
      message: 'Ok',
      success: true,
    };
    return Promise.resolve( res );
  }

  updateRole( updateRole: IUpdateRoleItem, roleId: number ): Promise<IRoleItem> {
    throw new Error( 'Method updateRole not implemented.' );
  }

  createRole( createRole: IUpdateRoleItem ): Promise<IRoleItem> {
    throw new Error( 'Method updateRole not implemented.' );
  }

  deleteRoleGroup( groupId: number, roleId: number ): Promise<DataResponse> {
    throw new Error( 'Method deleteRoleGroup not implemented.' );
  }

  deleteRoleUser( userId: number, roleId: number ): Promise<DataResponse> {
    throw new Error( 'Method deleteRoleUser not implemented.' );
  }

  deleteRoleUsers( userIds: number[], roleId: number ): Promise<ApiResponse> {
    throw new Error( 'Method deleteRoleUsers not implemented.' );
  }

  deleteRole( roleId: number ): Promise<DataResponse> {
    // TODO Implement later because backend is not ready yet
    /*
    const result = this.api.request<DataResponse>( new DeleteRoleRequest( roleId ) )
      .then( ( response ) => {
        
      } );
    */
    const res: DataResponse = {
      message: 'Ok',
      success: true,
    };
    return Promise.resolve( res );
  }
};

export const RoleServiceContext: React.Context<IRoleService> = React.createContext( undefined as any );

export const useRolesService = (): IRoleService => {
  return React.useContext( RoleServiceContext );
};
